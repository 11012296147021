import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CreateOrderRequest, EdenredSigninResponse, Order, OrderPreparation, OrderProcessingState, OrderProcessingStep, SwileSigninResponse } from '@app/core/api-client/models';
import { ApiOrdersService } from '@app/core/api-client/services';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ErrorsHelper } from '@app/core/helpers/errors-helper';
import { Logger, LogService } from '@app/core/logging';
import { PageVisibilityService } from '@app/core/ui/page-visibility/page-visibility.service';
import { CommandDetailSheetComponent } from '@app/features/command/components/command-detail-sheet/command-detail-sheet.component';
import { OrderPreparationDialogComponent, OrderPreparationDialogParameters, OrderPreparationDialogResult } from '@app/features/table-service/components/order-preparation-dialog/order-preparation-dialog.component';
import { merge, Observable, of, timer, tap, throttleTime, interval, throwError, asyncScheduler } from 'rxjs';
import { catchError, filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';
import { OrderState } from './state/order.state';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private _log: Logger;

  constructor(
    private _apiOrdersService: ApiOrdersService,
    @Inject(DOCUMENT) private _document: Document,
    private _navigationService: NavigationService,
    private _bottomSheet: MatBottomSheet,
    private _matDialog: MatDialog,
    private _orderState: OrderState,
    private _pageVisibilityService: PageVisibilityService,
    private _authenticationService: AuthenticationService,
    logService: LogService
  ) {
    this._log = logService.getLogger('OrderService');

    this.monitorLastOrderWithPendingPreparationChanges();
  }

  public getOrders(limit: number, offset: number): Observable<Order[]> {
    return this._apiOrdersService.GetOrders$Json({
      Limit: limit,
      Offset: offset,
    })
      .pipe(
        first()
      );
  }

  public getDailyPreparationOrders$(): Observable<Order[]> {
    return this._apiOrdersService.GetOrders$Json({
      Limit: 10,
      OnlyDailyPreparationOrders: true
    })
      .pipe(
        first()
      );
  }

  public getOrdersCount(): Observable<number> {
    return this._apiOrdersService.GetOrdersCount$Json()
      .pipe(
        first()
      );
  }

  public getOrder(orderId: number) {
    return this._apiOrdersService.GetOrder$Json({ orderId })
      .pipe(
        first()
      );
  }

  /**
   * Process payment actions if needed (other checkouts or display order confirmation)
   * returns True if no payment action is requested
   */
  public processOrderActions(order: Order, returnToMenuPage = true): Observable<boolean> {
    // check if order has a requestedAction
    const requestedAction = order.payments?.map(p => p.requestedAction).filter(a => a != null)[0];
    if (requestedAction) {
      const redirectMethod = requestedAction.redirectMethod;
      if (redirectMethod?.toUpperCase() === 'POST') {
        // setTimeout to let subscriber complete gracefuly
        setTimeout(() => {
          this.doFormPost(requestedAction.redirectUrl, requestedAction.redirectData, this._document);
        }, 100);
      }
      else if (redirectMethod?.toUpperCase() === 'GET') {
        // TODO : Dans ce cas on ne gère pas les éventuels requestedAction.redirectData
        this._document.location.href = requestedAction.redirectUrl;
      }
      else {
        throw new Error(`Unhandled OrderPaymentRequestedAction (redirectMethod=${redirectMethod})`);
      }
      return of(false);
    }
    else {

      return (returnToMenuPage
        ? this._navigationService.navigateToMenu$(order.restaurant, { replaceUrl: true })
        : of(true))
        .pipe(
          switchMap(() => {

            // handle TableService
            if (order.processingState?.step === OrderProcessingStep.PendingPreparation) {
              //do not way for dialog closed: => return of(true);
              this.displayOrderTableServiceDialog(order);
              return of(true);
            }
            else {
              this.displayOrderBottomSheet(order);
              return of(true);
            }

          }),
          // order may be updated
          tap(() => this._orderState.notifyOrderUpdated(order)),
        );
    }
  }

  private doFormPost(url: string, formValues: { [key: string]: string }, document: Document) {
    document = document || this._document;
    const form = document.createElement('form');
    const formId = Date.now().toString(10);

    form.setAttribute('id', formId);
    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');

    // eslint-disable-next-line guard-for-in
    for (const key in formValues) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.value = formValues[key];

      form.appendChild(input);
    }

    document.body.appendChild(form);

    const formElement = document.getElementById(formId) as HTMLFormElement;

    formElement.submit();
  }

  public displayOrderBottomSheet(order: Order) {

    // if order detail bootom sheet is already open, swap its Order data else open a new BottomSheet
    const currentOrderDetailSheet = this._bottomSheet._openedBottomSheetRef?.instance instanceof CommandDetailSheetComponent
      ? this._bottomSheet._openedBottomSheetRef.instance
      : null;
    if (currentOrderDetailSheet) {
      currentOrderDetailSheet.data = order;
      currentOrderDetailSheet.cd.markForCheck();
    }
    else {
      const commandSheet = this._bottomSheet.open(
        CommandDetailSheetComponent,
        {
          data: order,
          panelClass: 'order-detail-sheet-bottom-sheet-container',
          hasBackdrop: false
        }
      );
    }

  }

  public displayOrderTableServiceDialog(order: Order) {

    const dialogConfig = new MatDialogConfig<OrderPreparationDialogParameters>();
    dialogConfig.maxHeight = '90vh';
    dialogConfig.data = { order };
    const dialogRef = this._matDialog.open<OrderPreparationDialogComponent, OrderPreparationDialogParameters, OrderPreparationDialogResult>(OrderPreparationDialogComponent, dialogConfig);

    return dialogRef;
  }

  public signinEdenredAndPay(edenredSigninResponse: EdenredSigninResponse): Observable<Order> {
    return this._apiOrdersService.EdenredSignin$Json({ body: edenredSigninResponse })
      .pipe(
        first(),
        tap(order => this._orderState.notifyOrderUpdated(order)),
      );
  }

  public signinSwileAndPay(swileSigninResponse: SwileSigninResponse): Observable<Order> {
    return this._apiOrdersService.SwileSignin$Json({ body: swileSigninResponse })
      .pipe(
        first(),
        tap(order => this._orderState.notifyOrderUpdated(order)),
      );
  }

  public setOrderDestination(order: Order, destination: string): Observable<Order> {
    return this._apiOrdersService.SetOrderDestination$Json({
      body: {
        orderId: order.orderId,
        siteId: order.siteId,
        destination: destination
      }
    })
      .pipe(
        first(),
        tap(order => this._orderState.notifyOrderUpdated(order)),
      );
  }

  public sendOrdersToPreparation(orderPreparationId: number): Observable<OrderProcessingState> {
    return this._apiOrdersService.SendOrdersToPreparation$Json(
      {
        orderPreparationId
      }
    )
      .pipe(
        first(),
        tap(order => this._orderState.notifyOrderUpdated(null)), // can be multiple orders
      );
  }

  public sendCustomerOrdersToPreparation(orderPreparationId: number): Observable<OrderProcessingState> {
    return this._apiOrdersService.SendCustomerOrdersToPreparation$Json(
      {

        orderPreparationId

      }
    )
      .pipe(
        first(),
        tap(order => this._orderState.notifyOrderUpdated(null)), // can be multiple orders
      );
  }

  public getOrderPreparation(orderPreparationId: number): Observable<OrderPreparation> {
    return this._apiOrdersService.GetOrderPreparation$Json(
      {
        orderPreparationId
      }
    )
      .pipe(first());
  }

  private loadLastOrderWithPendingPreparation(): Observable<Order> {
    return this._apiOrdersService.GetLastOrderWithPendingPreparation$Json()
      .pipe(
        first(),
        // catch 404 to return null
        catchError((responseError: unknown) => {
          if (ErrorsHelper.isHttpRequestError(responseError) && responseError.status === 404) {
            return of(null);
          }
          return throwError(responseError); // forward error down Subscription tree ()
        }),
      );
  }

  public getLastOrderWithPendingPreparation$(): Observable<Order> {
    return this._orderState.getLastOrderWithPendingPreparation$();
  }

  /// Reloads LastOrderWithPendingPreparation when an order has been created / updated or after an interval
  private monitorLastOrderWithPendingPreparationChanges(): void {

    merge(
      //when an order has been created / updated
      this._orderState.getOrderUpdated$().pipe(map(() => 'OrderUpdated notification')),
      // after an interval of 15min
      interval(900_000) // ms = 15min
        .pipe(
          filter(() => this._pageVisibilityService.isPageVisible()), // only when page is visible
          map(() => 'Interval expired')
        ),
      //Reload also when app is resumed
      this._pageVisibilityService.$onPageVisible.pipe(
        throttleTime(30_000), // 30s
        map(() => 'PageVisible')
      ),
      this._authenticationService.currentUser$.pipe(map(user => user ? 'User logged in' : 'User logged out'))
    )
      .pipe(
        // switchMap(reloadCause => {
        //   if(reloadMenuCause === 'User logged in' || reloadMenuCause === 'User logged out') {
        //     return this.loadLastOrderWithPendingPreparation();
        //   }
        // }),
        throttleTime(1_000, asyncScheduler, { leading: false, trailing: true }), // 1s throttle time to avoid multiple reloads when page is visible and user is logged in at the app loading

        tap(reloadCause => {
          console.info('🗘 LastOrderWithPendingPreparation reload : ' + reloadCause);
        }),
        //load from API, only if user is logged (else we set null)
        withLatestFrom(this._authenticationService.currentUser$),
        switchMap(([reloadCause, user]) => user ? this.loadLastOrderWithPendingPreparation() : of(null)),
        //update state
        switchMap(order => this._orderState.setLastOrderWithPendingPreparation$(order)),
      )
      .subscribe();

  }

  public setDefaultDestination(destination: string | null) {
    this._orderState.setDestination$(destination).subscribe();
  }

  public getDefaultDestination$(): Observable<string | null> {
    return this._orderState.getDestination$();
  }

}
