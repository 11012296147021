<ul *ngIf="!loadingOrderPreparation"
    class="category-list">
  <li *ngFor="let category of productCategories"
      class="category-item">
    <span class="category-name">{{ category.name }}</span>
    <ul class="product-list">
      <li *ngFor="let product of category.products"
          class="product-item"
          [class.served-or-prepared]="product.status === 'State3_ServedOrPrepared'">
        <span class="product-name">{{ product.name }}</span>
        <span class="product-quantity">x{{ (product.quantity >= 10 ? '': ' ') + product.quantity }}</span>
      </li>
    </ul>
  </li>
</ul>
<app-loader *ngIf="loadingOrderPreparation"></app-loader>
