import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
// import { catchOffline } from '../network/network.service';
import { AuthenticationService } from './authentication.service';
import { Logger } from '../logging';

// inspiré de https://stackblitz.com/angular/nvpaqgbrkeme?file=src%2Fapp%2Fauth%2Fauth.guard.ts
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url: string = state.url;
    return this.checkLogin(url);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  private checkLogin(url: string): Observable<boolean> {
    // Accès autorisé si l'utilisateur est logué, ou, à défaut de réseau et qu'il y ait un user authentifié précédemment (mode offline)
    return this.authenticationService.isLoggedIn$()
      .pipe(
        // catchOffline(error => {
        //   // pas de réseau => il y a un user authentifié précédemment
        //   // return this._authenticationService.currentUser$
        //   //   .pipe(
        //   //     first(), // pour compléter dès la première valeur
        //   //     map(user => {
        //   //       return user !== null; // il y a un user authentifié précédemment (mode offline)
        //   //     })
        //   //   );
        // }),
        switchMap(allowed => {
          if (!allowed) {
            // accès non authentifié, on redirige vers le login en stockant l'url de redirection
            return this.authenticationService.signOut$(true, url)
              .pipe(map(() => false));
          }
          return of(true);
        })
      );
  }
}
