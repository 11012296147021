<div class="signin-page mat-typography custom-input"
     fxLayout="column"
     fxLayoutAlign="space-between stretch">
  <!-- Signin page Header-->
  <div class="landing-page-header"
       [ngClass.gt-xs]="{'full-size': true}"
       fxFlex>
    <app-header imageUrl="./assets/imgs/backgrounds/signin.jpg">
      <app-back-button></app-back-button>
      <app-logo class="site-search-page-logo"
                fxHide.gt-xs></app-logo>
    </app-header>
  </div>

  <!-- Login form-->
  <div class="login-container"
       [ngClass.gt-xs]="{'centered': true}">
    <app-signin-form (signIn)="signIn($event)"
                     (signUp)="signUp()"
                     (goBack)="goBack()"
                     [disabled]="isLoading"
                     [showGoToMenuButton]="showGoToMenuButton"
                     (goToMenu)="goToMenu()"></app-signin-form>
  </div>
</div>
