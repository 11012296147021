import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { DOCUMENT, isPlatformServer } from '@angular/common';

class HiddenKeyConstant {
  static DEFAULT = "hidden";
  static MS = "msHidden";
  static WEB_KIT = "webkitHidden";
}

class VisibilityStatusConstant {
  static VISIBLE = "visible";
  static HIDDEN = "hidden";
  static PRERENDER = "prerender";
  static UNLOADED = "unloaded";
}

export enum PageVisibilityStateEnum {
  VISIBLE,
  HIDDEN,
  PRERENDER,
  UNLOADED
}

//adapted from https://github.com/olivierlsc/angular-page-visibility/blob/master/projects/angular-page-visibility-lib/src/lib/angular-page-visibility.service.ts

@Injectable({
  providedIn: "root"
})
export class PageVisibilityService {
  private _onPageVisibleSource: Subject<void> = new Subject<void>();
  private _onPageHiddenSource: Subject<void> = new Subject<void>();
  private _onPagePrerenderSource: Subject<void> = new Subject<void>();
  private _onPageUnloadedSource: Subject<void> = new Subject<void>();
  private _onPageVisibilityChangeSource: Subject<PageVisibilityStateEnum> = new Subject<PageVisibilityStateEnum>();
  private _hidden: string;
  private _visibilityChange: string;
  private _visibilityState: string;
  readonly $onPageVisible: Observable<void> = this._onPageVisibleSource.asObservable();
  readonly $onPageHidden: Observable<void> = this._onPageHiddenSource.asObservable();
  readonly $onPagePrerender: Observable<void> = this._onPagePrerenderSource.asObservable();
  readonly $onPageUnloaded: Observable<void> = this._onPageUnloadedSource.asObservable();
  readonly $onPageVisibilityChange: Observable<PageVisibilityStateEnum> = this._onPageVisibilityChangeSource.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.addEventListenerVibilityChange();
  }

  isPageVisible(): boolean {
    return (VisibilityStatusConstant.VISIBLE === this.getVisibilityState() || !this.isHidden());
  }

  isPageHidden(): boolean {
    return (VisibilityStatusConstant.HIDDEN === this.getVisibilityState() || this.isHidden());
  }

  isPagePrerender(): boolean {
    return VisibilityStatusConstant.PRERENDER === this.getVisibilityState();
  }

  isPageUnloaded(): boolean {
    return VisibilityStatusConstant.UNLOADED === this.getVisibilityState();
  }

  private isHidden(): boolean {
    return this._document[this._hidden];
  }

  private getVisibilityState(): string {
    return this._document[this._visibilityState];
  }

  private defineBrowserSupport() {
    if (typeof this._document[HiddenKeyConstant.DEFAULT] !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      this._hidden = HiddenKeyConstant.DEFAULT;
      this._visibilityChange = "visibilitychange";
      this._visibilityState = "visibilityState";
    } else if (typeof this._document[HiddenKeyConstant.MS] !== "undefined") {
      this._hidden = HiddenKeyConstant.MS;
      this._visibilityChange = "msvisibilitychange";
      this._visibilityState = "msVisibilityState";
    } else if (typeof this._document[HiddenKeyConstant.WEB_KIT] !== "undefined") {
      this._hidden = HiddenKeyConstant.WEB_KIT;
      this._visibilityChange = "webkitvisibilitychange";
      this._visibilityState = "webkitVisibilityState";
    }
  }

  private addEventListenerVibilityChange(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.defineBrowserSupport();
    this._document.addEventListener(
      this._visibilityChange,
      () => {
        const vibilityState = this.getVisibilityState();
        switch (vibilityState) {
          case VisibilityStatusConstant.VISIBLE:
            this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.VISIBLE);
            this._onPageVisibleSource.next();
            break;
          case VisibilityStatusConstant.HIDDEN:
            this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.HIDDEN);
            this._onPageHiddenSource.next();
            break;
          case VisibilityStatusConstant.PRERENDER:
            this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.PRERENDER);
            this._onPagePrerenderSource.next();
            break;
          case VisibilityStatusConstant.UNLOADED:
            this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.UNLOADED);
            this._onPageUnloadedSource.next();
            break;
          default:
            if (this.isHidden()) {
              this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.HIDDEN);
              this._onPageHiddenSource.next();
            } else {
              this._onPageVisibilityChangeSource.next(PageVisibilityStateEnum.VISIBLE);
              this._onPageVisibleSource.next();
            }
        }
      },
      false
    );
  }
}
