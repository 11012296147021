import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticFileService {
  constructor(private _httpClient: HttpClient) { }


  // Récupérer la page a propos de
  public getAboutPage(lang: string): Observable<string> {
    // return this._httpClient.get('assets/html/about.' + lang + '.html', {
    //   responseType: 'text'
    // });
    return this.getHtmlFromPath('assets/html/cms/bienvenue/index.html');
  }


  // Récuperer la page CGU selon la langue
  public getLegalPage(lang: string): Observable<string> {
    return this._httpClient.get('assets/html/legal.' + lang + '.html', {
      responseType: 'text'
    });
  }


  // Récuperer la page CGV selon la langue
  public getCGVPage(lang: string): Observable<string> {
    return this._httpClient.get('assets/html/legal.' + lang + '.html', {
      responseType: 'text'
    });
  }

  // Récuperer la page Help selon la langue
  public getHelpPage(lang: string): Observable<string> {
    return this._httpClient.get(
      'assets/help-register/help-register.' + lang + '.html',
      { responseType: 'text' }
    );
  }

  // Récupérer une page html
  public getHtmlFromPath(path: string) {
    return this._httpClient.get(path, { responseType: 'text' });
  }
}
