import { Injectable } from '@angular/core';

@Injectable()
export class Searcher {
  private _list: Record<string, string>[] = [];
  private _searchProperty = '';
  private _searchText = '';
  private _previousSearchText = '';
  private _previousInputtype = '';
  private _filteredList: Record<string, string>[] = [];
  private _previousFilteredList: Record<string, string>[] = [];
  private _shouldReturnPreviousFilteredList = true;

  initSearch(list: Record<string, string>[], searchProperties: string[]): void {
    if (searchProperties.length > 1) {
      this._list = list.map(item => (
        { ...item, concatedValues: this.concateValues(item, searchProperties) }
      ));
      this._searchProperty = 'concatedValues';
    } else {
      this._list = list;
      this._searchProperty = searchProperties[0];
    }
    this._previousFilteredList = this._list;
  }

  filterList(inputEvent: InputEvent): Record<string, string>[] | undefined {
    if (inputEvent.data === ' ') {
      return undefined;
    }
    const searchText = (inputEvent.target as HTMLInputElement).value;
    const removeWhitespaces = (text: string) => text.split(' ').join('');
    const searchTextInLowerCase = removeWhitespaces(searchText).toLocaleLowerCase();
    this._searchText = searchTextInLowerCase;

    const list = this.getList();
    this._previousSearchText = searchTextInLowerCase;
    this._previousInputtype = inputEvent.inputType;
    if (!list) {
      this._filteredList = this._previousFilteredList;
      return this._previousFilteredList;
    }
    this._filteredList = list.filter(item =>
      removeWhitespaces(item[this._searchProperty]).toLowerCase().includes(this._searchText));
    return this._filteredList;
  }

  private concateValues(item: Record<string, string>, searchProperties: string[]): string {
    let concatedValues = '';
    searchProperties.forEach(property => concatedValues += item[property]);
    return concatedValues;
  }

  private getList(): Record<string, string>[] | undefined {
    if (this._previousSearchText && this._searchText.includes(this._previousSearchText)) {
      this._previousFilteredList = this._filteredList;
      this._shouldReturnPreviousFilteredList = true;
      return this._filteredList;
    }

    const isLastTextFromPaste = this._previousInputtype === 'insertFromPaste';
    const canReturnPreviousFilteredList = this.isBackSpacedLastChar() &&
      !isLastTextFromPaste && this._shouldReturnPreviousFilteredList;

    if (canReturnPreviousFilteredList) {
      this._shouldReturnPreviousFilteredList = false;
      return undefined;
    }
    return this._list;
  }

  private isBackSpacedLastChar(): boolean {
    const isTextDecrementedBy1 = this._previousSearchText.length - this._searchText.length === 1;
    const lastChar = this._previousSearchText.charAt(this._previousSearchText.length - 1);
    const concatedText = this._searchText + lastChar;
    return isTextDecrementedBy1 && concatedText === this._previousSearchText;
  }
}
