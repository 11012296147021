import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Order } from '@app/core/api-client/models';

export interface OrderPreparationDialogResult {

}

export interface OrderPreparationDialogParameters {
  order: Order;
}

@Component({
  templateUrl: './order-preparation-dialog.component.html',
  styleUrls: ['./order-preparation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<OrderPreparationDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: OrderPreparationDialogParameters
  ) {
  }

  ngOnInit(): void {

  }

  onConfirmed(){
    this._dialogRef.close(true);
  }

}
