import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, interval, of, Subject, timer } from 'rxjs';
import { Menu, RestaurantOrderingSettings } from '@app/core/api-client/models';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { PickupSchedule } from '../models/pickup-schedule.model';
import {
  differenceInMilliseconds,
  // add as addDate,
  // differenceInSeconds,
  format as formatDate,
  // parse as parseDate,
  startOfDay,
  // max as maxDate
} from 'date-fns';
import { DateHelper } from '@app/core/helpers/date-helper';
import _flatten from 'lodash-es/flatten';
import _uniqBy from 'lodash-es/uniqBy';
import _groupBy from 'lodash-es/groupBy';
import _map from 'lodash-es/map';
import { StorageMap } from '@ngx-pwa/local-storage';
import { StringHelper } from '@app/core/helpers/string-helper';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalizationService } from '../../globalization/globalization.service';


interface OrderingMessageEntry {
  hiddenAt: string //Date
}

export interface LoadMenuRequest {
  restaurantId?: number;

  /**
   * Ask to force reload of the menu/cart from server even if the current restaurant is the same
   * null = true by default
   */
  force?: boolean;

  /**
   * Ask to load the stored cart
   * null = true by default
   */
  loadCart?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuState {

  private _updating$ = new BehaviorSubject<boolean>(false);
  private _currentMenu$ = new BehaviorSubject<Menu>(null);
  private _selectedCategoryId$ = new BehaviorSubject<number>(null);
  private _currentMenuRequiresReload$ = new Subject<LoadMenuRequest>();

  constructor(
    private _storage: StorageMap,
    private _translateService: TranslocoService,
    private _globalizationService: GlobalizationService
  ) { }

  setUpdating(isUpdating: boolean) {
    this._updating$.next(isUpdating);
  }

  isUpdating() {
    return this._updating$.value;
  }

  isUpdating$() {
    return this._updating$.asObservable();
  }

  setCurrentMenu(menu: Menu) {
    this._currentMenuRequiresReload$.next({ restaurantId: menu?.restaurant.restaurantId, force: false });
    this._currentMenu$.next(menu);
  }

  //just push same state to refresh UI
  notifyCurrentMenuChanged() {
    this.setCurrentMenu(this.getCurrentMenu())
  }

  getCurrentMenu() {
    return this._currentMenu$.value;
  }

  getCurrentMenu$() {
    return this._currentMenu$.asObservable();
  }

  getCurrentMenuRequiresReload$() {
    return this._currentMenuRequiresReload$.asObservable();
  }

  setCurrentMenuRequiresReload(request?: LoadMenuRequest) {
    this._currentMenuRequiresReload$.next(request ?? { force: true });
  }

  setSelectedCategoryId(categoryId?: number) {
    this._selectedCategoryId$.next(categoryId);
  }

  getSelectedCategory$() {
    return combineLatest([
      this._currentMenu$,
      this._selectedCategoryId$.pipe(distinctUntilChanged())
    ])
      .pipe(
        map(([menu, selectedCategoryId]) => menu?.categories.find(c => c.categoryId === selectedCategoryId))
      );
  }

  // getAvailablePickupSchedules(menu?: Menu): PickupSchedule[] {

  //   menu = menu || this.getCurrentMenu();

  //   const pickupSchedules: PickupSchedule[] = [];
  //   if (!(menu?.pickupSchedules?.schedules?.length > 0)) {
  //     return pickupSchedules;
  //   }

  //   const now = new Date();
  //   const today = startOfDay(now);
  //   const todayOfWeek = formatDate(now, 'EEEE'); // https://date-fns.org/v2.14.0/docs/format
  //   const todayPickupSchedules = menu.pickupSchedules.schedules.filter((ps) => ps.dayOfWeek === todayOfWeek);

  //   // get today (if opened)
  //   if (todayPickupSchedules?.length > 0) {

  //     // apply delay
  //     const delay = parseDate(menu.pickupSchedules.delay || '00:00:00', 'HH:mm:ss', today);
  //     const delaySeconds = differenceInSeconds(delay, today);
  //     const minStartTime = addDate(now, { seconds: delaySeconds });

  //     let dateSlots: Date[] = [];
  //     for (const todayPickupSchedule of todayPickupSchedules) {

  //       const scheduleStart = parseDate(todayPickupSchedule.startTime, 'HH:mm:ss', today);
  //       const scheduleEnd = parseDate(todayPickupSchedule.endTime, 'HH:mm:ss', today);

  //       const start = maxDate([minStartTime, scheduleStart]);

  //       if (scheduleEnd > start) {
  //         dateSlots.push(...DateHelper.eachMinutesOfInterval({ start: start, end: scheduleEnd }, 15));
  //       }
  //     }
  //     // append dateSlots
  //     if (dateSlots.length > 0) {
  //       // unique values
  //       dateSlots = _uniqBy(dateSlots, d => d.getTime());
  //       // sorted
  //       dateSlots = dateSlots.sort((a, b) => a.getTime() - b.getTime());
  //       pickupSchedules.push({ day: today, dateSlots: dateSlots });
  //     }

  //   }

  //   // if preOrdering is enabled, add next opened days
  //   const isPreOrderingEnabled = menu.orderingSettings.isPreorderingEnabled;
  //   if (isPreOrderingEnabled) {
  //     // get next opened days
  //     let nextDayIndex = 1;
  //     while (pickupSchedules.length < menu.pickupSchedules.pickupScheduleDays) {
  //       const nextDay = addDate(today, { days: nextDayIndex++ });
  //       const nextDayOfWeek = formatDate(nextDay, 'EEEE');
  //       const nextDayPickupSchedules = menu.pickupSchedules.schedules.filter((ps) => ps.dayOfWeek === nextDayOfWeek);
  //       if (nextDayPickupSchedules?.length > 0) {
  //         let dateSlots: Date[] = [];
  //         for (const pickupSchedule of nextDayPickupSchedules) {
  //           const start = parseDate(pickupSchedule.startTime, 'HH:mm:ss', nextDay);
  //           const end = parseDate(pickupSchedule.endTime, 'HH:mm:ss', nextDay);
  //           dateSlots.push(...DateHelper.eachMinutesOfInterval({ start: start, end: end }, 15));
  //         }
  //         if (dateSlots.length > 0) {
  //           // unique values
  //           dateSlots = _uniqBy(dateSlots, d => d.getTime());
  //           // sorted
  //           dateSlots = dateSlots.sort((a, b) => a.getTime() - b.getTime());
  //           pickupSchedules.push({ day: nextDay, dateSlots: dateSlots });
  //         }
  //       }
  //     }
  //   }
  //   return pickupSchedules;
  // }

  getAvailableSchedules(menu?: Menu): PickupSchedule[] {
    menu = menu || this.getCurrentMenu();

    const pickupSchedules: PickupSchedule[] = [];
    if (!(menu?.schedules?.length > 0)) {
      return pickupSchedules;
    }

    const now = new Date();

    const schedules = menu.schedules // non passed schedules mapped converted as Date
      .map(s => ({ startTime: new Date(s.startTime), endTime: new Date(s.endTime) }))
      .filter(s => s.endTime > now);

    const dateSlots = // all unique dateSlots intervals sorted
      _uniqBy(
        _flatten(
          schedules.map(s => DateHelper.eachMinutesOfInterval({ start: s.startTime, end: s.endTime }, 15))
        ),
        d => d.getTime()
      )
        .sort((a, b) => a.getTime() - b.getTime())
        .filter(date => date >= now); //eachMinutesOfInterval may round down to past dates

    const availableSchedules = // map to PickupSchedule[] by grouping on Day
      _map(
        _groupBy(dateSlots, d => startOfDay(d)),
        (slots, day) => ({ day: new Date(day), dateSlots: slots } as PickupSchedule)
      );

    return availableSchedules;
  }


  //#region OrderingMessage Visibility

  private computeOrderingMessageCacheKey(orderingMessage: string): string {
    const messageHash = StringHelper.hashCode(orderingMessage);
    return `OrderingMessage:${messageHash.toFixed(0)}`;
  }

  getOrderingMessage$(orderingMessage: string): Observable<string | false> {
    if (!orderingMessage) {
      return of(false);
    }

    const key = this.computeOrderingMessageCacheKey(orderingMessage);
    // if an entry is stored then message is hidden
    return this._storage.get(key).pipe(
      take(1),
      map(r => (!r) ? orderingMessage : false)
    );
  }

  hideOrderingMessage$(orderingMessage: string): Observable<unknown> {
    const key = this.computeOrderingMessageCacheKey(orderingMessage);
    // A message is hidden when an entry is stored
    return this._storage.set(key, { message: orderingMessage, hiddenAt: new Date().toISOString() } as OrderingMessageEntry)
      .pipe(
        take(1)
      );
  }

  //#endregion OrderingMessage Visibility

  //#region ClosingMessage Visibility

  private computeClosingMessageCacheKey(orderingSettings: RestaurantOrderingSettings): string {
    const messageHash = StringHelper.hashCode(`${orderingSettings.openAt}|${orderingSettings.closeAt}`);
    return `ClosingMessage:${messageHash.toFixed(0)}`;
  }

  // Store hidden ClosingMessages in session (variable in service)
  // these messages are rare, so it is acceptable to store them in memory rather than in the sessionStorage
  private _hiddenClosingMessages: { [hash: string]: OrderingMessageEntry | undefined } = {}

  getClosingMessage$(orderingSettings: RestaurantOrderingSettings): Observable<{ hidden: boolean, message: string, key: string } | false> {
    if (!orderingSettings) {
      return of(false)
    }
    const key = this.computeClosingMessageCacheKey(orderingSettings);

    return timer(0, 10_000) // check every 10s
      .pipe(
        switchMap(_ => {
          // if an entry is stored then message is hidden
          const hidden = (this._hiddenClosingMessages[key] != null);

          //else, compute message
          return of(orderingSettings?.isOpen === false && orderingSettings?.isOpenMessage || '')
            .pipe(
              // append closing warning message
              switchMap(closingMessage => {
                const closingDelay = orderingSettings.closeAt && differenceInMilliseconds(new Date(orderingSettings.closeAt), new Date());
                if (closingDelay != null && closingDelay <= 300_000) //300_000ms = 5min
                {
                  const closeAtDate = new Date(orderingSettings.closeAt);
                  return combineLatest([
                    this._translateService.selectTranslate('CART.ORDERING_CLOSES_IN'),
                    this._globalizationService.formatDateDistanceToNow$(closeAtDate)
                  ]).pipe(
                    map(([closesPrefix, closesAtFromNow]) => `${closingMessage} ${closesPrefix}${closesAtFromNow} (${formatDate(closeAtDate, 'p')}).`)
                  );
                }
                return of(closingMessage);
              }),
              // append reopening warning message
              switchMap(closingMessage => {
                if (orderingSettings.openAt) {
                  const openAtDate = new Date(orderingSettings.openAt);
                  return combineLatest([
                    this._translateService.selectTranslate('CART.ORDERING_OPENS_IN'),
                    this._globalizationService.formatDateDistanceToNow$(openAtDate)
                  ]).pipe(
                    map(([opensPrefix, opensAtFromNow]) => `${closingMessage} ${opensPrefix}${opensAtFromNow} (${formatDate(openAtDate, 'p')}).`)
                  );
                }
                return of(closingMessage);
              }),
              map(closingMessage => (closingMessage && { message: closingMessage, key, hidden } || false))
            );
        }
        )
      );

  }

  hideClosingMessage$(key: string): Observable<unknown> {
    // A message is hidden when an entry is stored
    this._hiddenClosingMessages[key] = { hiddenAt: new Date().toISOString() } as OrderingMessageEntry;
    return of(true);
  }

  //#endregion ClosingMessage Visibility

}
