import { ErrorHandler, Injectable } from '@angular/core';
import { CartPayment, CartProduct as ApiCartProduct, CartStep as ApiCartStep, CreateOrderRequest, Menu, Order, OrderProduct, ReviewCartError, ReviewCartErrorSeverity, OrderDiscountType } from '@app/core/api-client/models';
import { ApiOrdersService } from '@app/core/api-client/services';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ErrorsHelper } from '@app/core/helpers/errors-helper';
import { Logger, LogService } from '@app/core/logging';
import _flatMap from 'lodash-es/flatMap';
import _flatMapDeep from 'lodash-es/flatMapDeep';
import _keyBy from 'lodash-es/keyBy';
import _groupBy from 'lodash-es/groupBy';
import _sortedIndexBy from 'lodash-es/sortedIndexBy';
import _reduce from 'lodash-es/reduce';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, shareReplay, skip, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AppAnalyticsService } from '../analytics/app-analytics.service';
import { ProductEventSource } from '../analytics/models/analytic-events';
import { MapperService } from '../mapper.service';
import { MenuState } from '../menu/state/menu.state';
import { Cart, CartDeliveryAddress, CartProduct } from './models/cart.model';
import { CartState } from './state/cart.state';
import { MenuModelHelper } from '../menu/models/menu-model-helper';
import { CartModelHelper } from './models/cart-model-helper';
import { OrderState } from '../order/state/order.state';
import { OrderService } from '../order/order.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private _log: Logger;

  private _queueCartReviewSubject: Subject<Cart>;

  constructor(
    private _menuState: MenuState,
    private _cartState: CartState,
    private _orderState: OrderState,
    private _apiOrdersService: ApiOrdersService,
    private _notificationService: ToastrService,
    private _errorHandler: ErrorHandler,
    private _authenticationService: AuthenticationService,
    private _appAnalyticsService: AppAnalyticsService,
    private _modelMapper: MapperService,
    logService: LogService
  ) {
    this._log = logService.getLogger('CartService');

    this._queueCartReviewSubject = new Subject<Cart>();
    this._queueCartReviewSubject
      .pipe(
        tap(_ => console.debug('🛒☑️❓ review cart requested')),
        debounceTime(200),
        switchMap(cart => { // switchMap to cancel review call when another review is requested
          this._cartState.setReviewingCart(true);
          console.debug('🛒☑️▶️ review cart started')
          return this.reviewCart(cart)
            .pipe(
              // to preserve observal, catch errors in inner pipeline to prevent higher-order observal to fail => see: https://stackoverflow.com/a/58661330/590741
              catchError((error: unknown) => {
                console.debug('🛒☑️❌ review cart error to handle')
                // notify error but continue observal
                this._errorHandler.handleError(error);
                return EMPTY; // continue observal by emit a dummy value
              }),
              finalize(() => this._cartState.setReviewingCart(false)),
              // cancel call early (without waiting for debounce), else it is already canceled later by switchMap
              takeUntil(this._queueCartReviewSubject)
            );
        })
      )
      .subscribe();

    // monitor user authentication in order to review cart with user updated fees & discounts
    this._authenticationService.currentUser$
      .pipe(
        withLatestFrom(this._cartState.getCurrentCart$()),
        filter(([user, cart]) => cart != null)
      )
      .subscribe(([user, cart]) => {
        this._queueCartReviewSubject.next(cart);
      });
  }

  isReviewingCart$() {
    return this._cartState.isReviewingCart$();
  }

  isUpdating$() {
    return this._cartState.isUpdating$();
  }

  setCurrentCart(cart: Cart, keepVersion: boolean = false) {
    //sort products
    if (cart) {
      cart.products = this.sortCartProducts(cart?.products);
    }
    this._cartState.setCurrentCart(cart, keepVersion);
  }

  getCurrentCart() {
    return this._cartState.getCurrentCart();
  }

  getCurrentCart$() {
    return this._cartState.getCurrentCart$();
  }

  getQuantityPerProductId$() {
    return this._cartState.getCurrentCart$()
      .pipe(
        map((cart) => {
          return this.getQuantityPerProductId(cart);
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  getQuantityPerProductId(cart: Cart = this.getCurrentCart()) {

    // internal recursive function
    function getSumQuantityByProductId(product: CartProduct, quantityByProductId: Map<number, number>) {
      const productId = product.product.productId;
      quantityByProductId.set(productId, (quantityByProductId.get(productId) || 0) + product.quantity);

      if (product.steps?.length > 0) {
        for (const step of product.steps) {
          if (step.products?.length > 0) {
            for (const stepProduct of step.products) {
              getSumQuantityByProductId(stepProduct, quantityByProductId);
            }
          }
        }
      }
    }

    const productGroups = new Map<number, number>();
    if (cart) {
      for (const p of cart.products) {
        getSumQuantityByProductId(p, productGroups);
      }
    }
    return productGroups;
  }

  private _countProducts$ = this._cartState.getCurrentCart$()
    .pipe(
      map((cart) => cart?.products.reduce<number>((count, p) => count + (p.quantity || 0), 0) || 0),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  getCountProduct$() {
    return this._countProducts$;
  }

  private _countStandardProducts$ = this._cartState.getCurrentCart$()
    .pipe(
      map((cart) => {
        if (!cart) return 0;
        const cartHasRequiringCutleryProducts = this.countCartRequiredCutleryAmount(cart) > 0;

        return cart?.products
          // on compte les couverts que s'il sont ajoutés manuellement sans repas
          .filter(p => !p.product.isCutlery || !cartHasRequiringCutleryProducts)
          //on exclue les consignes
          .filter(p => !p.product.isReturnableContainer)
          .reduce<number>((count, p) => count + (p.quantity || 0), 0) || 0;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  getCountStandardProducts$() {
    return this._countStandardProducts$;
  }

  /**
   * Increments (or add if product not exists) a product into cart
   *
   * @param CartProduct cartProductToAdd The product to increment/add
   * @param boolean [forceAddNewCartProduct=false] If True then a new instance of the product is added to cart (usefull for composed product where same Product can be ordered in multiples configurations)
   */
  incrementCartProduct$(cartProductToAdd: CartProduct, forceAddNewCartProduct: boolean = false, eventSource: ProductEventSource): Observable<CartProduct> { // TODO remove Observable return type
    return forkJoin([
      this._menuState.getCurrentMenu$().pipe(first()),
      this._cartState.getCurrentCart$().pipe(
        filter(cart => cart != null),
        first()
      ),
    ]).pipe(
      switchMap(([menu, cart]) => {
        if (!menu) {
          return throwError(() => 'invalid "increment product" operation : menu is undefined.');
        }
        if (!cart) {
          return throwError(() => 'invalid "increment product" operation : cart is undefined.');
        }

        let cartProduct = forceAddNewCartProduct ? null : cart.products.find((p) => p.id === cartProductToAdd.id);

        if (cartProduct == null) {
          // get product from menu
          const product = menu.products.find((p) => p.productId === cartProductToAdd.product.productId);
          if (!product) {
            return throwError(() => `invalid "increment product" operation : product (${cartProductToAdd.product.productId}) not found.`);
          }
          cartProductToAdd.quantity = 1;
          cart.products.push(cartProductToAdd);
          cartProductToAdd.addedAt = new Date().getTime();

          cartProduct = cartProductToAdd;

          this._appAnalyticsService.trackProductAddedToCartEvent({
            cartId: cart.cartId,
            product: cartProductToAdd,
            source: eventSource
          });
          this._log.debug(`product '${cartProductToAdd.product.name}' added to cart`);
        } else {
          // increment cart product
          cartProduct.quantity++;

          this._appAnalyticsService.trackProductQuantityUpdatedEvent({
            cartId: cart.cartId,
            product: cartProduct,
            delta: +1,
            quantity: cartProduct.quantity,
            source: eventSource
          });
          this._log.debug(`product '${cartProduct.product.name}' quantity increased to ${cartProduct.quantity}`);
        }

        if (this.checkCartProductRequiresCutlery(cartProduct)) {
          this.addCutleryProducts(cart, menu, true);
        }

        if (this.checkCartProductIsWithReturnableContainer(cartProduct)) {
          this.adjustReturnableContainerProducts(cart, menu, true);
        }

        // update state
        this.updateCart(cart); // update and review cart
        return of(cartProductToAdd);
      })
    );
  }

  editProductQuantity$(cartProductToEdit: CartProduct, quantity: number, eventSource: ProductEventSource): Observable<CartProduct> {
    return forkJoin([
      this._menuState.getCurrentMenu$().pipe(first()),
      this._cartState.getCurrentCart$().pipe(
        filter(cart => cart != null),
        first()
      ),
    ]).pipe(
      switchMap(([menu, cart]) => {

        const cartProduct = cart.products.find((p) => p.id === cartProductToEdit.id);
        if (!cartProduct) {
          return throwError(() => `invalid "editProductQuantity" operation : cart product (${cartProductToEdit.product.productId}) not found.`);
        }

        //compute vars before updating quantity
        const cartProductRequiresCutlery = this.checkCartProductRequiresCutlery(cartProduct);
        const cartProductIsWithReturnableContainer = this.checkCartProductIsWithReturnableContainer(cartProduct);

        const oldQuantity = cartProduct.quantity;
        cartProduct.quantity = quantity;

        if (cartProduct.quantity === 0) {
          // remove from cart
          cart.products = cart.products.filter((p) => p.id !== cartProduct.id);

          this._appAnalyticsService.trackProductRemovedFromCartEvent({
            cartId: cart.cartId,
            product: cartProduct,
            source: eventSource
          });
          this._log.debug(`product '${cartProduct.product.name}' removed from cart`);
        } else {

          this._appAnalyticsService.trackProductQuantityUpdatedEvent({
            cartId: cart.cartId,
            product: cartProduct,
            delta: quantity - oldQuantity,
            quantity: cartProduct.quantity,
            source: eventSource
          });
          this._log.debug(`product '${cartProduct.product.name}' quantity updated to ${cartProduct.quantity}`);
        }

        if (cartProductRequiresCutlery) {
          this.addCutleryProducts(cart, menu, true);
        }

        if (cartProductIsWithReturnableContainer) {
          this.adjustReturnableContainerProducts(cart, menu, true);
        }

        // update state
        this.updateCart(cart); // update and review cart
        return of(cartProduct);
      })
    );
  }

  addComposedProduct$(product: CartProduct, eventSource: ProductEventSource): Observable<CartProduct> {
    if (!(product?.steps?.length > 0)) {
      throw new Error(`Product '${product?.product.name}' is not a composed product`);
    }

    return this._cartState.getCurrentCart$()
      .pipe(
        filter(cart => cart != null),
        first(),
        switchMap(cart => {

          const productHash = this.hashCartProduct(product, true);
          // find if a match composed product is in cart
          const matchedCartProduct = cart.products.find(p => this.hashCartProduct(p, true) === productHash);
          return matchedCartProduct
            // equivalent composed product found in cart so increment it
            ? this.incrementCartProduct$(matchedCartProduct, false, eventSource)
            // force add new product instance to cart
            : this.incrementCartProduct$(product, true, eventSource);
        })
      );

  }

  /**
   * Get a CartProduct reference cross Cart versions
   */
  getCartProduct$(cartProductId: string): Observable<CartProduct> {
    return this._cartState.getCurrentCart$()
      .pipe(
        map(cart => cart?.products.find(cp => cp.id === cartProductId)),
        distinctUntilChanged()
      );
  }

  /**
   * Persists Cart state, refresh local prices and launch remote review
   *
   * @param {Cart} cart the new/updated cart to persist
   * @param {boolean} [reviewCart=true] Actives cart review by server
   * @param {boolean} [computeCartPrices=null] If true then computes cart prices, if null it computes prices when reviewCart=true
   */
  private updateCart(cart: Cart, reviewCart: boolean = true, computeCartPrices: boolean = null) { // TODO remove Observable return type

    const doComputeCartPrices = computeCartPrices ?? reviewCart;
    if (cart && doComputeCartPrices) {
      // Local price refresh;
      this.computeCartPrices(cart);
      // reorganize formula products
      // this.organizeCart(cart);
    }

    //reorder cart product according category position
    const menu = this._menuState.getCurrentMenu();
    if (menu && cart.products) {
      this.classifyCartProducts(cart, menu);
    }

    // Update cart application state to notify updates
    const keepCartVersion = !reviewCart;
    this.setCurrentCart(cart, keepCartVersion);

    if (reviewCart) {
      // async remote cart validation/computation
      this._queueCartReviewSubject.next(cart); // this.reviewCart(cart);
    }
  }

  /**
   * Reorganize products according products root categories position
   */
  private classifyCartProducts(cart: Cart, menu: Menu) {
    const productsByRootCategory = CartModelHelper.GroupCartProductsByRootCategory(cart.products, menu);
    const orderedProducts = productsByRootCategory.reduce<CartProduct[]>((prev, current) => [...prev, ...current.cartProducts], []);
    cart.products = orderedProducts;

    //HACK assign cartProducts rootCategoryName to simplify presentation logic in others components
    for (const categoryProducts of productsByRootCategory) {
      for (const cartProduct of categoryProducts.cartProducts) {
        cartProduct.rootCategoryName = categoryProducts.category?.name;
      }
    }
  }

  clearCart() {
    this._cartState
      .getCurrentCart$()
      .pipe(
        withLatestFrom(this._menuState.getCurrentMenu$()),
        first(),
        tap(([cart, menu]) => {
          const emptyCart = this.createEmptyCart(menu);
          this.updateCart(emptyCart, false); // set empty cart as current (without review)
        })
      )
      .subscribe();
  }

  private createEmptyCart(menu?: Menu): Cart {
    menu = menu ?? this._menuState.getCurrentMenu();

    const newCart =
      {
        // new empty cart
        cartId: Date.now().toString(10),
        menuId: menu.menuId,
        siteId: menu.restaurant.site.siteId,
        restaurantId: menu.restaurant.restaurantId,
        deliveryDate: null,
        products: [],
        fees: [],
        discounts: [],
        taxRatesBreakdown: [],
        comment: null,
        payment: null,
        promocode: null,
        invalidReviewCount: 0,
        totalPrice: 0,
        totalVatPrice: 0
      } as Cart;

    if (menu && !menu.consumptionMode.forcePickupScheduleInput) {
      newCart.deliveryDate = this.computeAllowedDeliveryDate(newCart, menu)?.toISOString();
    }

    return newCart;
  }

  /**
   * Load cart from storage, adjust delivery date and
   */
  loadCart(menu: Menu | null) {
    // update current cart
    if (menu?.restaurant) {
      this._cartState.setUpdating(true);
      try {
        // load cart from local storage if exist
        let cart = this._cartState.loadStoredCart(menu.restaurant.restaurantId);
        // reset cart if active menu has changed
        if (cart?.menuId !== menu.menuId) {
          if (cart) {
            this._log.warn(`cart has been emptied because menu has changed (from ${cart.menuId} to ${menu.menuId})`)
          }
          cart = this.createEmptyCart(menu);
        }
        this.reReferenceProductsFromMenu(cart.products, menu);
        // reset deliveryDate if needed (deliveryDate has been selected or input isn't forced)
        const newDeliveryDate = this.computeAllowedDeliveryDate(cart, menu)?.toISOString();
        const deliveryDateNeedUpdate = newDeliveryDate && newDeliveryDate !== cart.deliveryDate;

        if (deliveryDateNeedUpdate) {
          if (menu.consumptionMode.forcePickupScheduleInput) {
            cart.deliveryDate = null;
          }
          else {
            cart.deliveryDate = newDeliveryDate;
          }
        }

        // launch review only if ordering is open
        const launchCartReview = menu.orderingSettings.isOpen;
        // Update cart state & review
        this.updateCart(cart, launchCartReview);
      } finally {
        this._cartState.setUpdating(false);
      }
    } else {
      // Empty cart state without review
      this.updateCart(null, false);
    }
  }

  /**
   * Remap all persisted "CartProduct.Product" references to Matching Menu products
   */
  private reReferenceProductsFromMenu(cartProducts: CartProduct[], menu: Menu) {
    if (cartProducts?.length > 0) {
      const productById = _keyBy(menu.products, p => p.productId);
      for (const cartProduct of cartProducts) {
        const product = productById[cartProduct.product.productId];
        if (product) {
          cartProduct.product = product;
        }
      }
    }
  }

  private reviewCart(cart: Cart): Observable<Cart> {
    if (!cart || cart.menuId === -1) {
      // "null cart" // TODO allow real null cart
      return of(cart);
    }
    // remote cart validation/computation
    return this._apiOrdersService.ReviewCart$Json({
      body: {
        cartId: cart.cartId,
        cartVersion: cart.cartVersion,
        menuId: cart.menuId,
        siteId: cart.siteId,
        restaurantId: cart.restaurantId,
        comment: cart.comment, // pas très utile
        deliveryDate: cart.deliveryDate && new Date(cart.deliveryDate).toJSON(),
        products: cart.products.map(p => this.mapCartProductToApi(p)),
        promoCode: cart.promocode,
        deliveryAddress: cart.deliveryAddress,
        invalidReviewCount: 0
      }
    })
      .pipe(
        first(),
        catchError((error: unknown) => this.handleCartReviewError$(error as Error, false)),
        // retrieve cart
        switchMap(reviewResult => {
          return forkJoin([
            this.getCurrentCart$().pipe(first()),
            of(reviewResult)
          ]);
        }),
        // only if cart has not changed since
        filter(([currentCart, reviewResult]) => {
          if (reviewResult.cartVersion === currentCart?.cartVersion) { // currentCart may be nulled when receiving cart Review
            tap(_ => console.debug(`🛒☑️❎ review cart discarded cause cart has changed (current= ${currentCart?.cartVersion}, reviewed=${reviewResult.cartVersion})`));
            return true;
          }
          return false;
        }),
        // apply reviewed product prices/fees/subventions/promocodes
        map(([currentCart, reviewResult]) => {
          this.applyReviewedCartPrices(currentCart, reviewResult.order);

          //refresh applied promocode
          currentCart.promocode = this.getCartAppliedPromocode(currentCart)?.label;
          currentCart.promocodeError = null;

          console.debug('🛒☑️💲 review cart accepted, new prices applied');
          return currentCart;
        }),
        // apply new cart
        tap(computedCart => {
          // cart validation applied successfully => reset invalidReviewCount
          computedCart.invalidReviewCount = 0;
          this.setCurrentCart(computedCart);
          console.debug('🛒☑️✔️ review cart succeed, new cart applied');
        })
      );
  }

  private handleCartReviewError$(cartError: Error, isFromOrderCreation: boolean = false): Observable<never> {

    const forwardError = () => throwError(() => cartError); // forward error down Subscription tree ()
    const skipProcessing = () => EMPTY; // stop further processing
    const displayCartInfo = (errorMessage: string) => this._notificationService.info(errorMessage, null, { disableTimeOut: false });
    const displayCartWarning = (errorMessage: string) => this._notificationService.warning(errorMessage, null, { disableTimeOut: true });
    const displayCartError = (errorMessage: string) => this._notificationService.error(errorMessage, null, { disableTimeOut: true });

    if (!ErrorsHelper.isHttpRequestError(cartError)) {
      return forwardError();
    }

    const errorDetails = ErrorsHelper.getErrorDetails(cartError);
    if (!errorDetails) {
      return forwardError();
    }

    const isInvalidCartError = errorDetails.instance === 'ReviewCartFailedException';
    if (!isInvalidCartError) {
      return forwardError();
    }

    const reviewCartError = errorDetails.errorPayload as ReviewCartError;
    const cart = this._cartState.getCurrentCart();
    const menu = this._menuState.getCurrentMenu();
    if (!cart || reviewCartError?.request.cartVersion !== cart.cartVersion) {
      // cart has changed since review
      this._log.info('🛒☑️⏩️ cart has changed since review, ⏩️ skip review process');
      return skipProcessing();
    }

    if (reviewCartError.hasConfigurationOrTechnicalError) {
      // TODO proposer de vider le panier ou contacter un administrateur ?
      // console.error('🛒☑️❗ cart review has technical error \n', errorDetails.title, reviewCartError);
      this._log.error('🛒☑️❗ cart review has technical error \n' + JSON.stringify({ errorDetails, cart }));
      displayCartError(errorDetails.title);
      return skipProcessing();
    }

    let errorLevel = reviewCartError.severity;
    let shouldReviewCart = false;
    let shouldReloadMenu = reviewCartError.requiresMenuReload;

    if (reviewCartError.requiresRescheduling) {

      const proposedDeliveryDate = this.computeAllowedDeliveryDate(cart, menu)?.toISOString();

      // adjust CartDeliveryDate if needed
      if (menu.consumptionMode.forcePickupScheduleInput) {
        // prompt user to pickup another date
        cart.deliveryDate = null;
      }
      else {
        // auto-adjust DeliveryDate
        cart.deliveryDate = proposedDeliveryDate;
      }

      // check if it remains an available date else reload menu (with ordering settings & schedules)
      const hasAvailableDeliveryDate = !!proposedDeliveryDate;
      if (!hasAvailableDeliveryDate) {
        this._log.warn('🕑❌ no available delivery date, reloading menu...');
        shouldReloadMenu = true; // if no available date then reload menu (with fresh ordering settings & schedules)
      }
      else {
        shouldReviewCart = true;
      }

      // in table service mode, we let auto-adjust DeliveryDate without displaying an error
      if (menu?.consumptionMode.orderPreparationSettings.tableServiceEnabled) {
        errorLevel = ReviewCartErrorSeverity.Silent;
      }
    }

    let notifyProductQuantitiesUpdated = false;
    if (reviewCartError.requiresCartProductActions?.length > 0) {
      // process cart products updates
      const productsToDelete: CartProduct[] = [];
      for (const action of reviewCartError.requiresCartProductActions) {
        const product = cart.products[action.index];
        if (action.deleteProduct || action.newQuantity === 0) {
          // track deletion
          productsToDelete.push(product);
        } else if (action.newQuantity > 0) {
          // update product quantity
          product.quantity = action.newQuantity;
          notifyProductQuantitiesUpdated = true;

          // update product available quantity too to prevent reloading the whole menu (only if menu reload isn't required)
          if (!reviewCartError.requiresMenuReload) {
            product.product.quantityAvailable = action.newQuantity;
          }
        }
      }

      // remove products
      cart.products = cart.products.filter(p => !productsToDelete.includes(p));
      shouldReviewCart = true;
    }

    let computeCartPrices: boolean | null = null;
    if (reviewCartError.hasPaymentAmountError) {
      computeCartPrices = true;
    }

    // track/reset promocode error
    cart.promocode = null;
    cart.promocodeError = reviewCartError.hasPromocodeError ? reviewCartError.promocodeError : null;

    if (shouldReviewCart || shouldReloadMenu) {
      // prevent infinite validation / reload loops
      cart.invalidReviewCount++;
      const invalidReviewCountExceed = (cart.invalidReviewCount > 1);
      if (invalidReviewCountExceed) {
        // warn because this is not a nominal case
        this._log.error(`🛒☑️❗ Cart review count exceed (${cart.invalidReviewCount}), cartId=${cart.cartId} :\r\n` + JSON.stringify({ cart, errorDetails }));

        if (cart.invalidReviewCount === 2) {
          shouldReloadMenu = true;  // try to reload menu once also to refresh pickupSchedules & ordering settings
        } else if (cart.invalidReviewCount > 2) {
          // stop reloading & reviewing cart
          shouldReviewCart = false;
          shouldReloadMenu = false;
        }
      }
      // Prevent cart re-validation if invalidReviewCount threshold is reached or if menu will be reloaded (with cart)
      shouldReviewCart = shouldReviewCart && !invalidReviewCountExceed && !shouldReloadMenu;
      this.updateCart(cart, shouldReviewCart, computeCartPrices); // update and review cart
    } else {
      // Cart doesn't need review => reset invalidReview counter
      cart.invalidReviewCount = 0;
      // persist (= update without review)
      this.updateCart(cart, false, computeCartPrices);
    }

    if (shouldReloadMenu) {
      this._menuState.setCurrentMenuRequiresReload({ force: true, loadCart: true });
    } else {
      if (notifyProductQuantitiesUpdated) {
        // just push same state to refresh UI
        this._menuState.notifyCurrentMenuChanged();
      }
    }

    switch (errorLevel) {

      case ReviewCartErrorSeverity.Warning:
      case ReviewCartErrorSeverity.Error:
        displayCartWarning(errorDetails.title);
        // eslint-disable-next-line no-console
        console.warn(errorDetails.title, reviewCartError);
        break;
      case ReviewCartErrorSeverity.Silent:
        //silent error
        console.debug(errorDetails.title, reviewCartError);
        break;
      default:
        displayCartInfo(errorDetails.title);
        // eslint-disable-next-line no-console
        console.info(errorDetails.title, reviewCartError);
        break;
    }

    return skipProcessing();
  }

  public updateDeliveryDate(deliveryDate: Date | null) {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.deliveryDate = deliveryDate?.toISOString();
    this.updateCart(cart); // update and review cart
  }

  public updatePayment(payment: CartPayment) {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.payment = payment;
    this.updateCart(cart, false); // update without review (for the moment, will change when payment implies fees)
  }

  public updateComment(comment: string | null) {

    // finally don't No substitution, let server do it on order creation
    // // substitute additionalInformation if allowed & defined
    // const menu = this._menuState.getCurrentMenu();
    // if (!(comment?.trim().length > 0)
    //   && menu.consumptionMode.additionalInformationEnabled
    //   && menu.consumptionMode.additionalInformationAllowSkip) {
    //   comment = menu.consumptionMode.additionalInformationValueIfSkipped;
    // }

    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.comment = comment;
    this.updateCart(cart, false); // update without review
  }

  public updateDeliveryAddress(deliveryAddress: CartDeliveryAddress | null) {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.deliveryAddress = deliveryAddress;
    this.updateCart(cart, false); // update without review
  }

  public updateDestination(destination: string | null) {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.destination = destination;
    this.updateCart(cart, false); // update without review
  }

  public applyPromocode(promocode: string | null) {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.promocode = promocode;
    cart.promocodeError = null;
    this.updateCart(cart); // update and review cart
  }

  public removePromocode() {
    const cart = this.getCurrentCart();
    if (cart == null) return;
    cart.promocode = null;
    this.updateCart(cart); // update and review cart
  }

  // Updates Cart prices updated from the reviewedCart (Order)
  private applyReviewedCartPrices(cart: Cart, order: Order): void {

    // console.log('applicating reviewed cart...');
    // console.log(order);

    // apply prices
    cart.fees = order.fees;
    cart.discounts = order.discounts;
    cart.taxRatesBreakdown = order.taxRatesBreakdown;
    cart.totalPrice = order.totalInclTaxWithDiscount;
    cart.totalVatPrice = order.totalTaxWithDiscount;

    // Apply product prices review
    this.applyReviewedCartProductsPrices(cart, cart.products, order, order.products);
  }

  public getCartAppliedPromocode(cart: Cart) {
    return cart?.discounts?.find(d => d.type === OrderDiscountType.PromoCode);
  }

  private applyReviewedCartProductsPrices(cart: Cart, cartProducts: CartProduct[], order: Order, orderProducts: OrderProduct[]): void {
    // Apply product prices review

    // 1 index the same level of products by hash key
    // 2 match CartProducts & OrderProducts using hashkey
    // 3 update CartProduct price
    let productIndex = 0; // add index to assert products with same structure are distincts
    const cartProductByHash = _keyBy(cartProducts, p => `${productIndex++}|${this.hashCartProduct(p)}`);
    productIndex = 0;
    const orderProductByHash = _keyBy(orderProducts, p => `${productIndex++}|${this.hashOrderProduct(p)}`);

    // eslint-disable-next-line guard-for-in
    for (const hash in cartProductByHash) {
      const cartProduct = cartProductByHash[hash];
      const orderProduct = orderProductByHash[hash];

      if (!orderProduct) {
        // order product not found => log warning
        this._log.warn(`Product ${cartProduct.product.name}(${hash}) not found in reviewed cart . \r\n cart: \r\n${JSON.stringify(cart)} \r\n order: \r\n${JSON.stringify(order)}`);
      } else {
        // apply reviewed price to CartProduct
        cartProduct.unitPrice = orderProduct.unitPrice;
        cartProduct.totalPrice = orderProduct.price;
        cartProduct.vatPrice = orderProduct.vatPrice;

        // apply recursively down the product childrens
        const cartProductChildren = _flatMap((cartProduct.steps || []), s => s.products); // = SelectMany
        const orderProductChildren = _flatMap((orderProduct.steps || []), s => s.products); // = SelectMany
        this.applyReviewedCartProductsPrices(cart, cartProductChildren, order, orderProductChildren);
      }
    }
  }

  private hashProduct(productId: number, quantity: number) {
    return `${productId};${quantity}`;
  }

  private getAllCartProducts(cartProduct: CartProduct): CartProduct[] {
    var products = [cartProduct];
    for (const step of cartProduct.steps) {
      for (const product of step.products) {
        products.push(...this.getAllCartProducts(product));
      }
    }
    return products;
  }

  private hashCartProduct(cartProduct: CartProduct, skipRootProductQuantity: boolean = false): string {
    // const products = _flatMapDeep([cartProduct], (p) => [p, ...(p.steps || []).map(s => s.products)]);
    const products = this.getAllCartProducts(cartProduct);
    const hash = products.reduce<string>((h, p) => h + '|' + this.hashProduct(p.product.productId, (skipRootProductQuantity && p === cartProduct) ? 1 : p.quantity), '');
    return hash;
  }

  private getAllOrderProducts(cartProduct: OrderProduct): OrderProduct[] {
    var products = [cartProduct];
    for (const step of cartProduct.steps) {
      for (const product of step.products) {
        products.push(...this.getAllOrderProducts(product));
      }
    }
    return products;
  }

  private hashOrderProduct(orderProduct: OrderProduct): string {
    // const products = _flatMapDeep([orderProduct], (p) => [p, ...(p.steps || []).map(s => s.products)]);
    const products = this.getAllOrderProducts(orderProduct);
    const hash = products.reduce<string>((h, p) => h + '|' + this.hashProduct(p.productId, p.quantity), '');
    return hash;
  }

  private mapCartProductToApi(p: CartProduct): ApiCartProduct {
    return {
      productId: p.product.productId,
      name: p.product.name,
      quantity: p.quantity,
      steps: p.steps && p.steps.map(s => ({
        stepId: s.step.stepId,
        name: s.step.name,
        products: s.products && s.products.map(sp => this.mapCartProductToApi(sp))
      } as ApiCartStep))
    };
  }

  private sortCartProducts(products: CartProduct[]): CartProduct[] {
    const groups = _groupBy(products || [], p => {
      return p.product.isCutlery
        ? 1 // 'Cutlery'
        : p.product.isReturnableContainer
          ? 2 // 'ReturnableContainer'
          : 0 // 'StandardProduct'
    });

    const sortedProducts = Object.values(groups).reduce<CartProduct[]>((prev, current) => [...prev, ...current], []);
    return sortedProducts;
  }

  private createOrderRequestFromCart(): Observable<CreateOrderRequest> {
    return this.getCurrentCart$()
      .pipe(
        first(),
        map((cart) => {
          if (!cart.deliveryDate) {
            throw new Error('Date & hour Delivery not selected');
          }
          return {
            cartId: cart.cartId,
            cartVersion: cart.cartVersion,
            menuId: cart.menuId,
            siteId: cart.siteId,
            restaurantId: cart.restaurantId,
            comment: cart.comment,
            deliveryDate: new Date(cart.deliveryDate).toJSON(),
            destination: cart.destination,
            products: cart.products.map(p => this.mapCartProductToApi(p)),
            promoCode: cart.promocode,
            payment: cart.payment,
            amount: cart.totalPrice
          };
        })
      );
  }

  createOrderFromCart(): Observable<Order> {
    return this.createOrderRequestFromCart()
      .pipe(
        switchMap(request => {
          return this._apiOrdersService.CreateOrder$Json({
            body: request
          });
        }),
        first(),
        catchError((error: unknown) => this.handleCartReviewError$(error as Error, true)),
        tap(order => {
          this._orderState.notifyOrderUpdated(order);
        }),
      );
  }

  // computes CartPrices in client-side (while computing cart review on server)
  private computeCartPrices(cart: Cart) {
    cart.totalPrice = 0;
    cart.totalVatPrice = 0;
    cart.products.forEach((p) => {
      const productPrices = this.computeCartProductPrices(p);
      cart.totalPrice += productPrices.totalPrice;
      cart.totalVatPrice += productPrices.totalVatPrice;
    });
    // append discound & fees computed by Server
    cart.discounts?.forEach(d => {
      cart.totalPrice -= d.amount || 0;
      cart.totalVatPrice -= d.taxRatesBreakdown?.reduce((sum, current) => sum + current.totalTax, 0) || 0;
    });
    cart.fees?.forEach(f => {
      cart.totalPrice += f.totalInclTax || 0;
      cart.totalVatPrice += f.totalTax || 0;
    });
  }

  private computeCartProductPrices(cartProduct: CartProduct): { totalPrice: number, totalVatPrice: number } {
    const unitPrice: number = (cartProduct.stepProduct)
      ? cartProduct.stepProduct.price // TODO handle StepQuantity rule (link below)
      : cartProduct.product.price;

    // eslint-disable-next-line max-len
    // StepQuantity rule : https://elior.sharepoint.com/sites/DsiFr-Digital/_layouts/OneNote.aspx?id=%2Fsites%2FDsiFr-Digital%2FSiteAssets%2FDsiFr-Digital%20Notebook&wd=target%28SmartOrdering.one%7C2C17AC5C-F7D8-4061-BF9A-169151755530%2FRegles%20de%20calcul%20d%C3%A9passement%7C5CACFF10-1F85-4CC7-A6FD-672FCAAA7368%2F%29

    const productUnitVatPrice = (unitPrice - (unitPrice / (1 + cartProduct.product.vat)));

    const stepsPrices = (cartProduct.steps || [])
      // SelectMany step.Products
      .reduce<CartProduct[]>(
        (products, step) => [...products, ...step.products],
        []
      )
      // sum each product price & vatPrice
      .reduce<{ totalPrice: number; totalVatPrice: number }>(
        (price, product) => {
          const productPrices = this.computeCartProductPrices(product);
          price.totalPrice += productPrices.totalPrice;
          price.totalVatPrice += productPrices.totalVatPrice;
          return price;
        },
        { totalPrice: 0, totalVatPrice: 0 }
      );

    // update product prices
    cartProduct.unitPrice = unitPrice + stepsPrices.totalPrice;
    cartProduct.totalPrice = cartProduct.unitPrice * cartProduct.quantity;
    cartProduct.vatPrice = (productUnitVatPrice * cartProduct.quantity) + stepsPrices.totalVatPrice;

    return {
      totalPrice: cartProduct.totalPrice,
      totalVatPrice: cartProduct.vatPrice,
    };
  }

  /**
   * Find a available date in schedules for specified (or current) cart's deliveryDate & menu
   */
  private computeAllowedDeliveryDate(cart?: Cart, menu?: Menu): Date | null {

    cart = cart ?? this.getCurrentCart();
    const pickupSchedules = this._menuState.getAvailableSchedules(menu);

    const dateSlots = pickupSchedules.reduce<Date[]>((dates, slot) => [...dates, ...slot.dateSlots], []);
    if (dateSlots.length === 0) {
      return null;
    }

    let deliveryDate = (cart?.deliveryDate && new Date(cart.deliveryDate)) || new Date(); // cart date or now
    // find the estimated position of the date in the slots array and select the resulting slot
    const index = _sortedIndexBy(dateSlots, deliveryDate, d => d.getTime());
    // select resulting slot
    deliveryDate = (index < dateSlots.length) ? dateSlots[index] : dateSlots[dateSlots.length - 1];

    return deliveryDate;
  }

  checkCartHasCutleryProducts(cart?: Cart) {
    cart = cart ?? this.getCurrentCart();
    //check if has already any cutlery product in cart (not in composed products)
    const cartHasCutleryProducts = cart.products.some(p => p.product.isCutlery);
    return cartHasCutleryProducts;
  }

  checkCartProductHasCutleryProducts(cartProduct: CartProduct) {
    //check if has already any cutlery product in cart
    return cartProduct.product.isCutlery
      || cartProduct.steps?.some(s => s.products?.some(p => this.checkCartProductHasCutleryProducts(p)));
  }

  countCartRequiredCutleryAmount(cart?: Cart): number {
    cart = cart ?? this.getCurrentCart();

    //compute serving count recursively
    const servingCount = cart.products.reduce<number>((prev, current) => prev += this.countCartProductRequiredCutleryAmount(current), 0);
    return servingCount;
  }

  countCartProductRequiredCutleryAmount(cartProduct: CartProduct): number {
    let amount = 0;

    if (cartProduct.product.requiresCutlery) {
      amount += (cartProduct.quantity * (cartProduct.product.servingCount || 1)); // count at least 1 cutlery if no servingCount defined
    }

    //count children products RequiringCutlery
    amount += _flatMap(cartProduct.steps, s => s.products)
      .reduce<number>((prev, current) => prev + (this.countCartProductRequiredCutleryAmount(current) * cartProduct.quantity), 0); // multiply with product quantity

    return amount;
  }

  checkCartProductRequiresCutlery(cartProduct: CartProduct): boolean {
    return this.countCartProductRequiredCutleryAmount(cartProduct) > 0;
  }

  addCutleryProducts(cart?: Cart, menu?: Menu, skipUpdateCart: boolean = false, isExplicitCustomerChoice: boolean = false): void {
    cart = cart ?? this.getCurrentCart();
    menu = menu ?? this._menuState.getCurrentMenu();

    // skip processing if cart has already any cutlery product or if user has explicitly choosed he requires cutlery (or not)
    if (!isExplicitCustomerChoice && cart.customerRequiresCutlery != null) {
      return;
    }

    // keep customer choice
    if (isExplicitCustomerChoice) {
      cart.customerRequiresCutlery = true;
    }

    // skip if cart has already any cutlery product
    const cartHasCutleryProducts = this.checkCartHasCutleryProducts(cart);
    if (cartHasCutleryProducts) {
      return;
    }

    // compute serving count recursively
    // const mealsWithRequiringCutlery = this.countCartRequiredCutleryAmount(cart);

    //add cutlery products to cart
    const menuCutleryProducts = menu.products.filter(p => p.isCutlery);
    for (const cutleryProduct of menuCutleryProducts) {
      const productDetailModel = this._modelMapper.toProductDetailModel(cutleryProduct, null);
      const cartProductToAdd = this._modelMapper.toCartProduct(productDetailModel);

      // add to cart
      // cartProductToAdd.quantity = mealsWithRequiringCutlery;
      cartProductToAdd.quantity = 1; // Evol => add only one CutleryProduct then customer manually adjusts quantity
      cart.products.push(cartProductToAdd);
      cartProductToAdd.addedAt = new Date().getTime();

      // log
      this._appAnalyticsService.trackProductAddedToCartEvent({
        cartId: cart.cartId,
        product: cartProductToAdd,
        source: ProductEventSource.Cart
      });
      this._log.debug(`🍴 cutlery product '${cartProductToAdd.product.name}' added to cart`);
    }

    // update state
    if (!skipUpdateCart) {
      this.updateCart(cart); // update and review cart
    }

  }

  removeCutleryProducts(cart?: Cart, skipUpdateCart: boolean = false, isExplicitCustomerChoice: boolean = false): void {
    cart = cart ?? this.getCurrentCart();

    const cartCutleryProducts = cart.products.filter(p => p.product.isCutlery);
    const cartCutleryProductIds = cartCutleryProducts.map(p => p.id);

    // remove from cart
    cart.products = cart.products.filter((p) => !cartCutleryProductIds.includes(p.id));

    // log
    for (const cartProductToDelete of cartCutleryProducts) {
      this._appAnalyticsService.trackProductRemovedFromCartEvent({
        cartId: cart.cartId,
        product: cartProductToDelete,
        source: ProductEventSource.Cart
      });
      this._log.debug(`🍴 cutlery product '${cartProductToDelete.product.name}' removed from cart`);
    }

    // keep customer choice
    if (isExplicitCustomerChoice) {
      cart.customerRequiresCutlery = false;
    }

    // update state
    if (!skipUpdateCart) {
      this.updateCart(cart); // update and review cart
    }
  }

  countCartProductsWithReturnableContainerAmount(cart?: Cart): number {
    cart = cart ?? this.getCurrentCart();

    //compute recursively
    const servingCount = cart.products.reduce<number>((prev, current) => prev += this.countCartProductWithReturnableContainerAmount(current), 0);
    return servingCount;
  }

  countCartProductWithReturnableContainerAmount(cartProduct: CartProduct): number {
    let amount = 0;

    if (cartProduct.product.hasReturnableContainer) {
      amount += cartProduct.quantity;
    }

    //count children products WithReturnableContainer
    amount += _flatMap(cartProduct.steps, s => s.products)
      .reduce<number>((prev, current) => prev + (this.countCartProductWithReturnableContainerAmount(current) * cartProduct.quantity), 0); // multiply with product quantity

    return amount;
  }

  checkCartProductIsWithReturnableContainer(cartProduct: CartProduct): boolean {
    return this.countCartProductWithReturnableContainerAmount(cartProduct) > 0;
  }

  adjustReturnableContainerProducts(cart?: Cart, menu?: Menu, skipUpdateCart: boolean = false): void {
    cart = cart ?? this.getCurrentCart();
    menu = menu ?? this._menuState.getCurrentMenu();

    //find first ReturnableContainerProduct in menu
    const menuReturnableContainerProduct = menu.products.find(p => p.isReturnableContainer);
    if (!menuReturnableContainerProduct) {
      return; // nothing to update
    }

    const productsWithReturnableContainerCount = this.countCartProductsWithReturnableContainerAmount(cart);
    const returnableContainerProductsCount = cart.products.filter(p => p.product.isReturnableContainer).reduce<number>((prev, current) => prev + current.quantity, 0);

    if (productsWithReturnableContainerCount !== returnableContainerProductsCount) {
      // clear ReturnableContainerProduct from cart
      cart.products = cart.products.filter(p => !p.product.isReturnableContainer);

      if (productsWithReturnableContainerCount > 0) {
        // add ReturnableContainerProduct to cart
        const productDetailModel = this._modelMapper.toProductDetailModel(menuReturnableContainerProduct, null);
        const cartProductToAdd = this._modelMapper.toCartProduct(productDetailModel);

        cartProductToAdd.quantity = productsWithReturnableContainerCount;
        cart.products.push(cartProductToAdd);
        cartProductToAdd.addedAt = new Date().getTime();

        // log
        this._appAnalyticsService.trackProductAddedToCartEvent({
          cartId: cart.cartId,
          product: cartProductToAdd,
          source: ProductEventSource.Cart
        });
        this._log.debug(`🍵 returnable container product '${cartProductToAdd.product.name}' added to cart`);
      }

      // update state
      if (!skipUpdateCart) {
        this.updateCart(cart); // update and review cart
      }
    }
  }

}
