import { Theme } from '../models/theme';

export const themeOrange: Theme = {
  name: 'orange',
  properties: {

    '--mat-primary-base-color': '#e95a0c',

    // Mat Primary Theme
    // Généré par  http://mcg.mbitson.com/ en partant de  '--mat-primary-base-color'
    '--mat-primary-50': '#fcebe2',
    '--mat-primary-100': '#f8ceb6',
    '--mat-primary-200': '#f4ad86',
    '--mat-primary-300': '#f08c55',
    '--mat-primary-400': '#ec7330',
    '--mat-primary-500': '#e95a0c',
    '--mat-primary-600': '#e6520a',
    '--mat-primary-700': '#e34808',
    '--mat-primary-800': '#df3f06',
    '--mat-primary-900': '#d92e03',
    '--mat-primary-A100': '#ffffff',
    '--mat-primary-A200': '#ffd5ce',
    '--mat-primary-A400': '#ffa99b',
    '--mat-primary-A700': '#ff9481',

    '--mat-primary-contrast-50': '#000000',
    '--mat-primary-contrast-100': '#000000',
    '--mat-primary-contrast-200': '#000000',
    '--mat-primary-contrast-300': '#000000',
    '--mat-primary-contrast-400': '#000000',
    '--mat-primary-contrast-500': '#ffffff',
    '--mat-primary-contrast-600': '#ffffff',
    '--mat-primary-contrast-700': '#ffffff',
    '--mat-primary-contrast-800': '#ffffff',
    '--mat-primary-contrast-900': '#ffffff',
    '--mat-primary-contrast-A100': '#000000',
    '--mat-primary-contrast-A200': '#000000',
    '--mat-primary-contrast-A400': '#000000',
    '--mat-primary-contrast-A700': '#000000',

    '--typo-headline-color': '#525257',
    '--typo-title-color': '#333333',
    '--typo-subheading-color': '#aeaeae',

    // Mat Accentt Theme
    // Généré par  http://mcg.mbitson.com/ en partant de  '--mat-accent-base-color'

    '--mat-accent-base-color': '#525257',

    '--mat-accent-50': '#eaeaeb',
    '--mat-accent-100': '#cbcbcd',
    '--mat-accent-200': '#a9a9ab',
    '--mat-accent-300': '#868689',
    '--mat-accent-400': '#6c6c70',
    '--mat-accent-500': '#525257',
    '--mat-accent-600': '#4b4b4f',
    '--mat-accent-700': '#414146',
    '--mat-accent-800': '#38383c',
    '--mat-accent-900': '#28282c',
    '--mat-accent-A100': '#8585f5',
    '--mat-accent-A200': '#5656f2',
    '--mat-accent-A400': '#1515ff',
    '--mat-accent-A700': '#0000fb',

    '--mat-accent-contrast-50': '#000000',
    '--mat-accent-contrast-100': '#000000',
    '--mat-accent-contrast-200': '#000000',
    '--mat-accent-contrast-300': '#000000',
    '--mat-accent-contrast-400': '#ffffff',
    '--mat-accent-contrast-500': '#ffffff',
    '--mat-accent-contrast-600': '#ffffff',
    '--mat-accent-contrast-700': '#ffffff',
    '--mat-accent-contrast-800': '#ffffff',
    '--mat-accent-contrast-900': '#ffffff',
    '--mat-accent-contrast-A100': '#000000',
    '--mat-accent-contrast-A200': '#ffffff',
    '--mat-accent-contrast-A400': '#ffffff',
    '--mat-accent-contrast-A700': '#ffffff',
  },
};
