/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiAdminService } from './services/api-admin.service';
import { ApiAuthService } from './services/api-auth.service';
import { ApiHealthCheckService } from './services/api-health-check.service';
import { ApiOrdersService } from './services/api-orders.service';
import { ApiPaymentService } from './services/api-payment.service';
import { ApiQrCodeService } from './services/api-qr-code.service';
import { ApiRestaurantsService } from './services/api-restaurants.service';
import { ApiSitesService } from './services/api-sites.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiAdminService,
    ApiAuthService,
    ApiHealthCheckService,
    ApiOrdersService,
    ApiPaymentService,
    ApiQrCodeService,
    ApiRestaurantsService,
    ApiSitesService,
    ApiConfiguration
  ],
})
export class ApiClientModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiClientModule> {
    return {
      ngModule: ApiClientModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiClientModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiClientModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
