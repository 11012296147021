import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { ForgotPasswordInput } from '../../models/forgot-password-input';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit {
  @Output() forgotPassword = new EventEmitter<ForgotPasswordInput>();
  @Output() cancel = new EventEmitter<void>();
  forgotPasswordForm: UntypedFormGroup;
  submitted = false;

  @Input() submitting = false;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit() { }

  private createForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onForgotPasswordClick() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.forgotPassword.emit({
      email: this.forgotPasswordForm.value.email,
    } as ForgotPasswordInput);
  }

  onCancelClick() {
    this.cancel.emit();
  }
}
