import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogState as MatDialogState } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ResetCredentialsDialogComponent, ResetCredentialsDialogParameters } from '@app/features/signin/components/reset-credentials-dialog/reset-credentials-dialog.component';
import { ConfirmDialogData } from '@app/shared/components/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { combineLatest, firstValueFrom, from, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { Restaurant } from '../api-client/models';
import { AuthenticationService, REDIRECT_URL_QUERYPARAM } from '../authentication/authentication.service';
import { StringHelper } from '../helpers/string-helper';
import { MediaQueryService } from '../layout/media-query.service';
import { Logger, LogService } from '../logging';
import { AppRoutes } from '../routes';
import { SiteState } from './site/state/site.state';

export enum RestaurantSource {
  CurrentRestaurant,
  UserRestaurant
}

const defaultNavOptions: NavigationExtras = {
  skipLocationChange: false,
  replaceUrl: false
};

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  private _previousUrl: string;
  private _currentUrl: string;
  private _routeHistory: string[] = [];
  private _logger: Logger;


  constructor(
    private _siteState: SiteState,
    private _authenticationService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    // private _locationStrategy: LocationStrategy,
    // private _platformLocation: PlatformLocation,
    private _dialog: MatDialog,
    private _mediaQueryService: MediaQueryService,
    logService: LogService,
  ) {
    this._logger = logService.getLogger('NavigationService');
    this.monitorRouterNavigation();
    this.monitorUserPasswordResetPrompt();

    // // DEBUG
    // this._locationStrategy.onPopState(($event: LocationChangeEvent) => {
    //   console.log("_locationStrategy.onPopState", $event);
    // });

    // this._location.onUrlChange((url, state) => {
    //   console.log("_location.onUrlChange", { url, state });
    // })
  }

  // stores the attempted URL for redirecting
  private monitorRouterNavigation() {
    this._router.events
      .pipe(
        filter(event => (event instanceof NavigationEnd))
      )
      .subscribe((event: NavigationEnd) => {
        const tempUrl = this._currentUrl;
        this._previousUrl = tempUrl;
        this._currentUrl = event.urlAfterRedirects;
        this._routeHistory.push(event.urlAfterRedirects);
      });
  }

  // checks users password expiration and open credentials reset dialog if needed
  private _resetDialogRef: MatDialogRef<ResetCredentialsDialogComponent, any> = null;
  private monitorUserPasswordResetPrompt() {
    this._authenticationService.userPasswordIsExpired$
      .pipe(
        tap((isExpired) => {
          const dialogIsClosed = (this._resetDialogRef == null || this._resetDialogRef.getState() != MatDialogState.OPEN);
          if (isExpired && dialogIsClosed) {
            setTimeout(() => this.openResetCreentialsDialog(), 200);
          };
        }),
      )
      .subscribe();
  }

  get previousUrl(): string {
    return this._previousUrl;
  }

  get currentUrl(): string {
    return this._currentUrl;
  }

  get routeHistory(): string[] {
    return this._routeHistory;
  }

  back(): void {
    this._routeHistory.pop();
    if (this._routeHistory.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl(`/${AppRoutes.default}`);
    }
  }

  /**
   * Compute restaurant menu route according specified strategy
   */
  private getRouteToDefaultMenu$(preferedSource: RestaurantSource = RestaurantSource.CurrentRestaurant): Observable<{ menuRoute: string, promptToSelectRestaurant: boolean }> {
    return combineLatest([
      this._siteState.getCurrentRestaurant$(),
      this._authenticationService.currentUser$
    ]).pipe(
      first(),
      // resolve selected restaurant according prefered option
      map(([restaurant, user]) => {

        if (restaurant) {
          if (preferedSource === RestaurantSource.CurrentRestaurant) {
            return { menuRoute: this.getRouteToMenu(restaurant), promptToSelectRestaurant: false };
          }

          const isLogged = !!user;
          if (!isLogged) {
            // not logged, return current restaurant
            return { menuRoute: this.getRouteToMenu(restaurant), promptToSelectRestaurant: false };
          }

          // if restaurant is in user scope, return it
          const isUserRestaurant = user.restaurants.some(r => r.restaurantId === restaurant.restaurantId);
          if (isUserRestaurant) {
            return { menuRoute: this.getRouteToMenu(restaurant), promptToSelectRestaurant: false };
          }

          // else return first user restaurant
          return { menuRoute: this.getRouteToMenu(user.restaurants[0]), promptToSelectRestaurant: false };

        }
        else {
          // no current restaurant => return first user restaurant if logged

          // TODO Add Flag to prompt user to choose restaurant (if site has many restaurant)
          return { menuRoute: this.getRouteToMenu(user?.restaurants[0]), promptToSelectRestaurant: false };
        }
      })
    );
  }

  private getRouteToMenu(restaurant: Restaurant) {
    return this.getRouteToMenuByRestaurantId(restaurant?.restaurantId, restaurant?.name);
  }

  private getRouteToMenuByRestaurantId(restaurantId: number | null, restaurantName: string) {
    return (restaurantId != null)
      ? `/${AppRoutes.restaurant}/${StringHelper.slugify(restaurantName || 'restaurant')}/${restaurantId}` // also this._router.createUrlTree([`/${AppRoutes.restaurant}`, StringHelper.slugify(restaurant.name), restaurant.restaurantId]);
      : `/${AppRoutes.default}`;
  }

  getReturnUrl(): string {
    const fromQuery = this._activatedRoute.snapshot.queryParams[REDIRECT_URL_QUERYPARAM];
    return this.checkReturnUrl(fromQuery);
  }

  checkReturnUrl(url: string) {
    if (url && !(url.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(url))) {
      // This is an extra check to prevent open redirects.
      throw new Error(`Invalid return url (${url}). The return url needs to have the same origin as the current page.`);
    }
    return url;
  }

  navigateToMenu$(restaurant: Restaurant, navoptions: NavigationBehaviorOptions = null): Observable<boolean> {
    const route = this.getRouteToMenu(restaurant);
    return from(this._router.navigate([route], navoptions || defaultNavOptions));
  }

  navigateToMenuByRestaurantId$(restaurantId: number | null, restaurantName: string, extras?: NavigationExtras): Observable<boolean> {
    const route = this.getRouteToMenuByRestaurantId(restaurantId, restaurantName);
    return from(this._router.navigate([route], extras || defaultNavOptions));
  }

  checkUserScopeAndNavigateToMenu$(restaurant: Restaurant, doNavigate: boolean = true): Observable<boolean> {
    // check if user is logged and has access to restaurant, if not, notify it will be disconnected
    const user = this._authenticationService.currentUser;
    if (user && !user.restaurants.some(r => r.restaurantId === restaurant.restaurantId)) {

      const confirmChangePlaceDialog = this._dialog.open(ConfirmDialogComponent, {
        data: {
          message: 'MENU.NOT_REGISTERED'
        } as ConfirmDialogData
      });

      return confirmChangePlaceDialog.afterClosed()
        .pipe(
          map(answer => !!answer), // answer is undefined if user has closed the dialog without using a button
          switchMap(answer => {
            if (answer) {
              // signout confirmed, signout and navigate to menu
              return this._authenticationService.signOut$(false)
                .pipe(
                  switchMap(redirected => doNavigate ? this.navigateToMenu$(restaurant) : of(true)),
                );
            }
            // user denies to signout => maybe redirect him to site selection or default site
            return of(false);
          })
        );
    }
    else {
      // user not logged , navigate to restaurant menu
      return doNavigate ? this.navigateToMenu$(restaurant) : of(true);
    }
  }

  /**
   * redirect to menu of current/user restaurant
   */
  navigateToDefaultMenu$(preferedSource: RestaurantSource = RestaurantSource.CurrentRestaurant): Observable<boolean> {
    return this.getRouteToDefaultMenu$(preferedSource)
      .pipe(
        switchMap(route => from(this._router.navigate([route.menuRoute], { state: { promptToSelectRestaurant: route.promptToSelectRestaurant } })))
      );
  }

  navigateToSignInForm(redirectUrl?: string, signoutCurrentUser?: boolean) {

    const route = redirectUrl
      ? `${AppRoutes.signin}?${REDIRECT_URL_QUERYPARAM}=${this.checkReturnUrl(redirectUrl)}`
      : AppRoutes.signin;

    const user = this._authenticationService.currentUser;
    if (user && signoutCurrentUser) {

      const confirmChangePlaceDialog = this._dialog.open(ConfirmDialogComponent, {
        data: {
          message: 'REGISTER.GOTOSIGNIN_BUT_ALREADYLOGGED'
        } as ConfirmDialogData
      });

      return firstValueFrom(confirmChangePlaceDialog.afterClosed()
        .pipe(
          map(answer => !!answer), // answer is undefined if user has closed the dialog without using a button
          switchMap(answer => {
            if (answer) {
              // signout confirmed, signout and navigate to menu
              return this._authenticationService.signOut$(false)
                .pipe(
                  switchMap(redirected => this._router.navigateByUrl(route)),
                );
            }
            // user denies to signout => maybe redirect him to site selection or default site
            return of(false);
          })
        )
      ); // subscribes and map to promise
    }
    else {
      // user not logged , navigate
      return this._router.navigateByUrl(route);
    }

  }

  navigateToSiteSelection(redirectUrl?: string) {
    const route = redirectUrl
      ? `${AppRoutes.siteSearch}?${REDIRECT_URL_QUERYPARAM}=${this.checkReturnUrl(redirectUrl)}`
      : AppRoutes.siteSearch;
    return this._router.navigateByUrl(route);
  }

  navigateToRegisterOnSite$(restaurant: Restaurant) {
    const registerRoute = `/${AppRoutes.register}/${StringHelper.slugify(restaurant?.name)}/${restaurant?.restaurantId}`;
    return from(this._router.navigate([registerRoute]));
  }

  navigateToRegisterOnCurrentRestaurant$() {
    return this._siteState.getCurrentRestaurant$()
      .pipe(
        take(1),
        switchMap(restaurant => restaurant
          ? this.navigateToRegisterOnSite$(restaurant)
          : this.navigateToRegister()
        )
      );
  }

  navigateToRegister() {
    return this._router.navigate([`/${AppRoutes.register}`]);
  }

  navigateToAccount() {
    return this._router.navigate([`/${AppRoutes.account}`]);
  }

  navigateToOrdersList() {
    return this._router.navigate([`/${AppRoutes.ordersBase_FR}`]);
  }

  navigateToMyPaymentMethods() {
    return this._router.navigate([`/${AppRoutes.myPaymentMethods}`]);
  }


  navigateHome(navoptions: NavigationBehaviorOptions = null) {
    return this._router.navigate(['/'], navoptions || defaultNavOptions);
  }

  navigateTo404() {
    return this._router.navigate([`/${AppRoutes.notfound}`], { skipLocationChange: true });
  }

  log404() {
    const url = this._router.routerState.snapshot.url;

    this._logger.info(`Page not found: ${url}`);
  }

  openResetCreentialsDialog(canCancelDialog: boolean = false) {
    const isDesktopRes = this._mediaQueryService.isDesktop();
    this._resetDialogRef = this._dialog.open(ResetCredentialsDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      closeOnNavigation: false,
      maxWidth: isDesktopRes ? '80vw' : '100vw', // 80vw is the max width of the dialog on desktop. in mobile we want full width
      data: {
        canCancelDialog: canCancelDialog
      } as ResetCredentialsDialogParameters
    });
  }
}
