<div mat-dialog-title>
  <app-reset-credentials-header></app-reset-credentials-header>
</div>
<mat-dialog-content>
  <app-reset-credentials [context]="context$ | async"
                         (resetCredentials)="onResetCredentials($event)"
                         [inProgress]="inProgress"
                         [canCancel]="canCancel"
                         (cancel)="onCancel()"></app-reset-credentials>
</mat-dialog-content>
