/* tslint:disable */
/* eslint-disable */

/**
 * Auto
 *
 * List
 *
 * DropDown
 */
export enum ChoicesDisplayMode {
  Auto = 'Auto',
  List = 'List',
  DropDown = 'DropDown'
}
