/* tslint:disable */
/* eslint-disable */

/**
 * Grant
 *
 * PromoCode
 *
 * Reduction
 */
export enum OrderDiscountType {
  Grant = 'Grant',
  PromoCode = 'PromoCode',
  Reduction = 'Reduction'
}
