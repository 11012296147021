/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DeleteCustomerPaymentMethodResult } from '../../models/delete-customer-payment-method-result';

export interface DeleteCustomerPaymentMethod$Json$Params {
  customerPaymentMethodId: number;
  'force-tenant'?: String;
}

export function DeleteCustomerPaymentMethod$Json(http: HttpClient, rootUrl: string, params: DeleteCustomerPaymentMethod$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCustomerPaymentMethodResult>> {
  const rb = new RequestBuilder(rootUrl, DeleteCustomerPaymentMethod$Json.PATH, 'delete');
  if (params) {
    rb.path('customerPaymentMethodId', params.customerPaymentMethodId, {});
    rb.header('force-tenant', params['force-tenant'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DeleteCustomerPaymentMethodResult>;
    })
  );
}

DeleteCustomerPaymentMethod$Json.PATH = '/api/Payment/customerPaymentMethod/{customerPaymentMethodId}';
