import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation, Renderer2, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { ResizeObserverEntry } from '@juggle/resize-observer';


export type LogoTypeClass = 'horizontal' | 'vertical' | 'icon-only';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {

  @Input() logoType?: LogoTypeClass = null;

  constructor(
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  onResize(entry: ResizeObserverEntry) {
    // check if LogoType is defined
    if (this.logoType !== null) {
      this.setLogo(entry.target, this.logoType);
      return;
    }

    // TODO revoir pour simplifier les regles de tailles
    if (entry.contentRect.height <= 40) {
      if (entry.contentRect.width <= 60) {
        this.setLogo(entry.target, 'icon-only');
      } else {
        this.setLogo(entry.target, 'horizontal');
      }
    }
    else if (entry.contentRect.width >= 150 && entry.contentRect.height >= 150) {
      this.setLogo(entry.target, 'vertical');
    }
    else if ((entry.contentRect.width / entry.contentRect.height) > 1) {
      this.setLogo(entry.target, 'horizontal');
    } else {
      this.setLogo(entry.target, 'vertical');
    }
  }

  private setLogo(element: Element, logoType: LogoTypeClass) {

    // DEBUG
    // console.log({ forcedLogoType: this.logoType, logoType });

    for (const logoClass of (['horizontal', 'vertical', 'icon-only'] as LogoTypeClass[])) {
      this._renderer.removeClass(element, logoClass);
    }

    this._renderer.addClass(element, logoType);
  }

}
