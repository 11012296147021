import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { isCurrencyCode } from './validate-currency-code.helper';

@UntilDestroy()
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent implements OnInit, OnChanges {
  @Input() price: number;
  @Input() floating: boolean = true;

  @Input() currencyCode: string | null = null; // restaurant currency
  @Input() currencyString: string | null = null;  // overriden restaurant currency

  integerPartString: string;
  fractionPartString: string;

  constructor(
    private _localeService: TranslocoLocaleService,
    private _translocoService: TranslocoService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    // monitorLangChange transloco lang change to update component (because OnPush)
    this._translocoService.langChanges$
      .pipe(
        tap(_ => {
          this.formatPrice();
          this._cd.markForCheck();
        }),
        untilDestroyed(this)
      ).subscribe();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatPrice();
  }

  private formatPrice() {
    const currencyCode = this.currencyCode ?? this._localeService._resolveCurrencyCode();
    const locale = this._localeService.getLocale();

    // Intl.NumberFormat.prototype.formatToParts is not yet compatible with all browsers & typescript versions
    // if (Intl.NumberFormat.prototype.formatToParts) {
    //   // use of "NumberFormat.formatToParts"
    //   // samples :
    //   //
    //   // console.table(new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2,style:"currency",currency:"USD", currencyDisplay:"narrowSymbol" }).formatToParts(11234.699))
    //   // (index) type value
    //   // 0	"integer"	"11"
    //   // 1	"group"	" "
    //   // 2	"integer"	"234"
    //   // 3	"decimal"	","
    //   // 4	"fraction"	"70"
    //   // 5	"literal"	" "
    //   // 6	"currency"	"$"
    //   //
    //   // console.table(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2,style:"currency",currency:"USD" }).formatToParts(11234.699))
    //   // (index) type value
    //   // 0	"currency"	"$"
    //   // 1	"integer"	"11"
    //   // 2	"group"	","
    //   // 3	"integer"	"234"
    //   // 4	"decimal"	"."
    //   // 5	"fraction"	"70"
    //   //
    //   // NumberFormat parameters documentation : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
    //   const priceParts = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, style: "currency", currency: currencyCode, currencyDisplay: "narrowSymbol" }).formatToParts(this.price);

    //   // override currencyString if defined
    //   if (this.currencyString != null) {
    //     const currencyPart = priceParts.find(p => p.type === 'currency');
    //     currencyPart.value = this.currencyString;
    //   }

    //   // seek for decimal part then join strings to integerPart / fractionPart
    //   const decimal = priceParts.find(p => p.type === 'decimal');
    //   const decimalIndex = priceParts.indexOf(decimal);

    //   this.integerPartString = priceParts.slice(0, decimalIndex).join('');
    //   this.fractionPartString = priceParts.slice(decimalIndex).join('');
    // }
    // else {

    // use of "NumberFormat.format" and custom decimal separator resolution

    const decimalSeparator = this.getDecimalSeparator(locale);
    let priceString: string;

    const overrideCurrency = (this.currencyString != null) || !isCurrencyCode(currencyCode);

    if (overrideCurrency) {
      // ouput with overriden currency symbol
      const dummyCurrencyCode = 'USD'; // used for replacement by an overriden currencyString
      const overridenCurrencyCode = this.currencyString ?? this.currencyCode;
      priceString = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, style: "currency", currency: dummyCurrencyCode, currencyDisplay: "code" })
        .format(this.price)
        .replace(dummyCurrencyCode, overridenCurrencyCode);
    } else {
      // ouput with specified currency code symbol
      try {
        priceString = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, style: "currency", currency: currencyCode, currencyDisplay: "narrowSymbol" }).format(this.price);
      } catch (error) {

        // if (error instanceof Error && error.message.indexOf('currencyDisplay') >= 0) {
        if (error && error.toString().indexOf('currencyDisplay') >= 0) {
          // unhandled currencyDisplay :  narrowSymbol may not be compatible with old iOS versions ('currencyDisplay must be either "code, "symbol", or "name"')
          priceString = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, style: "currency", currency: currencyCode, currencyDisplay: "symbol" }).format(this.price);
        }
        else {
          // rethrow
          throw error;
        }

      }
    }

    const priceParts = priceString.split(decimalSeparator);
    this.integerPartString = priceParts[0];
    this.fractionPartString = decimalSeparator + priceParts[1];
    // }
  }

  private getDecimalSeparator(locale: string): string {
    const numberWithDecimalSeparator = 1.1;

    return numberWithDecimalSeparator
      .toLocaleString(locale)
      .substring(1, 2);
  }

  // private getCurrencySymbol(str: string): string {
  //   //replace all numbers, spaces, commas, and periods with an empty string
  //   //we should only be left with the currency symbols
  //   return str.replace(/[\d\., ]/g, '');
  // }

  // private getCurrencySymbol(locale: string): string {
  //   return this._localeService.getCurrencySymbol(locale);
  // }


}
