<app-bottom-container [title]="'FORGOT_PASSWORD.TITLE' | transloco"
                      [hasLogo]="true">
  <!--Content-->
  <form [formGroup]="forgotPasswordForm"
        class="forgot-password-form-content"
        (ngSubmit)="onForgotPasswordClick()"
        fxLayout="column"
        fxLayoutAlign="start stretch">
    <!-- Description-->
    <div class="description">
      <app-html-content [data]="'FORGOT_PASSWORD.DESCRIPTION' | transloco">
      </app-html-content>
    </div>

    <!-- Email-->
    <mat-form-field class="forgot-password-field">
      <input type="email"
             formControlName="email"
             matInput
             [appAutofocus]="true"
             [placeholder]="'FORGOT_PASSWORD.EMAIL' | transloco"
             autocomplete="off"
             [attr.readonly]="submitting" />
      <mat-icon matSuffix
                color="accent"
                svgIcon="at"></mat-icon>
      <mat-error *ngIf="submitted && forgotPasswordForm.controls['email'].hasError('required')">{{ "COMMON.ERRORS.EMAIL_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="submitted && forgotPasswordForm.controls['email'].hasError('email')">{{ "COMMON.ERRORS.EMAIL_FORMAT" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Buttons-->
    <div class="forgot-password-buttons"
         fxLayout="column"
         fxLayoutGap="10px">
      <button class="full-width"
              mat-flat-button
              color="accent"
              [disabled]="submitting">
        {{ "FORGOT_PASSWORD.BUTTON" | transloco }}
        <app-loader *ngIf="submitting"></app-loader>
      </button>
      <!-- <button type="button" class="full-width" mat-button color="accent" (click)="onCancelClick() ">
        {{ "COMMON.CANCEL" | transloco }}
      </button> -->
    </div>
  </form>
</app-bottom-container>
