<ng-container *ngIf="isLoading$ | async; else elseTemplate">
  <app-mini-content-loader></app-mini-content-loader>
</ng-container>
<ng-template #elseTemplate>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       *ngrxLet="restaurant$ as restaurant"
       (click)="!isReadOnly && onPlaceSectionClick()"
       class="place-selector-container"
       [class.disabled]="isReadOnly">
    <mat-icon svgIcon="map-marker"
              [color]="isReadOnly ? 'accent' : iconColor"></mat-icon>
    <ng-container *ngIf="restaurant; else noRestaurantTemplate">
      <span> {{ restaurant.name }} </span>
      <mat-icon class="delivery-icon"
                *ngIf="!isReadOnly">keyboard_arrow_down</mat-icon>
    </ng-container>

    <ng-template #noRestaurantTemplate>
      {{ "COMMON.RESTAURANT" | transloco }}
    </ng-template>
  </div>
</ng-template>
