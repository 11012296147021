<mat-divider class="command-divider"></mat-divider>
<div class="section order-number-section"
     fxLayout="row"
     fxLayoutAlign="start end"
     fxLayoutGap="10px">
  <mat-icon class="command-icon"
            color="primary"
            [ngClass]="consumptionMode.iconColor"
            [svgIcon]="consumptionMode.icon"></mat-icon>
  <span class="order-number-label">{{ 'COMMAND.ORDER_NUMBER_PREFIX' | transloco }}</span>
  <span class="order-number-text">{{ order.ticketNumber }}</span>
</div>
<div fxLayoutAlign="end"
     class="order-consumptionmode">{{consumptionMode.transKey | transloco}}</div>

<ng-container *ngIf="order.pickupCode !== null && (order.status === 'Validated' || order.status === 'Finalized')">
  <mat-divider class="command-divider"></mat-divider>
  <div class="section qrcode-section"
       fxLayout="row"
       fxLayoutAlign="start center">
    <app-qrcode class="order-qrcode"
                [settings]="{
                              qrdata : (order.pickupCode + '|' + order.customer.customerId),
                              version : 1,
                              errorCorrectionLevel : 'M',
                              margin : 1,
                              width : 150
                            }"></app-qrcode>
    <div class="order-pickupcode">
      <span class="order-pickupcode-title">{{ 'COMMAND.PICKUPCODE' | transloco }} : </span>
      <span class="order-pickupcode-text">{{ order.pickupCode }}</span>
    </div>
  </div>
</ng-container>

<mat-divider class="command-divider"></mat-divider>
<div class="section delivery-date-section"
     fxLayout="row"
     fxLayoutAlign="start end"
     fxLayoutGap="10px">
  <mat-icon class="command-icon"
            svgIcon="clock"
            color="primary"></mat-icon>
  <app-delivery-date-selector [deliveryDate]="deliveryDate"
                              [isReadOnly]="isReadOnly"
                              [showIcon]="false"></app-delivery-date-selector>
</div>
<div fxLayoutAlign="end"
     class="order-ordered-date">
  {{ 'COMMAND.ORDERED_AT' | transloco: { date: (order.createdAt | translocoDate: { dateStyle: 'short' }), time: (order.createdAt | translocoDate: { timeStyle: 'short' }) } }}
</div>
<mat-divider class="command-divider"></mat-divider>
<div class="section customer-section"
     fxLayout="row"
     fxLayoutAlign="start end"
     fxLayoutGap="10px">
  <!--comment-->
  <mat-icon class="command-icon"
            color="primary">person_outline</mat-icon>
  <span>{{ customerDisplayName }}</span>
</div>
<mat-divider class="command-divider"></mat-divider>

<div class="section"
     fxLayout="row"
     fxLayoutAlign="start start"
     fxLayoutGap="10px">
  <!--Restaurant adress-->
  <mat-icon class="command-icon"
            color="primary">location_on</mat-icon>
  <span>
    {{ order.restaurant?.name }}
    <br />
    <span class="sub-section">{{ order.restaurant?.address }}</span>
  </span>
</div>

<mat-divider class="command-divider"></mat-divider>

<ng-container *ngIf="order?.processingState?.destination">
  <div class="section orderpreparation-destination-section"
       fxLayout="row"
       fxLayoutAlign="start end"
       fxLayoutGap="10px">
    <!--comment-->
    <mat-icon class="command-icon"
              color="primary">room_service</mat-icon>
    <span>{{ order.processingState.destination }}</span>
  </div>
  <mat-divider class="command-divider"></mat-divider>
</ng-container>

<div class="section comment-section"
     fxLayout="row"
     fxLayoutAlign="start start"
     fxLayoutGap="10px"
     *ngIf="!hideComment">
  <!--comment-->
  <mat-icon class="command-icon"
            color="primary">chat_bubble_outline</mat-icon>
  <span>{{ order.comment }}</span>
</div>
