import { CartPayment, ProblemDetails, ValidationProblemDetails } from '@app/core/api-client/models';

export class ModelsHelper {

  static implementsTKeys<T>(obj: any, keys: (keyof T)[]): obj is T {
    if (!obj || typeof obj !== 'object' || !Array.isArray(keys)) {
      return false;
    }

    const implementKeys = keys.reduce((impl, key) => impl && key in obj, true);

    return implementKeys;
  }

  static isNullOrUndefined<T>(val: T | undefined | null): val is undefined | null {
    return val === undefined || val === null;
  };

  static isProblemDetail(obj: any): obj is ProblemDetails {
    return ModelsHelper.implementsTKeys<ProblemDetails>(obj, ['title', 'instance']);
  }

  static isValidationProblemDetail(obj: any): obj is ValidationProblemDetails {
    return ModelsHelper.implementsTKeys<ValidationProblemDetails>(obj, ['title', 'instance', 'errors']);
  }

  static isCartPayment(obj: any): obj is CartPayment {
    return ModelsHelper.implementsTKeys<CartPayment>(obj, ['paymentMethodId']);
  }

  /**
   * https://stackblitz.com/edit/typescript-pwsl83
   */
  static flatten<T extends Record<string, any>>(object: T, path: string | null = null, separator = '.'): T {
    return Object.keys(object).reduce((acc: T, key: string): T => {
      const value = object[key];
      const newPath = Array.isArray(object)
        ? `${path ? path : ''}[${key}]`
        : [path, key].filter(Boolean).join(separator);
      const isObject = [
        typeof value === 'object',
        value !== null,
        !(value instanceof Date),
        !(value instanceof RegExp),
        !(Array.isArray(value) && value.length === 0),
      ].every(Boolean);

      return isObject
        ? { ...acc, ...ModelsHelper.flatten(value, newPath, separator) }
        : { ...acc, [newPath]: value };
    }, {} as T);
  }

}
