import {
  ChangeDetectionStrategy, Component,
  EventEmitter,
  Input, OnInit, Output
} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent implements OnInit {
  @Input() path: string;
  @Input() stopNavigation: boolean;
  @Input() style: { [klass: string]: any; };
  @Input() fixed: boolean;

  @Output() goBack = new EventEmitter();

  constructor(
    private _navigationService: NavigationService,
    private _router: Router
  ) { }

  ngOnInit(): void { }

  onGoBack() {

    this.goBack.emit();

    if (this.stopNavigation) {
      return;
    }

    if (!this.path) {
      this._navigationService.back();
    } else {
      this._router.navigate([this.path]);
    }
  }
}
