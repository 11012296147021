
import { filter } from 'rxjs/operators';
import { AnalyticsDimensions } from '../models/analytics-dimensions.model';
import { AnalyticsProvider } from './analytics-provider';
import { Inject, Injectable } from '@angular/core';

import { Router, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { AppSettings } from '@app/core/settings/app-settings';
import { AnalyticsEvent } from '../models/analytics-event.model';
import { DOCUMENT, Location } from '@angular/common';

// declare var _gaq: any;
// declare var ga: any;

// interface GoogleAnalyticsCommand {
//   command: string;
//   action: string;
//   values?: { [parameterName: string]: string | number | boolean };
// }
type GtagFnArgs = (string | Date | { [param: string]: string | number | boolean })[];
// type GtagFn = (...args: GtagFnArgs) => {}; // https://github.com/maxandriani/ngx-google-analytics/blob/master/projects/ngx-google-analytics/src/lib/types/gtag.type.ts
// type DataLayer = Array<(string | { [param: string]: string })>; // https://github.com/maxandriani/ngx-google-analytics/blob/master/projects/ngx-google-analytics/src/lib/types/data-layer.type.ts

// function getDataLayerFn(window: Window): DataLayer {
//   return (window)
// eslint-disable-next-line , @typescript-eslint/dot-notation
//     ? window['dataLayer'] = window['dataLayer'] || [] // 'dataLayer' => see https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag
//     : null;
// }

// function getGtagFn(window: Window, dataLayer: DataLayer): GtagFn {
//   return (window)
// eslint-disable-next-line @typescript-eslint/dot-notation
//     ? window['gtag'] = (window['gtag'] || (() => { dataLayer.push(arguments as any); })) // see https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag
//     : null;
// }

// const gtag: GtagFn = getGtagFn(window, getDataLayerFn(window));

/**
 * https://stackblitz.com/edit/typescript-pwsl83
 */
function flatten<T extends Record<string, any>>(object: T, path: string | null = null, separator = '.'): T {
  return Object.keys(object).reduce((acc: T, key: string): T => {
    const value = object[key];
    const newPath = Array.isArray(object)
      ? `${path ? path : ''}[${key}]`
      : [path, key].filter(Boolean).join(separator);
    const isObject = [
      typeof value === 'object',
      value !== null,
      !(value instanceof Date),
      !(value instanceof RegExp),
      !(Array.isArray(value) && value.length === 0),
    ].every(Boolean);

    return isObject
      ? { ...acc, ...flatten(value, newPath, separator) }
      : { ...acc, [newPath]: value };
  }, {} as T);
}

@Injectable()
export class GoogleAnalyticsProvider extends AnalyticsProvider {
  loadStartTime: number = null;
  loadTime: number = null;

  /* eslint-disable @typescript-eslint/dot-notation */

  private init_gtag_beforeload() {
    if (!window) {
      return;
    }
    window['dataLayer'] = window['dataLayer'] || [];
  }

  private gtag(...args: GtagFnArgs) {
    (window['dataLayer'] as any[]).push(arguments);
  }

  /* eslint-enable @typescript-eslint/dot-notation */

  constructor(
    private _router: Router,
    private _appSettings: AppSettings,
    private _location: Location,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    super();

    if (!_appSettings.googleAnalyticsKey) {
      // Add proper log
      console.warn('googleAnalyticsKey setting not found : GA is disabled');
      return;
    }

    // TODO migrate to gtag https://developers.google.com/analytics/devguides/collection/gtagjs

    // eslint-disable  , @typescript-eslint/dot-notation, prefer-arrow/prefer-arrow-functions, 
    // // analytics doc : https://developers.google.com/analytics/devguides/collection/analyticsjs
    // (function(i, s, o, g, r, a, m) {
    //   i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function() {
    //     (i[r].q = i[r].q || []).push(arguments);
    //   }, i[r].l = new Date().valueOf(); a = s.createElement(o),
    //     m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
    // })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    // eslint-enable  @typescript-eslint/dot-notation, prefer-arrow/prefer-arrow-functions

    // ga('create', _appSettings.googleAnalyticsKey, 'auto');

    const uri = `https://www.googletagmanager.com/gtag/js?id=${_appSettings.googleAnalyticsKey}`;
    // these commands should run first!
    this.init_gtag_beforeload();
    this.gtag('js', new Date());
    this.gtag('config', _appSettings.googleAnalyticsKey, { send_page_view: false }); // https://developers.google.com/gtagjs/reference/api#config

    // add user consent
    // see: https://blog.google/products/marketingplatform/360/measure-conversions-while-respecting-user-consent-choices/
    //      https://www.cookiebot.com/en/google-consent-mode/

    this.gtag('consent', 'default', {
      // 'ad_storage': 'denied',
      analytics_storage: 'granted'
    });

    // add script to head tag
    // a more generic way : http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/ => (!) add async=true
    const scriptEl: HTMLScriptElement = this._document.createElement('script');
    scriptEl.async = true;
    scriptEl.src = uri;
    const headEl: HTMLHeadElement = this._document.getElementsByTagName('head')[0];
    headEl.appendChild(scriptEl);

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event => this.startTimer());

    this._router.events
      .pipe(
        filter(event => (event instanceof NavigationError) || (event instanceof NavigationEnd))
      )
      .subscribe(error => this.stopTimer());
  }

  startTimer() {
    this.loadStartTime = Date.now();
    this.loadTime = null;
  }

  stopTimer() {
    this.loadTime = Date.now() - this.loadStartTime;
    this.loadStartTime = null;
  }

  pageTrack_internal(title: string, path: string, dimensions: AnalyticsDimensions) {
    // if ((typeof _gaq !== 'undefined') && _gaq) {
    //   _gaq.push(['_trackPageview', path]);
    // }
    // if ((typeof ga !== 'undefined') && ga) {
    //   this.prepareDimensionsTracking(dimensions);

    //   ga('send', 'pageview', path);
    // }

    this.prepareDimensionsTracking(dimensions);
    // this.gtag('config', this._appSettings.googleAnalyticsKey, { // https://developers.google.com/gtagjs/reference/api#config
    //   page_path: path,
    //   page_title: title
    // });

    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
    this.gtag('event', 'page_view', {
      page_title: title,
      // page_location: '<Page Location>',
      page_path: path
    });
  }

  eventTrack_internal(event: AnalyticsEvent, dimensions: AnalyticsDimensions) {

    // // GA requires that eventValue be an integer, see:
    // // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
    // // https://github.com/luisfarzati/angulartics/issues/81
    // // https://developers.google.com/analytics/devguides/collection/analyticsjs/events#event_fields

    // const eventOptions = {
    //   eventCategory: event.category || 'GLOBAL', // Google Analytics requires an Event Category
    //   eventAction: event.eventName,
    //   eventLabel: JSON.stringify(event),
    //   eventValue: event.metricValue,
    //   nonInteraction: !event.isUserInteraction, // https://support.google.com/analytics/answer/1033068#NonInteractionEvents
    //   page: event.page || this._location.path(true),
    //   userId: dimensions.userId
    // };

    // if (typeof ga !== 'undefined') {
    //   this.prepareDimensionsTracking(dimensions);
    //   ga('send', 'event', eventOptions);
    // }
    // else if (typeof _gaq !== 'undefined') {
    //   _gaq.push(['_trackEvent', eventOptions.eventCategory, eventOptions.eventAction, eventOptions.eventLabel, eventOptions.eventValue, eventOptions.nonInteraction]);
    // }

    // GTAG EVENTS https://developers.google.com/gtagjs/reference/api#event
    // TODO add "recommended events" => https://developers.google.com/gtagjs/reference/event

    this.prepareDimensionsTracking(dimensions);
    const flattenEvent = flatten(event); // google analytics doesn't handle deep objects
    this.gtag('event', event.eventName, flattenEvent);

  }

  setUserId_internal(userId: string | null) {
    //   ga('set', '&uid', userId);
    //   ga('set', 'userId', userId); // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
    this.gtag('set', {
      '&uid': userId,
      userId: userId
    });
  }

  clear() {
    // Nothing specific here
  }

  private prepareDimensionsTracking(dimensions: any) {
    this.setUserId_internal(dimensions.userId);

    if (Object.keys(dimensions).length > 0) {
      this.gtag('set', dimensions);
    }
  }

}
