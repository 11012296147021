import { Injectable } from '@angular/core';
import { AppSettings } from './app-settings';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  public appSettings: AppSettings;

  constructor() { }

  /**
   * Charge la configuration à partir d'un fichier sur le serveur
   */
  public load(): Promise<void | AppSettings> {
    const url = 'client-settings.json';

    // On utilise un XmlHttpRequest (ou fetch) plutôt que HttpClient pour éviter l'injection des dépendances des interceptors
    // see: https://sebastienollivier.fr/blog/angular/pourquoi-il-ne-faut-pas-utiliser-httpclient-dans-appinitializer
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // TODO prefer HttpXhrBackend usage
      xhr.open('GET', url);

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          this.appSettings = this.appSettings || new AppSettings();
          Object.assign(this.appSettings, JSON.parse(xhr.responseText));
          resolve(this.appSettings);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          // TODO LOG THIS
          const error = `appsettings endpoint not reachable (${xhr.statusText})`;
          console.error(error);
          reject(error);
        }
      });

      xhr.send(null);
    });
  }
}
