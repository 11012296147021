import { ConsumptionModeType } from "@app/core/api-client/models";

export const consumptionModes: ConsumptionMode[] = [
  {
    type: ConsumptionModeType.TakeAway,
    transKey: 'MENU.CONSUMPTIONMODE.TAKEAWAY',
    descriptionTransKey: '',
    icon: 'take-away'
  },
  {
    type: ConsumptionModeType.SitIn,
    transKey: 'MENU.CONSUMPTIONMODE.SITIN',
    descriptionTransKey: '',
    icon: 'sit-in'
  },
  {
    type: ConsumptionModeType.Delivery,
    transKey: 'MENU.CONSUMPTIONMODE.DELIVERY',
    descriptionTransKey: '',
    icon: 'delivery'
  },
  {
    type: ConsumptionModeType.Drive,
    transKey: 'MENU.CONSUMPTIONMODE.DRIVE',
    descriptionTransKey: '',
    icon: 'drive'
  },
]

export interface ConsumptionMode {
  type: ConsumptionModeType;
  transKey: string;
  descriptionTransKey?: string;
  icon: string;
  iconColor?: string;
}
