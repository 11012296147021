import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from '@app/core/settings/app-settings';
import { CookieService } from '@app/core/storage/cookie.service';
import { SessionStorageProvider } from '@app/core/storage/session-storage-provider';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinct, map, Observable, shareReplay, tap } from 'rxjs';

const forceTenantQueryStringParam = 'force-tenant';
const forceTenantStorageParam = 'force-tenant';
const forceTenantCookieParam = 'force-tenant';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private _tenantSuject: BehaviorSubject<string>;
  private _tenant$: Observable<string>;

  constructor(
    private _appSettings: AppSettings,
    private _cookieService: CookieService,
    private _router: ActivatedRoute,
    private _storage: SessionStorageProvider
  ) {
    this._tenantSuject = new BehaviorSubject<string>(this.resolve());
    this.monitorQueryString();
    this._tenant$ = this._tenantSuject.asObservable().pipe(distinct());
    // this.monitorCookies();
  }

  public resolve(): string | null {
    return this._router.snapshot.queryParamMap.get(forceTenantQueryStringParam)
      || this._storage.getString(forceTenantStorageParam)
      || this._cookieService.get(forceTenantCookieParam)
      || this._appSettings.forceTenant;
  }

  public getTenant$() {
    return this._tenant$;
  }

  private monitorQueryString() {
    //observe querystring parameter and store it for the session lifetime
    this._router.queryParamMap
      .pipe(
        untilDestroyed(this),
        map(paramMap => paramMap.get(forceTenantQueryStringParam)),
        distinct(),
        tap(tenant => {
          if (tenant) {
            this._storage.setString(forceTenantStorageParam, tenant);
          }
          this._tenantSuject.next(this.resolve());
        }),
      )
      .subscribe();
  }

  // private monitorCookies() {

  // }

}
