import { LogService } from './log.service';

export class Logger {

  constructor(private _logService: LogService, private _name: string) {
  }

  public debug(msg: any): void {
    this._logService.debug(msg, this._name);
  }

  public info(msg: any, logger?: string): void {
    this._logService.info(msg, this._name);
  }

  public warn(msg: any, logger?: string): void {
    this._logService.warn(msg, this._name);
  }

  public error(msg: any, logger?: string): void {
    this._logService.error(msg, this._name);
  }

  public fatal(msg: any, logger?: string): void {
    this._logService.fatal(msg, this._name);
  }
}

