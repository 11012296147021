import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PlaceSelectorDialogResult } from '../place-selector-dialog/place-selector-dialog-result.model';

@Component({
  selector: 'app-menu-context-selector',
  templateUrl: './menu-context-selector.component.html',
  styleUrls: ['./menu-context-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuContextSelectorComponent implements OnInit {

  @Input() deliveryDate: Date;
  @Input() orderingIsOpen: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() iconColor: ThemePalette = 'primary';

  @Output() deliveryDateChange = new EventEmitter<Date>();
  @Output() placeEditEvent = new EventEmitter<PlaceSelectorDialogResult>();

  constructor() { }

  ngOnInit(): void {
  }

  onDeliveryDateChange(selectedDate: Date) {
    this.deliveryDateChange.emit(selectedDate);
  }

  onPlaceSectionClick(event: PlaceSelectorDialogResult) {
    this.placeEditEvent.emit(event);
  }

}
