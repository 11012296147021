import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Order, User } from '@app/core/api-client/models';
import { CartProduct } from '@app/core/services/cart/models/cart.model';
import { MapperService } from '@app/core/services/mapper.service';

@Component({
  selector: 'app-command-detail',
  templateUrl: './command-detail.component.html',
  styleUrls: ['./command-detail.component.scss'],
})
export class CommandDetailComponent implements OnChanges {
  @Input() order: Order;
  @Output() exit = new EventEmitter();
  @Output() trackOrder = new EventEmitter<Order>();

  cartProductList: CartProduct[];

  constructor(private _mapperService: MapperService) { }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (changes['order']) {
      this.cartProductList = this.order.products.map(p => this._mapperService.orderProductToCartProduct(p));
    }
  }

  onExit() {
    this.exit.emit();
  }

  goToTrackOrder(){
    this.trackOrder.emit(this.order);
  }
}
