import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Order } from '@app/core/api-client/models';
import { OrderService } from '@app/core/services/order/order.service';
import { BackBottomSheet } from '@app/shared/classes/back-bottom-sheet';

@Component({
  selector: 'app-command-detail-sheet',
  templateUrl: './command-detail-sheet.component.html',
  styleUrls: ['./command-detail-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CommandDetailSheetComponent extends BackBottomSheet<CommandDetailSheetComponent> implements OnInit {

  constructor(
    bottomSheetRef: MatBottomSheetRef<CommandDetailSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Order,
    _router: Router,
    @Inject(PLATFORM_ID) platformId: object,
    public cd : ChangeDetectorRef,
    private _orderService: OrderService
  ) {
    super(bottomSheetRef, _router, platformId);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.removeStateOnDestroy = true;
  }

  onExit() {
    this.bottomSheetRef.dismiss(true);
  }

  goToTrackOrder(order: Order) {
    // this._navigationService.navigateToOrderTrackingPage(order);
    // this._orderService.processOrderActions(order, false).subscribe();
    this._orderService.displayOrderTableServiceDialog(order);
  }
}
