import { Injectable } from '@angular/core';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  appInsights: ApplicationInsights | null;

  private _queue: Array<() => void> = [];
  private _isLoaded = false;

  constructor() { }

  downloadAndSetup(instrumentationKey: string, appVersion: string, appBuild: string, tenant: string) {

    this.appInsights = new ApplicationInsights({
      config: {
        // see doc : https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#configuration
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: false, // route changes are tracked manually. doc: Automatically track route changes in Single Page Applications (SPA).
        isCookieUseDisabled: true,
        enableAjaxErrorStatusText: true, // Default false. If true, include response error data text in dependency event on failed AJAX requests.
        disableFetchTracking: false, // If true, Fetch requests are not autocollected. Default is true
        disableCorrelationHeaders: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true, // If true, AJAX & Fetch request headers is tracked, default is false.
        accountId: tenant,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C
      },
      queue: this._queue
    });

    this.queue(() => {
      // paramétrage de l'applicationContext (version)
      this.appInsights.context.application.ver = appVersion;
      this.appInsights.context.application.build = appBuild;
    });

    this.appInsights.loadAppInsights();
    this._isLoaded = true;
  }


  /**
   *  Append action to AppInsigth queue (usefull when lazy loading ApplicationInsights)
   */
  queue(action: (appInsights: ApplicationInsights) => void) {
    if (this._isLoaded) {
      // if appInsight is loaded (queue has been processed), execute action
      action(this.appInsights);
    }
    else {
      // else delay execution when appInsight is loaded ( when calling loadAppInsights() )
      this._queue.push(() => action(this.appInsights));
    }
  }

}
