import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Order, OrderPreparation } from '@app/core/api-client/models';

@Component({
  selector: 'app-order-preparation-destination-orders-summary',
  templateUrl: './order-preparation-destination-orders-summary.component.html',
  styleUrls: ['./order-preparation-destination-orders-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationDestinationOrdersSummaryComponent implements OnInit, OnChanges {

  @Input() order: Order | null;
  @Input() orderPreparation: OrderPreparation | null;

  ordersPendingCount: number = 0;
  ordersInPreparationCount: number = 0;
  ordersCount: number = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // orderPreparation is loaded asynchroneously, so it may be null at first
    this.ordersPendingCount = this.orderPreparation?.destinationOrdersPendingCount ?? this.order?.processingState.destinationOrdersPendingCount ?? 0;
    this.ordersInPreparationCount = this.orderPreparation?.destinationOrdersInPreparationCount ?? this.order?.processingState.destinationOrdersInPreparationCount ?? 0;
    this.ordersCount = this.orderPreparation?.ordersCount ?? this.order?.processingState.ordersCount ?? 0;
  }

  ngOnInit(): void {
  }

}
