import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DefaultNoComponentGlobalConfig, ToastrService } from 'ngx-toastr';
import { ErrorHandlingService } from './error-handling.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorSeverity } from './models/error-model.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastErrorsHandler implements ErrorHandler {
  private _lazyNotificationService: ToastrService;
  private get _notificationService(): ToastrService {
    // lazy load ToastrService to prevent "cyclic dependency!"" on ApplicationRef
    return this._lazyNotificationService || (this._lazyNotificationService = this._injector.get(ToastrService));
  }

  private static mapErrorSeverityToToastType(severity: ErrorSeverity): string {

    switch (severity) {
      case ErrorSeverity.Information:
        return DefaultNoComponentGlobalConfig.iconClasses.info;
      case ErrorSeverity.Warning:
        return DefaultNoComponentGlobalConfig.iconClasses.warning;
      case ErrorSeverity.Error:
        return DefaultNoComponentGlobalConfig.iconClasses.error;

      default:
        return DefaultNoComponentGlobalConfig.iconClasses.error;
    }
  }

  // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
  constructor(
    private _injector: Injector,
    private _errorHandlingService: ErrorHandlingService,
  ) {
  }

  handleError(error: Error) {

    this._errorHandlingService.formatError(error)
      .pipe(
        take(1),
        switchMap(errorModel => {
          const toast = this._notificationService.show(
            errorModel.message + '\r' + (errorModel.details || ''),
            errorModel.title,
            null,
            ToastErrorsHandler.mapErrorSeverityToToastType(errorModel.severity)
          );

          // if error has a resolve action, invoke it when toast is dismissed
          if (errorModel.resolve) {
            return toast.onHidden
              .pipe(
                tap(() => errorModel.resolve()),
                map(() => errorModel)
              )
          }

          return of(errorModel);
        })
      ).subscribe();

  }


}
