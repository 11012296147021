/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetSite$Json } from '../fn/sites/get-site-json';
import { GetSite$Json$Params } from '../fn/sites/get-site-json';
import { GetSite$Plain } from '../fn/sites/get-site-plain';
import { GetSite$Plain$Params } from '../fn/sites/get-site-plain';
import { SearchSites$Json } from '../fn/sites/search-sites-json';
import { SearchSites$Json$Params } from '../fn/sites/search-sites-json';
import { SearchSites$Plain } from '../fn/sites/search-sites-plain';
import { SearchSites$Plain$Params } from '../fn/sites/search-sites-plain';
import { SearchSitesResultItem } from '../models/search-sites-result-item';
import { Site } from '../models/site';

@Injectable({ providedIn: 'root' })
export class ApiSitesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSitesSearch()` */
  static readonly GetSitesSearchPath = '/api/Sites/search';

  /**
   * get-sites-search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SearchSites$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchSites$Plain$Response(params?: SearchSites$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchSitesResultItem>>> {
    return SearchSites$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-sites-search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SearchSites$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchSites$Plain(params?: SearchSites$Plain$Params, context?: HttpContext): Observable<Array<SearchSitesResultItem>> {
    return this.SearchSites$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchSitesResultItem>>): Array<SearchSitesResultItem> => r.body)
    );
  }

  /**
   * get-sites-search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SearchSites$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchSites$Json$Response(params?: SearchSites$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchSitesResultItem>>> {
    return SearchSites$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-sites-search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SearchSites$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchSites$Json(params?: SearchSites$Json$Params, context?: HttpContext): Observable<Array<SearchSitesResultItem>> {
    return this.SearchSites$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SearchSitesResultItem>>): Array<SearchSitesResultItem> => r.body)
    );
  }

  /** Path part for operation `getSitesBySiteid()` */
  static readonly GetSitesBySiteidPath = '/api/Sites/{siteId}';

  /**
   * get-sites-by-siteid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSite$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSite$Plain$Response(params: GetSite$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Site>> {
    return GetSite$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-sites-by-siteid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSite$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSite$Plain(params: GetSite$Plain$Params, context?: HttpContext): Observable<Site> {
    return this.GetSite$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Site>): Site => r.body)
    );
  }

  /**
   * get-sites-by-siteid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSite$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSite$Json$Response(params: GetSite$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Site>> {
    return GetSite$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-sites-by-siteid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSite$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSite$Json(params: GetSite$Json$Params, context?: HttpContext): Observable<Site> {
    return this.GetSite$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Site>): Site => r.body)
    );
  }

}
