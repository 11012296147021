import { Order, OrderProduct } from "../api-client/models";

/**
 * Returns a description of the order (according the most expensive product)
 */
export function getOrderDescription(order: Order | null) {
  // return most expensive product (by unitPrice)
  const mostExpensiveProduct = getOrderMostExpensiveProduct(order);
  return mostExpensiveProduct?.name;
}

/**
 * Returns the most expensive product of an order
 */
export function getOrderMostExpensiveProduct(order: Order | null): OrderProduct | null {
  // return most expensive product (by unitPrice)
  const mostExpensiveProduct = order?.products
    .slice() //clone because sort mutates
    .sort((a, b) => (a.unitPrice || 0) - (b.unitPrice || 0)) // sort by unitPrice asc
    .slice(-1)[0]; // take last

  return mostExpensiveProduct;
}
