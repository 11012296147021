<div class="command-item">
  <mat-icon class="command-item-status-icon"
            [ngClass]="commandStatus?.iconColor">{{ commandStatus?.icon }}</mat-icon>
  <div class="command-item-content">
    <div class="command-name">
      <!-- <span class="command-number">n° {{ order?.ticketNumber }}</span> -->
      <mat-icon class="command-consumption-icon"
            [svgIcon]="consumptionMode.icon"
            ></mat-icon>
      <span class="command-date numeric-monospace">{{ order?.deliveryDate | translocoDate: { dateStyle: 'short' } }} {{ order?.deliveryDate | translocoDate: { timeStyle: 'short' } }}</span>
      <span class="separator"></span>
      <span class="command-status">{{ commandStatus?.transKey | transloco }}</span>
      <span class="separator"></span>
      <span class="command-amount numeric-monospace">
        <app-price [price]="order?.totalInclTaxWithDiscount"
                   [floating]="false"
                   [currencyCode]="order?.restaurant.currency"></app-price>
      </span>
    </div>
    <div class="command-description">
      <span class="command-description-text">{{ "COMMON.NUMBER_ABBREVIATION" | transloco }}{{ order.ticketNumber }} : {{ getOrderDescription(order) }}</span>
    </div>
  </div>
  <mat-icon class="command-item-select-icon">keyboard_arrow_right</mat-icon>
</div>
