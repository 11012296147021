import { Component, OnInit, Input } from '@angular/core';
import { CartProduct } from '@app/core/services/cart/models/cart.model';

@Component({
  selector: 'app-product-recap',
  templateUrl: './product-recap.component.html',
  styleUrls: ['./product-recap.component.scss']
})
export class ProductRecapComponent implements OnInit {
  @Input() cartProduct: CartProduct;
  @Input() currencyCode: string | null = null; // restaurant currency

  constructor() { }

  ngOnInit(): void {
  }

}
