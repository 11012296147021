/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Order } from '../../models/order';
import { SwileSigninResponse } from '../../models/swile-signin-response';

export interface SwileSignin$Plain$Params {
  'force-tenant'?: String;
      body?: SwileSigninResponse
}

export function SwileSignin$Plain(http: HttpClient, rootUrl: string, params?: SwileSignin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
  const rb = new RequestBuilder(rootUrl, SwileSignin$Plain.PATH, 'post');
  if (params) {
    rb.header('force-tenant', params['force-tenant'], {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Order>;
    })
  );
}

SwileSignin$Plain.PATH = '/api/Orders/swile-signin';
