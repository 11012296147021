<div class="command-list"
     *ngIf="commandList?.length > 0; else emptyOrdersTemplate">
  <div *ngFor="let command of commandList">
    <div class="command-list-row"
         matRipple>
      <app-command-list-item [order]="command"
                             (click)="commandOnClick(command)"></app-command-list-item>
    </div>
    <mat-divider class="command-row-divider"></mat-divider>
  </div>
  <app-command-list-template *ngIf="isLoading"></app-command-list-template>
  <div *ngIf="displayMoreOrder"
       class="command-list-more">
    <button class="command-list-more-button full-width"
            mat-flat-button
            color="accent"
            (click)="moreOrdersOnClick()">
      {{ 'COMMAND.GET_MORE' | transloco }}
    </button>
  </div>
</div>

<ng-template #emptyOrdersTemplate>
  <div *ngIf="!isLoading"
       class="commands-empty"
       fxLayout="column"
       fxLayoutAlign="center center">
    <div class="commands-title">
      {{ 'COMMAND.NO_COMMANDS' | transloco }}
    </div>
    <div *ngIf="(isEmbedded === false)">
      <button class="full-width"
              mat-flat-button
              color="primary"
              (click)="onCreateOrderClick()">
        {{ 'COMMAND.CREATE' | transloco }}
      </button>
    </div>
  </div>
  <app-command-list-template *ngIf="isLoading"></app-command-list-template>
</ng-template>
