/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExportRestaurantMenu$Json } from '../fn/restaurants/export-restaurant-menu-json';
import { ExportRestaurantMenu$Json$Params } from '../fn/restaurants/export-restaurant-menu-json';
import { ExportRestaurantMenu$Plain } from '../fn/restaurants/export-restaurant-menu-plain';
import { ExportRestaurantMenu$Plain$Params } from '../fn/restaurants/export-restaurant-menu-plain';
import { GetRestaurant$Json } from '../fn/restaurants/get-restaurant-json';
import { GetRestaurant$Json$Params } from '../fn/restaurants/get-restaurant-json';
import { GetRestaurant$Plain } from '../fn/restaurants/get-restaurant-plain';
import { GetRestaurant$Plain$Params } from '../fn/restaurants/get-restaurant-plain';
import { GetRestaurantConsumptionMode$Json } from '../fn/restaurants/get-restaurant-consumption-mode-json';
import { GetRestaurantConsumptionMode$Json$Params } from '../fn/restaurants/get-restaurant-consumption-mode-json';
import { GetRestaurantConsumptionMode$Plain } from '../fn/restaurants/get-restaurant-consumption-mode-plain';
import { GetRestaurantConsumptionMode$Plain$Params } from '../fn/restaurants/get-restaurant-consumption-mode-plain';
import { GetRestaurantMenu$Json } from '../fn/restaurants/get-restaurant-menu-json';
import { GetRestaurantMenu$Json$Params } from '../fn/restaurants/get-restaurant-menu-json';
import { GetRestaurantMenu$Plain } from '../fn/restaurants/get-restaurant-menu-plain';
import { GetRestaurantMenu$Plain$Params } from '../fn/restaurants/get-restaurant-menu-plain';
import { GetRestaurantOrderingSettings$Json } from '../fn/restaurants/get-restaurant-ordering-settings-json';
import { GetRestaurantOrderingSettings$Json$Params } from '../fn/restaurants/get-restaurant-ordering-settings-json';
import { GetRestaurantOrderingSettings$Plain } from '../fn/restaurants/get-restaurant-ordering-settings-plain';
import { GetRestaurantOrderingSettings$Plain$Params } from '../fn/restaurants/get-restaurant-ordering-settings-plain';
import { GetSiteRestaurant$Json } from '../fn/restaurants/get-site-restaurant-json';
import { GetSiteRestaurant$Json$Params } from '../fn/restaurants/get-site-restaurant-json';
import { GetSiteRestaurant$Plain } from '../fn/restaurants/get-site-restaurant-plain';
import { GetSiteRestaurant$Plain$Params } from '../fn/restaurants/get-site-restaurant-plain';
import { GetSiteRestaurantMenu$Json } from '../fn/restaurants/get-site-restaurant-menu-json';
import { GetSiteRestaurantMenu$Json$Params } from '../fn/restaurants/get-site-restaurant-menu-json';
import { GetSiteRestaurantMenu$Plain } from '../fn/restaurants/get-site-restaurant-menu-plain';
import { GetSiteRestaurantMenu$Plain$Params } from '../fn/restaurants/get-site-restaurant-menu-plain';
import { Menu } from '../models/menu';
import { Restaurant } from '../models/restaurant';
import { RestaurantConsumptionMode } from '../models/restaurant-consumption-mode';
import { RestaurantOrderingSettings } from '../models/restaurant-ordering-settings';
import { SearchRestaurants$Json } from '../fn/restaurants/search-restaurants-json';
import { SearchRestaurants$Json$Params } from '../fn/restaurants/search-restaurants-json';
import { SearchRestaurants$Plain } from '../fn/restaurants/search-restaurants-plain';
import { SearchRestaurants$Plain$Params } from '../fn/restaurants/search-restaurants-plain';

@Injectable({ providedIn: 'root' })
export class ApiRestaurantsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRestaurantsSearch()` */
  static readonly GetRestaurantsSearchPath = '/api/Restaurants/search';

  /**
   * get-restaurants-search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SearchRestaurants$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchRestaurants$Plain$Response(params?: SearchRestaurants$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Restaurant>>> {
    return SearchRestaurants$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SearchRestaurants$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchRestaurants$Plain(params?: SearchRestaurants$Plain$Params, context?: HttpContext): Observable<Array<Restaurant>> {
    return this.SearchRestaurants$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Restaurant>>): Array<Restaurant> => r.body)
    );
  }

  /**
   * get-restaurants-search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SearchRestaurants$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchRestaurants$Json$Response(params?: SearchRestaurants$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Restaurant>>> {
    return SearchRestaurants$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SearchRestaurants$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SearchRestaurants$Json(params?: SearchRestaurants$Json$Params, context?: HttpContext): Observable<Array<Restaurant>> {
    return this.SearchRestaurants$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Restaurant>>): Array<Restaurant> => r.body)
    );
  }

  /** Path part for operation `getRestaurantsByRestaurantid()` */
  static readonly GetRestaurantsByRestaurantidPath = '/api/Restaurants/{restaurantId}';

  /**
   * get-restaurants-by-restaurantid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurant$Plain$Response(params: GetRestaurant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Restaurant>> {
    return GetRestaurant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-restaurantid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurant$Plain(params: GetRestaurant$Plain$Params, context?: HttpContext): Observable<Restaurant> {
    return this.GetRestaurant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Restaurant>): Restaurant => r.body)
    );
  }

  /**
   * get-restaurants-by-restaurantid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurant$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurant$Json$Response(params: GetRestaurant$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Restaurant>> {
    return GetRestaurant$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-restaurantid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurant$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurant$Json(params: GetRestaurant$Json$Params, context?: HttpContext): Observable<Restaurant> {
    return this.GetRestaurant$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Restaurant>): Restaurant => r.body)
    );
  }

  /** Path part for operation `getRestaurantsByRestaurantidMenu()` */
  static readonly GetRestaurantsByRestaurantidMenuPath = '/api/Restaurants/{restaurantId}/menu';

  /**
   * get-restaurants-by-restaurantid--menu.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantMenu$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantMenu$Plain$Response(params: GetRestaurantMenu$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return GetRestaurantMenu$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-restaurantid--menu.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantMenu$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantMenu$Plain(params: GetRestaurantMenu$Plain$Params, context?: HttpContext): Observable<Menu> {
    return this.GetRestaurantMenu$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

  /**
   * get-restaurants-by-restaurantid--menu.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantMenu$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantMenu$Json$Response(params: GetRestaurantMenu$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return GetRestaurantMenu$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-restaurantid--menu.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantMenu$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantMenu$Json(params: GetRestaurantMenu$Json$Params, context?: HttpContext): Observable<Menu> {
    return this.GetRestaurantMenu$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

  /** Path part for operation `getRestaurantsBySiteidByRestaurantid()` */
  static readonly GetRestaurantsBySiteidByRestaurantidPath = '/api/Restaurants/{siteId}/{restaurantId}';

  /**
   * get-restaurants-by-siteid--by-restaurantid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSiteRestaurant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurant$Plain$Response(params: GetSiteRestaurant$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Restaurant>> {
    return GetSiteRestaurant$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSiteRestaurant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurant$Plain(params: GetSiteRestaurant$Plain$Params, context?: HttpContext): Observable<Restaurant> {
    return this.GetSiteRestaurant$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Restaurant>): Restaurant => r.body)
    );
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSiteRestaurant$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurant$Json$Response(params: GetSiteRestaurant$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Restaurant>> {
    return GetSiteRestaurant$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSiteRestaurant$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurant$Json(params: GetSiteRestaurant$Json$Params, context?: HttpContext): Observable<Restaurant> {
    return this.GetSiteRestaurant$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Restaurant>): Restaurant => r.body)
    );
  }

  /** Path part for operation `getRestaurantsBySiteidByRestaurantidMenu()` */
  static readonly GetRestaurantsBySiteidByRestaurantidMenuPath = '/api/Restaurants/{siteId}/{restaurantId}/menu';

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSiteRestaurantMenu$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurantMenu$Plain$Response(params: GetSiteRestaurantMenu$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return GetSiteRestaurantMenu$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSiteRestaurantMenu$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurantMenu$Plain(params: GetSiteRestaurantMenu$Plain$Params, context?: HttpContext): Observable<Menu> {
    return this.GetSiteRestaurantMenu$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSiteRestaurantMenu$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurantMenu$Json$Response(params: GetSiteRestaurantMenu$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return GetSiteRestaurantMenu$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSiteRestaurantMenu$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSiteRestaurantMenu$Json(params: GetSiteRestaurantMenu$Json$Params, context?: HttpContext): Observable<Menu> {
    return this.GetSiteRestaurantMenu$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

  /** Path part for operation `getRestaurantsBySiteidByRestaurantidSettings()` */
  static readonly GetRestaurantsBySiteidByRestaurantidSettingsPath = '/api/Restaurants/{siteId}/{restaurantId}/settings';

  /**
   * get-restaurants-by-siteid--by-restaurantid--settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantOrderingSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantOrderingSettings$Plain$Response(params: GetRestaurantOrderingSettings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestaurantOrderingSettings>> {
    return GetRestaurantOrderingSettings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantOrderingSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantOrderingSettings$Plain(params: GetRestaurantOrderingSettings$Plain$Params, context?: HttpContext): Observable<RestaurantOrderingSettings> {
    return this.GetRestaurantOrderingSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestaurantOrderingSettings>): RestaurantOrderingSettings => r.body)
    );
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantOrderingSettings$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantOrderingSettings$Json$Response(params: GetRestaurantOrderingSettings$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RestaurantOrderingSettings>> {
    return GetRestaurantOrderingSettings$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantOrderingSettings$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantOrderingSettings$Json(params: GetRestaurantOrderingSettings$Json$Params, context?: HttpContext): Observable<RestaurantOrderingSettings> {
    return this.GetRestaurantOrderingSettings$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestaurantOrderingSettings>): RestaurantOrderingSettings => r.body)
    );
  }

  /** Path part for operation `getRestaurantsBySiteidByRestaurantidConsumptionModeByConsumptionmode()` */
  static readonly GetRestaurantsBySiteidByRestaurantidConsumptionModeByConsumptionmodePath = '/api/Restaurants/{siteId}/{restaurantId}/consumption-mode/{consumptionMode}';

  /**
   * get-restaurants-by-siteid--by-restaurantid--consumption-mode-by-consumptionmode.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantConsumptionMode$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantConsumptionMode$Plain$Response(params: GetRestaurantConsumptionMode$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RestaurantConsumptionMode>> {
    return GetRestaurantConsumptionMode$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--consumption-mode-by-consumptionmode.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantConsumptionMode$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantConsumptionMode$Plain(params: GetRestaurantConsumptionMode$Plain$Params, context?: HttpContext): Observable<RestaurantConsumptionMode> {
    return this.GetRestaurantConsumptionMode$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestaurantConsumptionMode>): RestaurantConsumptionMode => r.body)
    );
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--consumption-mode-by-consumptionmode.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetRestaurantConsumptionMode$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantConsumptionMode$Json$Response(params: GetRestaurantConsumptionMode$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RestaurantConsumptionMode>> {
    return GetRestaurantConsumptionMode$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--consumption-mode-by-consumptionmode.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetRestaurantConsumptionMode$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetRestaurantConsumptionMode$Json(params: GetRestaurantConsumptionMode$Json$Params, context?: HttpContext): Observable<RestaurantConsumptionMode> {
    return this.GetRestaurantConsumptionMode$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RestaurantConsumptionMode>): RestaurantConsumptionMode => r.body)
    );
  }

  /** Path part for operation `getRestaurantsBySiteidByRestaurantidMenuExport()` */
  static readonly GetRestaurantsBySiteidByRestaurantidMenuExportPath = '/api/Restaurants/{siteId}/{restaurantId}/menu/export';

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu-export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ExportRestaurantMenu$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExportRestaurantMenu$Plain$Response(params: ExportRestaurantMenu$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return ExportRestaurantMenu$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu-export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ExportRestaurantMenu$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExportRestaurantMenu$Plain(params: ExportRestaurantMenu$Plain$Params, context?: HttpContext): Observable<Menu> {
    return this.ExportRestaurantMenu$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu-export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ExportRestaurantMenu$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExportRestaurantMenu$Json$Response(params: ExportRestaurantMenu$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Menu>> {
    return ExportRestaurantMenu$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-restaurants-by-siteid--by-restaurantid--menu-export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ExportRestaurantMenu$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ExportRestaurantMenu$Json(params: ExportRestaurantMenu$Json$Params, context?: HttpContext): Observable<Menu> {
    return this.ExportRestaurantMenu$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Menu>): Menu => r.body)
    );
  }

}
