<div [ngSwitch]="preparationStep"
     class="order-preparation-page-container">

  <section *ngSwitchCase="'RequireDestination'">

    <!-- ============== RequireDestination ============== -->

    <app-order-preparation-confirmation [order]="order"></app-order-preparation-confirmation>

    <form [formGroup]="destinationForm"
          (ngSubmit)="onSubmitDestination()"
          fxLayout="column"
          fxLayoutGap="10px">
      <app-order-preparation-destination-input [preparationSettings]="preparationSettings$ | async"
                                               formControlName="destination"
                                               appearance="fill"
                                               [readonly]="inProgress"></app-order-preparation-destination-input>

      <button type="submit"
              mat-flat-button
              class="confirm-destination-button"
              color="accent"
              [disabled]="inProgress">
        <span>{{ "COMMON.CONFIRM" | transloco }}&nbsp;</span>
        <app-loader *ngIf="inProgress"></app-loader>
      </button>
    </form>

  </section>

  <section *ngSwitchCase="'PromptForAction'">

    <!-- ============== PromptForAction ============== -->

    <app-order-preparation-header [order]="order"
                                  [orderPreparation]="orderPreparation"
                                  [loadingOrderPreparation]="loadingOrderPreparation"></app-order-preparation-header>

    <app-order-preparation-prompt-for-action [order]="order"
    [orderPreparation]="orderPreparation"
    [loading]="inProgress"
                                             #actionSelector></app-order-preparation-prompt-for-action>

    <div class="action-buttons-panel">

      <button (click)="onConfirmSelectedAction(actionSelector.selectedAction)"
              mat-flat-button
              class="confirm-button"
              color="accent"
              [disabled]="inProgress || actionSelector.selectedAction === null">
        <span>{{ "COMMON.CONFIRM" | transloco }}&nbsp;</span>
        <app-loader *ngIf="inProgress"></app-loader>
      </button>

      <button type="button"
              class="main-button"
              mat-button
              (click)="onAddArticlesClicked()">
        {{ "COMMON.CLOSE" | transloco }}
      </button>

    </div>

  </section>

  <section *ngSwitchCase="'DisplayConfirmation'">

    <!-- ============== DisplayConfirmation ============== -->

    <app-order-preparation-confirmation [order]="order"></app-order-preparation-confirmation>

    <app-order-preparation-header [expanded]="true"
                                  [order]="order"
                                  [orderPreparation]="orderPreparation"
                                  [loadingOrderPreparation]="loadingOrderPreparation"></app-order-preparation-header>

    <button (click)="onOkCloseClicked()"
            mat-flat-button
            class="confirm-button"
            color="accent">
      <span>{{ "COMMON.CLOSE" | transloco }}&nbsp;</span>
      <app-loader *ngIf="inProgress"></app-loader>
    </button>

  </section>

  <section *ngSwitchDefault>

    <!-- ============== Default / Loading ============== -->

    <app-page-loader></app-page-loader>
  </section>

</div>
