import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAuthService } from '@app/core/api-client/services';
import { RegisterInput } from '@app/features/register/models/register-input';
import { StorageMap } from '@ngx-pwa/local-storage';
import dateIsAfter from 'date-fns/isAfter';
import dateSubDays from 'date-fns/subDays';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  forkJoin,
  from, interval, Observable,
  of,
  Subject, throwError
} from 'rxjs';
import {
  catchError, distinctUntilChanged,
  first, map,
  shareReplay,
  startWith,
  switchMap, tap, withLatestFrom
} from 'rxjs/operators';
import { GrantType, LoginResponse, User } from '../api-client/models';
import { Logger, LogService } from '../logging';
import { AppRoutes } from '../routes';
import {
  AuthenticationToken,
  OauthToken
} from './models/authentication-token.model';

export const REDIRECT_URL_QUERYPARAM = 'redirectUrl';

const USER_STORAGEKEY = 'USER';
const TOKEN_STORAGEKEY = 'TOKEN';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private _currentTokenSubject = new Subject<AuthenticationToken | null>();
  public currentToken$ = this._currentTokenSubject.asObservable()
    .pipe(
      distinctUntilChanged((prev, current) => prev?.accessToken === current?.accessToken),
      shareReplay({ bufferSize: 1, refCount: false }) // on partage toujours la même subscription et on propose la dernière valeur
    );

  private _currentUserSubject = new Subject<User | null>();
  public currentUser$ = this._currentUserSubject.asObservable()
    .pipe(
      // keep user reference
      tap(user => this._user = user),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }) // on partage toujours la même subscription et on propose la dernière valeur
    );

  public userPasswordIsExpired$ = this.currentUser$
    .pipe(
      withLatestFrom(
        interval(10000).pipe(startWith(0)) // ms = 10s (DEBUG) but emit immediately
        // interval(900_000).pipe(startWith(0)) // ms = 15min but emit immediately
      ),
      map(([user]) => user
        && user.passwordExpiresAt
        && dateIsAfter(new Date(), new Date(user.passwordExpiresAt))
      ),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }) // on partage toujours la même subscription et on propose la dernière valeur
    );

  private _user: User;
  public get currentUser(): User {
    return this._user;
  }

  private _log: Logger;

  constructor(
    private _storage: StorageMap, //TODO use @ionic/storage-angular (depends on localforage) with a fallback to 'localforage-driver-memory' package
    private _apiAuthenticationService: ApiAuthService,
    private _router: Router,
    logService: LogService
  ) {
    this._log = logService.getLogger('AuthenticationService');

    // warm up User/Token (preload from storage)
    this.currentToken$.pipe(first()).subscribe();
    this.currentUser$.pipe(first()).subscribe();

    this.loadStoredToken$().pipe(first()).subscribe(token => this._currentTokenSubject.next(token));
    this.loadStoredUser$().pipe(first()).subscribe(user => this._currentUserSubject.next(user));
  }

  public signIn$(username: string, password: string): Observable<LoginResponse> {
    return this._apiAuthenticationService
      .Login$Json({
        body: { email: username, password: password },
      })
      .pipe(
        first(),
        this.handleLoginResponse()
        // TODO voir pour debouncer les appels
        // , shareReplay() // We are calling shareReplay to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions.
      );
  }

  public ssoSignIn$(ssoToken: string, siteId: number): Observable<LoginResponse> {
    return this._apiAuthenticationService
      .SsoLogin$Json({
        body: { ssoToken: ssoToken, siteId: siteId },
      })
      .pipe(
        first(),
        this.handleLoginResponse()
        // TODO voir pour debouncer les appels
        // , shareReplay() // We are calling shareReplay to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions.
      );
  }

  public getToken$(checkExpiration = true, canRefreshToken = true): Observable<AuthenticationToken | null> {

    return this.currentToken$
      .pipe(
        first(),
        switchMap(token => {
          if (token && checkExpiration) {
            if (!token.isAccessTokenExpired()) {
              return of(token);
            }

            this._log.debug('access token expired');
            if (canRefreshToken && !token.isRefreshTokenExpired()) {
              return this.refreshToken$()
                .pipe(
                  catchError((e: unknown) => {
                    this._log.debug('refresh token error, signout');
                    this.signOut$(true);
                    return throwError(e);
                  })
                );
            }
            return of(null);
          } else {
            return of(token);
          }
        })
      );

  }

  private loadUserFromApi$(): Observable<User> {
    return this._apiAuthenticationService.Me$Json();
  }

  private storeUser$(user?: User): Observable<User> {
    return this._storage.set(USER_STORAGEKEY, user)
      .pipe(
        first(),
        // store current value
        map((_) => (this._user = user)),
        // notify
        tap((u) => this._currentUserSubject.next(u))
      );
  }

  private loadStoredUser$(): Observable<User> {
    return this._storage.get(USER_STORAGEKEY)
      .pipe(
        first(),
        map(stored => stored as User | null)
      );
  }

  private storeToken$(oauthToken?: OauthToken): Observable<AuthenticationToken> {
    return this._storage.set(TOKEN_STORAGEKEY, oauthToken)
      .pipe(
        first(),
        // wrap to AuthenticationToken and store current value
        map((_) => oauthToken && new AuthenticationToken(oauthToken)),
        // notify
        tap(authToken => this._currentTokenSubject.next(authToken))
      );
  }

  private loadStoredToken$(): Observable<AuthenticationToken> {
    return this._storage.get(TOKEN_STORAGEKEY)
      .pipe(
        first(),
        map(stored => stored as OauthToken | null),
        map(oauthToken => oauthToken && new AuthenticationToken(oauthToken))
      );
  }

  public refreshToken$(): Observable<AuthenticationToken> {
    return this.currentToken$
      .pipe(
        first(),
        switchMap(token => {
          if (!token) {
            return throwError(() => new Error('No refresh token'));
          }

          if (token.isRefreshTokenExpired()) {
            this._log.debug('refresh token expired');
            return throwError(() => new Error('Refresh token expired'));
          }

          this._log.debug('refreshing access token...');
          return this._apiAuthenticationService
            .Token$Json({
              body: {
                grant_type: GrantType.refresh_token,
                refresh_token: token.refreshToken,
              },
            })
            .pipe(
              first(),
              switchMap((refreshedToken) => {
                this._log.debug('access token refreshed');
                return this.storeToken$(refreshedToken);
              })
            );
        })
      );
  }

  /*
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   */
  public signOut$(rerouteToLogin: boolean = true, redirectUrl: string = null): Observable<boolean> {

    const signOutObs = forkJoin([
      this.storeToken$(null),
      this.storeUser$(null),
    ]).pipe(
      first(),
      switchMap((_) => {
        this._log.info('User logged off');

        if (rerouteToLogin) {
          if (redirectUrl) {
            const queryParams = {};
            queryParams[REDIRECT_URL_QUERYPARAM] = redirectUrl;
            return from(this._router.navigate([AppRoutes.signin], { queryParams }));
          }
          return from(this._router.navigate([AppRoutes.signin]));
        }

        return of(false);
      })
    );

    return signOutObs;
  }

  public isLoggedIn$(): Observable<boolean> {
    return concat(
      this.getToken$(), // getToken$ return only one value...
      this.currentToken$ // ...so observe also currentToken$ source...
    )
      .pipe(
        distinctUntilChanged(), // ... and discard same token.

        //TODO add option to monitor and push value when token is expired
        map((token) => {
          return !!token && !token.isAccessTokenExpired();
        })
      );
  }

  public _debug_ExpireToken() {
    // expire le token (que côté client, le token original n'est pas modifié)
    this.currentToken$.pipe(first()).subscribe(token => {
      token.accessTokenExpirationDate = dateSubDays(new Date(), 1);
    });
  }

  public _debug_TestAuthApi(): Observable<any> {
    // appel à un service qui requiert le token d'authentification
    return this._apiAuthenticationService.Me$Json();
  }

  public forgotPassword(email: string) {
    return this._apiAuthenticationService.ForgotPassword({
      body: {
        email: email,
        siteId: null
      }
    }).pipe(
      first()
    );
  }

  public resetPassword(token: string, newPassword: string) {
    return this._apiAuthenticationService.ResetPassword$Json({
      body: {
        forgotPasswordToken: token,
        newPassword: newPassword
      }
    })
      .pipe(
        first(),
        this.handleLoginResponse()
      );
  }

  public resetCredentials(newEmail: string | null, newPassword: string | null) {
    return this._apiAuthenticationService.ResetEmailPassword$Json({
      body: {
        newEmail: newEmail,
        newPassword: newPassword
      }
    })
      .pipe(
        first(),
        this.handleLoginResponse()
      );
  }


  public register(registerInput: RegisterInput): Observable<LoginResponse> {
    return this._apiAuthenticationService
      .Register$Json({
        body: {
          siteId: registerInput.siteId,
          firstName: registerInput.firstname,
          lastName: registerInput.lastname,
          // phone: registerInput.phone,
          email: registerInput.email,
          password: registerInput.password,
        },
      })
      .pipe(
        first(),
        this.handleLoginResponse()
      );
  }

  private handleLoginResponse() {
    return switchMap((loginResponse: LoginResponse) => {
      // wait for token & user storage...
      return forkJoin([
        of(loginResponse),
        this.storeToken$(loginResponse),
        this.storeUser$(loginResponse.user),
      ]).pipe(
        // ...then return initial loginResponse
        map(([logResp]) => logResp)
      );
    });
  }

}
