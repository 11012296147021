import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Order, OrderPreparation } from '@app/core/api-client/models';

export enum OrderPreparationAction {
  WaitForOtherOrders = 'WaitForOtherOrders',
  SendTable = 'SendTable',
  SendMyOrder = 'SendMyOrder',
}

@Component({
  selector: 'app-order-preparation-prompt-for-action',
  templateUrl: './order-preparation-prompt-for-action.component.html',
  styleUrls: ['./order-preparation-prompt-for-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationPromptForActionComponent implements OnInit, OnChanges {

  actions = OrderPreparationAction;
  selectedAction: OrderPreparationAction | null = null;

  @Input() order: Order;
  @Input() orderPreparation: OrderPreparation;
  @Input() loading: boolean = false;

  @Output() actionSelected = new EventEmitter<OrderPreparationAction>();

  displaySendToTable: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.displaySendToTable = (this.orderPreparation?.destinationOrdersPendingCount ?? this.order?.processingState?.destinationOrdersPendingCount) > 1;
  }

  ngOnInit(): void {
  }

  onSelectedActionChanged(action: OrderPreparationAction) {
    this.selectedAction = action;
    this.actionSelected.emit(this.selectedAction);
  }

}
