import { Directive, Input, OnChanges, SimpleChanges, Renderer2, ChangeDetectorRef, ViewContainerRef, SimpleChange } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { CustomIconService } from '@app/core/ui/CustomIcon/custom-icon.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-icon[customSvgIcon]'
})
export class CustomSvgIconDirective implements OnChanges {

  @Input() defaultCustomSvgIcon: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() customSvgIcon: string;

  constructor(
    private _matIcon: MatIcon,
    private _customIconService: CustomIconService,
    private _renderer: Renderer2
    // private _cd: ChangeDetectorRef,
    // private _location: ViewContainerRef
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const customSvgIconChanges = changes['customSvgIcon'];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const defaultcustomSvgIconChanges = changes['defaultCustomSvgIcon'];

    if (customSvgIconChanges || defaultcustomSvgIconChanges) {
      const svgIcon = this._customIconService.getCustomIconName(this.customSvgIcon, this.defaultCustomSvgIcon);
      if (this._matIcon.svgIcon !== svgIcon) {
        this._setMatIconSvgIcon(svgIcon);
      }
    }
  }

  private _setMatIconSvgIcon(svgIconName: string) {

    // set svgIcon property on MatIcon component
    const oldValue = this._matIcon.svgIcon;
    this._matIcon.svgIcon = svgIconName;
    // HACK le set svgIcon ne lance pas la changeDetection, et même en la forcant ça n'affecte pas le MatIcon.
    // => workaround: on appelle directement "ngOnChange" . see https://github.com/angular/components/blob/1106cb1ea7d07ce603e2afeb3a9e0e0f71d4e5db/src/material/icon/icon.ts#L223
    // this._matIcon.ngOnChanges({ svgIcon: new SimpleChange(oldValue, svgIconName, false) });

    // Hide element if svgIcon is null (in case of defaultCustomSvgIcon=null)
    const element = this._matIcon._elementRef.nativeElement as HTMLElement;
    if (svgIconName === null) {
      if (!element.classList.contains('hidden')) {
        this._renderer.addClass(element, 'hidden');
      }
    } else {
      if (element.classList.contains('hidden')) {
        this._renderer.removeClass(element, 'hidden');
      }
    }

  }
}
