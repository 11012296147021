import { Component, OnInit, Renderer2 } from '@angular/core';
import { SideService } from '@app/core/layout/side.service';
import { CartService } from '@app/core/services/cart/cart.service';
import { MenuState } from '@app/core/services/menu/state/menu.state';
import { NavigationService } from '@app/core/services/navigation.service';
import { SiteState } from '@app/core/services/site/state/site.state';
import { CartPageSidePanelWrapperComponent } from '@app/features/cart/pages/cart-page-side-panel-wrapper/cart-page-side-panel-wrapper.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PlaceSelectorDialogResult } from '../place-selector-dialog/place-selector-dialog-result.model';

@UntilDestroy()
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {

  orderingIsOpen$ = this._menuState.getCurrentMenu$().pipe(
    untilDestroyed(this),
    map(menu => menu?.orderingSettings?.isOpen)
  );

  cartBadgeValue$ = combineLatest([this._cartService.getCountStandardProducts$(), this.orderingIsOpen$])
  .pipe(
    untilDestroyed(this),
    // display productsCount only if orderingIsOpen
    map(([productsCount, orderingIsOpen]) => (orderingIsOpen && productsCount) || undefined),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  deliveryDate$ = this._cartService.getCurrentCart$().pipe(
    map(cart => cart?.deliveryDate && new Date(cart.deliveryDate))
  );

  isLoading$ = combineLatest([
    this._menuState.isUpdating$(),
    this._siteState.isUpdating$()
  ]).pipe(
    untilDestroyed(this),
    map(([menuIsLoading, siteIsLoading]) => menuIsLoading || siteIsLoading)
  );



  constructor(
    private _menuState: MenuState,
    private _siteState: SiteState,
    private _cartService: CartService,
    private _sideService: SideService,
    private _navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
  }

  onPanelClick() {
    this._sideService.toggle();
    this._sideService.setComponent({ component: CartPageSidePanelWrapperComponent, data: null });
  }

  onDeliveryDateChange(selectedDate: Date) {
    this._cartService.updateDeliveryDate(selectedDate);
  }

  onPlaceEdit(event: PlaceSelectorDialogResult) {
    if (event.action === 'ChangeContext') {
      this._navigationService.navigateToMenu$(event.newContext.restaurant).subscribe();
    } else if (event.action === 'GotoSiteSelection') {
      this._navigationService.navigateToSiteSelection();
    }
  }

  onHomeClick() {
    this._navigationService.navigateToDefaultMenu$().subscribe();
  }

  onAccountClick() {
    this._navigationService.navigateToAccount();
  }

}
