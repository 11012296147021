import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { QRCodeSettings } from './qrcodeSettings';

// see doc : https://medium.com/angular-in-depth/lazy-load-components-in-angular-596357ab05d8
// see lib https://github.com/negue/gewd/blob/master/libs/lazy/loader/lazy.component.ts
// see lib https://github.com/gund/ng-dynamic-component
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QRCodeComponent implements OnInit, OnChanges {

  @Input() settings: QRCodeSettings;

  @ViewChild('targetContainer', { read: ViewContainerRef, static: true })
  targetContainer: ViewContainerRef;

  @Output()
  public componentCreated = new EventEmitter();
  @Output()
  public componentLoading = new EventEmitter();

  private _firstLoad = true;

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.createQrCode();
    }
  }

  ngOnInit(): void {
    if (this._firstLoad)
      this.createQrCode();
  }

  // Lazy loads a QrCode component
  private async createQrCode() {
    this._firstLoad = false;

    this.componentLoading.emit(true);

    //TODO migrate to Angular 13+ without ComponentFactory : https://www.wittyprogramming.dev/articles/lazy-load-component-angular-without-routing/#for-angular-13

    const imported = await import(/* webpackPrefetch: true */'./lazy/lazy-qrcode.component')
      .then(({ LazyQRCodeComponent }) => LazyQRCodeComponent);

    const componentFactory = this._resolver.resolveComponentFactory(imported);
    this.targetContainer.clear();
    const componentRef = this.targetContainer.createComponent(componentFactory, 0, this._injector);
    componentRef.changeDetectorRef.markForCheck()

    const componentInstance = componentRef.instance;

    //set inputs
    // console.info('setInputs', componentInstance, this.settings);
    if (componentInstance && this.settings) {
      const inputs = Object.keys(this.settings);

      for (const inputKey of inputs) {
        // console.info('set ', inputKey)
        componentInstance[inputKey] = this.settings[inputKey];
      }
    }

    // componentRef.changeDetectorRef.markForCheck();
    // HACK Unable to do change detection on lazyLoaded Component so force QrCode generation
    componentInstance.createQRCode();

    componentRef.changeDetectorRef.reattach()

    this.componentLoading.emit(false);
    this.componentCreated.emit(componentRef.instance);

  }

}
