/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ForgotPassword } from '../fn/auth/forgot-password';
import { ForgotPassword$Params } from '../fn/auth/forgot-password';
import { Login$Json } from '../fn/auth/login-json';
import { Login$Json$Params } from '../fn/auth/login-json';
import { Login$Plain } from '../fn/auth/login-plain';
import { Login$Plain$Params } from '../fn/auth/login-plain';
import { LoginResponse } from '../models/login-response';
import { Me$Json } from '../fn/auth/me-json';
import { Me$Json$Params } from '../fn/auth/me-json';
import { Me$Plain } from '../fn/auth/me-plain';
import { Me$Plain$Params } from '../fn/auth/me-plain';
import { OauthTokenResponse } from '../models/oauth-token-response';
import { Register$Json } from '../fn/auth/register-json';
import { Register$Json$Params } from '../fn/auth/register-json';
import { Register$Plain } from '../fn/auth/register-plain';
import { Register$Plain$Params } from '../fn/auth/register-plain';
import { ResetEmailPassword$Json } from '../fn/auth/reset-email-password-json';
import { ResetEmailPassword$Json$Params } from '../fn/auth/reset-email-password-json';
import { ResetEmailPassword$Plain } from '../fn/auth/reset-email-password-plain';
import { ResetEmailPassword$Plain$Params } from '../fn/auth/reset-email-password-plain';
import { ResetPassword$Json } from '../fn/auth/reset-password-json';
import { ResetPassword$Json$Params } from '../fn/auth/reset-password-json';
import { ResetPassword$Plain } from '../fn/auth/reset-password-plain';
import { ResetPassword$Plain$Params } from '../fn/auth/reset-password-plain';
import { SsoLogin$Json } from '../fn/auth/sso-login-json';
import { SsoLogin$Json$Params } from '../fn/auth/sso-login-json';
import { SsoLogin$Plain } from '../fn/auth/sso-login-plain';
import { SsoLogin$Plain$Params } from '../fn/auth/sso-login-plain';
import { Token$Json } from '../fn/auth/token-json';
import { Token$Json$Params } from '../fn/auth/token-json';
import { Token$Plain } from '../fn/auth/token-plain';
import { Token$Plain$Params } from '../fn/auth/token-plain';
import { UnregisterMe } from '../fn/auth/unregister-me';
import { UnregisterMe$Params } from '../fn/auth/unregister-me';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class ApiAuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAuthMe()` */
  static readonly GetAuthMePath = '/api/Auth/me';

  /**
   * get-auth-me.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Me$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me$Plain$Response(params?: Me$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return Me$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-auth-me.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Me$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me$Plain(params?: Me$Plain$Params, context?: HttpContext): Observable<User> {
    return this.Me$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /**
   * get-auth-me.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Me$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me$Json$Response(params?: Me$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return Me$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-auth-me.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Me$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Me$Json(params?: Me$Json$Params, context?: HttpContext): Observable<User> {
    return this.Me$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `deleteAuthMe()` */
  static readonly DeleteAuthMePath = '/api/Auth/me';

  /**
   * delete-auth-me.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `UnregisterMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  UnregisterMe$Response(params?: UnregisterMe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return UnregisterMe(this.http, this.rootUrl, params, context);
  }

  /**
   * delete-auth-me.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `UnregisterMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  UnregisterMe(params?: UnregisterMe$Params, context?: HttpContext): Observable<void> {
    return this.UnregisterMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postAuthLogin()` */
  static readonly PostAuthLoginPath = '/api/Auth/login';

  /**
   * post-auth-login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Login$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Login$Plain$Response(params?: Login$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return Login$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Login$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Login$Plain(params?: Login$Plain$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.Login$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /**
   * post-auth-login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Login$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Login$Json$Response(params?: Login$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return Login$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Login$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Login$Json(params?: Login$Json$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.Login$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `postAuthSsologin()` */
  static readonly PostAuthSsologinPath = '/api/Auth/ssologin';

  /**
   * post-auth-ssologin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SsoLogin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SsoLogin$Plain$Response(params?: SsoLogin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return SsoLogin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-ssologin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SsoLogin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SsoLogin$Plain(params?: SsoLogin$Plain$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.SsoLogin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /**
   * post-auth-ssologin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SsoLogin$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SsoLogin$Json$Response(params?: SsoLogin$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return SsoLogin$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-ssologin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SsoLogin$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SsoLogin$Json(params?: SsoLogin$Json$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.SsoLogin$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `postAuthToken()` */
  static readonly PostAuthTokenPath = '/api/Auth/token';

  /**
   * post-auth-token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Token$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Token$Plain$Response(params?: Token$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OauthTokenResponse>> {
    return Token$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Token$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Token$Plain(params?: Token$Plain$Params, context?: HttpContext): Observable<OauthTokenResponse> {
    return this.Token$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OauthTokenResponse>): OauthTokenResponse => r.body)
    );
  }

  /**
   * post-auth-token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Token$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Token$Json$Response(params?: Token$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OauthTokenResponse>> {
    return Token$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Token$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  Token$Json(params?: Token$Json$Params, context?: HttpContext): Observable<OauthTokenResponse> {
    return this.Token$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OauthTokenResponse>): OauthTokenResponse => r.body)
    );
  }

  /** Path part for operation `postAuthRegister()` */
  static readonly PostAuthRegisterPath = '/api/Auth/register';

  /**
   * post-auth-register.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Register$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Register$Plain$Response(params?: Register$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return Register$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-register.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Register$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Register$Plain(params?: Register$Plain$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.Register$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /**
   * post-auth-register.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Register$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Register$Json$Response(params?: Register$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return Register$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-register.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Register$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Register$Json(params?: Register$Json$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.Register$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `postAuthForgotPassword()` */
  static readonly PostAuthForgotPasswordPath = '/api/Auth/forgot-password';

  /**
   * post-auth-forgot-password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ForgotPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ForgotPassword$Response(params?: ForgotPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ForgotPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-forgot-password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ForgotPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ForgotPassword(params?: ForgotPassword$Params, context?: HttpContext): Observable<void> {
    return this.ForgotPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postAuthResetPassword()` */
  static readonly PostAuthResetPasswordPath = '/api/Auth/reset-password';

  /**
   * post-auth-reset-password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetPassword$Plain$Response(params?: ResetPassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return ResetPassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-reset-password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetPassword$Plain(params?: ResetPassword$Plain$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.ResetPassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /**
   * post-auth-reset-password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ResetPassword$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetPassword$Json$Response(params?: ResetPassword$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return ResetPassword$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-reset-password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ResetPassword$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetPassword$Json(params?: ResetPassword$Json$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.ResetPassword$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `postAuthResetEmailPassword()` */
  static readonly PostAuthResetEmailPasswordPath = '/api/Auth/reset-email-password';

  /**
   * post-auth-reset-email-password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ResetEmailPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetEmailPassword$Plain$Response(params?: ResetEmailPassword$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return ResetEmailPassword$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-reset-email-password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ResetEmailPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetEmailPassword$Plain(params?: ResetEmailPassword$Plain$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.ResetEmailPassword$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /**
   * post-auth-reset-email-password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ResetEmailPassword$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetEmailPassword$Json$Response(params?: ResetEmailPassword$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return ResetEmailPassword$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-auth-reset-email-password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ResetEmailPassword$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ResetEmailPassword$Json(params?: ResetEmailPassword$Json$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.ResetEmailPassword$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

}
