/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ErrorCorrectionLevel } from '../../models/error-correction-level';

export interface GenerateQrCode$Params {
  Content: string;

/**
 * L
 *
 * M
 *
 * Q
 *
 * H
 */
  ErrorCorrectionLevel?: ErrorCorrectionLevel;

/**
 * The width and height, in pixels, of each module.
 */
  Scale?: number;

/**
 * The number of border modules to add to each of the four sides.
 */
  Border?: number;
  ForgroundColorHex?: string;
  BackgroundColorHex?: string;
  'force-tenant'?: String;
}

export function GenerateQrCode(http: HttpClient, rootUrl: string, params: GenerateQrCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, GenerateQrCode.PATH, 'get');
  if (params) {
    rb.query('Content', params.Content, {});
    rb.query('ErrorCorrectionLevel', params.ErrorCorrectionLevel, {});
    rb.query('Scale', params.Scale, {});
    rb.query('Border', params.Border, {});
    rb.query('ForgroundColorHex', params.ForgroundColorHex, {});
    rb.query('BackgroundColorHex', params.BackgroundColorHex, {});
    rb.header('force-tenant', params['force-tenant'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

GenerateQrCode.PATH = '/api/QrCode';
