
/**
 * Returns the smaller of a set of supplied numeric expressions.
 * @param values Numeric expressions to be evaluated.
 */
export function MathMin(...values: (number | string)[]): number {
  const parsedValues = (values || [])
    .map(v => parseFloat(v as string))
    .filter(v => !isNaN(v));
  return Math.min.apply(null, parsedValues);
}
