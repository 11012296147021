<div class="confirm-dialog">
  <!-- Title-->
  <div class="confirm-dialog-title"
       *ngIf="data.title"
       mat-dialog-title
       [innerHtml]="(data.translateTitle ? (data.title | transloco) : data.title) | sanitizeHtml">
  </div>

  <!-- Message -->
  <div mat-dialog-content>
    <div class="confirm-dialog-message"
         [innerHtml]="(data.translateMessage ? (data.message | transloco) : data.message)  | sanitizeHtml"></div>
  </div>

  <!-- Buttons-->
  <div class="confirm-dialog-buttons"
       fxLayout="row"
       fxLayoutAlign="end center">
    <button fxFlex="30"
            mat-button
            color="accent"
            *ngIf="data.showDenyButton"
            (click)="onNoClick()"
            cdkFocusInitial
            [innerHtml]="(data.translateDenyButtonLabel ? (data.denyButtonLabel | transloco) : data.denyButtonLabel) | sanitizeHtml">
    </button>
    <button fxFlex="30"
            mat-button
            color="primary"
            *ngIf="data.showConfirmButton"
            (click)="onYesClick()"
            [innerHtml]="(data.translateConfirmButtonLabel ? (data.confirmButtonLabel | transloco) : data.confirmButtonLabel) | sanitizeHtml">
    </button>
  </div>
</div>
