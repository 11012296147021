import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { PickupSchedule } from '@app/core/services/menu/models/pickup-schedule.model';
import { DateFormatOptions } from '@ngneat/transloco-locale';
import { differenceInCalendarDays, startOfDay } from 'date-fns';

@Component({
  selector: 'app-delivery-date-time',
  templateUrl: './delivery-date-time.component.html',
  styleUrls: ['./delivery-date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryDateTimeComponent implements OnInit, OnChanges {
  @Input() selectedDate?: Date | null;
  @Input() pickupDays: PickupSchedule[];
  @Output() selectedDateChange = new EventEmitter<Date>();

  selectedDay: Date | null;
  currentDate: Date = new Date();
  heuresRetrait: Date[];

  dateFormatOptions: DateFormatOptions = { dateStyle: 'full' };

  deliveryDateForm: UntypedFormGroup = new UntypedFormGroup({
    deliveryDate: new UntypedFormControl(null, Validators.required)
  });

  constructor(
    private _cd: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    if (this.pickupDays?.length > 0) {
      this.selectDate(this.selectedDate);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pickupDays']) {
      const hasMoreThanTwoBusinessDayDiff = this.pickupDays.some(pickupDay => this.dayDiff(this.currentDate, pickupDay.day) > 2);
      this.dateFormatOptions = hasMoreThanTwoBusinessDayDiff ? { dateStyle: 'full' } : { weekday: "long" };
    }
  }

  onSelectDay(event: MatButtonToggleChange) {
    const date: Date = event.value;
    this.selectDate(date);
  }

  onSelectTime(event: MatSelectChange) {
    const date: Date = event.value;
    this.selectDate(date);
  }

  public selectDate(date: Date | null) {
    if (date) {
      const day = date && startOfDay(date);
      const pickupDate = this.pickupDays.find(x => x.day.toLocaleDateString() === day?.toLocaleDateString());
      this.selectedDay = pickupDate.day;
      this.heuresRetrait = pickupDate?.dateSlots;
      const pickupDateSlot = pickupDate?.dateSlots.find(x => x.getTime() === date.getTime()) || pickupDate?.dateSlots[0];
      this.selectedDate = pickupDateSlot;
    }
    else {
      const pickupDate = this.pickupDays[0];
      this.heuresRetrait = pickupDate?.dateSlots;
      this.selectedDay = pickupDate?.day;
      this.selectedDate = null;
    }

    //apply date to formControl
    this.deliveryDateForm.controls['deliveryDate'].setValue(this.selectedDate);

    this._cd.markForCheck();
  }

  onValueChanges() {
    if (this.deliveryDateForm.valid) {
      this.selectedDateChange.emit(this.selectedDate);
    }
  }

  dayDiff(startDate: Date, endDate: Date): number {
    // return differenceInBusinessDays(endDate, startDate);
    return differenceInCalendarDays(endDate, startDate);
  }

  // utilisé par le control mat-select car les Dates js sont des objets distincts et this.selectedDate n'est pas une instance contenue dans les pickupDays
  // cependant, dans la méthode selectDate on vient récupérer une instance existante ce qui rends cette fonction inutile mais je laisse là pour référence
  compareDates(dateA: Date, dateB: Date): boolean {
    return dateA?.valueOf() === dateB?.valueOf();
  }

}
