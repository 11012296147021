// Des constants en vrac à réorganiser dans différents endroits
export class Constants {
  public static readonly minSearchLength = 1;
  public static readonly searchDebounceTimeInMs = 300;

  // public static readonly passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
  // #54662 Règle de validation de mot de passe https://regex101.com/r/6SwB4R/1
  public static readonly passwordMinLength = 10;
  public static readonly passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\[\]{}€£?;.:,\-+='\"~<>]).{10,50}$/;

  public static readonly whiteMarkEmbeddedParam = 'embedded';
}
