/* tslint:disable */
/* eslint-disable */

/**
 * Pending
 *
 * WaitingPayment
 *
 * PaymentSucceeded
 *
 * Validated
 *
 * Cancelled
 *
 * Finalized
 *
 * PaymentFailed
 */
export enum OrderStatus {
  Pending = 'Pending',
  WaitingPayment = 'WaitingPayment',
  PaymentSucceeded = 'PaymentSucceeded',
  Validated = 'Validated',
  Cancelled = 'Cancelled',
  Finalized = 'Finalized',
  PaymentFailed = 'PaymentFailed'
}
