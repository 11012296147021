import { Component, OnInit, Output, Input, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CartProduct } from '@app/core/services/cart/models/cart.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './cart-product-edit.component.html',
  styleUrls: ['./cart-product-edit.component.scss']
})
export class CartProductEditComponent implements OnInit {

  @Output() quantityEdit = new EventEmitter<{ product: CartProduct, newQuantity: number }>();

  get isAvailable() {
    return this.cartProduct?.product?.quantityAvailable == null
      || this.cartProduct?.product?.quantityAvailable > 0;
  }

  get canOrderProduct() {
    return this.isAvailable && (this.cartProduct?.product?.quantityAvailable == null || this.cartProduct?.product?.quantityAvailable > (this.cartProduct?.quantity || 0));
  }

  cartProduct: CartProduct;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CartProductEditComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private _cartProduct$: Observable<CartProduct>,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._cartProduct$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(cartProduct => {
        this.cartProduct = cartProduct;
        this._cd.markForCheck();
      });
  }

  onExit() {
    this.bottomSheetRef.dismiss();
  }

  onProductAdded() {
    this.quantityEdit.emit({ product: this.cartProduct, newQuantity: this.cartProduct.quantity + 1 });
  }

  onProductRemoved() {
    const newQuantity = Math.max(this.cartProduct.quantity - 1, 1);
    if (newQuantity !== this.cartProduct.quantity) {
      this.quantityEdit.emit({ product: this.cartProduct, newQuantity });
    }
  }

  onDeleteQuantityProduct() {
    this.quantityEdit.emit({ product: this.cartProduct, newQuantity: 0 });
    this.bottomSheetRef.dismiss();
  }

}
