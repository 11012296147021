import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmDialogData } from './confirm-dialog-data';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { }

  ngOnInit() {
    this.initDefaultConfirmDialogData();
  }

  private initDefaultConfirmDialogData() {
    // translateMessage?: boolean; // defaults to false

    // showConfirmButton?: boolean; // defaults to true
    // confirmButtonLabel?: string; // defaults to "COMMON.YES"
    // translateConfirmButtonLabel?: boolean; // defaults to true if no confirmButtonLabel specified

    // showDenyButton?: boolean; // defaults to true
    // denyButtonLabel?: string; // defaults to "COMMON.NO"
    // translateDenyButtonLabel?: boolean; // defaults to true  if no denyButtonLabel specified

    this.data.translateTitle ??= true; // Nullish Coalescing : https://mariusschulz.com/blog/nullish-coalescing-the-operator-in-typescript
    this.data.translateMessage ??= true;

    this.data.showConfirmButton ??= true;
    this.data.confirmButtonLabel ??= 'COMMON.YES';
    this.data.translateConfirmButtonLabel ??= true;

    this.data.showDenyButton ??= true;
    this.data.denyButtonLabel ??= 'COMMON.NO';
    this.data.translateDenyButtonLabel ??= true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
