import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sign',
})
export class SignPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    if (value === 0) {
      return '';
    }

    return  value > 0 ? '+' : '-';
  }
}
