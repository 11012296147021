import { SvgSet } from './svg-set.model';

export const svgSets: SvgSet[] = [
  {
    folder: 'svgs',
    icons: ['account-circle', 'account-circle-solid', 'home', 'utensils', 'invoice', 'newspaper', 'heart',
      'file_invoice_dollar', 'circle', 'location_arrow', 'shopping-basket', 'search',
      'map-marker', 'clock', 'empty', 'pencil', 'receipt', 'badge-check', 'question-circle', 'wallet', 'file', 'power-off',
      'lock', 'unlock', 'at', 'credit-card', 'pending', 'coupon'
    ]
  },
  {
    folder: 'svgs/allergens',
    icons: ['all_MILK', 'all_SOYBEANS', 'all_EGGS', 'all_FISH', 'all_CRUSTACEANS', 'all_CEREALS_CONTAINING_GLUTEN', 'all_TREE_NUTS',
      'all_SESAME_SEEDS', 'all_SULPHUR_DIOXIDE', 'all_PEANUTS', 'all_CELERY', 'all_MUSTARD', 'all_LUPINE', 'all_MOLLUSCS']
  },
  {
    folder: 'svgs/typos',
    fallbackIcon: null,
    icons: ['typo_bio', 'typo_vegan',
            'typo_local', 'typo_sain']
  },
  // {
  //   folder: 'svgs/plats',
  //   icons: ['plat_default', 'plat_HORS D\'OEUVRE', 'plat_PLAT PRINCIPAL', 'plat_GARNITURE', 'plat_FROMAGELAITAGE',
  //     'plat_DESSERT', 'plat_PAIN', 'plat_DIVERS', 'plat_HORS-D\'OEUVRE', 'plat_PLATS PRINCIPAUX', 'plat_LEGUMES',
  //     'plat_FROMAGES ET LAITAGES', 'plat_DESSERTS', 'plat_GOUTER']
  // },
  {
    folder: 'svgs/categories',
    icons: [
      'formules', 'entrees', 'plats', 'plats chauds', 'desserts', 'snacking', 'boissons', 'boisson chaude', 'fruit', 'compote', 'epicerie', 'burgers', 'salades', 'pizzas', 'default-category', 'p\'tits creux', 'sandwichs'
    ]
  },
  {
    folder: 'svgs/payment-method',
    icons: [
      'timechef',
      'timechefondelivery',
      'worldline',
      'ondelivery',
      'edenred',
      'swile',
      'stripe'
    ]
  },
  {
    folder: 'svgs/consumption-modes',
    icons: [
      'delivery', 'drive', 'take-away', 'sit-in'
    ]
  },
  // {
  //   folder: 'svgs/carbonscore',
  //   icons: [
  //     'carbonscore-A', 'carbonscore-B', 'carbonscore-C', 'carbonscore-D', 'carbonscore-E',
  //   ]
  // },
];

export const fallbackIcon = 'empty';
