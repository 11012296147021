import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BarStateModel } from '.';
import { AppRoutes } from '@app/core/routes';
import { CartService } from '@app/core/services/cart/cart.service';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BarComponent implements OnInit {
  @Input() state: BarStateModel;

  constructor() {}

  ngOnInit(): void {
  }
}
