import { NgModule } from '@angular/core';
import { LetModule } from '@ngrx/component';
import { FlexModule } from "@angular/flex-layout/flex";
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MaterialModule } from './modules/material/material.module';
import { CommonModule } from '@angular/common';
import { BarItemComponent } from './components/bar';
import { BottomContainerComponent } from './components/bottom-container/bottom-container.component';
import { HeaderComponent } from './components/header/header.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { TranslocoLocaleModule, } from '@ngneat/transloco-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { HorizontalScrollDirective } from './directives/horizontal-scroll.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { HtmlDialogComponent } from './components/html-dialog/html-dialog.component';
import { CustomSvgIconDirective } from './directives/custom-svg-icon.directive';
import { AnimationDirective } from './directives/animation.directive';
import { HtmlContentComponent } from './components/html-content/html-content.component';
import { SignPipe } from './pipes/sign.pipe';
import { ChangeQuantityComponent } from './components/change-quantity/change-quantity.component';
import { DeliveryDateTimeComponent } from './components/delivery-date-time/delivery-date-time.component';
import { DeliveryDateSelectorComponent } from './components/delivery-date-selector/delivery-date-selector.component';
import { PriceComponent } from './components/price/price.component';
import { PercentageBarComponent } from './components/percentage-bar/percentage-bar.component';
import { NutriscoreComponent } from './components/nutriscore/nutriscore.component';
import { RoundPercentageComponent } from './components/round-percentage/round-percentage.component';
import { CartProductListComponent } from './components/cart-product-list/cart-product-list.component';
import { CartProductTileComponent } from './components/cart-product-tile/cart-product-tile.component';
import { CartProductTotalComponent } from './components/cart-product-total/cart-product-total.component';
import { ProductRecapComponent } from './components/product-recap/product-recap.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PlaceSelectorComponent } from './components/place-selector/place-selector.component';
import { LogoComponent } from './components/logo/logo.component';
import { SideContainerComponent } from './components/side-container/side-container.component';
import { DeliveryDateTimeDialogComponent } from './components/delivery-date-time-dialog/delivery-date-time-dialog.component';
import { CmsSheetComponent } from './components/cms-sheet/cms-sheet.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { ApplicationUpdateDialogComponent } from './components/application-update-dialog/application-update-dialog.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { ScrollableContainerComponent } from './components/scrollable-container/scrollable-container.component';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { MiniContentLoaderComponent } from './components/mini-content-loader/mini-content-loader.component';
import { SiteSearchListComponent } from './components/site-search/components/site-search-list/site-search-list.component';
import { SiteSearchComponent } from './components/site-search/components/site-search/site-search.component';
import { SiteSearchNoResultComponent } from './components/site-search/components/site-search-list/site-search-no-result/site-search-no-result.component';
import MessageBarComponent from './components/message-bar/message-bar.component';
import { DragMoveDirective } from './directives/drag-move.directive';
import { HotToastModule } from '@ngneat/hot-toast';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { MenuContextSelectorComponent } from './components/menu-context-selector/menu-context-selector.component';
import { PlaceSelectorDialogComponent } from './components/place-selector-dialog/place-selector-dialog.component';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { SanitizeUrlPipe } from './pipes/sanitizeUrl.pipe';
import { QRCodeComponent } from './components/qrcode/qrcode.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarbonscoreComponent } from './components/carbonscore/carbonscore.component';

// import { MtxTooltipModule } from '@ng-matero/extensions/tooltip' // not compatible ng12

@NgModule({
  declarations: [
    BarItemComponent,
    AutofocusDirective,
    BottomContainerComponent,
    HeaderComponent,
    BackButtonComponent,
    SlugifyPipe,
    HorizontalScrollDirective,
    HtmlDialogComponent,
    CustomSvgIconDirective,
    AnimationDirective,
    HtmlContentComponent,
    SignPipe,
    ChangeQuantityComponent,
    DeliveryDateTimeComponent,
    DeliveryDateSelectorComponent,
    PlaceSelectorComponent,
    PriceComponent,
    PercentageBarComponent,
    NutriscoreComponent,
    CarbonscoreComponent,
    RoundPercentageComponent,
    ProductRecapComponent,
    CartProductListComponent,
    CartProductTileComponent,
    CartProductTotalComponent,
    ConfirmDialogComponent,
    LogoComponent,
    SideContainerComponent,
    DeliveryDateTimeDialogComponent,
    CmsSheetComponent,
    ApplicationUpdateDialogComponent,
    DisableControlDirective,
    LoaderComponent,
    ScrollableContainerComponent,
    ResizeObserverDirective,
    MiniContentLoaderComponent,
    SiteSearchComponent,
    SiteSearchListComponent,
    SiteSearchNoResultComponent,
    MessageBarComponent,
    DragMoveDirective,
    PageLoaderComponent,
    LoadingPageComponent,
    MenuContextSelectorComponent,
    PlaceSelectorDialogComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    QRCodeComponent,
    MatSelectSearchComponent,

  ],
  imports: [
    CommonModule,
    LetModule,
    FlexModule, ExtendedModule, // exclude GridModule to free bundle space
    MaterialModule,
    // MtxTooltipModule,

    TranslocoModule,
    TranslocoLocaleModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    LetModule,
    FlexModule, ExtendedModule, // exclude GridModule to free bundle space
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    TranslocoLocaleModule,
    HotToastModule,
    // MtxTooltipModule,

    BarItemComponent,
    AutofocusDirective,
    BottomContainerComponent,
    HeaderComponent,
    BackButtonComponent,
    HtmlDialogComponent,
    SlugifyPipe,
    HorizontalScrollDirective,
    CustomSvgIconDirective,
    AnimationDirective,
    HtmlContentComponent,
    SignPipe,
    ChangeQuantityComponent,
    DeliveryDateTimeComponent,
    DeliveryDateSelectorComponent,
    PlaceSelectorComponent,
    PriceComponent,
    PercentageBarComponent,
    NutriscoreComponent,
    CarbonscoreComponent,
    RoundPercentageComponent,

    ProductRecapComponent,
    CartProductListComponent,
    CartProductTileComponent,
    CartProductTotalComponent,
    LogoComponent,
    SideContainerComponent,
    CmsSheetComponent,
    ContentLoaderModule,
    ApplicationUpdateDialogComponent,
    DisableControlDirective,
    LoaderComponent,
    ScrollableContainerComponent,
    ResizeObserverDirective,
    MiniContentLoaderComponent,
    MatSelectSearchComponent,

    SiteSearchComponent,

    MessageBarComponent,

    DragMoveDirective,

    PageLoaderComponent,

    LoadingPageComponent,

    MenuContextSelectorComponent,
    QRCodeComponent,

    SanitizeHtmlPipe,
    SanitizeUrlPipe
  ],
})
export class SharedModule { }
