import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from '@app/core/api-client/models';
import { CartProduct } from '@app/core/services/cart/models/cart.model';

@Component({
  selector: 'app-cart-cutlery',
  templateUrl: './cart-cutlery.component.html',
  styleUrls: ['./cart-cutlery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px', opacity: 0 }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto', opacity: 1 })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)', opacity: 0 }))
        ])
      ]
    )
  ]
})
export class CartCutleryComponent implements OnInit, OnChanges {

  @Input() cartProductsList: CartProduct[];
  @Input() currencyCode: string | null;
  @Input() isReadOnly: boolean = false;
  @Input() mealCount: number | null = null;

  @Output() editCartProductClick = new EventEmitter<CartProduct>();
  @Output() addCutleryProducts = new EventEmitter<any>();
  @Output() removeCutleryProducts = new EventEmitter<any>();

  cartCutleryProductsList: CartProduct[];
  hasCutleryProducts: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cartProductsList']) {
      this.cartCutleryProductsList = this.cartProductsList?.filter(p => p.product.isCutlery);
      this.hasCutleryProducts = this.cartCutleryProductsList?.length > 0 || false;
    }
  }

  ngOnInit(): void { }

  onEditCartProductClick(cartProduct: CartProduct) {
    if (!this.isReadOnly) {
      this.editCartProductClick.emit(cartProduct);
    }
  }

  onToggleCutlerySwitch(checked: boolean) {
    if (checked) {
      this.addCutleryProducts.emit();
    } else {
      this.removeCutleryProducts.emit();
    }
  }

}
