import { CanActivateChild, Router, ActivatedRouteSnapshot } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Injectable({
    providedIn: 'root'
})

export class CanActivateChildGuard implements CanActivateChild {
    constructor(
        private readonly router: Router,
        private readonly location: Location,
        private readonly dialog: MatDialog,
        private readonly bottomSheet: MatBottomSheet
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot): boolean {
        if (this.dialog.openDialogs.length > 0) {
            // fix navigation history, see github issue for more details
            // https://github.com/angular/angular/issues/13586
            const currentUrlTree = this.router.createUrlTree([], route);
            const currentUrl = currentUrlTree.toString();
            this.location.go(currentUrl);

            this.dialog.closeAll();
            this.bottomSheet.dismiss();
            return false;
        } else {
            return true;
        }
    }
}
