import { CartProduct } from '../../cart/models/cart.model';

// export interface AnalyticEvent {
//   category?: string;
//   eventName: string;
//   nonInteraction?: boolean;
//   [property: string]: any;
// }

export interface BaseAnalyticEvent {
  isUserRegistered: boolean;
}

export interface BaseMenuEvent extends BaseAnalyticEvent {
  restaurant: string;
  menuName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MenuOpened extends BaseMenuEvent {
}

export enum ProductEventSource {
  MenuTile = 'MenuTile',
  Cart = 'Cart',
  Search = 'Search',
  DeepLink = 'DeepLink',
  ProductDetail = 'ProductDetail'
}

export interface ProductOpened {
  productId: number;
  productName: string;
  source: ProductEventSource;
}

export interface ProductAddedToCart {
  product: CartProduct;
  cartId: string;
  source: ProductEventSource;
}

export interface ProductQuantityUpdated {
  product: CartProduct;
  delta: number;
  quantity: number;
  cartId: string;
  source: ProductEventSource;
}

export interface ProductRemovedFromCart {
  product: CartProduct;
  cartId: string;
  source: ProductEventSource;
}

export interface ProductSearch {
  criteria: string;
  resultCount: number;
}
