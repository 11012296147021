import {
  Component,
  ChangeDetectionStrategy,
  Input, OnChanges, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { Order } from '@app/core/api-client/models';
import { getOrderDescription } from '@app/core/helpers/order-helper';
import { ConsumptionMode, consumptionModes } from '@app/core/services/menu/models/consumption-mode.model';
import { CommandsHelper, CommandStatus } from '../../helpers/commands-helper';

@Component({
  selector: 'app-command-list-item',
  templateUrl: './command-list-item.component.html',
  styleUrls: ['./command-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CommandListItemComponent implements OnChanges {

  @Input() order: Order;

  commandStatus: CommandStatus;
  consumptionMode: ConsumptionMode;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (changes['order']) {
      this.commandStatus = CommandsHelper.getCommandStatutLabel(this.order?.status);
      this.consumptionMode = this.order && consumptionModes.find(c => c.type === this.order.consumptionMode);
    }
  }

  getOrderDescription(order: Order | null) {

    return getOrderDescription(order);
  }

}
