<div class="order-preparation-form"
     fxLayout="column"
     fxLayoutGap="10px">

  <label *ngIf="headerPrompt">{{ headerPrompt | transloco }}</label>
  <mat-form-field class="destination-field"
                  [appearance]="appearance">
    <mat-label>{{ preparationSettings?.tableNamePrefix ?? 'Table ' }}</mat-label>
    <mat-icon matPrefix
              color="primary">room_service</mat-icon>
    <input matInput
           type="number"
           [formControl]="destinationFormControl"
           [placeholder]="preparationSettings?.tableNamePrefix ?? 'Table'"
           min="1"
           [max]="this.preparationSettings?.tableServiceMaxTables ?? 99">
    <mat-hint align="start">{{ 'COMMAND.ORDER_PREPARATION.TABLE_NUMBER' | transloco }}</mat-hint>
    <mat-hint align="end"
              *ngIf="preparationSettings?.tableServiceMaxTables">de 1 à {{preparationSettings.tableServiceMaxTables}}</mat-hint>
    <mat-error *ngIf="destinationFormControl.touched && destinationFormControl.hasError('required')">
      {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: 'COMMAND.ORDER_PREPARATION.TABLE_NUMBER' | transloco } }}
    </mat-error>
    <mat-error *ngIf="destinationFormControl.touched && (destinationFormControl.hasError('min') || destinationFormControl.hasError('max'))">
      {{ 'COMMON.ERRORS.FIELD_RANGE_FORMAT' | transloco : { field: 'COMMAND.ORDER_PREPARATION.TABLE_NUMBER' | transloco, minValue: 1, maxValue: this.preparationSettings?.tableServiceMaxTables ?? 99 } }}
    </mat-error>
  </mat-form-field>

</div>
