<app-bottom-container [title]="'RESET_PASSWORD.TITLE' | transloco"
                      [hasLogo]="true">
  <!--content-->
  <form content
        [formGroup]="resetPasswordForm"
        class="reset-password-form"
        (ngSubmit)="onResetPasswordClick()"
        fxLayout="column"
        fxLayoutAlign="start stretch">
    <!-- Email-->
    <!-- <mat-form-field class="reset-password-field">
      <input type="email"
             formControlName="email"
             matInput
             [appAutofocus]
             [placeholder]="'RESET_PASSWORD.EMAIL' | transloco"
             autocomplete="off" />
      <mat-icon matSuffix
                color="accent"
                svgIcon="at"></mat-icon>
      <mat-error *ngIf="submitted && resetPasswordForm.controls['email'].hasError('required')">{{ "COMMON.ERRORS.EMAIL_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="submitted && resetPasswordForm.controls['email'].hasError('email')">{{ "COMMON.ERRORS.EMAIL_FORMAT" | transloco }}</mat-error>
    </mat-form-field> -->

    <!-- Password-->
    <mat-form-field class="reset-password-field">
      <input matInput
             formControlName="password"
             [placeholder]="'RESET_PASSWORD.PASSWORD' | transloco"
             [type]="showPassword ? 'text' : 'password'"
             autocomplete="off"
             [attr.readonly]="submitting" />
      <mat-icon matSuffix
                color="accent"
                class="show-password-icon"
                (click)="showPassword = !showPassword">{{ showPassword ? "lock_open" : "lock" }}</mat-icon>
      <mat-error *ngIf="submitted && resetPasswordForm.controls['password'].hasError('required')">
        {{ "COMMON.ERRORS.PASSWORD_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="submitted && resetPasswordForm.controls['password'].hasError('minlength') && !resetPasswordForm.controls['password'].hasError('required')">
        {{ "COMMON.ERRORS.PASSWORD_MIN_LENGTH" | transloco }}</mat-error>
      <mat-error style="padding-bottom: 5px;"
                 *ngIf="submitted && resetPasswordForm.controls['password'].hasError('pattern') && !resetPasswordForm.controls['password'].hasError('required')
                 && !resetPasswordForm.controls['password'].hasError('minlength')">
        {{ "COMMON.ERRORS.PASSWORD_PATTERN" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Buttons-->
    <div class="reset-password-buttons"
         fxLayout="column"
         fxLayoutGap="10px">
      <button class="full-width"
              mat-flat-button
              color="accent"
              [disabled]="submitting">
        {{ "RESET_PASSWORD.BUTTON" | transloco }}
        <app-loader *ngIf="submitting"></app-loader>
      </button>
      <!-- <button type="button" class="full-width" mat-button color="accent" (click)="onCancelClick()">
        {{ "COMMON.CANCEL" | transloco }}
      </button> -->
    </div>
  </form>
</app-bottom-container>
