<div class="cart-returnable-container">


  <app-cart-product-list [cartProductList]="cartReturnableContainerProductsList"
                         [currencyCode]="currencyCode"
                         [showTitle]="false"
                         [showZeroPrices]="false"
                         [showDivider]="false"
                         [isReadOnly]="true"></app-cart-product-list>

  <mat-divider class="cart-returnable-product-tile-divider"></mat-divider>

</div>
