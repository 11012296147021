import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrderService } from '@app/core/services/order/order.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { User, Order, OrderProcessingStep } from '@app/core/api-client/models';
import { map, first, finalize, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommandDetailSheetComponent } from '../../components/command-detail-sheet/command-detail-sheet.component';
import { WhiteMarkService } from '@app/core/layout/white-mark.service';
import { NavigationService, RestaurantSource } from '@app/core/services/navigation.service';
import { getOrderDescription } from '@app/core/helpers/order-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  templateUrl: './command-list-page.component.html',
  styleUrls: ['./command-list-page.component.scss'],
})
export class CommandListPageComponent implements OnInit {
  orders: Order[];
  private _ordersCount: number;
  limit: number = 10;
  offset: number = 0;
  displayMoreOrders: boolean = false;
  isLoading: boolean = false;
  isEmbedded$ = this._whiteMark.isEmbedded$();

  private _preparationOrdersSubject = new BehaviorSubject<Order[]>([]);
  preparationOrders$ = this._preparationOrdersSubject.asObservable();
  hasPreparationOrders$ = this.preparationOrders$.pipe(map(orders => orders.length > 0));
  lastPreparationOrder$ = this.preparationOrders$.pipe(map(orders => orders[0]));

  constructor(
    private _orderService: OrderService,
    private _whiteMark: WhiteMarkService,
    private _cd: ChangeDetectorRef,
    private _navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._cd.markForCheck();

    this._orderService.getLastOrderWithPendingPreparation$().pipe(
      untilDestroyed(this),
      tap(order => {
        this._preparationOrdersSubject.next(order ? [order] : []);
      })
    ).subscribe();

    forkJoin([
      this._orderService.getOrdersCount(),
      this._orderService.getOrders(this.limit, this.offset),
    ])
      .pipe(
        untilDestroyed(this),
        tap(([count, orders]) => {
          this._ordersCount = count;
          this.orders = orders;
          this.displayMoreOrders = this.orders.length < this._ordersCount;
          // this.computePreparationOrders();
        }),
        finalize(() => {
          this.isLoading = false;
          this._cd.markForCheck();
        })
      )
      .subscribe();
  }

  goToOrderDetail(order: Order) {
    this._orderService.displayOrderBottomSheet(order);
  }

  goToCreateOrder() {
    this._navigationService.navigateToDefaultMenu$(RestaurantSource.UserRestaurant).subscribe();
  }

  goToTrackOrder(order: Order) {
    // this._navigationService.navigateToOrderTrackingPage(order);
    // this._orderService.processOrderActions(order, false).subscribe();
    this._orderService.displayOrderTableServiceDialog(order);
  }

  getMoreOrders() {
    this.isLoading = true;
    const newOffset = this.offset + this.limit;
    this._orderService
      .getOrders(this.limit, newOffset)
      .pipe(
        untilDestroyed(this),
        tap((newOrders) => {
          this.offset = newOffset;
          this.orders = this.orders.concat(newOrders);
          this.displayMoreOrders = this.orders.length < this._ordersCount;
          // this.computePreparationOrders();
        }),
        finalize(() => {
          this.isLoading = false;
          this._cd.markForCheck();
        })
      )
      .subscribe();
  }

  // private computePreparationOrders() {
  //   const preparationOrders = this.orders.filter(order => order.processingState.isDailyPreparationOrder);

  //   // sort by processingStep priority
  //   preparationOrders.sort((a, b) => {
  //     return (OrderProcessingStepPriority[a.processingState?.step] ?? 99) - (OrderProcessingStepPriority[b.processingState?.step] ?? 99);
  //   });

  //   this._preparationOrdersSubject.next(preparationOrders);
  // }

  getOrderDescription(order: Order | null) {
    return getOrderDescription(order);
  }

}

const OrderProcessingStepPriority: { [key in OrderProcessingStep]?: number } =
{
  [OrderProcessingStep.Creating]: 1,
  [OrderProcessingStep.PendingValidation]: 2,
  [OrderProcessingStep.PendingPreparation]: 3,
  [OrderProcessingStep.Processing]: 4,
  [OrderProcessingStep.Processed]: 5,
  [OrderProcessingStep.Finalized]: 6,
  [OrderProcessingStep.Cancelled]: 7
};
