import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@app/core/ui/theme';

@Component({
  selector: 'app-mini-content-loader',
  templateUrl: './mini-content-loader.component.html',
  styleUrls: ['./mini-content-loader.component.scss']
})
export class MiniContentLoaderComponent implements OnInit {

   // ça c curieux !!!
   secondaryColor = this._themeService.getActiveTheme().properties['--mat-accent-100'];

  constructor(private  _themeService: ThemeService) { }

  ngOnInit(): void {
  }

}
