/* tslint:disable */
/* eslint-disable */

/**
 * password
 *
 * refresh_token
 */
export enum GrantType {
  password = 'password',
  refresh_token = 'refresh_token'
}
