import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CartProduct } from '@app/core/services/cart/models/cart.model';


@Component({
  selector: 'app-cart-returnable-container',
  templateUrl: './cart-returnable-container.component.html',
  styleUrls: ['./cart-returnable-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px', opacity: 0 }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto', opacity: 1 })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)', opacity: 0 }))
        ])
      ]
    )
  ]
})
export class CartReturnableContainerComponent implements OnInit, OnChanges {

  @Input() cartProductsList: CartProduct[];
  @Input() currencyCode: string | null;

  cartReturnableContainerProductsList: CartProduct[];
  hasReturnableContainerProducts: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cartProductsList']) {
      this.cartReturnableContainerProductsList = this.cartProductsList?.filter(p => p.product.isReturnableContainer);
      this.hasReturnableContainerProducts = this.cartReturnableContainerProductsList?.length > 0 || false;
    }
  }


}
