import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order, User } from '@app/core/api-client/models';
import { CartProduct } from '@app/core/services/cart/models/cart.model';
import { MapperService } from '@app/core/services/mapper.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  @Input() order: Order = null;
  @Output() exit = new EventEmitter();

  cartProductList: CartProduct[] = [];

  constructor(private _mapperService: MapperService) { }

  ngOnInit(): void {
    if (this.order == null) {
      throw new Error('\'order\' input parameter is null');
    } else {
      this.cartProductList = this.order.products?.map(p => this._mapperService.orderProductToCartProduct(p)) || [];
    }
  }
  onExit() {
    this.exit.emit();
  }

}
