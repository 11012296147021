import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ErrorsHelper } from '@app/core/helpers/errors-helper';
import { AppRoutes } from '@app/core/routes';
import { NavigationService } from '@app/core/services/navigation.service';
import { DelayAnimations } from '@app/core/ui/animation/animations/delayAnimations';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';
import { ResetPasswordInput } from '../../models/reset-password-input';

@UntilDestroy()
@Component({
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  signinLink = `/${AppRoutes.signin}`;

  changePasswordToken: string | null;
  submitting = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslocoService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   this.changePasswordToken = decodeURI(params.token).replace(/ /gi, '+'); // Hack pour remplacer les blanc par un + (token)
    // });

    // REMOVED : Token from route disturb ASPNET request resolution, use a fragment instead
    // this.activatedRoute.paramMap
    //   .pipe(
    //     first(),
    //     map((paramMap) => paramMap.get('token')),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(token => {
    //     this.changePasswordToken = token;
    //   });

    this.activatedRoute.fragment
      .pipe(
        first(),
        untilDestroyed(this)
      )
      .subscribe(token => {
        this.changePasswordToken = token;
      });
  }

  resetPassword(input: ResetPasswordInput) {
    this.submitting = true;

    this.authenticationService
      .resetPassword(input.changePasswordToken, input.newPassword)
      .pipe(
        this.handleTokenError(),
        finalize(() => {
          this.submitting = false;
          this._cd.markForCheck();
        })
      )
      .subscribe(() => this.resetPasswordSuccess());
  }

  cancel() {
    this._navigationService.navigateToSignInForm();
  }

  private resetPasswordSuccess() {
    this._navigationService.navigateHome();

    setTimeout(() => {
      this.toastr.success(
        this.translate.translate('RESET_PASSWORD.SUCCESS_MESSAGE'),
        this.translate.translate('RESET_PASSWORD.SUCCESS_TITLE')
      );
    }, DelayAnimations.medium);
  }

  private handleTokenError() {
    return catchError((err: unknown) => {

      if (ErrorsHelper.isHttpRequestError(err)) {
        const errorDetails = ErrorsHelper.getErrorDetails(err);
        if (errorDetails?.instance === 'ResetPasswordTokenExpiredException') {
          this._router.navigate([AppRoutes.forgotPassword]);
        }
      }

      // rethrow
      return throwError(err);
    });
  }

}
