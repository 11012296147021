import { trigger, transition, style, animate } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, forwardRef, Injectable, Injector, Input, OnChanges, OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Validator, ControlValueAccessor, ValidationErrors, AbstractControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { extractTouchedChanges } from '@app/core/helpers/forms-helper';
import { DeliveryDateTimeService } from '@app/shared/services/delivery-date-time.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPast, isSameWeek, isToday } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-delivery-date-selector',
  templateUrl: './delivery-date-selector.component.html',
  styleUrls: ['./delivery-date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // component self validates
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DeliveryDateSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryDateSelectorComponent),
      multi: true,
    }
  ],
  animations: [
    trigger(
      'errorInOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
        ])
      ]
    )
  ]
})
export class DeliveryDateSelectorComponent implements OnInit, OnChanges, Validator, ControlValueAccessor, AfterViewInit {

  @Input() deliveryDate: Date | null = null;

  @Output() deliveryDateChange = new EventEmitter<Date>();

  @Input() isReadOnly: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() dateFormat: Intl.DateTimeFormatOptions | null; // see Intl.DateTimeFormat cheatsheet => https://devhints.io/wip/intl-datetime
  @Input() timeFormat: Intl.DateTimeFormatOptions | null;

  @Input() showIcon: boolean = true;
  @Input() iconColor: ThemePalette = 'primary';

  @Input() promptMessageWhenEmpty: string = 'CART.DELIVERY_DATE_TIME_TITLE'; //'CART.DELIVERY_DATE_TIME_TITLE_ALTERNATIVE';

  deliveryDateLabel: string | null = null;
  deliveryHourLabel: string | null = null;

  private _controlValueAccessorOnChange: (_: any) => void = null;
  private _controlValueAccessorOnTouched: (_: any) => void = null;
  private _validatorChange: (_: any) => void = null;

  // observable errors for internal use
  errors$ = new BehaviorSubject<ValidationErrors>({});

  control: AbstractControl;

  constructor(
    private _deliveryDatetimeService: DeliveryDateTimeService,
    private _translationService: TranslocoService,
    private _cd: ChangeDetectorRef,
    private _injector: Injector,
  ) {

    this._translationService.langChanges$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.refreshDisplay();
        this._cd.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (changes['deliveryDate'] || changes['dateFormat'] || changes['timeFormat'] || changes['isReadOnly']) {
      this.refreshDisplay();
    }
    if (changes['deliveryDate']) {
      this._validatorChange?.call(this);
    }
  }

  private refreshDisplay() {
    const currentLang = this._translationService.getActiveLang();

    const deliveryIsToday = this.deliveryDate && isToday(this.deliveryDate);

    this.deliveryDateLabel = deliveryIsToday
      ? this._translationService.translate('COMMON.TODAY')
      : this.deliveryDate?.toLocaleDateString(currentLang, this.computeDateFormat());

    const timeFormat = this.timeFormat || { hour: 'numeric', minute: 'numeric' };
    this.deliveryHourLabel = this.deliveryDate?.toLocaleString(currentLang, timeFormat);
  }

  private computeDateFormat(): Intl.DateTimeFormatOptions {
    if (this.dateFormat) { // is a forced format ?
      return this.dateFormat;
    }

    // if date is past or from another week then display also short date (not only the weekday)
    // see Intl.DateTimeFormat cheatsheet => https://devhints.io/wip/intl-datetime
    if (isPast(this.deliveryDate) || !isSameWeek(this.deliveryDate, new Date())) {
      return { weekday: 'short', day: '2-digit', month: '2-digit', year: '2-digit' };
    }
    else {
      return { weekday: 'long' };
    }
  }

  ngOnInit(): void { }

  onDeliverySectionClick() {
    if (!this.isReadOnly) {
      this._deliveryDatetimeService.showDeliveryDateTimePicker(this.deliveryDate)
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(selectedDate => {
          if (selectedDate?.getTime() !== this.deliveryDate?.getTime()) {
            this.deliveryDate = selectedDate;
            this.deliveryDateChange.emit(selectedDate);

            this._controlValueAccessorOnChange?.call(this, selectedDate);
            this._controlValueAccessorOnTouched?.call(this);

            this._cd.markForCheck();
          }
        });
    }
  }

  //#region ControlValueAccessor implementation
  // see : https://blog.angular-university.io/angular-custom-form-controls/#understandingthecontrolvalueaccessorinterface

  writeValue(obj: any): void {
    if ((obj === null || obj instanceof Date)
      && obj != this.deliveryDate) {
      this.deliveryDate = obj;
    }
  }
  registerOnChange(fn: (_: any) => void): void {
    this._controlValueAccessorOnChange = fn;
  }
  registerOnTouched(fn: (_: any) => void): void {
    this._controlValueAccessorOnTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  //#endregion

  //#region ControlValueAccessor hack to get FormControl instance
  // see https://stackoverflow.com/a/44732530

  // private getControl(): AbstractControl {
  //   return this.formControl || this._controlContainer.control.get(this.formControlName);
  // }

  ngAfterViewInit(): void {
    // resolve formControl for this instance, using injection AfterViewInit. see: https://stackoverflow.com/a/51126965
    // or use new inject method : https://netbasal.com/forwarding-form-controls-to-custom-control-components-in-angular-701e8406cc55

    this.control = this._injector.get(NgControl, null)?.control;
    if (this.control) {
      // update component state if FormControl is touched (from Form submition)
      // this.control.statusChanges
      extractTouchedChanges(this.control)
        .pipe(
          untilDestroyed(this),
          tap(() => this._cd.markForCheck())
        )
        .subscribe();

    } else {
      // Component is missing form control binding
    }
  }

  //#endregion


  //#region Validator implementation
  // see : https://blog.angular-university.io/angular-custom-form-controls/#introductiontothevalidatorinterface

  validate(control: AbstractControl): ValidationErrors {
    const errors = !this.deliveryDate && {
      required: this._translationService.translate('CART.DELIVERY_DATE_REQUIRED')
    };

    // notify error for internal use
    this.errors$.next(errors);

    return errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._validatorChange = fn;
  }

  //#endregion

}
