import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  // HttpXhrBackend,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiClientModule } from './core/api-client/api-client.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppSettingsService } from './core/settings/app-settings.service';
import { AppSettings } from './core/settings/app-settings';
import { AppHttpInterceptor } from './core/http/app-http-interceptor';
import { environment } from '@environments/environment';
import { AppInsightsService } from './core/telemetry/app-insights.service';
import { AnalyticsModule } from './core/analytics/analytics.module';
import { AppInsightsAnalyticsProvider } from './core/analytics/providers/appinsights-analytics-provider';
import { GoogleAnalyticsProvider } from './core/analytics/providers/google-analytics-provider';
import { ANALYTICS_PROVIDERS } from './core/analytics/analytics-service';
import { AuthHttpInterceptor } from './core/http/auth-http-interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { ToastErrorsHandler } from './core/errors/toast-errors-handler';
import { ApiConfiguration } from './core/api-client/api-configuration';
import { ToastrModule } from 'ngx-toastr';
import { BarComponent } from './shared/components/bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StorageProvider } from './core/storage/storage-provider';
import { LocalStorageProvider } from './core/storage/local-storage-provider';
import { AppHammerConfig } from './core/ui/hammer/app-hammer.config';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import { CommandModule } from './features/command/command.module';
import { CacheHttpInterceptor } from './core/http/cache-http-interceptor';
import { HotToastModule } from '@ngneat/hot-toast';
import { CommonModule } from '@angular/common';
import { LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from './core/maps/google-map-api-loader.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

// HACK pour éviter un cyclic-dependency à cause du CustomHttpService référencé en Htpp, on injecte la classe (non initialisée) pour l'initialiser ensuite
export function onAppInit(
  appSettingsService: AppSettingsService,
  appInsightsService: AppInsightsService
): () => Promise<any> {
  // see https://hackernoon.com/hook-into-angular-initialization-process-add41a6b7e
  // also (+) https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/
  return async () => {
    await appSettingsService.load();
    initAppInsight(appSettingsService.appSettings, appInsightsService);
  };
}

function initAppInsight(appSettings: AppSettings, appInsightsService: AppInsightsService) {
  if (!appSettings.appInsightsInstrumentationKey) {
    console.warn('No instrumentation key : Application Insights disabled');
    return;
  }

  // if (!environment.production) {
  //   console.warn('Mode debug (environment.production = false) : Application Insights disabled');
  //   return;
  // }

  appInsightsService.downloadAndSetup(appSettings.appInsightsInstrumentationKey, environment.appVersion, appSettings.environment, appSettings.forceTenant);
}

export function appSettingsFactory(appSettingsService: AppSettingsService) {
  if (!appSettingsService.appSettings) {
    throw new Error('appSettingsService.appSettings not loaded');
  }

  return appSettingsService.appSettings;
}

export function apiConfigurationFactory(appSettings: AppSettings) {
  if (!appSettings) {
    throw new Error('appSettings not loaded');
  }
  const apiConfiguration = new ApiConfiguration();
  apiConfiguration.rootUrl = appSettings.apiUrl;
  return apiConfiguration;
}

@NgModule({
  declarations: [AppComponent, BarComponent, TopBarComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiClientModule, // .forRoot({ rootUrl: '' }), // configured by apiConfigurationFactory
    SharedModule,
    BrowserAnimationsModule,
    AnalyticsModule,
    TranslocoRootModule,
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-US',
        fr: 'fr-FR',
      },
    }),
    ToastrModule.forRoot({
      maxOpened: 3,
      closeButton: true,
      tapToDismiss: true,
      countDuplicates: true,
      positionClass: 'toast-bottom-center',
      onActivateTick: true,
      enableHtml: true,
      preventDuplicates: true,
      progressBar: true,
      timeOut: 10_000, // 10s
      extendedTimeOut: 3_000 // 3s
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:5000',
    }),
    HammerModule,
    CommandModule,
    HotToastModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [AppSettingsService, AppInsightsService],
    },
    {
      provide: AppSettings,
      useFactory: appSettingsFactory,
      deps: [AppSettingsService],
    },
    {
      provide: ApiConfiguration,
      useFactory: apiConfigurationFactory,
      deps: [AppSettings],
    },
    { provide: ErrorHandler, useClass: ToastErrorsHandler },
    { provide: HTTP_INTERCEPTORS, useClass: CacheHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: ANALYTICS_PROVIDERS,
      useClass: AppInsightsAnalyticsProvider,
      multi: true,
    },
    {
      provide: ANALYTICS_PROVIDERS,
      useClass: GoogleAnalyticsProvider,
      multi: true,
    },
    { provide: StorageProvider, useClass: LocalStorageProvider },
    { provide: HAMMER_GESTURE_CONFIG, useClass: AppHammerConfig },
    {
      provide: LAZY_MAPS_API_CONFIG, useValue: {
        apiKey: 'AIzaSyDOz0VahumaQuQHgUNNJlhXT8MIC66-H00',
        libraries: ['places'],
      } as LazyMapsAPILoaderConfigLiteral
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
