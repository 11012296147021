import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, concat, Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged, first, tap, shareReplay, takeUntil } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { RestaurantContext } from '../models/restaurant-context.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const CURRENT_RESTAURANT_STORAGEKEY = 'SiteState_CurrentRestaurantContext';
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SiteState {

  private _updating$ = new BehaviorSubject<boolean>(false);

  private _currentRestaurantContextSubject = new Subject<RestaurantContext | null>();
  private _currentRestaurantContext: RestaurantContext | null;
  private _currentRestaurantContext$ = concat(
    this.load$(),
    this._currentRestaurantContextSubject.asObservable()
  ).pipe(
    // keep reference
    tap(restaurant => this._currentRestaurantContext = restaurant),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }), // on partage toujours la même subscription et on propose la dernière valeur
    untilDestroyed(this)
  );

  constructor(
    private _storage: StorageMap
  ) {

    // Force le chargement dès l'instantiation
    this._currentRestaurantContext$
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  // protected _componentDestroyedSubject = new Subject<void>();
  // ngOnDestroy(): void {
  //   this._componentDestroyedSubject.next();
  //   this._componentDestroyedSubject.complete();
  // }

  setUpdating(isUpdating: boolean) {
    this._updating$.next(isUpdating);
  }

  isUpdating$() {
    return this._updating$.asObservable();
  }

  setCurrentRestaurantContext(restaurant: RestaurantContext | null) {
    this.setCurrentRestaurantContext$(restaurant).subscribe(); // fire & forget
  }

  setCurrentRestaurantContext$(restaurant: RestaurantContext | null): Observable<RestaurantContext | null> {
    return this.store$(restaurant)
      .pipe(
        tap(() => this._currentRestaurantContextSubject.next(restaurant))
      );
  }

  private store$(restaurant: RestaurantContext): Observable<RestaurantContext | null> {
    return this._storage.set(CURRENT_RESTAURANT_STORAGEKEY, restaurant)
      .pipe(
        first(),
        map(() => restaurant)
      );
  }

  private load$(): Observable<RestaurantContext | null> {
    return this._storage.get(CURRENT_RESTAURANT_STORAGEKEY)
      .pipe(
        first(),
        map(stored => stored as RestaurantContext | null)
      );
  }

  getCurrentRestaurant$() {
    return this._currentRestaurantContext$
      .pipe(
        map(restaurantContext => restaurantContext?.restaurant)
      );
  }

  getCurrentRestaurant() {
    return this._currentRestaurantContext?.restaurant;
  }

  getCurrentSite$() {
    return this._currentRestaurantContext$
      .pipe(
        map(r => r?.site),
        distinctUntilChanged((site1, site2) => site1?.siteId === site2?.siteId)
      );
  }

  getCurrentSite() {
    return this._currentRestaurantContext?.site;
  }

  getCurrentRestaurantContext$() {
    return this._currentRestaurantContext$;
  }

  getCurrentRestaurantContext() {
    return this._currentRestaurantContext;
  }

}
