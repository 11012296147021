
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator, Validators } from '@angular/forms';
import { GoogleMapApiLoaderService } from '@app/core/maps/google-map-api-loader.service';
import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import { ModelsHelper } from '@app/core/helpers/models-helper';
import { extractTouchedChanges } from '@app/core/helpers/forms-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, tap } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { CartDeliveryAddress } from '@app/core/services/cart/models/cart.model';

//inspired from https://github.com/angular-material-extensions/google-maps-autocomplete/blob/master/projects/angular-material-extensions/google-maps-autocomplete/src/lib/component/mat-google-maps-autocomplete.component.ts#L125

function isAddress(obj: any): obj is CartDeliveryAddress {
  return ModelsHelper.implementsTKeys<CartDeliveryAddress>(obj, ['name', 'latitude', 'longitude']);
}

//AutocompleteOptions : https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions
const autocompleteOptions: AutocompleteOptions = {
  types: ['address'], //https://developers.google.com/maps/documentation/javascript/supported_types?hl=fr#table3
  fields: ['address_components', 'formatted_address', 'geometry.location'] //https://developers.google.com/maps/documentation/javascript/reference/places-service?hl=fr#PlaceResult-Properties
};

@UntilDestroy()
@Component({
  selector: 'app-cart-delivery-location',
  templateUrl: './cart-delivery-location.component.html',
  styleUrls: ['./cart-delivery-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CartDeliveryLocationComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CartDeliveryLocationComponent),
      multi: true,
    }
  ]
})
export class CartDeliveryLocationComponent implements OnInit, Validator, ControlValueAccessor, AfterViewInit {

  @Input() readonly: boolean;
  @Output() addressChanged = new EventEmitter<CartDeliveryAddress>();

  @Input() address: CartDeliveryAddress;

  @ViewChild('search')
  searchElementRef: ElementRef;

  // observable errors for internal use
  errors$ = new BehaviorSubject<ValidationErrors>({});

  addressSearchControl: FormControl<string> = new FormControl<string>(null as string, Validators.compose([Validators.required]));
  control: AbstractControl;

  private _controlValueAccessorOnChange: (_: any) => void = null;
  private _controlValueAccessorOnTouched: (_: any) => void = null;
  private _validatorChange: (_: any) => void = null;

  constructor(
    private _googleMapApiLoader: GoogleMapApiLoaderService,
    private _translationService: TranslocoService,
    private _ngZone: NgZone,
    private _injector: Injector,
    private _cd: ChangeDetectorRef,
  ) { }


  validate(control: AbstractControl<any, any>): ValidationErrors {
    const errors = !this.address && {
      required: this._translationService.translate('CART.ADDRESS_ERROR_REQUIRED')
    };

    //TODO : check if address is valid (with google maps ?)


    // notify error for internal use
    this.errors$.next(errors);

    return errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._validatorChange = fn;
  }

  writeValue(obj: any): void {
    if (obj === null || isAddress(obj)) {
      this.setAddress(obj);
    }
  }
  registerOnChange(fn: (_: any) => void): void {
    this._controlValueAccessorOnChange = fn;
  }
  registerOnTouched(fn: (_: any) => void): void {
    this._controlValueAccessorOnTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  ngAfterViewInit(): void {
    // resolve formControl for this instance, using injection AfterViewInit.
    // see: https://stackoverflow.com/a/51126965
    this.control = this._injector.get(NgControl, null)?.control;
    if (this.control) {
      // update component state if FormControl is touched (from Form submition)
      // this.control.statusChanges
      extractTouchedChanges(this.control)
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.addressSearchControl.markAsTouched();
            this._cd.markForCheck();
          })
        )
        .subscribe();

    } else {
      // Component is missing form control binding
    }
  }

  ngOnInit(): void {
    this.initGoogleMapsAutocomplete();
  }

  private setAddress(address: CartDeliveryAddress | null, emitOnChanged: boolean = true) {
    if (this.address !== address) {
      this.address = address;
      this.addressSearchControl.setValue(address?.name);
      if (emitOnChanged) {
        // notify control for value change (from UI)
        this.addressChanged.emit(this.address);
        this._controlValueAccessorOnChange?.call(this, address);
        this._controlValueAccessorOnTouched?.call(this);
      }
    }
  }

  private initGoogleMapsAutocomplete() {
    this._googleMapApiLoader
      .load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, autocompleteOptions);
        autocomplete.addListener('place_changed', () => {
          this._ngZone.run(() => {
            // get the place result
            const place: PlaceResult = autocomplete.getPlace();

            let address: CartDeliveryAddress | null = null;

            if (place?.formatted_address && place?.geometry?.location) {
              address = {
                name: place.formatted_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                country: null,
                locality: null,
                postalCode: null,
                route: null,
                state: null,
                streetNumber: null
              };
              if (place.address_components) {
                place.address_components.forEach(value => {
                  if (value.types.indexOf('street_number') > -1) {
                    address.streetNumber = value.short_name;
                  }
                  if (value.types.indexOf('route') > -1) {
                    address.route = value.long_name;
                  }
                  if (value.types.indexOf('postal_code') > -1) {
                    address.postalCode = value.short_name;
                  }
                  // if (value.types.indexOf('sublocality') > -1) {
                  //   address.sublocality = value.long_name;
                  // }
                  if (value.types.indexOf('locality') > -1) {
                    address.locality = value.long_name;
                    // address.locality.short = value.short_name;
                  }
                  if (value.types.indexOf('administrative_area_level_1') > -1) {
                    address.state = value.long_name;
                    // address.state.short = value.short_name;
                  }
                  if (value.types.indexOf('country') > -1) {
                    address.country = value.long_name;
                    // address.country.short = value.short_name;
                  }
                  // if (value.types.indexOf('administrative_area_level_3') > -1) {
                  //   address.locality.short = value.short_name;
                  // }
                });
              }
            }

            this.setAddress(address);
          });
        });
      })
      .catch((err) => console.log(err));
  }

}
