<div class="dialog-title"
     mat-dialog-title
     *ngIf="!siteHasSingleRestaurant">
  {{ ( 'MENU.CHANGE_RESTAURANT.TITLE' | transloco) }}
</div>

<mat-dialog-content>

  <ng-container *ngIf="siteHasSingleRestaurant; else restaurantNavListTemplate">
    <p>{{ 'MENU.CHANGE_RESTAURANT.PROMPT_CHANGE_SITE' | transloco }}</p>
  </ng-container>
  <ng-template #restaurantNavListTemplate>
    <mat-action-list>
      <!-- SITE RESTAURANTS -->
      <button *ngFor="let restaurant of data.site.restaurants"
              mat-list-item
              (click)="onGotoRestaurant(restaurant)">
        <div class="restaurant-result-item"
             fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="10px">
          <mat-icon class="restaurant-icon"
                    [color]="(restaurant.restaurantId === currentRestaurant.restaurantId)? 'accent' : 'primary'">location_on</mat-icon>
          <div class="restaurant-infos"
               fxLayout="column">
            <div class="restaurant-name">{{ restaurant.name }}</div>
            <div class="restaurant-address">{{ restaurant.address }}</div>
          </div>
        </div>
      </button>
      <!-- SELECT OTHER SITE -->
      <button mat-list-item
              (click)="onGotoSiteSelection()">
        <div class="restaurant-result-item"
             fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="10px">
          <mat-icon class="restaurant-icon"
                    color="primary">my_location</mat-icon>
          <div class="restaurant-infos"
               fxLayout="column">
            <span>{{ 'MENU.CHANGE_RESTAURANT.CHANGE_SITE_OPTION' | transloco }}</span>
          </div>
        </div>
      </button>
    </mat-action-list>
  </ng-template>

</mat-dialog-content>

<mat-dialog-actions>

  <button fxFlex="30"
          mat-button
          color="accent"
          (click)="onCancelClick()"
          cdkFocusInitial>
    {{ 'COMMON.CANCEL' | transloco }}
  </button>
  <button fxFlex="30"
          mat-button
          color="primary"
          *ngIf="siteHasSingleRestaurant"
          (click)="onGotoSiteSelection()">
    {{ 'COMMON.YES' | transloco }}
  </button>

</mat-dialog-actions>
