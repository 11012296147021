<app-scrollable-container class="html-dialog">
  <!-- header-->
  <header header class="title"
       [innerHtml]="title"></header>
  <!-- content-->
  <main content [innerHtml]="content"></main>
  <!-- footer-->
  <footer footer>
    <button mat-raised-button
            color="primary"
            mat-dialog-close>{{'COMMON.OK' | transloco}}</button>
  </footer>
</app-scrollable-container>
