import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CartProduct } from '@app/core/services/cart/models/cart.model';
import _groupBy from 'lodash-es/groupBy';

@Component({
  selector: 'app-cart-product-list',
  templateUrl: './cart-product-list.component.html',
  styleUrls: ['./cart-product-list.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px', opacity: 0 }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto', opacity: 1 })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)', opacity: 0 }))
        ])
      ]
    )
  ]
})
export class CartProductListComponent implements OnInit, OnChanges {
  @Input() cartProductList: CartProduct[];
  @Input() currencyCode: string | null;
  @Input() isReadOnly: boolean;
  @Input() showTitle: boolean = true;
  @Input() showZeroPrices: boolean = true;
  @Input() showDivider: boolean = true;
  @Input() showCategory: boolean = false;
  @Input() filter?: (cartProduct: CartProduct) => boolean;

  @Output() editCartProductClick = new EventEmitter<CartProduct>();

  displayedCartProducts: CartProduct[];
  displayedCartProductsByCategory: { categoryName: string, cartProducts: CartProduct[] }[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cartProductList'] || changes['filter']) {
      this.displayedCartProducts = this.filter
        ? this.cartProductList?.filter(p => this.filter(p))
        : this.cartProductList;

      this.computeCartProductsByCategory();
    }
  }

  ngOnInit(): void { }

  private computeCartProductsByCategory() {
    this.displayedCartProductsByCategory = [];
    const productsByCategory = _groupBy(this.displayedCartProducts, p => p.rootCategoryName ?? '');
    for (const [categoryName, cartProducts] of Object.entries(productsByCategory)) {
      this.displayedCartProductsByCategory.push({ categoryName, cartProducts });
    }
  }

  onEditCartProductClick(cartProduct: CartProduct) {
    if (!this.isReadOnly) {
      this.editCartProductClick.emit(cartProduct);
    }
  }

  trackByCartProductIdentity(index: number, cartProduct: CartProduct): any {
    return cartProduct.id;
  }

  trackByCategoryName(index: number, categoryGroup: { categoryName: string, cartProducts: CartProduct[] }): any {
    return categoryGroup.categoryName;
  }
}
