import { Injectable } from '@angular/core';
import { SearchSiteInput } from '@app/core/models/search-site-input.model';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { MapperService } from '../mapper.service';
import { ApiSitesService, ApiRestaurantsService } from '@app/core//api-client/services';
import { SiteModel } from '@app/core/models/site.model';
import { SiteState } from './state/site.state';
import { Restaurant } from '@app/core/api-client/models';
import { RestaurantContext } from './models/restaurant-context.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {

  constructor(
    private apiSitesService: ApiSitesService,
    private mapper: MapperService,
    private _restaurantApiService: ApiRestaurantsService,
    private _siteState: SiteState
  ) {

    // TODO Monitor Restaurant change to Load whole site & restaurants
    // Or load all from a single APi (Menu => MenuContext)
  }

  search$(searchInput: SearchSiteInput): Observable<SiteModel[]> {
    return this.apiSitesService
      .SearchSites$Json({
        Lat: searchInput.lat,
        Lng: searchInput.lng,
        SearchTerm: searchInput.searchTerm,
      })
      .pipe(
        first(),
        map((resultSites) =>
          resultSites.map((site) => this.mapper.mapSearchSitesResultToSiteModel(site))
        )
      );
  }

  getRestaurantById$(restaurantId: number): Observable<Restaurant> {
    return this.getCurrentRestaurant$()
      .pipe(
        switchMap(restaurant => {
          // try get restaurant from current cached restaurant
          if (restaurant?.restaurantId === restaurantId) {
            return of(restaurant);
          }
          // else get from Api
          return this._restaurantApiService.GetRestaurant$Json({
            restaurantId: restaurantId
          }).pipe(
            first()
          );
        })
      );
  }

  isUpdating$() {
    return this._siteState.isUpdating$();
  }

  getCurrentRestaurant() {
    return this._siteState.getCurrentRestaurant();
  }

  getCurrentRestaurant$() {
    return this._siteState.getCurrentRestaurant$();
  }

  getCurrentSite() {
    return this._siteState.getCurrentSite();
  }

  getCurrentSite$() {
    return this._siteState.getCurrentSite$();
  }

  setCurrentRestaurantContext(restaurant: RestaurantContext) {
    this._siteState.setCurrentRestaurantContext(restaurant);
  }

  setCurrentRestaurantContext$(restaurant: RestaurantContext) {
    return this._siteState.setCurrentRestaurantContext$(restaurant);
  }

  getCurrentRestaurantContext() {
    return this._siteState.getCurrentRestaurantContext();
  }

  getCurrentRestaurantContext$() {
    return this._siteState.getCurrentRestaurantContext$();
  }

  // setCurrentRestaurantById$(restaurantId: number): Observable<boolean> {
  //   return this.internalSetCurrentRestaurantById$(
  //     restaurantId,
  //     (id) => this._restaurantApiService.GetRestaurant$Json({ restaurantId: id })
  //   );
  // }

  // setCurrentRestaurant$(restaurant: Restaurant): Observable<boolean> {
  //   return this.internalSetCurrentRestaurantById$(restaurant.restaurantId, (id) => of(restaurant));
  // }

  // private internalSetCurrentRestaurantById$(
  //   restaurantId: number,
  //   restaurantLoader$: (id: number) => Observable<Restaurant>
  // ): Observable<boolean> {
  //   // check & prompt si panier avec un menu d'un autre resto
  //   // si annuler panier alors chargement nouveau restaurant/menu
  //   //  => ou alors conserver les paniers par menuId et les restaurer lors de la re-consultation du menu?
  //   // sinon redirect vers /Menu et reste sur le restaurant courant
  //   return this.getCurrentRestaurant$()
  //     .pipe(
  //       switchMap(currentRestaurant => {
  //         let changeRestaurant = false;
  //         if ((currentRestaurant && currentRestaurant.restaurantId) !== restaurantId) {
  //           // TODO prompt user to discard cart or cancel "change-restaurant" action
  //           // => https://itnext.io/building-a-reusable-dialog-module-with-angular-material-4ce406117918

  //           // user has confirmed change
  //           changeRestaurant = true;
  //           return restaurantLoader$(restaurantId)
  //             .pipe(
  //               map(restaurant => {
  //                 this._siteState.setCurrentRestaurant(restaurant);

  //                 // TODO trouver un moyen de faire un fire&forget tout en gérant un cancel en cas de changement de restau
  //                 // (ce que fait normalement le switchMap)
  //                 // this.loadRestaurantMenu(restaurant.restaurantId);
  //                 return true;
  //               })
  //             );
  //         } else {
  //           return of(true);
  //         }

  //       })
  //     );
  // }


}
