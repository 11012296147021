<div class="reset-password-page mat-typography custom-input"
     fxLayout="column"
     fxLayoutAlign="start stretch">
  <!-- Header-->
  <div class="reset-password-page-header"
       [ngClass.gt-xs]="{'full-size':true}"
       fxFlex>
    <app-header imageUrl="./assets/imgs/backgrounds/signin.jpg">
      <!-- back button-->
      <app-back-button [stopNavigation]="true"
                       (goBack)="cancel()"></app-back-button>
    </app-header>
  </div>

  <!-- form container-->
  <div class="reset-password-container"
       [ngClass.gt-xs]="{'centered':true}">
    <app-reset-password [token]="changePasswordToken"
                        (resetPassword)="resetPassword($event)"
                        (cancel)="cancel()"
                        [submitting]="submitting"></app-reset-password>
  </div>
</div>
