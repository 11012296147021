import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@app/core/logging';
import { Observable } from 'rxjs';
import { BaseHttpInterceptor, HttpInterceptorOptions } from './base-http-interceptor';

@Injectable()
export class CacheHttpInterceptor extends BaseHttpInterceptor {

  constructor(
    logService: LogService
  ) {
    super(
      logService.getLogger('CacheHttpInterceptor')
    );
  }

  configureInterceptorOptions(): HttpInterceptorOptions {
    return {
      whitelistedRoutes: [
        /\/(api|api-internal)\//i,
      ],
      blacklistedRoutes: []
    };
  }

  protected handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-store, max-age=0'
        }
    });

    return next.handle(request);
  }

}
