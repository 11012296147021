import { AnalyticsDimensionMappings } from '../models/analytics-dimension-mapping.model';
import { AnalyticsDimensions } from '../models/analytics-dimensions.model';
import { AnalyticsService } from '../analytics-service';
import { AnalyticsEvent } from '../models/analytics-event.model';

export abstract class AnalyticsProvider {
  public dimensionPropertyMappings: AnalyticsDimensionMappings = new AnalyticsDimensionMappings();

  subscribeToMainService(analyticsService: AnalyticsService) {
    analyticsService.setUserId$.subscribe(userId => {
      this.setUserId_internal(userId);
    });

    analyticsService.eventTrack$.subscribe(payload => {
      this.eventTrack(payload.event, payload.dimensions);
    });

    analyticsService.pageTrack$.subscribe(payload => {
      this.pageTrack(payload.title, payload.path, payload.dimensions);
    });
  }

  protected abstract pageTrack_internal(title: string, path: string, dimensions: AnalyticsDimensions): void;
  protected abstract eventTrack_internal(event: AnalyticsEvent, dimensions: AnalyticsDimensions): void;
  protected abstract setUserId_internal(userId: string): void;


  private pageTrack(title: string, path: string, dimensions: AnalyticsDimensions) {
    const mappedDimensions = this.mapDimensionProperties(dimensions);
    this.pageTrack_internal(title, path, mappedDimensions);
  }

  private eventTrack(event: AnalyticsEvent, dimensions: AnalyticsDimensions) {
    const mappedDimensions = this.mapDimensionProperties(dimensions);
    this.eventTrack_internal(event, mappedDimensions);
  }

  private mapDimensionProperties(dimensions: AnalyticsDimensions): AnalyticsDimensions {
    const mappedDimensions: AnalyticsDimensions = Object.assign({}, dimensions);
    const mappings = this.dimensionPropertyMappings;

    if (mappings) {
      for (const fromProp in mappings) {
        if (mappedDimensions[fromProp]) {
          const toProp = mappings[fromProp];
          mappedDimensions[toProp] = mappedDimensions[fromProp];
          delete mappedDimensions[fromProp];
        }
      }
    }

    return mappedDimensions;
  }
}
