import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { InMemoryStorage } from '@app/core/storage/in-memory-storage';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch(err => console.error(err));

// add eruda console
; (function () {
  if (!/console=true/.test(window.location.href)) {
    return
  };

  // polyfill localstore if needed
  // let storageSupported = false;
  // try { storageSupported = (window.localStorage && true) } catch (e) { }

  // if (!storageSupported) {
  //   console.debug('Local storage not supported, using in-memory storage');
  //   window.localStorage = new InMemoryStorage();
  // }

  (window as any).__initEruda = () => {
    const eruda = (window as any).eruda;
    if (!eruda) {
      setTimeout(function () { (window as any).__initEruda(); }, 200);
    } else {
      eruda.init();
    }
  }

  // add script to head tag
  const scriptEl: HTMLScriptElement = window.document.createElement('script');
  scriptEl.src = 'https://cdn.jsdelivr.net/npm/eruda';

  const scriptElinit: HTMLScriptElement = window.document.createElement('script');
  scriptElinit.innerText = 'setTimeout(function(){__initEruda();},200)';

  const headEl: HTMLHeadElement = window.document.getElementsByTagName('head')[0];
  headEl.appendChild(scriptEl);
  headEl.appendChild(scriptElinit);

})();
