import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@app/core/logging';
import { AppSettings } from '@app/core/settings/app-settings';
import { Observable } from 'rxjs';
import { BaseHttpInterceptor, HttpInterceptorOptions } from './base-http-interceptor';
import { environment } from '@environments/environment';
import { GlobalizationState } from '../services/globalization/globalization.state';
import { TenantService } from '../services/tenant/tenant.service';

@Injectable()
export class AppHttpInterceptor extends BaseHttpInterceptor {

  constructor(
    private _appSettings: AppSettings,
    private _globalizationState: GlobalizationState,
    private _tenantService: TenantService,
    logService: LogService
  ) {
    super(
      logService.getLogger('AppHttpInterceptor')
    );
  }

  configureInterceptorOptions(): HttpInterceptorOptions {
    return {
      whitelistedRoutes: [
        /\/(api|api-internal)\//i,
      ],
      blacklistedRoutes: []
    };
  }

  protected handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const intlOptions = Intl.DateTimeFormat().resolvedOptions();
    const headers: { [name: string]: string | string[]; } = {
      // ajout de la version de l'api
      'client-version': environment.appVersion,
      // ajout de la subscriberkey API Managment
      'Ocp-Apim-Subscription-Key': this._appSettings.apiSubscriberKey,
      // localization headers
      'client-timezone': intlOptions.timeZone,
      'client-locale': this._globalizationState.getActiveLang() || '' // null can throw error
      // DEBUG TEST
      // 'client-timezone': 'America/Guyana',
      // 'client-time': new Date().toString(),
      // 'client-utcoffset': (new Date().getTimezoneOffset() / -60).toString(),
    };

    // sur la dev/rec, l'api est sur le même host. Du coup on ajoute du tenant
    const tenant = this._tenantService.resolve();
    if (tenant) {
      headers['force-tenant'] = tenant;
    }

    request = request.clone({
      setHeaders: headers,
      withCredentials: true
    });

    return next.handle(request);
  }

}
