<!-- Format prix centimes en flotant-->

<!-- attr.cents="{{ 'COMMON.PRICE_CENT_FORMAT' | transloco: { cents: decimalPart | number: '2.0' } }} €"> -->

<div *ngIf="floating"
     class="price"
     attr.cents="{{ fractionPartString }}">
  {{ integerPartString }}
</div>

<!-- Format prix entier un seul niveau-->
<ng-container *ngIf="!floating">
  {{ integerPartString }}{{ fractionPartString }}
</ng-container>
