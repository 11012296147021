import { OauthTokenResponse } from '@app/core/api-client/models';
import { JwtHelper } from '../helpers/jwt-helper.helper';

export type OauthToken = OauthTokenResponse;

export class AuthenticationToken {
  public authToken: OauthToken;

  public get accessToken() { return this.authToken.access_token; }
  public get refreshToken() { return this.authToken.refresh_token; }

  public accessTokenExpirationDate: Date;
  public refreshTokenExpirationDate: Date;

  constructor(token: OauthToken) {
    this.authToken = token;
    this.accessTokenExpirationDate = JwtHelper.getTokenExpirationDate(this.accessToken);
    this.refreshTokenExpirationDate = JwtHelper.getTokenExpirationDate(this.refreshToken);
  }

  public isAccessTokenExpired() {
    return this.isExpired(this.accessTokenExpirationDate);
  }

  public isRefreshTokenExpired() {
    return this.isExpired(this.refreshTokenExpirationDate);
  }

  private isExpired(expirationDate: Date, offsetSeconds?: number): boolean {
    offsetSeconds = offsetSeconds || 0;
    if (expirationDate === null) {
      return true;
    }
    return !(expirationDate.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }
}
