import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cart-clear-button',
  templateUrl: './cart-clear-button.component.html',
  styleUrls: ['./cart-clear-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartClearButtonComponent implements OnInit {

  @Output() clearCart = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClearCart(){
    this.clearCart.emit();
  }
}
