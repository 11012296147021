import { Injectable } from '@angular/core';
import { svgSets, fallbackIcon } from './svg-sets';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StringHelper } from '@app/core/helpers/string-helper';
import flatMap from 'lodash-es/flatMap';
import Fuzzyset from 'fuzzyset';
@Injectable({
  providedIn: 'root'
})
export class CustomIconService {

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) { }

  public addCustomSvgIcons() {

    svgSets.forEach(svgSet => {
      svgSet.icons.forEach(icon => {
        const safeUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(`./assets/imgs/${svgSet.folder}/${icon}.svg`);
        const iconName = this.sanitizeIconName(icon);
        const namespace = this.sanitizeIconName(svgSet.namespace);
        if (svgSet.namespace) {
          this._matIconRegistry.addSvgIconInNamespace(namespace, iconName, safeUrl);
        } else {
          this._matIconRegistry.addSvgIcon(iconName, safeUrl);
        }
      });

      // sanitize iconNames
      svgSet.icons = svgSet.icons.map(icon => this.sanitizeIconName(icon));
    });
  }

  public getCustomIconName(iconName: string, fallbackIconName: string = fallbackIcon, minSimilarityScore: number = 1): string {
    // sanitize requested iconName
    iconName = this.sanitizeIconName(iconName);
    // check iconName has namespace
    const iconPaths = iconName.split(':');
    if (iconPaths.length === 1) {
      const allIcons = flatMap(svgSets, s => s.icons);
      if (allIcons.includes(iconName)) {
        return iconName;
      }
      if (minSimilarityScore < 1) {
        const fuzzyset = Fuzzyset(allIcons);
        const match = fuzzyset.get(iconName, undefined, minSimilarityScore);
        if (match?.length > 0) {
          return match[0][1];
        }
      }
      return fallbackIconName;
    }
    else {
      const svgSet = svgSets.find(s => s.namespace === iconPaths[0]);
      if (svgSet) {
        const iconNameWithoutNamespace = iconPaths.slice(1).join(':');
        if (svgSet && svgSet.icons.includes(iconNameWithoutNamespace)) {
          return iconNameWithoutNamespace;
        }
      }
    }
    return fallbackIconName;
  }

  public sanitizeIconName(iconName: string): string {
    if (iconName == null) {
      return iconName;
    }
    return StringHelper.withoutAccent(iconName).toLowerCase();
  }
}
