/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { HealthCheckAll$Json } from '../fn/health-check/health-check-all-json';
import { HealthCheckAll$Json$Params } from '../fn/health-check/health-check-all-json';
import { HealthCheckAll$Plain } from '../fn/health-check/health-check-all-plain';
import { HealthCheckAll$Plain$Params } from '../fn/health-check/health-check-all-plain';
import { HealthCheckDependencies$Json } from '../fn/health-check/health-check-dependencies-json';
import { HealthCheckDependencies$Json$Params } from '../fn/health-check/health-check-dependencies-json';
import { HealthCheckDependencies$Plain } from '../fn/health-check/health-check-dependencies-plain';
import { HealthCheckDependencies$Plain$Params } from '../fn/health-check/health-check-dependencies-plain';
import { HealthCheckLive$Json } from '../fn/health-check/health-check-live-json';
import { HealthCheckLive$Json$Params } from '../fn/health-check/health-check-live-json';
import { HealthCheckLive$Plain } from '../fn/health-check/health-check-live-plain';
import { HealthCheckLive$Plain$Params } from '../fn/health-check/health-check-live-plain';
import { HealthCheckReady$Json } from '../fn/health-check/health-check-ready-json';
import { HealthCheckReady$Json$Params } from '../fn/health-check/health-check-ready-json';
import { HealthCheckReady$Plain } from '../fn/health-check/health-check-ready-plain';
import { HealthCheckReady$Plain$Params } from '../fn/health-check/health-check-ready-plain';
import { HealthCheckReport } from '../models/health-check-report';
import { HealthCheckReport$Json } from '../fn/health-check/health-check-report-json';
import { HealthCheckReport$Json$Params } from '../fn/health-check/health-check-report-json';
import { HealthCheckReport$Plain } from '../fn/health-check/health-check-report-plain';
import { HealthCheckReport$Plain$Params } from '../fn/health-check/health-check-report-plain';
import { HealthCheckSiteReport$Json } from '../fn/health-check/health-check-site-report-json';
import { HealthCheckSiteReport$Json$Params } from '../fn/health-check/health-check-site-report-json';
import { HealthCheckSiteReport$Plain } from '../fn/health-check/health-check-site-report-plain';
import { HealthCheckSiteReport$Plain$Params } from '../fn/health-check/health-check-site-report-plain';
import { Ping$Json } from '../fn/health-check/ping-json';
import { Ping$Json$Params } from '../fn/health-check/ping-json';
import { Ping$Plain } from '../fn/health-check/ping-plain';
import { Ping$Plain$Params } from '../fn/health-check/ping-plain';

@Injectable({ providedIn: 'root' })
export class ApiHealthCheckService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getHealthcheckPing()` */
  static readonly GetHealthcheckPingPath = '/api/HealthCheck/ping';

  /**
   * get-healthcheck-ping.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Ping$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping$Plain$Response(params?: Ping$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return Ping$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-ping.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Ping$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping$Plain(params?: Ping$Plain$Params, context?: HttpContext): Observable<string> {
    return this.Ping$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * get-healthcheck-ping.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Ping$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping$Json$Response(params?: Ping$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return Ping$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-ping.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Ping$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping$Json(params?: Ping$Json$Params, context?: HttpContext): Observable<string> {
    return this.Ping$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getHealthcheck()` */
  static readonly GetHealthcheckPath = '/api/HealthCheck';

  /**
   * get-healthcheck.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReport$Plain$Response(params?: HealthCheckReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return HealthCheckReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReport$Plain(params?: HealthCheckReport$Plain$Params, context?: HttpContext): Observable<string> {
    return this.HealthCheckReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * get-healthcheck.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckReport$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReport$Json$Response(params?: HealthCheckReport$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return HealthCheckReport$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckReport$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReport$Json(params?: HealthCheckReport$Json$Params, context?: HttpContext): Observable<string> {
    return this.HealthCheckReport$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getHealthcheckBySiteid()` */
  static readonly GetHealthcheckBySiteidPath = '/api/HealthCheck/{siteId}';

  /**
   * get-healthcheck-by-siteid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckSiteReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckSiteReport$Plain$Response(params: HealthCheckSiteReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return HealthCheckSiteReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-by-siteid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckSiteReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckSiteReport$Plain(params: HealthCheckSiteReport$Plain$Params, context?: HttpContext): Observable<string> {
    return this.HealthCheckSiteReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * get-healthcheck-by-siteid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckSiteReport$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckSiteReport$Json$Response(params: HealthCheckSiteReport$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return HealthCheckSiteReport$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-by-siteid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckSiteReport$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckSiteReport$Json(params: HealthCheckSiteReport$Json$Params, context?: HttpContext): Observable<string> {
    return this.HealthCheckSiteReport$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getHealthcheckAll()` */
  static readonly GetHealthcheckAllPath = '/api/HealthCheck/all';

  /**
   * get-healthcheck-all.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckAll$Plain$Response(params?: HealthCheckAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-all.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckAll$Plain(params?: HealthCheckAll$Plain$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckAll$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /**
   * get-healthcheck-all.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckAll$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckAll$Json$Response(params?: HealthCheckAll$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckAll$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-all.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckAll$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckAll$Json(params?: HealthCheckAll$Json$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckAll$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /** Path part for operation `getHealthcheckLive()` */
  static readonly GetHealthcheckLivePath = '/api/HealthCheck/live';

  /**
   * get-healthcheck-live.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckLive$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckLive$Plain$Response(params?: HealthCheckLive$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckLive$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-live.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckLive$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckLive$Plain(params?: HealthCheckLive$Plain$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckLive$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /**
   * get-healthcheck-live.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckLive$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckLive$Json$Response(params?: HealthCheckLive$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckLive$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-live.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckLive$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckLive$Json(params?: HealthCheckLive$Json$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckLive$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /** Path part for operation `getHealthcheckReady()` */
  static readonly GetHealthcheckReadyPath = '/api/HealthCheck/ready';

  /**
   * get-healthcheck-ready.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckReady$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReady$Plain$Response(params?: HealthCheckReady$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckReady$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-ready.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckReady$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReady$Plain(params?: HealthCheckReady$Plain$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckReady$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /**
   * get-healthcheck-ready.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckReady$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReady$Json$Response(params?: HealthCheckReady$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckReady$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-ready.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckReady$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckReady$Json(params?: HealthCheckReady$Json$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckReady$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /** Path part for operation `getHealthcheckDependencies()` */
  static readonly GetHealthcheckDependenciesPath = '/api/HealthCheck/dependencies';

  /**
   * get-healthcheck-dependencies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckDependencies$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckDependencies$Plain$Response(params?: HealthCheckDependencies$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckDependencies$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-dependencies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckDependencies$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckDependencies$Plain(params?: HealthCheckDependencies$Plain$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckDependencies$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

  /**
   * get-healthcheck-dependencies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `HealthCheckDependencies$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckDependencies$Json$Response(params?: HealthCheckDependencies$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<HealthCheckReport>> {
    return HealthCheckDependencies$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-healthcheck-dependencies.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `HealthCheckDependencies$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  HealthCheckDependencies$Json(params?: HealthCheckDependencies$Json$Params, context?: HttpContext): Observable<HealthCheckReport> {
    return this.HealthCheckDependencies$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<HealthCheckReport>): HealthCheckReport => r.body)
    );
  }

}
