import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: unknown, ...args: unknown[]) {
    const html = this._sanitizer.sanitize(SecurityContext.HTML, value);
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}
