import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Order } from '@app/core/api-client/models';
import { AppRoutes } from '@app/core/routes';
import { NavigationService } from '@app/core/services/navigation.service';

@Component({
  selector: 'app-command-list',
  templateUrl: './command-list.component.html',
  styleUrls: ['./command-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandListComponent implements OnInit {
  @Input() commandList: Order[] = [];
  @Input() displayMoreOrder: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isEmbedded: boolean = false;

  @Output() commandClick = new EventEmitter<Order>();
  @Output() getMoreOrdersClick = new EventEmitter();
  @Output() createOrderClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  commandOnClick(command: Order) {
    this.commandClick.emit(command);
  }

  moreOrdersOnClick() {
    this.getMoreOrdersClick.emit();
  }

  onCreateOrderClick() {
    this.createOrderClick.emit(); // this._navigationService.navigateToDefaultMenu$().subscribe();
  }

}
