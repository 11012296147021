import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, skip, tap, withLatestFrom } from 'rxjs/operators';
import { GlobalizationState } from './globalization.state';

import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { enUS as dateFnsLocale_en, fr as dateFnsLocale_fr } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class GlobalizationService implements OnDestroy {

  constructor(
    private _translocoService: TranslocoService,
    private _globalizationState: GlobalizationState,
    private _authenticationService: AuthenticationService
  ) {
    this.monitorLangChange();
    this.monitorUserSigninWithLocale();
  }

  ngOnDestroy(): void {
    //required by UntilDestroy
  }

  private monitorLangChange() {
    // sync transloco lang change with GlobalizationState to keep lang stored
    this._translocoService.langChanges$
      .pipe(
        untilDestroyed(this),
        tap(lang => {
          //store active lang
          this._globalizationState.setActiveLang(lang);
          //set default dateFns locale
          const dateFnsLocale = this._globalizationState.getActiveLang() === "en" ? dateFnsLocale_en : dateFnsLocale_fr;
          setDefaultOptions({ locale: dateFnsLocale });
        })
      ).subscribe();
  }

  private monitorUserSigninWithLocale() {
    this._authenticationService.currentUser$
      .pipe(
        untilDestroyed(this),
        map(user => user?.locale),
        filter(locale => locale != null),
        tap(locale => {
          // const defaultLang = this._translocoService.getDefaultLang();
          const availableLangs = this._translocoService.getAvailableLangs() as string[];
          // if (locale !== defaultLang && availableLangs.includes(locale)) {
          if (availableLangs.includes(locale)) {
            this._translocoService.setActiveLang(locale);
          }
        })
      ).subscribe();
  }

  toggleLanguage() {
    const availableLangs = this._translocoService.getAvailableLangs() as string[];
    const activeLang = this._translocoService.getActiveLang();
    const nextLang = availableLangs[(availableLangs.indexOf(activeLang) + 1) % availableLangs.length];
    this._translocoService.setActiveLang(nextLang);
  }

  formatDateDistanceToNow(date: Date) {
    const dateFnsLocale = this._globalizationState.getActiveLang() === "en" ? dateFnsLocale_en : dateFnsLocale_fr;
    return formatDistanceToNow(date, { locale: dateFnsLocale });
  }

  formatDateDistanceToNow$(date: Date) {
    return this._globalizationState.getActiveLang$().pipe(
      map(_ => this.formatDateDistanceToNow(date))
    );
  }

}
