import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ResetCredentialsDialogParameters {
  // siteId: number;
  // customerId: number;
  // email: string;
  canCancelDialog?: boolean;
}

@Component({
  templateUrl: './reset-credentials-dialog.component.html',
  styleUrls: ['./reset-credentials-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetCredentialsDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<ResetCredentialsDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ResetCredentialsDialogParameters
  ) {
  }

  ngOnInit(): void {

  }

  onConfirmed() {
    this._dialogRef.close(true);
  }

  onCancel() {
    this._dialogRef.close(true);
  }
}
