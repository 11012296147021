import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/core/models/constants';
import { ResetPasswordInput } from '../../models/reset-password-input';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  @Input() token: string;
  @Input() submitting = false;

  @Output() resetPassword = new EventEmitter<ResetPasswordInput>();
  @Output() cancel = new EventEmitter();
  resetPasswordForm: UntypedFormGroup;
  submitted = false;
  showPassword = false;
  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      // email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(Constants.passwordMinLength),
          Validators.pattern(Constants.passwordPattern),
        ],
      ],
    });
  }

  onResetPasswordClick() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.resetPassword.emit({
      // email: this.resetPasswordForm.value.email,
      changePasswordToken: this.token,
      newPassword: this.resetPasswordForm.value.password,
    } as ResetPasswordInput);
  }

  onCancelClick() {
    this.cancel.emit();
  }

}
