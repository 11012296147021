import { Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';
import { InMemoryStorage } from './in-memory-storage';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageProvider extends StorageProvider {

//   if (typeof window.localStorage !== 'undefined') {
//     // SETUP SESSION, AUHT, LOCALE, SETTINGS ETC
// } else {
//     // PROVIDE FEEDBACK TO THE USER
// }

  constructor() {
    let storageProvider: Storage;

    let storageSupported = false;
    try { storageSupported = (window.localStorage && true) } catch (e) { }

    if (!storageSupported) {
      console.debug('Local storage not supported, using in-memory storage');
      storageProvider = new InMemoryStorage();
    }
    else {
      storageProvider = localStorage;
    }

    super(storageProvider);
  }

}

