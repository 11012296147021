import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Order, OrderProcessingStep } from '@app/core/api-client/models';


type ConfirmationDisplay = 'Loading' | 'RequiresDestinationInput' | 'WaitingForOtherOrders' | 'OrderInPreparation' | 'TableInPreparation';

@Component({
  selector: 'app-order-preparation-confirmation',
  templateUrl: './order-preparation-confirmation.component.html',
  styleUrls: ['./order-preparation-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationConfirmationComponent implements OnInit, OnChanges {

  @Input() order: Order;
  @Input() loading: boolean = false;

  displayMode: ConfirmationDisplay = 'Loading';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayMode = this.resolveDisplayMode(this.order);
  }

  ngOnInit(): void {
  }

  private resolveDisplayMode(order: Order): ConfirmationDisplay {
    if (!order) {
      return 'Loading';
    }

    if (order.processingState.step === OrderProcessingStep.PendingPreparation) {
      return (order.processingState.destination == null)
        ? 'RequiresDestinationInput'
        : 'WaitingForOtherOrders';
    }
    else if (order.processingState.step === OrderProcessingStep.Processing || order.processingState.step === OrderProcessingStep.Processed) {
      if (order.processingState.destinationOrdersPendingCount == 0) {
        return 'TableInPreparation';
      }
      return 'OrderInPreparation';
    }
    //else
    return 'Loading';
  }

}
