
import { filter } from 'rxjs/operators';

import { AnalyticsDimensions } from '../models/analytics-dimensions.model';
import { AnalyticsProvider } from './analytics-provider';
import { Injectable } from '@angular/core';

import { AppInsightsService } from '@app/core/telemetry/app-insights.service';

import { Router, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { AnalyticsEvent } from '../models/analytics-event.model';

@Injectable()
export class AppInsightsAnalyticsProvider extends AnalyticsProvider {
  loadStartTime: number = null;
  loadTime: number = null;

  constructor(
    private _router: Router,
    private _appInsightsService: AppInsightsService
  ) {
    super();

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event => this.startTimer());

    this._router.events
      .pipe(
        filter(event => (event instanceof NavigationError) || (event instanceof NavigationEnd))
      )
      .subscribe(error => this.stopTimer());
  }

  startTimer() {
    this.loadStartTime = Date.now();
    this.loadTime = null;
  }

  stopTimer() {
    this.loadTime = Date.now() - this.loadStartTime;
    this.loadStartTime = null;
  }

  pageTrack_internal(title: string, path: string, dimensions: AnalyticsDimensions) {
    // TODO revoir l'appel pour renseigner toutes les propriétés

    this._appInsightsService.appInsights.trackPageView(
      {
        name: title,
        uri: path,
        properties: {
          duration: this.loadTime,
          ...dimensions
        },

      });

  }

  eventTrack_internal(event: AnalyticsEvent, dimensions: AnalyticsDimensions) {
    this._appInsightsService.appInsights.trackEvent(
      {
        name: event.eventName,
        properties: { ...event, ...dimensions }
      });
  }

  setUserId_internal(userId: string) {
    this._appInsightsService.appInsights.setAuthenticatedUserContext(userId);
  }

  clear() {
    this._appInsightsService.appInsights.clearAuthenticatedUserContext();
  }

}
