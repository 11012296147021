import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ConfirmDialogData } from '@app/shared/components/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, of, switchMap, tap } from 'rxjs';
import { ResetCredentialsInput } from '../../models/reset-credentials-input';

@UntilDestroy()
@Component({
  selector: 'app-reset-credentials-page',
  templateUrl: './reset-credentials-page.component.html',
  styleUrls: ['./reset-credentials-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetCredentialsPageComponent implements OnInit {

  context$ = this._authenticationService.currentUser$.pipe(
    map(user => ({
      siteId: user.siteId,
      customerId: user.userId,
      email: user.email
    }))
  );

  inProgress = false;

  @Input() canCancel = false;

  @Output() resetCredentials = new EventEmitter<ResetCredentialsInput>();
  @Output() cancel = new EventEmitter();

  constructor(
    private _authenticationService: AuthenticationService,
    private _cd: ChangeDetectorRef,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onResetCredentials(input: ResetCredentialsInput) {

    of(true)
      .pipe(
        tap(() => {
          this.inProgress = true;
        }),
        switchMap(() => {
          return this._authenticationService.resetCredentials(input.newEmail, input.newPassword);
        }),
        tap(() => {

          //close the current dialog (emit event) and show confirmation
          this.resetCredentials.emit(input);

          this.inProgress = false;
          this._cd.markForCheck();

          const confirmChangePlaceDialog = this._dialog.open(ConfirmDialogComponent, {
            data: {
              message: 'RESET_CREDENTIALS.UPDATE_CONFIRMATION',
              confirmButtonLabel : 'COMMON.CONTINUE',
              showDenyButton: false
            } as ConfirmDialogData
          });

        }),
        tap({
          error: () => {
            this.inProgress = false;
            this._cd.markForCheck();
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onCancel() {
    this.cancel.emit();
  }
}
