import { Theme } from '../models/theme';

export const themeGreen: Theme = {
  name: 'green',
  properties: {

    '--mat-primary-base-color': '#01913c',

    // Mat Primary Theme
    // Généré par  http://mcg.mbitson.com/ en partant de  '--mat-primary-base-color'
    '--mat-primary-50': '#e1f2e8',
    '--mat-primary-100': '#b3dec5',
    '--mat-primary-200': '#80c89e',
    '--mat-primary-300': '#4db277',
    '--mat-primary-400': '#27a259',
    '--mat-primary-500': '#01913c',
    '--mat-primary-600': '#018936',
    '--mat-primary-700': '#017e2e',
    '--mat-primary-800': '#017427',
    '--mat-primary-900': '#00621a',
    '--mat-primary-A100': '#92ffa5',
    '--mat-primary-A200': '#5fff7a',
    '--mat-primary-A400': '#2cff50',
    '--mat-primary-A700': '#13ff3b',

    '--mat-primary-contrast-50': '#000000',
    '--mat-primary-contrast-100': '#000000',
    '--mat-primary-contrast-200': '#000000',
    '--mat-primary-contrast-300': '#000000',
    '--mat-primary-contrast-400': '#000000',
    '--mat-primary-contrast-500': '#ffffff',
    '--mat-primary-contrast-600': '#ffffff',
    '--mat-primary-contrast-700': '#ffffff',
    '--mat-primary-contrast-800': '#ffffff',
    '--mat-primary-contrast-900': '#ffffff',
    '--mat-primary-contrast-A100': '#000000',
    '--mat-primary-contrast-A200': '#000000',
    '--mat-primary-contrast-A400': '#000000',
    '--mat-primary-contrast-A700': '#000000',

    '--typo-headline-color': '#525257',
    '--typo-title-color': '#333333',
    '--typo-subheading-color': '#aeaeae',

    // Mat Accentt Theme
    // Généré par  http://mcg.mbitson.com/ en partant de  '--mat-accent-base-color'

    '--mat-accent-base-color': '#525257',

    '--mat-accent-50': '#eaeaeb',
    '--mat-accent-100': '#cbcbcd',
    '--mat-accent-200': '#a9a9ab',
    '--mat-accent-300': '#868689',
    '--mat-accent-400': '#6c6c70',
    '--mat-accent-500': '#525257',
    '--mat-accent-600': '#4b4b4f',
    '--mat-accent-700': '#414146',
    '--mat-accent-800': '#38383c',
    '--mat-accent-900': '#28282c',
    '--mat-accent-A100': '#8585f5',
    '--mat-accent-A200': '#5656f2',
    '--mat-accent-A400': '#1515ff',
    '--mat-accent-A700': '#0000fb',

    '--mat-accent-contrast-50': '#000000',
    '--mat-accent-contrast-100': '#000000',
    '--mat-accent-contrast-200': '#000000',
    '--mat-accent-contrast-300': '#000000',
    '--mat-accent-contrast-400': '#ffffff',
    '--mat-accent-contrast-500': '#ffffff',
    '--mat-accent-contrast-600': '#ffffff',
    '--mat-accent-contrast-700': '#ffffff',
    '--mat-accent-contrast-800': '#ffffff',
    '--mat-accent-contrast-900': '#ffffff',
    '--mat-accent-contrast-A100': '#000000',
    '--mat-accent-contrast-A200': '#ffffff',
    '--mat-accent-contrast-A400': '#ffffff',
    '--mat-accent-contrast-A700': '#ffffff',
  },
};
