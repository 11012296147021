import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PickupSchedule } from '@app/core/services/menu/models/pickup-schedule.model';

export interface DeliveryDateTimeDialogResult {
  deliveryDate: Date | null;
}

export interface DeliveryDateTimeDialogParameters {
  deliveryDate: Date | null;
  pickupDays: PickupSchedule[];
}

@Component({
  templateUrl: './delivery-date-time-dialog.component.html',
  styleUrls: ['./delivery-date-time-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryDateTimeDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<DeliveryDateTimeDialogComponent, DeliveryDateTimeDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DeliveryDateTimeDialogParameters
  ) { }

  ngOnInit(): void {
  }

  onSelectedDateChange(selectedDate: Date) {
    this._dialogRef.close({ deliveryDate: selectedDate });
  }

}
