<mat-drawer-container [hasBackdrop]="false" class="side-container">
  <mat-drawer
    mode="over"
    [opened]="isSideOpened$ | async"
    position="end"
    class="side-container-drawer"
    fxHide
    fxShow.gt-xs
  >
    <ng-template [cdkPortalOutlet]="drawerPortal"></ng-template>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
