import { DOCUMENT, Location } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { concat, from, interval, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WhiteMarkService } from '../layout/white-mark.service';
import { Logger, LogService } from '../logging';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUpdateService {

  private _applicationUpdatedSubject = new Subject();

  private _stopMonitoring = new Subject();

  applicationUpdated$: Observable<unknown> = this._applicationUpdatedSubject.asObservable();

  private _logger: Logger;

  constructor(
    logService: LogService,
    private _swUpdate: SwUpdate,
    private _appRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: Document,
    private _whiteMarkService: WhiteMarkService,
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _translateService: TranslocoService,
    private _location: Location,
    private _router: Router
  ) {

    this._logger = logService.getLogger('ApplicationUpdateService');
  }

  startMonitorApplicationUpdate() {
    /* FORCE APP UPDATE via service worker */
    // on recherche régulièrement s'il y a une mise à jour disponible (important pour les tablettes où l'app n'est jamais rechargée)
    // https://angular.io/guide/service-worker-communications#available-and-activated-updates
    // also https://medium.com/@arjenbrandenburgh/angulars-pwa-swpush-and-swupdate-15a7e5c154ac
    if (this._swUpdate.isEnabled) {

      const appUpdateInfo = { updateData: {} };
      this._swUpdate.available
        .pipe(
          switchMap((updateEvent) => from(this._swUpdate.activateUpdate()).pipe(map(_ => updateEvent))),
          tap(updateEvent => {
            // Log application update found
            appUpdateInfo.updateData = updateEvent;
            this._logger.info('Application update detected : ' + JSON.stringify(appUpdateInfo.updateData));
          }),
          switchMap(() => this._whiteMarkService.isEmbedded$()),
          switchMap(isEmbedded => isEmbedded
            // si embedded on ne propose pas la popup d'installation, on force directement le reload
            ? of(true)
            // sinon on affiche la popup d'installation
            : this.showUpdatePopup()
          ),
          first(confirmation => confirmation === true),
          takeUntil(this._stopMonitoring)
        )
        .subscribe(() => {
          this._logger.info('Application installation confirmed : ' + JSON.stringify(appUpdateInfo.updateData));

          this._document.location.reload();
        });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this._appRef.isStable.pipe(first(isStable => isStable === true));
      const everyHours$ = interval(1 * 60 * 60 * 1000); // 1 hour
      const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);
      everyHoursOnceAppIsStable$
        .pipe(
          takeUntil(this._stopMonitoring)
        )
        .subscribe(() => this._swUpdate.checkForUpdate());

      // Log application installation
      this._swUpdate.activated
        .pipe(
          takeUntil(this._stopMonitoring)
        )
        .subscribe(activationEvent => {
          this._logger.info('Application activated : ' + JSON.stringify(activationEvent));
        });

    }
  }

  // affiche une popup de confirmation et retourne le résultat
  showUpdatePopup(): Observable<boolean> {

    // on affiche la popup d'installation
    // return this._dialog.open<ApplicationUpdateDialogComponent, any, boolean>(ApplicationUpdateDialogComponent).afterClosed();

    // on affiche un toast sans demande de confirmation
    return this._translateService.selectTranslate(['APP_UPDATE.TITLE', 'APP_UPDATE.TEXT'])
      .pipe(
        switchMap(([title, text]) => {
          return this._toastr.info(text, title, {
            progressAnimation: 'increasing',
            progressBar: true
          }).onHidden;
        }),
        map(_ => true) // always confirm
      );
  }

  stopMonitorApplicationUpdate() {
    this._stopMonitoring.next(null);
  }

  getServiceWorkerState$(): Observable<string> {

    const ngswStateUrl = this._location.prepareExternalUrl('ngsw/state');

    return from(fetch(ngswStateUrl))
      .pipe(
        switchMap(r => from(r.text()))
      );
  }

}
