import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Restaurant } from '@app/core/api-client/models';
import { PlaceSelectorDialogData } from './place-selector-dialog-data.model';
import { PlaceSelectorDialogResult } from './place-selector-dialog-result.model';

@Component({
  templateUrl: './place-selector-dialog.component.html',
  styleUrls: ['./place-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceSelectorDialogComponent implements OnInit {

  siteHasSingleRestaurant: boolean = (this.data.site.restaurants || []).length === 1;
  currentRestaurant = this.data.restaurant;

  constructor(
    public dialogRef: MatDialogRef<PlaceSelectorDialogComponent, PlaceSelectorDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: PlaceSelectorDialogData
  ) { }

  ngOnInit(): void {
  }

  onGotoSiteSelection() {
    this.dialogRef.close({ action: 'GotoSiteSelection' });
  }

  onCancelClick() {
    this.dialogRef.close({ action: 'None' });
  }

  onGotoRestaurant(restaurant: Restaurant) {
    this.dialogRef.close({
      action: 'ChangeContext',
      newContext: {
        site: this.data.site,
        restaurant: restaurant
      }
    });
  }

}
