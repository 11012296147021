import { Injectable, NgZone } from '@angular/core';
import { JL } from 'jsnlog';
import { Logger } from './logger.helper';
import { environment } from '@environments/environment';
import { AppInsightsService } from '../telemetry/app-insights.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private _ngZone: NgZone,
    private _appInsightsService: AppInsightsService
  ) {

    // console.log('configuring LogService...');

    const consoleAppender = JL.createConsoleAppender(`consoleAppender_${new Date().getTime()}`);
    consoleAppender.setOptions({
      // level: isDevMode() ? JL.getTraceLevel() : JL.getWarnLevel()
      level: JL.getTraceLevel()
    });

    // création d'un appender JSNLog pour ApplicationInsight (à partir d'un appender quelconque en réécrivant la méthode d'envoi)
    const appInsightAppender = JL.createAjaxAppender(`appInsightAppender_${new Date().getTime()}`);
    appInsightAppender.setOptions({
      // see:http://jsnlog.com/Documentation/JSNLogJs/AjaxAppender/SetOptions
      storeInBufferLevel: JL.getTraceLevel(), // on stocke toutes les traces
      level: JL.getWarnLevel(), // on envoie les logs warn/error/fatal
      sendWithBufferLevel: JL.getErrorLevel(), // à partir de error on envoie aussi toutes les traces
      bufferSize: 20,
      beforeSend: this.addRequestHeaders
    });
    // Dirty replace Appender log function with a custom one implementing AppInsight
    (appInsightAppender as any).sendLogItems = (logItems: LogItem[], successCallback: () => void) => {
      for (const log of logItems) {
        const props = {
          logger: log.n,
          timestamp: new Date(log.t).toISOString() // moment(log.t).toISOString()
        };
        const level = this.JSNLogLevelToAppInsightLevel(log.l);

        if (level < SeverityLevel.Error) { // Verbose, Information, Warning
          this._appInsightsService.queue(appInsights => appInsights.trackTrace({
            message: log.m,
            properties: props,
            severityLevel: level
          })
          );
        } else { // Error | Fatal
          this._appInsightsService.queue(appInsights => appInsights.trackException({
            exception: new Error(log.m),
            severityLevel: level,
            properties: props
          })
          );
        }
      }
      successCallback();
    };

    // Attention: à faire après le lazy load AppInsights (voir https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#addtelemetryinitializer)
    this._appInsightsService.queue((appInsights) => {
      // On ajoute un telemetryInitializer au TelemetryContext de AppInsight pour redéfinir le time des élément trackés (car poussés en batch par l'appender précédent)
      appInsights.addTelemetryInitializer(envelope => {
        const timestamp = envelope?.baseData?.properties?.timestamp;
        if (timestamp) {
          envelope.time = timestamp;
          delete envelope.baseData.properties.timestamp;
        }
      });
    });

    JL().setOptions({
      // see http://jsnlog.com/Documentation/JSNLogJs/JL/SetOptions
      //            appenders: [ajaxAppender, consoleAppender],
      appenders: [consoleAppender, appInsightAppender],
    });
  }

  private addRequestHeaders(xhr: XMLHttpRequest): void {
    // ajout de la version de l'api
    xhr.setRequestHeader('client-version', environment.appVersion);
  }

  debug(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).debug(msg);
    });
  }

  info(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).info(msg);
    });
  }

  warn(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).warn(msg);
    });
  }

  error(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).error(this.DumpError(msg));
    });
  }

  fatal(msg: any, logger?: string): void {
    this._ngZone.runOutsideAngular(() => {
      JL(logger).fatal(this.DumpError(msg));
    });
  }

  public getLogger(name: string): Logger {
    return new Logger(this, name);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private DumpError(error: any): any {
    let logPayload = error;
    if (typeof (logPayload) === 'object') {
      logPayload = {};
      if (error.message) {
        logPayload.message = error.message;
        logPayload.stack = error.stack;
      } else {
        // Prevent recursive json serialization on objects
        logPayload.message = (error as any).toString();
      }
    }
    return logPayload;
  }

  private JSNLogLevelToAppInsightLevel(level: number): SeverityLevel {
    // if (level <= 1000) { return 0; } // trace => Verbose (0)
    if (level <= 2000) { return SeverityLevel.Verbose; } // debug => Verbose (0)
    if (level <= 3000) { return SeverityLevel.Information; } // info => Information (1)
    if (level <= 4000) { return SeverityLevel.Warning; } // warn => Warning (2)
    if (level <= 5000) { return SeverityLevel.Error; } // error => Error (3)
    return SeverityLevel.Critical; // fatal => Critical (4)
  }
}

class LogItem {
  // l: level
  // m: message
  // n: logger name
  // t (timeStamp) is number of milliseconds since 1 January 1970 00:00:00 UTC
  //
  // Keeping the property names really short, because they will be sent in the
  // JSON payload to the server.
  constructor(
    public l: number,
    public m: string,
    public n: string,
    public t: number) { }
}
