import { ErrorHandler, Injectable } from '@angular/core';
import { CustomerPaymentMethod, DeleteCustomerPaymentMethodResult, DeleteCustomerPaymentMethodStatus } from '@app/core/api-client/models';
import { ApiPaymentService } from '@app/core/api-client/services';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, first, observeOn, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { SiteState } from '../site/state/site.state';
import { PaymentState } from './state/payment.state';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  customerPaymentMethods$: Observable<CustomerPaymentMethod[]> = this.getCustomerPaymentMethods();
  private _reloadCustomerPaymentMethodsSubject = new Subject();

  constructor(
    private _apiPaymentService: ApiPaymentService,
    private _paymentState: PaymentState,
    private _authenticationService: AuthenticationService,
    private _siteState: SiteState,
    private _errorHandler: ErrorHandler,
  ) {
    this.monitorCustomerPaymentMethods();
    this._reloadCustomerPaymentMethodsSubject.next(null);
  }

  private monitorCustomerPaymentMethods() {
    combineLatest([
      this._authenticationService.isLoggedIn$(),
      this._reloadCustomerPaymentMethodsSubject,
      // this._siteState.getCurrentSite$() // à décommenter lorsque l'on gèrera le multisite
    ]).pipe(
      tap(() => this._paymentState.setUpdating(true)),
      // switchMap(([user, site]) => { // à décommenter lorsque l'on gèrera le multisite
      switchMap(([isLoggedIn]) => {
        return (isLoggedIn // is user logged?
          ? this._apiPaymentService.GetCustomerPaymentMethods$Json().pipe(first())
          : of(null)
        )
          .pipe(
            // to preserve observal, catch errors in inner pipeline to prevent higher-order observal to fail => see: https://stackoverflow.com/a/58661330/590741
            catchError((error: unknown) => {
              // notify error but continue observal
              this._errorHandler.handleError(error);
              return EMPTY; // continue observal by emit a dummy value
            }),
            finalize(() => this._paymentState.setUpdating(false))
          );
      })
    )
      .subscribe(customerPaymentMethods => this._paymentState.setCurrentCustomerPaymentMethods(customerPaymentMethods));
  }

  public getCustomerPaymentMethods(): Observable<CustomerPaymentMethod[] | null> {
    return this._paymentState.getCurrentCustomerPaymentMethods$();
  }

  public deleteCustomerPaymentMethods$(customerPaymentMethodId: number): Observable<DeleteCustomerPaymentMethodResult> {
    return this._apiPaymentService.DeleteCustomerPaymentMethod$Json({ customerPaymentMethodId: customerPaymentMethodId })
      .pipe(
        first(),
        tap(() => this._reloadCustomerPaymentMethodsSubject.next(null))
      );
  }

  public isUpdating$() {
    return this._paymentState.isUpdating$();
  }

  public reloadCustomerPaymentMethods() {
    this._reloadCustomerPaymentMethodsSubject.next(null);
  }

}
