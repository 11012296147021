/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SearchSitesResultItem } from '../../models/search-sites-result-item';

export interface SearchSites$Json$Params {
  Lat?: number;
  Lng?: number;
  SearchTerm?: string;
  'force-tenant'?: String;
}

export function SearchSites$Json(http: HttpClient, rootUrl: string, params?: SearchSites$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SearchSitesResultItem>>> {
  const rb = new RequestBuilder(rootUrl, SearchSites$Json.PATH, 'get');
  if (params) {
    rb.query('Lat', params.Lat, {});
    rb.query('Lng', params.Lng, {});
    rb.query('SearchTerm', params.SearchTerm, {});
    rb.header('force-tenant', params['force-tenant'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SearchSitesResultItem>>;
    })
  );
}

SearchSites$Json.PATH = '/api/Sites/search';
