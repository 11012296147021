export class InMemoryStorage implements Storage {
  private data = new Map<string, string>()

  /** Returns the number of key/value pairs. */
  get length(): number {
    return this.data.size
  }
  /**
   * Removes all key/value pairs, if there are any.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  clear(): void {
    this.data.clear()
  }
  /** Returns the current value associated with the given key, or null if the given key does not exist. */
  getItem(key: string): string | null {
    return this.data.get(String(key)) ?? null
  }
  /** Returns the name of the nth key, or null if n is greater than or equal to the number of key/value pairs. */
  key(index: number): string | null {
    return [...this.data.keys()][Number(index)] ?? null
  }
  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  removeItem(key: string): void {
    this.data.delete(String(key))
  }
  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  setItem(key: string, value: string): void {
    this.data.set(String(key), String(value))
  }

  public get(name: string): any {
    return this.getItem(name);
  }

  // This doesn't work either
  public set(name: string, value: any) {
    this.data.set(name, value);
  }

}
