<div *ngIf="!isLoading; else loadingTemplate"
     class="container"
     fxLayout="row"
     fxLayoutAlign="start center"
     (click)="onDeliverySectionClick()"
     [class.readonly]="isReadOnly">
  <ng-container *ngIf="showIcon">
    <mat-icon class="delivery-icon"
              svgIcon="clock"
              [color]="isReadOnly ? 'accent' : iconColor"></mat-icon>
  </ng-container>

  <span class="delivery-title"
        *ngIf="deliveryDate">{{ 'COMMON.DATE_AT_TIME' | transloco: { date: deliveryDateLabel, time: deliveryHourLabel } }}</span>

  <span *ngIf="!deliveryDate && !isReadOnly">{{ promptMessageWhenEmpty | transloco }}</span>

  <mat-icon class="delivery-icon"
            *ngIf="!isReadOnly">keyboard_arrow_down</mat-icon>
</div>

<ng-template #loadingTemplate>
  <app-mini-content-loader></app-mini-content-loader>
</ng-template>

<!-- TODO add error ui -->
<!--
<div *ngIf="control?.touched && (errors$ | async) as errors"
@errorInOutAnimation
class="cart-payment-errors">
<mat-error *ngIf="errors['required']">{{ errors['required'] }}</mat-error>
</div> -->
