/* tslint:disable */
/* eslint-disable */

/**
 * Delivery
 *
 * TakeAway
 *
 * SitIn
 *
 * Drive
 */
export enum ConsumptionModeType {
  Delivery = 'Delivery',
  TakeAway = 'TakeAway',
  SitIn = 'SitIn',
  Drive = 'Drive'
}
