import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';

const entriesMap = new WeakMap();

// see https://web.dev/resize-observer/
// also https://github.com/juggle/resize-observer/blob/v3/README.md
const ro = new ResizeObserver(entries => {
  for (const entry of entries) {
    if (entriesMap.has(entry.target)) {
      const comp = entriesMap.get(entry.target);
      comp._resizeCallback(entry);
    }
  }
});

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[resizeObserver]' })
export class ResizeObserverDirective implements OnDestroy {

  @Output()
  resizeObserved = new EventEmitter<ResizeObserverEntry>();

  constructor(private el: ElementRef) {
    const target = this.el.nativeElement;
    entriesMap.set(target, this);
    ro.observe(target);
  }

  _resizeCallback(entry: ResizeObserverEntry) {
    this.resizeObserved.emit(entry);
  }

  ngOnDestroy() {
    const target = this.el.nativeElement;
    ro.unobserve(target);
    entriesMap.delete(target);
  }
}
