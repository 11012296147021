import { Injectable } from '@angular/core';
import { CustomerPaymentMethod } from '@app/core/api-client/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentState {

  private _updating$ = new BehaviorSubject<boolean>(false);
  private _currentCustomerPaymentMethods$ = new BehaviorSubject<CustomerPaymentMethod[]| null>(null);

  constructor() { }

  setUpdating(isUpdating: boolean) {
    this._updating$.next(isUpdating);
  }

  isUpdating$() {
    return this._updating$.asObservable();
  }

  setCurrentCustomerPaymentMethods(customerPaymentMethods: CustomerPaymentMethod[] | null) {
    this._currentCustomerPaymentMethods$.next(customerPaymentMethods);
  }

  getCurrentCustomerPaymentMethods$() {
    return this._currentCustomerPaymentMethods$.asObservable();
  }
}
