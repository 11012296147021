import { OrderStatus } from '@app/core/api-client/models';

export class CommandsHelper {
  private static commandStatus: CommandStatus[] = [
    {
      key: OrderStatus.Pending,
      transKey: 'COMMAND.STATUS.PENDING',
      descriptionTransKey: 'COMMAND.STATUS.PENDING_DESCRIPTION',
      icon: 'update',
      iconColor: 'order-icon-yellow',
    },
    {
      key: OrderStatus.WaitingPayment,
      transKey: 'COMMAND.STATUS.WAITINGPAYMENT',
      descriptionTransKey: 'COMMAND.STATUS.WAITINGPAYMENT_DESCRIPTION',
      icon: 'update',
      iconColor: 'order-icon-yellow',
    },
    {
      key: OrderStatus.PaymentSucceeded,
      transKey: 'COMMAND.STATUS.CONFIRMED',
      descriptionTransKey: 'COMMAND.STATUS.CONFIRMED_DESCRIPTION',
      icon: 'watch_later',
      iconColor: 'order-icon-green',
    },
    {
      key: OrderStatus.Validated,
      transKey: 'COMMAND.STATUS.CONFIRMED',
      descriptionTransKey: 'COMMAND.STATUS.CONFIRMED_DESCRIPTION',
      icon: 'watch_later',
      iconColor: 'order-icon-green',
    },
    {
      key: OrderStatus.Finalized,
      transKey: 'COMMAND.STATUS.DELIVRED',
      descriptionTransKey: 'COMMAND.STATUS.DELIVRED_DESCRIPTION',
      icon: 'check_circle',
      iconColor: 'order-icon-green',
    },
    {
      key: OrderStatus.Cancelled,
      transKey: 'COMMAND.STATUS.CANCELED',
      descriptionTransKey: 'COMMAND.STATUS.CANCELED_DESCRIPTION',
      icon: 'cancel',
      iconColor: 'order-icon-red',
    },
    {
      key: OrderStatus.Cancelled,
      transKey: 'COMMAND.STATUS.CANCELED',
      descriptionTransKey: 'COMMAND.STATUS.CANCELED_DESCRIPTION',
      icon: 'cancel',
      iconColor: 'order-icon-red',
    },
    // {
    //   key: OrderStatus.Undefined,
    //   transKey: 'COMMAND.STATUS.UNDEFINED',
    //   descriptionTransKey: 'COMMAND.STATUS.UNDEFINED_DESCRIPTION',
    //   icon: 'fiber_manual_record',
    //   iconColor: 'order-icon-red',
    // },
  ];

  public static getCommandStatutLabel(key: OrderStatus): CommandStatus {
    const status = this.commandStatus.find((x) => x.key === key);
    if (!status) {
      return this.commandStatus[4]; // Undefined
    }
    return status;
  }
}

export interface CommandStatus {
  key: string;
  transKey: string;
  descriptionTransKey: string;
  icon: string;
  iconColor: string;
}
