/* tslint:disable */
/* eslint-disable */

/**
 * Silent
 *
 * Information
 *
 * Warning
 *
 * Error
 */
export enum ReviewCartErrorSeverity {
  Silent = 'Silent',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}
