<app-scrollable-container [noFooter]="true"
                          class="command-page"
                          fxLayout="column"
                          fxLayoutAlign="stretch">
  <mat-toolbar header
               class="command-header-toolbar"
               fxLayout="row"
               fxLayoutAlign="start center"
               fxLayoutGap="10px">
    <mat-toolbar-row fxLayout="row"
                     fxLayoutAlign="start center">
      <div>
        <!-- button retour-->
        <app-back-button fxFlexAlign="start"
                         [stopNavigation]="true"
                         (click)="onExit()"
                         [style]="{ padding: 0 }"></app-back-button>
      </div>
      <span [id]="order?.orderId"
            class="command-header-toolbar-title">{{ "COMMAND.DETAIL_TITLE" | transloco : { deliveryDate: (order?.deliveryDate | translocoDate: { dateStyle: 'short' }), orderNumber: order.ticketNumber } }}</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div content
       class="command-container">
    <app-command-track-order-bar *ngIf="order.processingState.isDailyPreparationOrder"
                                 [order]="order"
                                 [title]="'COMMAND.ORDER_PREPARATION.TRACK_ORDER'"
                                 [showOrderDescription]="false"
                                 (trackOrder)="goToTrackOrder()"></app-command-track-order-bar>
    <!--Statut-->
    <app-command-detail-state [order]="order"></app-command-detail-state>
    <app-command-detail-receipt [order]="order"></app-command-detail-receipt>

    <!--Livraison-->
    <app-command-detail-delivery [order]="order"
                                 [hideComment]="!order.comment"
                                 [isReadOnly]="true"
                                 [customerDisplayName]="order.customer.firstName + ' ' + order.customer.lastName"></app-command-detail-delivery>
    <mat-divider class="command-divider"></mat-divider>
    <!--Produits-->
    <app-cart-product-list [cartProductList]="cartProductList"
                           [currencyCode]="order.restaurant.currency"></app-cart-product-list>
    <!--Total-->
    <app-cart-product-total [totalVatPrice]="order.totalTax"
                            [totalPrice]="order.totalInclTaxWithDiscount"
                            [cartProductList]="cartProductList"
                            [discounts]="order.discounts"
                            [fees]="order.fees"
                            [currencyCode]="order.restaurant.currency"
                            [showVAT]="order.restaurant.showVAT"></app-cart-product-total>
  </div>
</app-scrollable-container>
