import { Category, Menu } from "@app/core/api-client/models";

export class MenuModelHelper {

  public static getRootCategory(category: Category, menu: Menu): Category {
    if (category == null) {
      return null;
    }
    // loop to parent root category
    while (category.parentCategoryId != null) {
      category = menu.categories.find((c) => c.categoryId === category.parentCategoryId);
    }
    return category;
  }

}
