/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CacheLockEntry } from '../models/cache-lock-entry';
import { GetLocks$Json } from '../fn/admin/get-locks-json';
import { GetLocks$Json$Params } from '../fn/admin/get-locks-json';
import { GetLocks$Plain } from '../fn/admin/get-locks-plain';
import { GetLocks$Plain$Params } from '../fn/admin/get-locks-plain';
import { RefreshCache } from '../fn/admin/refresh-cache';
import { RefreshCache$Params } from '../fn/admin/refresh-cache';
import { RefreshSiteCache } from '../fn/admin/refresh-site-cache';
import { RefreshSiteCache$Params } from '../fn/admin/refresh-site-cache';
import { ReleaseLock } from '../fn/admin/release-lock';
import { ReleaseLock$Params } from '../fn/admin/release-lock';

@Injectable({ providedIn: 'root' })
export class ApiAdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postAdminCacheRefresh()` */
  static readonly PostAdminCacheRefreshPath = '/api/Admin/Cache/Refresh';

  /**
   * post-admin-cache-refresh.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `RefreshCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  RefreshCache$Response(params?: RefreshCache$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return RefreshCache(this.http, this.rootUrl, params, context);
  }

  /**
   * post-admin-cache-refresh.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `RefreshCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  RefreshCache(params?: RefreshCache$Params, context?: HttpContext): Observable<void> {
    return this.RefreshCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postAdminCacheRefreshBySiteid()` */
  static readonly PostAdminCacheRefreshBySiteidPath = '/api/Admin/Cache/Refresh/{siteId}';

  /**
   * post-admin-cache-refresh-by-siteid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `RefreshSiteCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  RefreshSiteCache$Response(params: RefreshSiteCache$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return RefreshSiteCache(this.http, this.rootUrl, params, context);
  }

  /**
   * post-admin-cache-refresh-by-siteid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `RefreshSiteCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  RefreshSiteCache(params: RefreshSiteCache$Params, context?: HttpContext): Observable<void> {
    return this.RefreshSiteCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAdminLocks()` */
  static readonly GetAdminLocksPath = '/api/Admin/Locks';

  /**
   * get-admin-locks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetLocks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLocks$Plain$Response(params?: GetLocks$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CacheLockEntry>>> {
    return GetLocks$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-admin-locks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetLocks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLocks$Plain(params?: GetLocks$Plain$Params, context?: HttpContext): Observable<Array<CacheLockEntry>> {
    return this.GetLocks$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CacheLockEntry>>): Array<CacheLockEntry> => r.body)
    );
  }

  /**
   * get-admin-locks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetLocks$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLocks$Json$Response(params?: GetLocks$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CacheLockEntry>>> {
    return GetLocks$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-admin-locks.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetLocks$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLocks$Json(params?: GetLocks$Json$Params, context?: HttpContext): Observable<Array<CacheLockEntry>> {
    return this.GetLocks$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CacheLockEntry>>): Array<CacheLockEntry> => r.body)
    );
  }

  /** Path part for operation `deleteAdminLocksByLockkey()` */
  static readonly DeleteAdminLocksByLockkeyPath = '/api/Admin/Locks/{lockKey}';

  /**
   * delete-admin-locks-by-lockkey.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ReleaseLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  ReleaseLock$Response(params: ReleaseLock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ReleaseLock(this.http, this.rootUrl, params, context);
  }

  /**
   * delete-admin-locks-by-lockkey.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ReleaseLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ReleaseLock(params: ReleaseLock$Params, context?: HttpContext): Observable<void> {
    return this.ReleaseLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
