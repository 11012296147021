<div class="prompt-for-action-container">
  <div class="action-selection-container">
    <mat-selection-list [multiple]="false"
                        class="actions-list"
                        (selectionChange)="onSelectedActionChanged($event.options[0]?.value)"
                        [disabled]="loading">
      <mat-list-option [value]="actions.WaitForOtherOrders">
        <div class="action-item">
          <mat-icon class="action-icon"
                    svgIcon="pending"></mat-icon>
          <span class="action-item-title">{{'COMMAND.ORDER_PREPARATION.ACTIONS.WAIT_FOR_OTHERS' | transloco}}</span>
        </div>
      </mat-list-option>
      <mat-list-option [value]="actions.SendTable"
                       *ngIf="displaySendToTable">
        <div class="action-item">
          <mat-icon class="action-icon">room_service</mat-icon>
          <span class="action-item-title">{{'COMMAND.ORDER_PREPARATION.ACTIONS.SEND_TABLE' | transloco}}</span>
        </div>
      </mat-list-option>
      <mat-list-option [value]="actions.SendMyOrder">
        <div class="action-item">
          <mat-icon class="action-icon">fast_forward</mat-icon>
          <span class="action-item-title">{{'COMMAND.ORDER_PREPARATION.ACTIONS.SEND_MY_ORDER' | transloco}}</span>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
