import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutes } from './core/routes';
import { RouteDataModel } from './core/models/route-data.model';
import { CanActivateChildGuard } from './core/authentication/can-activate-child.guard';
import { CommandModule } from './features/command/command.module';
import { AuthGuard } from './core/authentication/auth.guard';
import { RequireRestaurantGuard } from './core/routes/require-restaurant.guard';
import { NavToMenuIfLoggedGuard } from './core/routes/nav-to-menu-if-logged.guard';
import { SigninModule } from './features/signin/signin.module';

const routes: Routes = [
  {
    path: AppRoutes.default,
    loadChildren: () => SigninModule,
    data: { hideBarMenu: true } as RouteDataModel,
  },
  {
    path: AppRoutes.siteSearch,
    loadChildren: () => import('./features/site-search/site-search.module').then((m) => m.SiteSearchModule),
    data: { hideBarMenu: true } as RouteDataModel,
  },
  {
    path: AppRoutes.register,
    loadChildren: () => import('./features/register/register.module').then((m) => m.RegisterModule),
    data: { hideBarMenu: true, animation: 'register' } as RouteDataModel,
  },
  // {
  //   path: AppRoutes.sandbox,
  //   loadChildren: () => import('./features/sandbox/sandbox.module').then((m) => m.SandboxModule),
  // },
  {
    path: 'menu',
    loadChildren: () => import('./features/menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'home/:restaurantId/:consumptionModeId/menu',
    redirectTo : 'menu/restaurant/:restaurantId',
    pathMatch: 'full',
  },
  {
    path: AppRoutes.restaurant,
    loadChildren: () => import('./features/menu/menu.module').then((m) => m.MenuModule),
    data: { animation: 'home' } as RouteDataModel,
    canActivateChild: [CanActivateChildGuard]
  },
  {
    path: AppRoutes.productSearch,
    loadChildren: () => import('./features/menu/menu.module').then((m) => m.MenuModule),
    data: { animation: 'search' } as RouteDataModel,
  },
  {
    path: AppRoutes.cart,
    loadChildren: () => import('./features/cart/cart.module').then((m) => m.CartModule),
    data: { animation: 'cart', hideCartButton: true } as RouteDataModel,
  },
  // {
  //   path: AppRoutes.commandBase,
  //   loadChildren: () => import('./features/command/command.module').then((m) => m.CommandModule),
  // },
  // le module Command n'est pas lazy loadé car il est aussi utilisé par le Module Cart
  // TODO séparer les components dans un Module Shared
  {
    path: AppRoutes.orderBase_FR,
    loadChildren: () => CommandModule
  },
  {
    path: AppRoutes.ordersBase_FR,
    loadChildren: () => CommandModule
  },
  {
    path: AppRoutes.ordersBase,
    loadChildren: () => CommandModule
  },
  {
    path: AppRoutes.account,
    loadChildren: () => import('./features/account/account.module').then((m) => m.AccountModule),
    data: { animation: 'account' } as RouteDataModel,
  },
  { path: 'cms', loadChildren: () => import('./features/cms/cms.module').then(m => m.CmsModule) },
  { path: 'sso', loadChildren: () => import('./features/sso/sso.module').then(m => m.SsoModule) },
  { path: 'admin', loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule) },
  { path: AppRoutes.notfound, loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: 'privacy', loadChildren: () => import('./features/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: '**', loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule) }
];

@NgModule({
  imports: [
    // Router module settings GUIDE => https://link.medium.com/qU4vZ055Uab
    RouterModule.forRoot(routes, {
    enableTracing: false,
    // TODO preloadingStrategy. see: https://angular.io/guide/router#custom-preloading-strategy
    // preloadingStrategy:
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
