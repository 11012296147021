<div class="app-root-container">

<app-top-bar *ngIf="isBarMenuShown(outlet)" fxHide fxShow.gt-xs></app-top-bar>

<div [@routeAnimations]="prepareRoute(outlet)" class="app-container" [ngClass]="{'bar-menu-shown': isBarMenuShown(outlet), 'embedded': isEmbedded}">
    <router-outlet #outlet="outlet" (activate)="onActivate($event, '.app-container')"></router-outlet>
</div>

<app-bar [state]="barMenu" *ngIf="isBarMenuShown(outlet)" fxHide.gt-xs></app-bar>

<!-- Panier embedded-->
<button *ngIf="isCartFloatingButtonShown(outlet)" mat-fab color="primary" class="floating-panel" (click)="onPanelClick()">
    <mat-icon svgIcon="shopping-basket" color="white" [matBadge]="cartBadgeValue$ | async" matBadgeColor="accent" matBadgeSize="small" [matBadgeHidden]="(cartBadgeValue$ | async) < 1">
    </mat-icon>
</button>

</div>
