import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@app/core/storage/storage.service';
import { Cart } from '../models/cart.model';
import { map } from 'rxjs/operators';
import _keyBy from 'lodash-es/keyBy';
import _uniqBy from 'lodash-es/uniqBy';

@Injectable({
  providedIn: 'root'
})
export class CartState {

  private _updating$ = new BehaviorSubject<boolean>(false);
  private _reviewingCart$ = new BehaviorSubject<boolean>(false);
  private _currentCart$ = new BehaviorSubject<Cart>(null);

  constructor(
    private _storageService: StorageService
  ) { }

  setUpdating(isUpdating: boolean) {
    this._updating$.next(isUpdating);
  }

  isUpdating$() {
    return this._updating$.asObservable();
  }

  setReviewingCart(reviewingCart: boolean) {
    this._reviewingCart$.next(reviewingCart);
  }

  isReviewingCart$() {
    return this._reviewingCart$.asObservable();
  }

  loadStoredCart(restaurantId: number): Cart {
    const cart = this._storageService.loadStoredCart(restaurantId.toString());
    return cart;
  }

  setCurrentCart(cart: Cart, keepVersion: boolean = false) {
    if (cart) {
      if (!keepVersion) {
        // update cart version
        cart.cartVersion = Date.now().toString(10);
      }
      // make a deep copy to force immutability and reprocess to keep a reference on menu products
      const menuProducts = _uniqBy(cart.products.map(cp => cp.product), p => p.productId);
      const menuProductById = _keyBy(menuProducts, p => p.productId);
      // deep copy
      const newCart = cart && JSON.parse(JSON.stringify(cart));
      // rereference menu product
      for (const cartProduct of newCart.products) {
        const product = menuProductById[cartProduct.product.productId];
        if (product) {
          cartProduct.product = product;
        }
      }
      // notify update
      this._currentCart$.next(newCart);
      // store localy
      this._storageService.storeCart(newCart);
    } else {
      // cart is null => only notify update
      this._currentCart$.next(null);
    }
  }

  getCurrentCart() {
    return this._currentCart$.value; // || this.createEmptyCart();
  }

  getCurrentCart$() {
    return this._currentCart$.asObservable();
    // .pipe(
    //   map(cart => (cart !== null)
    //     ? cart
    //     : this.createEmptyCart())
    // );
  }

  // private createEmptyCart(): Cart {
  //   return {
  //     // empty cart // TODO allow null cart
  //     menuId: -1,
  //     siteId: -1,
  //     restaurantId: -1,
  //     products: []
  //   };
  // }

}
