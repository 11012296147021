<form [formGroup]="cartForm"
      #form="ngForm"
      *ngrxLet="cart$ as cart"
      class="cart-page"
      fxLayout="column"
      fxLayoutAlign="stretch"
      [ngClass]="{ 'cart-page-disabled': (isLoading$ | async) }">

  <mat-toolbar class="cart-header-toolbar">
    <mat-toolbar-row fxLayout="row"
                     fxLayoutAlign="center center">
      <div>
        <!-- button retour-->
        <app-back-button [style]="{ padding: 0 }"
                         [stopNavigation]="sideViewMode"
                         (goBack)="sideViewMode && onClose()"></app-back-button>
      </div>
      <div class="cart-page-title"> {{ "CART.TITLE" | transloco }} </div>
      <app-cart-clear-button *ngIf="cart && (cart.products?.length > 0 || cart.deliveryDate)"
                             (clearCart)="onClearCart(cart)"></app-cart-clear-button>
      <mat-progress-bar class="cart-page-title-loadingbar"
                        mode="query"
                        *ngIf="(isSubmittingOrReviewinData$ | async)"></mat-progress-bar>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="cart-container"
       [ngSwitch]="cartState$ | async">

    <div *ngSwitchCase="'LOADING'"
         class="cart-loading-template">
      <content-loader *ngFor="let i of [1, 2, 3]"
                      [viewBox]="null"
                      [style]="{ width: '100%', height: '60px' }">
        <svg:circle cx="15" cy="15" r="8" />
        <svg:rect x="40" y="0" rx="3" ry="3" width="60%" height="12" />
        <svg:rect x="40" y="22" rx="3" ry="3" width="40%" height="10" />
      </content-loader>
    </div>

    <div *ngSwitchCase="'EMPTY'"
         class="cart-empty"
         fxLayout="column"
         fxLayoutAlign="center center">
      <div class="cart-title"> {{ "CART.EMPTY" | transloco }} </div>
      <div>
        <button class="full-width"
                mat-flat-button
                color="primary"
                (click)="navigateToMenu()">{{ "CART.ADD_PRODUCTS" | transloco }}</button>
      </div>
    </div>

    <div [formGroup]="cartForm"
         *ngSwitchCase="'VISIBLE'">
      <ng-container *ngrxLet="menu$ as menu">

        <!--Produits-->
        <app-cart-product-list [cartProductList]="cart.products"
                               [filter]="nonCutleryNorReturnableContainerProductfilter"
                               [currencyCode]="currencyCode$ | async"
                               (editCartProductClick)="openProductEdit($event)"
                               [isReadOnly]="(canEdit$ | async)===false"
                               [showCategory]="true"></app-cart-product-list>

        <!--Cutlery-->
        <app-cart-cutlery *ngIf="showCutlery"
                          [cartProductsList]="cart.products"
                          [currencyCode]="currencyCode$ | async"
                          (editCartProductClick)="openProductEdit($event)"
                          [isReadOnly]="(canEdit$ | async)===false"
                          (addCutleryProducts)="onAddCutleryProducts()"
                          (removeCutleryProducts)="onRemoveCutleryProducts()"
                          [mealCount]="mealCount"></app-cart-cutlery>

        <!--Returnable containers-->
        <app-cart-returnable-container *ngIf="showReturnableProductContainers"
                                       [cartProductsList]="cart.products"
                                       [currencyCode]="currencyCode$ | async"></app-cart-returnable-container>

        <!--Total-->
        <app-cart-product-total [totalPrice]="cart.totalPrice"
                                [totalVatPrice]="cart.totalVatPrice"
                                [cartProductList]="cart.products"
                                [discounts]="cart.discounts"
                                [fees]="cart.fees"
                                [currencyCode]="currencyCode$ | async"
                                [showVAT]="showVAT$ | async"></app-cart-product-total>
        <mat-divider class="cart-divider"></mat-divider>

        <!--Ma commande-->
        <div class="cart-delivery">
          <div class="cart-delivery-title"> {{ "CART.DELIVERY_TITLE" | transloco }}</div>

          <div class="cart-delivery-content"
               fxLayout="column"
               fxLayoutAlign="start stretch">



            <ng-container *ngIf="isTableServiceEnabled$ | async; then tableServiceEnabledTemplate; else noTableServiceTemplate"></ng-container>
            <ng-template #tableServiceEnabledTemplate>
              <!-- Table prompt Date -->
              <app-order-preparation-destination-input [preparationSettings]="menu.consumptionMode.orderPreparationSettings"
                                                       formControlName="destination"
                                                       (destinationChange)="onDestinationChange($event)"
                                                       [readonly]="(canEdit$ | async)===false"
                                                       [headerPrompt]="null"
                                                       class="cart-delivery-destination-prompt"></app-order-preparation-destination-input>
            </ng-template>
            <ng-template #noTableServiceTemplate>
              <!-- Place -->
              <app-place-selector (placeEditEvent)="onPlaceChanged($event)"
                                  [isReadOnly]="(canEdit$ | async) === false || (isEmbedded$ | async) === true"></app-place-selector>

              <mat-divider class="command-divider"></mat-divider>

              <!-- Delivery Date -->

              <div *ngIf="menu.consumptionMode.pickupSchedulePrompt"
                   class="cart-delivery-schedule-prompt">{{ menu.consumptionMode.pickupSchedulePrompt }}</div>
              <app-delivery-date-selector formControlName="deliveryDate"
                                          [deliveryDate]="deliveryDate$ | async"
                                          (deliveryDateChange)="onDeliveryDateChange($event)"
                                          [promptMessageWhenEmpty]="(menu.consumptionMode.pickupSchedulePrompt) ? 'CART.DELIVERY_DATE_TIME_TITLE_ALTERNATIVE' : 'CART.DELIVERY_DATE_TIME_TITLE'"
                                          [isReadOnly]="(canEditDeliveryDate$ | async)===false"></app-delivery-date-selector>

              <ng-container *ngIf="cartForm.get('deliveryDate').touched && cartForm.get('deliveryDate').errors as errors; else cartDeliveryOkTemplate">
                <mat-divider class="command-divider command-divider-error"></mat-divider>
                <div class="cart-delivery-errors"
                     @errorInOutAnimation>
                  <mat-error *ngIf="errors['required']">{{ errors['required'] }}</mat-error>
                </div>
              </ng-container>
              <ng-template #cartDeliveryOkTemplate>
                <mat-divider class="command-divider"></mat-divider>
              </ng-template>

            </ng-template>
            <!-- </ng-container> -->
          </div>
        </div>

        <!--Livraison-->
        <div class="cart-delivery-location"
             *ngIf="isDeliveryEnabled$ | async">
          <div class="cart-delivery-location-title"> {{ "CART.DELIVERY_LOCATION_TITLE" | transloco }}</div>

          <!-- Address -->
          <div class="cart-delivery-location-content"
               fxLayout="column"
               fxLayoutAlign="start stretch">
            <app-cart-delivery-location formControlName="deliveryAddress"
                                        [readonly]="(canEdit$ | async)===false"
                                        (addressChanged)="onDeliveryAddressChange($event)"></app-cart-delivery-location>

            <!-- Phone -->
            <!-- TODO Delivery Phone... -->

          </div>
        </div>

        <app-cart-promocode *ngIf="enablePromocodeInput$ | async"
                            [readonly]="(canEdit$ | async)===false"
                            [isLoading]="isSubmittingOrReviewinData$ | async"
                            [appliedPromocode]="appliedPromocode"
                            [promocodeError]="promocodeError"
                            (applyPromocode)="onAppyPromocode($event)"
                            (removePromocode)="onRemovePromocode()"
                            #promocodeInput></app-cart-promocode>

        <!--Comment / prompt-->
        <app-cart-prompt formControlName="comment"
                         [choices]="menu.consumptionMode.customerPromptChoices"
                         [choicesDisplayMode]="menu.consumptionMode.customerPromptChoicesDisplayMode"
                         [comment]="cart.comment"
                         (commentChange)="onCommentChange($event)"
                         [commentTitle]="menu.consumptionMode.additionalInformationTitle"
                         [commentMandatory]="menu.consumptionMode.additionalInformationAllowSkip === false"
                         [readonly]="(canEdit$ | async)===false"></app-cart-prompt>

        <!--Paiement-->
        <app-cart-payment formControlName="payment"
                          [selectedPaymentMethod]="cart.payment"
                          (selectedPaymentMethodChange)="onPaymentSelected($event)"
                          [readonly]="(canEditPayment$ | async)===false"
                          *ngIf="showPayments$ | async"></app-cart-payment>

      </ng-container>
    </div>

    <div *ngSwitchCase="'ORDERING_CLOSED'"
         class="cart-ordering-closed">
      <mat-icon class="cart-ordering-closed-icon">disabled_by_default</mat-icon>
      <div class="cart-title"
           *ngIf="(closingMessage$ | async) as closingMessage"> {{ closingMessage.message }} </div>
    </div>

  </div>
  <mat-toolbar class="cart-footer-toolbar"
               [ngClass]="{ 'cart-footer-toolbar-embedded': (isEmbedded$ | async) }"
               fxLayout="column"
               fxLayoutAlign="center center"
               *ngIf="(cartState$ | async) === 'VISIBLE'">

    <!-- Validation summary -->
    <!-- <div *ngIf="form.invalid">
        <mat-error *ngIf="cartForm.get('payment').hasError('required') && form.submitted">
          {{ cartForm.get('payment').getError('required') }}
        </mat-error>
      </div> -->

    <!--Confirmation-->
    <app-cart-confirm [cartConfirmForm]="cartForm"
                      [total]="(cart$ | async).totalPrice"
                      (confirmCart)="confirmCart()"
                      [inProgress]="(canSubmit$ | async)===false"
                      [currencyCode]="currencyCode$ | async"></app-cart-confirm>
  </mat-toolbar>

</form>
