import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Optional, Output, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Subscription } from 'rxjs';
import { Searcher } from './searcher.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Searcher]
})
export class MatSelectSearchComponent implements AfterViewInit, OnDestroy {

  // Send the array which is to be searched/filtered
  @Input() list: Record<string, string>[] = [];

  // Send the keys of the object properties that is to be searched/filtered
  @Input() searchProperties: string[] = [];

  // Make true if input should be cleared on opening
  @Input() clearSearchInput = false;

  // Make true if there is a mat-option for selecting all values
  @Input() hasSelectAll = false;

  // Make true if it is needed to fix the search bar on top while scrolling.
  @Input() fixOnTop = false;

  @Input() searchPlaceholder = 'Search';

  @Output() filtered = new EventEmitter<Record<string, string>[]>();
  @ViewChild('input', { read: ElementRef, static: true }) element!: ElementRef;
  isLoading = false;
  private _filteredList: Record<string, string>[] | undefined = [];
  private _fullList: Record<string, string>[] = [];
  private _hasFilteredBefore = false;
  private _subscriptions = new Subscription();
  private _clickListenerSelectAll = () => { };

  constructor(
    @Inject(MatSelect) private _matSelect: MatSelect,
    @Optional() @Inject(MatOption) private _matOption: MatOption,
    private _renderer: Renderer2,
    private _searcher: Searcher,
  ) { }

  ngAfterViewInit(): void {
    this.configMatOption();
    this._fullList = this.list;
    this._searcher.initSearch(this.list, this.searchProperties);
    this._subscriptions
      .add(this._matSelect.openedChange
        .subscribe(() => {
          const input = this.element.nativeElement;
          input.focus();
          if ((this._filteredList && this._filteredList.length === 0 && this._hasFilteredBefore) || this.clearSearchInput) {
            input.value = '';
            this.filtered.emit(this._fullList);
          }
        })
        .add(this.filtered.subscribe(() => this.isLoading = false))
      );
    setTimeout(() => this.filtered.emit(this._fullList));
  }

  filterList(event: Event): void {
    const inputEvent = event as InputEvent;
    this._hasFilteredBefore = true;
    this.isLoading = true;
    this._filteredList = this._searcher.filterList(inputEvent);

    if (!this._filteredList) {
      this.isLoading = false;
      return;
    }

    const listWithoutConcatedValues = this._filteredList.map(item => {
      const itemCopy = { ...item };
      delete itemCopy['concatedValues'];
      return itemCopy;
    });
    this.filtered.emit(listWithoutConcatedValues);
  }

  stopCharPropagation(event: KeyboardEvent): void {

    // PREVENT PROPAGATION FOR ALL ALPHANUMERIC CHARACTERS IN ORDER TO AVOID SELECTION ISSUES
    const key = event.key;
    const isTextControlKey = key === ' ' || key === 'Home' || key === 'End' || (key >= 'a' && key <= 'z');
    if (isTextControlKey) { event.stopPropagation(); }

  }

  private configMatOption(): void {
    if (!this._matOption) {
      console.error('<lib-mat-select-search> must be placed inside a <mat-option> element');
      return;
    }
    this._matOption.disabled = true;
    const nativeMatOption = this._matOption._getHostElement();
    const checkBox = nativeMatOption.childNodes[0];
    this._renderer.removeChild(nativeMatOption, checkBox);

    if (this.hasSelectAll) this.enableSelectAll();
    if (this.fixOnTop) this.fixSearchBarOnTopWhileScroll();
  }

  private enableSelectAll(): void {
    const selectAll = this._matSelect.options.toArray()[1];
    const nativeSelectAll = selectAll._getHostElement();

    this._clickListenerSelectAll = this._renderer.listen(nativeSelectAll, 'click', () => {
      if (selectAll.selected) this.selectAllOptions(); else this.deselectAllOptions();
    });
  }

  private selectAllOptions(): void {
    const matOptions = this._matSelect.options;
    for (let i = 2; i < matOptions.length; i++) matOptions.toArray()[i].select();
  }

  private deselectAllOptions(): void {
    const matOptions = this._matSelect.options;
    for (let i = 2; i < matOptions.length; i++) matOptions.toArray()[i].deselect();
  }

  private fixSearchBarOnTopWhileScroll(): void {
    const searchBar = this._matSelect.options.toArray()[0]._getHostElement();
    this._renderer.setStyle(searchBar, 'position', 'sticky');
    this._renderer.setStyle(searchBar, 'top', '0');
    this._renderer.setStyle(searchBar, 'z-index', '1');
    this._renderer.setStyle(searchBar, 'background-color', 'white');
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._clickListenerSelectAll();
  }
}
