import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-scrollable-container',
  templateUrl: './scrollable-container.component.html',
  styleUrls: ['./scrollable-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableContainerComponent implements OnInit {
  @Input() noHeader: boolean;
  @Input() headerStyle: { [klass: string]: any; } | null;

  @Input() noFooter: boolean;
  @Input() footerStyle: { [klass: string]: any; } | null;

  @Input() noPadding: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
