<div class="receipt-page"
     fxLayout="column"
     fxLayoutAlign="stretch">
  <div class="receipt-container">
    <!--Numero de commande-->
    <!-- <span class="receipt-header-toolbar-title">{{ "COMMAND.DETAIL_TITLE" | transloco : { deliveryDate: (order?.deliveryDate | translocoDate: { dateStyle: 'short' }), orderNumber: order.ticketNumber } }}</span> -->
    <!--Livraison-->
    <app-command-detail-delivery [order]="order"
                                 [isReadOnly]="true"
                                 [hideComment]="true"
                                 [customerDisplayName]="order.customer.firstName + ' ' + order.customer.lastName"></app-command-detail-delivery>
    <mat-divider class="receipt-divider"></mat-divider>
    <!--Produits-->
    <app-cart-product-list [cartProductList]="cartProductList"
                           [currencyCode]="order.restaurant.currency"
                           [isReadOnly]="true"></app-cart-product-list>
    <!--Total-->
    <app-cart-product-total [totalVatPrice]="order.totalTaxWithDiscount"
                            [totalPrice]="order.totalInclTaxWithDiscount"
                            [totalExclTaxWithDiscount]="order.totalExclTaxWithDiscount"
                            [cartProductList]="cartProductList"
                            [discounts]="order.discounts"
                            [fees]="order.fees"
                            [withTaxDetails]="order.restaurant.showVAT"
                            [taxRatesBreakdown]="order.taxRatesBreakdown"
                            [currencyCode]="order.restaurant.currency"
                            [showVAT]="order.restaurant.showVAT"></app-cart-product-total>
  </div>
</div>
