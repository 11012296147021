import {
  ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnInit,
  Output
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SiteState } from '@app/core/services/site/state/site.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { PlaceSelectorDialogData } from '../place-selector-dialog/place-selector-dialog-data.model';
import { PlaceSelectorDialogResult } from '../place-selector-dialog/place-selector-dialog-result.model';
import { PlaceSelectorDialogComponent } from '../place-selector-dialog/place-selector-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-place-selector',
  templateUrl: './place-selector.component.html',
  styleUrls: ['./place-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceSelectorComponent implements OnInit {

  @Input() isReadOnly: boolean = false;
  @Input() iconColor: ThemePalette = 'primary';

  @Output() placeEditEvent = new EventEmitter<PlaceSelectorDialogResult>();

  restaurant$ = this._siteState.getCurrentRestaurant$();
  isLoading$ = this._siteState.isUpdating$();

  constructor(
    private _siteState: SiteState,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onPlaceSectionClick() {

    this._siteState.getCurrentRestaurantContext$()
      .pipe(
        first(),
        switchMap(ctx => {

          if (ctx?.restaurant == null) {
            //  go to site selection if no current site/restaurant selected/persisted
            return of({ action: 'GotoSiteSelection' } as PlaceSelectorDialogResult);
          }

          // open restaurant selection dialog
          const changePlaceDialog = this._dialog.open<PlaceSelectorDialogComponent, PlaceSelectorDialogData, PlaceSelectorDialogResult>(PlaceSelectorDialogComponent, {
            data: {
              ...ctx
            },
          });
          return changePlaceDialog.afterClosed()
            .pipe(
              map(result => result || { action: 'None' } as PlaceSelectorDialogResult) // result is null when dialog is closed withtou action
            );
        }),
        // do not raise event when action is 'None'
        filter(result => result.action !== 'None'),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.placeEditEvent.next(result);
      });

  }
}
