<div class="scrollable-container" fxLayout="column">
    <!-- Header-->
    <div class="scrollable-container-header" fxLayout="row" fxLayoutAlign="center center" *ngIf="!noHeader" [ngStyle]="headerStyle">
        <ng-content select="[header]" ]></ng-content>
    </div>
    <!-- Content-->
    <div class="scrollable-container-content" [ngClass]="{'no-padding': noPadding}">
        <ng-content select="[content]"></ng-content>
    </div>
    <!-- Footer-->
    <div class="scrollable-container-footer" fxLayout="row" fxLayoutAlign="center center" *ngIf="!noFooter" [ngStyle]="footerStyle">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
