import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { NavigationService, RestaurantSource } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NavToMenuIfLoggedGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private _authenticationService: AuthenticationService,
    private _navigationService: NavigationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const url: string = state.url;
    return this.check(url);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const url = `/${route.path}`;
    return this.check(url);
  }

  // private routeHasRedirectQueryParam(route: ActivatedRouteSnapshot) {
  //   return route.queryParamMap.get(REDIRECT_URL_QUERYPARAM);
  // }

  private check(url: string): Observable<boolean> {
    return this._authenticationService.isLoggedIn$()
      .pipe(
        switchMap(isLoggedIn => {
          if (isLoggedIn) {
            // redirect
            return this._navigationService.navigateToDefaultMenu$(RestaurantSource.UserRestaurant)
              .pipe(
                map(navigated => false) // false => activation has been redirected
              );
          } else {
            return of(true); // not logged => allow nav to sign-in
          }
        })
      );
  }

}
