/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreateOrder$Json } from '../fn/orders/create-order-json';
import { CreateOrder$Json$Params } from '../fn/orders/create-order-json';
import { CreateOrder$Plain } from '../fn/orders/create-order-plain';
import { CreateOrder$Plain$Params } from '../fn/orders/create-order-plain';
import { EdenredSignin$Json } from '../fn/orders/edenred-signin-json';
import { EdenredSignin$Json$Params } from '../fn/orders/edenred-signin-json';
import { EdenredSignin$Plain } from '../fn/orders/edenred-signin-plain';
import { EdenredSignin$Plain$Params } from '../fn/orders/edenred-signin-plain';
import { GetLastOrderWithPendingPreparation$Json } from '../fn/orders/get-last-order-with-pending-preparation-json';
import { GetLastOrderWithPendingPreparation$Json$Params } from '../fn/orders/get-last-order-with-pending-preparation-json';
import { GetLastOrderWithPendingPreparation$Plain } from '../fn/orders/get-last-order-with-pending-preparation-plain';
import { GetLastOrderWithPendingPreparation$Plain$Params } from '../fn/orders/get-last-order-with-pending-preparation-plain';
import { GetOrder$Json } from '../fn/orders/get-order-json';
import { GetOrder$Json$Params } from '../fn/orders/get-order-json';
import { GetOrder$Plain } from '../fn/orders/get-order-plain';
import { GetOrder$Plain$Params } from '../fn/orders/get-order-plain';
import { GetOrderPreparation$Json } from '../fn/orders/get-order-preparation-json';
import { GetOrderPreparation$Json$Params } from '../fn/orders/get-order-preparation-json';
import { GetOrderPreparation$Plain } from '../fn/orders/get-order-preparation-plain';
import { GetOrderPreparation$Plain$Params } from '../fn/orders/get-order-preparation-plain';
import { GetOrders$Json } from '../fn/orders/get-orders-json';
import { GetOrders$Json$Params } from '../fn/orders/get-orders-json';
import { GetOrders$Plain } from '../fn/orders/get-orders-plain';
import { GetOrders$Plain$Params } from '../fn/orders/get-orders-plain';
import { GetOrdersCount$Json } from '../fn/orders/get-orders-count-json';
import { GetOrdersCount$Json$Params } from '../fn/orders/get-orders-count-json';
import { GetOrdersCount$Plain } from '../fn/orders/get-orders-count-plain';
import { GetOrdersCount$Plain$Params } from '../fn/orders/get-orders-count-plain';
import { Order } from '../models/order';
import { OrderPreparation } from '../models/order-preparation';
import { OrderProcessingState } from '../models/order-processing-state';
import { Reconcile } from '../fn/orders/reconcile';
import { Reconcile$Params } from '../fn/orders/reconcile';
import { ReviewCart$Json } from '../fn/orders/review-cart-json';
import { ReviewCart$Json$Params } from '../fn/orders/review-cart-json';
import { ReviewCart$Plain } from '../fn/orders/review-cart-plain';
import { ReviewCart$Plain$Params } from '../fn/orders/review-cart-plain';
import { ReviewCartResult } from '../models/review-cart-result';
import { SendCustomerOrdersToPreparation$Json } from '../fn/orders/send-customer-orders-to-preparation-json';
import { SendCustomerOrdersToPreparation$Json$Params } from '../fn/orders/send-customer-orders-to-preparation-json';
import { SendCustomerOrdersToPreparation$Plain } from '../fn/orders/send-customer-orders-to-preparation-plain';
import { SendCustomerOrdersToPreparation$Plain$Params } from '../fn/orders/send-customer-orders-to-preparation-plain';
import { SendOrdersToPreparation$Json } from '../fn/orders/send-orders-to-preparation-json';
import { SendOrdersToPreparation$Json$Params } from '../fn/orders/send-orders-to-preparation-json';
import { SendOrdersToPreparation$Plain } from '../fn/orders/send-orders-to-preparation-plain';
import { SendOrdersToPreparation$Plain$Params } from '../fn/orders/send-orders-to-preparation-plain';
import { SetOrderDestination$Json } from '../fn/orders/set-order-destination-json';
import { SetOrderDestination$Json$Params } from '../fn/orders/set-order-destination-json';
import { SetOrderDestination$Plain } from '../fn/orders/set-order-destination-plain';
import { SetOrderDestination$Plain$Params } from '../fn/orders/set-order-destination-plain';
import { SwileSignin$Json } from '../fn/orders/swile-signin-json';
import { SwileSignin$Json$Params } from '../fn/orders/swile-signin-json';
import { SwileSignin$Plain } from '../fn/orders/swile-signin-plain';
import { SwileSignin$Plain$Params } from '../fn/orders/swile-signin-plain';
import { WorldLine } from '../fn/orders/world-line';
import { WorldLine$Params } from '../fn/orders/world-line';
import { WorldLineCheckout } from '../fn/orders/world-line-checkout';
import { WorldLineCheckout$Params } from '../fn/orders/world-line-checkout';

@Injectable({ providedIn: 'root' })
export class ApiOrdersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postOrdersReviewcart()` */
  static readonly PostOrdersReviewcartPath = '/api/Orders/reviewCart';

  /**
   * post-orders-reviewcart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ReviewCart$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ReviewCart$Plain$Response(params?: ReviewCart$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReviewCartResult>> {
    return ReviewCart$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-reviewcart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ReviewCart$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ReviewCart$Plain(params?: ReviewCart$Plain$Params, context?: HttpContext): Observable<ReviewCartResult> {
    return this.ReviewCart$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewCartResult>): ReviewCartResult => r.body)
    );
  }

  /**
   * post-orders-reviewcart.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ReviewCart$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ReviewCart$Json$Response(params?: ReviewCart$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReviewCartResult>> {
    return ReviewCart$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-reviewcart.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ReviewCart$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  ReviewCart$Json(params?: ReviewCart$Json$Params, context?: HttpContext): Observable<ReviewCartResult> {
    return this.ReviewCart$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReviewCartResult>): ReviewCartResult => r.body)
    );
  }

  /** Path part for operation `getOrders()` */
  static readonly GetOrdersPath = '/api/Orders';

  /**
   * get-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrders$Plain$Response(params?: GetOrders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Order>>> {
    return GetOrders$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrders$Plain(params?: GetOrders$Plain$Params, context?: HttpContext): Observable<Array<Order>> {
    return this.GetOrders$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Order>>): Array<Order> => r.body)
    );
  }

  /**
   * get-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrders$Json$Response(params?: GetOrders$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Order>>> {
    return GetOrders$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrders$Json(params?: GetOrders$Json$Params, context?: HttpContext): Observable<Array<Order>> {
    return this.GetOrders$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Order>>): Array<Order> => r.body)
    );
  }

  /** Path part for operation `postOrders()` */
  static readonly PostOrdersPath = '/api/Orders';

  /**
   * post-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateOrder$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  CreateOrder$Plain$Response(params?: CreateOrder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return CreateOrder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `CreateOrder$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  CreateOrder$Plain(params?: CreateOrder$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.CreateOrder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * post-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateOrder$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  CreateOrder$Json$Response(params?: CreateOrder$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return CreateOrder$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `CreateOrder$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  CreateOrder$Json(params?: CreateOrder$Json$Params, context?: HttpContext): Observable<Order> {
    return this.CreateOrder$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `getOrdersByOrderid()` */
  static readonly GetOrdersByOrderidPath = '/api/Orders/{orderId}';

  /**
   * get-orders-by-orderid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrder$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrder$Plain$Response(params: GetOrder$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return GetOrder$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-by-orderid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrder$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrder$Plain(params: GetOrder$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.GetOrder$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * get-orders-by-orderid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrder$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrder$Json$Response(params: GetOrder$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return GetOrder$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-by-orderid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrder$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrder$Json(params: GetOrder$Json$Params, context?: HttpContext): Observable<Order> {
    return this.GetOrder$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `getOrdersCount()` */
  static readonly GetOrdersCountPath = '/api/Orders/count';

  /**
   * get-orders-count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrdersCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrdersCount$Plain$Response(params?: GetOrdersCount$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return GetOrdersCount$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-count.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrdersCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrdersCount$Plain(params?: GetOrdersCount$Plain$Params, context?: HttpContext): Observable<number> {
    return this.GetOrdersCount$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * get-orders-count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrdersCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrdersCount$Json$Response(params?: GetOrdersCount$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return GetOrdersCount$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-count.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrdersCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrdersCount$Json(params?: GetOrdersCount$Json$Params, context?: HttpContext): Observable<number> {
    return this.GetOrdersCount$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `postOrdersWorldline()` */
  static readonly PostOrdersWorldlinePath = '/api/Orders/worldline';

  /**
   * post-orders-worldline.
   *
   * Server to Server Callback for worldline payment provider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `WorldLine()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  WorldLine$Response(params?: WorldLine$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return WorldLine(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-worldline.
   *
   * Server to Server Callback for worldline payment provider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `WorldLine$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  WorldLine(params?: WorldLine$Params, context?: HttpContext): Observable<void> {
    return this.WorldLine$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postOrdersWorldlineCheckout()` */
  static readonly PostOrdersWorldlineCheckoutPath = '/api/Orders/worldline-checkout';

  /**
   * post-orders-worldline-checkout.
   *
   * Callback from customer checkout form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `WorldLineCheckout()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  WorldLineCheckout$Response(params?: WorldLineCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return WorldLineCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-worldline-checkout.
   *
   * Callback from customer checkout form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `WorldLineCheckout$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  WorldLineCheckout(params?: WorldLineCheckout$Params, context?: HttpContext): Observable<void> {
    return this.WorldLineCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postOrdersEdenredSignin()` */
  static readonly PostOrdersEdenredSigninPath = '/api/Orders/edenred-signin';

  /**
   * post-orders-edenred-signin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `EdenredSignin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  EdenredSignin$Plain$Response(params?: EdenredSignin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return EdenredSignin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-edenred-signin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `EdenredSignin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  EdenredSignin$Plain(params?: EdenredSignin$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.EdenredSignin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * post-orders-edenred-signin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `EdenredSignin$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  EdenredSignin$Json$Response(params?: EdenredSignin$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return EdenredSignin$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-edenred-signin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `EdenredSignin$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  EdenredSignin$Json(params?: EdenredSignin$Json$Params, context?: HttpContext): Observable<Order> {
    return this.EdenredSignin$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `postOrdersSwileSignin()` */
  static readonly PostOrdersSwileSigninPath = '/api/Orders/swile-signin';

  /**
   * post-orders-swile-signin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SwileSignin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SwileSignin$Plain$Response(params?: SwileSignin$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return SwileSignin$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-swile-signin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SwileSignin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SwileSignin$Plain(params?: SwileSignin$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.SwileSignin$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * post-orders-swile-signin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SwileSignin$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SwileSignin$Json$Response(params?: SwileSignin$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return SwileSignin$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-swile-signin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SwileSignin$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SwileSignin$Json(params?: SwileSignin$Json$Params, context?: HttpContext): Observable<Order> {
    return this.SwileSignin$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `postOrdersReconcile()` */
  static readonly PostOrdersReconcilePath = '/api/Orders/reconcile';

  /**
   * post-orders-reconcile.
   *
   * Launch order reconciliation job
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Reconcile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Reconcile$Response(params?: Reconcile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return Reconcile(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-reconcile.
   *
   * Launch order reconciliation job
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Reconcile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  Reconcile(params?: Reconcile$Params, context?: HttpContext): Observable<void> {
    return this.Reconcile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postOrdersSetPreparationDestination()` */
  static readonly PostOrdersSetPreparationDestinationPath = '/api/Orders/set-preparation-destination';

  /**
   * post-orders-set-preparation-destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SetOrderDestination$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SetOrderDestination$Plain$Response(params?: SetOrderDestination$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return SetOrderDestination$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-set-preparation-destination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SetOrderDestination$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SetOrderDestination$Plain(params?: SetOrderDestination$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.SetOrderDestination$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * post-orders-set-preparation-destination.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SetOrderDestination$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SetOrderDestination$Json$Response(params?: SetOrderDestination$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return SetOrderDestination$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-set-preparation-destination.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SetOrderDestination$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SetOrderDestination$Json(params?: SetOrderDestination$Json$Params, context?: HttpContext): Observable<Order> {
    return this.SetOrderDestination$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `postOrdersPreparationByOrderpreparationidSendCustomerOrders()` */
  static readonly PostOrdersPreparationByOrderpreparationidSendCustomerOrdersPath = '/api/Orders/preparation/{orderPreparationId}/send-customer-orders';

  /**
   * post-orders-preparation-by-orderpreparationid--send-customer-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SendCustomerOrdersToPreparation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendCustomerOrdersToPreparation$Plain$Response(params: SendCustomerOrdersToPreparation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderProcessingState>> {
    return SendCustomerOrdersToPreparation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-customer-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SendCustomerOrdersToPreparation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendCustomerOrdersToPreparation$Plain(params: SendCustomerOrdersToPreparation$Plain$Params, context?: HttpContext): Observable<OrderProcessingState> {
    return this.SendCustomerOrdersToPreparation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderProcessingState>): OrderProcessingState => r.body)
    );
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-customer-orders.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SendCustomerOrdersToPreparation$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendCustomerOrdersToPreparation$Json$Response(params: SendCustomerOrdersToPreparation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderProcessingState>> {
    return SendCustomerOrdersToPreparation$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-customer-orders.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SendCustomerOrdersToPreparation$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendCustomerOrdersToPreparation$Json(params: SendCustomerOrdersToPreparation$Json$Params, context?: HttpContext): Observable<OrderProcessingState> {
    return this.SendCustomerOrdersToPreparation$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderProcessingState>): OrderProcessingState => r.body)
    );
  }

  /** Path part for operation `postOrdersPreparationByOrderpreparationidSendToPreparation()` */
  static readonly PostOrdersPreparationByOrderpreparationidSendToPreparationPath = '/api/Orders/preparation/{orderPreparationId}/send-to-preparation';

  /**
   * post-orders-preparation-by-orderpreparationid--send-to-preparation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SendOrdersToPreparation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendOrdersToPreparation$Plain$Response(params: SendOrdersToPreparation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderProcessingState>> {
    return SendOrdersToPreparation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-to-preparation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SendOrdersToPreparation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendOrdersToPreparation$Plain(params: SendOrdersToPreparation$Plain$Params, context?: HttpContext): Observable<OrderProcessingState> {
    return this.SendOrdersToPreparation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderProcessingState>): OrderProcessingState => r.body)
    );
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-to-preparation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SendOrdersToPreparation$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendOrdersToPreparation$Json$Response(params: SendOrdersToPreparation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderProcessingState>> {
    return SendOrdersToPreparation$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * post-orders-preparation-by-orderpreparationid--send-to-preparation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SendOrdersToPreparation$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  SendOrdersToPreparation$Json(params: SendOrdersToPreparation$Json$Params, context?: HttpContext): Observable<OrderProcessingState> {
    return this.SendOrdersToPreparation$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderProcessingState>): OrderProcessingState => r.body)
    );
  }

  /** Path part for operation `getOrdersPreparationByOrderpreparationid()` */
  static readonly GetOrdersPreparationByOrderpreparationidPath = '/api/Orders/preparation/{orderPreparationId}';

  /**
   * get-orders-preparation-by-orderpreparationid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrderPreparation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrderPreparation$Plain$Response(params: GetOrderPreparation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPreparation>> {
    return GetOrderPreparation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-preparation-by-orderpreparationid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrderPreparation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrderPreparation$Plain(params: GetOrderPreparation$Plain$Params, context?: HttpContext): Observable<OrderPreparation> {
    return this.GetOrderPreparation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderPreparation>): OrderPreparation => r.body)
    );
  }

  /**
   * get-orders-preparation-by-orderpreparationid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetOrderPreparation$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrderPreparation$Json$Response(params: GetOrderPreparation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPreparation>> {
    return GetOrderPreparation$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-preparation-by-orderpreparationid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetOrderPreparation$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetOrderPreparation$Json(params: GetOrderPreparation$Json$Params, context?: HttpContext): Observable<OrderPreparation> {
    return this.GetOrderPreparation$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderPreparation>): OrderPreparation => r.body)
    );
  }

  /** Path part for operation `getOrdersLastPendingPreparation()` */
  static readonly GetOrdersLastPendingPreparationPath = '/api/Orders/last-pending-preparation';

  /**
   * get-orders-last-pending-preparation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetLastOrderWithPendingPreparation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLastOrderWithPendingPreparation$Plain$Response(params?: GetLastOrderWithPendingPreparation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return GetLastOrderWithPendingPreparation$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-last-pending-preparation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetLastOrderWithPendingPreparation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLastOrderWithPendingPreparation$Plain(params?: GetLastOrderWithPendingPreparation$Plain$Params, context?: HttpContext): Observable<Order> {
    return this.GetLastOrderWithPendingPreparation$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * get-orders-last-pending-preparation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetLastOrderWithPendingPreparation$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLastOrderWithPendingPreparation$Json$Response(params?: GetLastOrderWithPendingPreparation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return GetLastOrderWithPendingPreparation$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-orders-last-pending-preparation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetLastOrderWithPendingPreparation$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetLastOrderWithPendingPreparation$Json(params?: GetLastOrderWithPendingPreparation$Json$Params, context?: HttpContext): Observable<Order> {
    return this.GetLastOrderWithPendingPreparation$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

}
