import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import {
  Category,
  CategoryProduct,
  Menu,
  Product,
  ProductStep,
  Step,
  StepProduct,
} from '@app/core/api-client/models';
import { ApiRestaurantsService } from '@app/core/api-client/services';
import { ProductDetailModel } from '@app/core/models/product-detail.model';
import { ProductStepModel } from '@app/core/models/product-step.model';

import { combineLatest, concat, EMPTY, merge, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, first, last, map, observeOn, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { CartService } from '../cart/cart.service';
import { MapperService } from '../mapper.service';
import { SiteState } from '../site/state/site.state';
import { PickupSchedule } from './models/pickup-schedule.model';
import { LoadMenuRequest, MenuState } from './state/menu.state';
import _flatten from 'lodash-es/flatten';
import _uniqBy from 'lodash-es/uniqBy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StringHelper } from '@app/core/helpers/string-helper';
import { TranslocoService } from '@ngneat/transloco';
import { PageVisibilityService } from '@app/core/ui/page-visibility/page-visibility.service';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { MathMin } from '@app/core/helpers/number-helper';
import { throttle } from 'lodash';
import { MenuModelHelper } from './models/menu-model-helper';

export const MAX_PRODUCT_STEP_LEVEL = 3;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {

  private _restaurantChangedSub: Subscription;
  private _lastLoadMenuRequest: LoadMenuRequest;

  constructor(
    private _menuState: MenuState,
    private _restaurantApiService: ApiRestaurantsService,
    private _mapper: MapperService,
    private _siteState: SiteState,
    private _cartService: CartService,
    private _translationService: TranslocoService,
    private _pageVisibilityService: PageVisibilityService,
    private _errorHandler: ErrorHandler,
  ) {

    // handle Menu reload requests
    this._menuState.getCurrentMenuRequiresReload$()
      .pipe(
        switchMap(loadMenuRequest =>
          this.loadMenu$(loadMenuRequest.restaurantId, loadMenuRequest.force ?? true, loadMenuRequest.loadCart)
            .pipe(
              // to preserve observal, catch errors in inner pipeline to prevent higher-order observal to fail => see: https://stackoverflow.com/a/58661330/590741
              catchError((error: unknown) => {
                // notify error but continue observal
                this._errorHandler.handleError(error);
                return EMPTY; // continue observal by emit a dummy value
              }),
              // cancel call early (without waiting for debounce), else it is already canceled later by switchMap
              takeUntil(this._menuState.getCurrentMenuRequiresReload$())
            )
        ),
        untilDestroyed(this),
      )
      .subscribe();

    this.monitorMenuChanges();
  }

  /// Reloads menu when restaurant settings changes (open/closes/timer)
  private monitorMenuChanges(defaultCheckDelay: number = 300_000): void { // 300 000ms = 5min

    merge(
      //reload menu when restaurant opening/closing changes
      this._menuState.getCurrentMenu$()
        .pipe(
          switchMap(menu => {
            const openingDelay = menu?.orderingSettings.openAt && differenceInMilliseconds(new Date(menu.orderingSettings.openAt), new Date());
            const closingDelay = menu?.orderingSettings.closeAt && differenceInMilliseconds(new Date(menu.orderingSettings.closeAt), new Date());
            const nextCheckDelay = MathMin(openingDelay, closingDelay, defaultCheckDelay);
            return timer(nextCheckDelay, nextCheckDelay);
          }),
          map(_ => 'Delay due')
        ),
      //Reload also when app is resumed if a timer (30s) is due
      this._pageVisibilityService.$onPageVisible
        .pipe(
          map(_ => 'PageVisible')
        )
    )
      .pipe(
        throttleTime(30_000), // 30s
        tap(reloadMenuCause => {
          console.info('🗘 Automatic menu reload : ' + reloadMenuCause);
          //reload menu
          this._menuState.setCurrentMenuRequiresReload({ force: true, loadCart: false });
        }),
        untilDestroyed(this),
      )
      .subscribe();

  }

  loadMenu$(restaurantId?: number, force?: boolean, loadCart?: boolean): Observable<Menu> {
    restaurantId ??= this._lastLoadMenuRequest?.restaurantId;
    force ??= false;
    loadCart ??= true;

    // restaurant undefined => null menu
    if (restaurantId == null) {
      return of(null as Menu);
    }

    // if requested id is different from last one or request is forced then load menu from Api
    const menuIsUpdating = this._menuState.isUpdating();
    const isSameRestaurant = (restaurantId == this._lastLoadMenuRequest?.restaurantId);
    if (force // request is forced
      || !isSameRestaurant // request changed
      || (!menuIsUpdating && !this._menuState.getCurrentMenu()) // previous request can be cancelled so check state
    ) {
      this._lastLoadMenuRequest = { restaurantId, force, loadCart };

      //display loaders when restaurant is not the same
      let getMenu$: Observable<Menu>;
      if (!isSameRestaurant) {
        this._siteState.setUpdating(true);
        this._menuState.setUpdating(true);

        getMenu$ = concat(
          of(null as Menu), // reset menu to null when reloading (for cleanup & display loaders)
          this._restaurantApiService.GetRestaurantMenu$Json({ restaurantId: restaurantId }).pipe(first())
        );
      }
      else {
        getMenu$ = this._restaurantApiService.GetRestaurantMenu$Json({ restaurantId: restaurantId }).pipe(first())
      }

      return getMenu$
        .pipe(
          tap((menu) => {
            // udpate current restaurant/site
            this._siteState.setCurrentRestaurantContext(menu ? { restaurant: menu?.restaurant, site: menu?.site } : null);
            // update current menu
            this._menuState.setCurrentMenu(menu);
            // update current cart
            if (loadCart) {
              this._cartService.loadCart(menu);
            }
          }),
          first(menu => !!menu), // exclude the null menu (used for cleanup before relaoding)
          finalize(() => {
            this._siteState.setUpdating(false);
            this._menuState.setUpdating(false);
          })
        );
    }
    else {
      const isLoadingRequestedMenu = menuIsUpdating && restaurantId === this._lastLoadMenuRequest?.restaurantId;

      this._lastLoadMenuRequest = { restaurantId, force, loadCart };

      if (isLoadingRequestedMenu) {
        return this.getCurrentMenu$()
          .pipe(
            // wait for requested menu
            first(menu => menu?.restaurant?.restaurantId === restaurantId)
          );
      }
      else {
        // else get currentMenu
        return this.getCurrentMenu$().pipe(
          first(),
          map((menu) => (menu?.restaurant?.restaurantId === restaurantId) ? menu : null)
        );
      }
    }
  }

  ngOnDestroy(): void {
    this._restaurantChangedSub.unsubscribe();
  }

  getCurrentMenu() {
    return this._menuState.getCurrentMenu();
  }

  getCurrentMenu$() {
    return this._menuState.getCurrentMenu$();
  }

  selectCategory(categoryId?: number) {
    this._menuState.setSelectedCategoryId(categoryId);
  }

  getSelectedCategory$() {
    return this._menuState.getSelectedCategory$();
  }

  getSelectedRootCategory$() {
    return combineLatest([
      this._menuState.getCurrentMenu$(),
      this._menuState.getSelectedCategory$(),
    ]).pipe(
      distinctUntilChanged(
        ([menuX, categoryX], [menuY, categoryY]) => categoryX === categoryY
      ),
      // retrieve only root categories
      map(([menu, category]) => MenuModelHelper.getRootCategory(category, menu)),
      distinctUntilChanged()
    );
  }

  private getKeywords(searchString: string): string[] {
    return searchString.split(' ').filter(s => s.trim());
  }

  getFilteredMenu(menu: Menu, searchString: string): [Menu, number] {
    const newMenu: Menu = JSON.parse(JSON.stringify(menu)); // json deseralize
    const kws = this.getKeywords(searchString);

    // chercher les produits
    const productsId = menu.products
      .filter((p) => this.isProductMatchkeyWords(p, kws))
      .map((p) => p.productId);

    let resultCount = 0;
    // Filtrer les produits dans les categories
    newMenu.categories.map((category) => {
      const productsCategoryFiltred = category.products.filter((p) => productsId.includes(p.productId));
      category.products = productsCategoryFiltred;
      resultCount += productsCategoryFiltred.length;
    });

    return [newMenu, resultCount];
  }

  getProductListFiltered(menu: Menu, searchString: string): Product[] {
    // Pas de menu pas de produit
    if (!menu) {
      return [];
    }

    const kws = this.getKeywords(searchString);

    // trouver les produits qui sont liés à des catégories
    const productIdsWithinCategories = _flatten(menu?.categories.map((category) => category.products.map((p) => p.productId)));

    // trouver les produits
    const productsFiltred = menu.products.filter(
      (p) => productIdsWithinCategories.includes(p.productId) && this.isProductMatchkeyWords(p, kws)
    );

    return productsFiltred;
  }

  private isProductMatchkeyWords(product: Product, kws: string[]): boolean {
    // TODO faire une recherche plus intelligente => https://github.com/nextapps-de/flexsearch
    return kws.every(kw =>
      StringHelper.includes(product.name, kw)
      || StringHelper.includes(product.description, kw)
      //|| StringHelper.includes(product.tags?.join(','), kw)
      || StringHelper.includes(product.additionalInformations?.additionalDescription, kw)
    );
  }

  public getDefaultResult(menu: Menu): [Menu, number] {
    // clone menu
    const newMenu: Menu = JSON.parse(JSON.stringify(menu));
    // create categories from "Default Result" tags
    let index = 0;

    const categories: Category[] = [];
    //add "dish of the day" category
    const dishOfTheDayProductIds = menu.products.filter((p) => p.isDishOfTheDay).map((p) => p.productId);
    if (dishOfTheDayProductIds.length > 0) {
      categories.push({
        categoryId: index++,
        position: index,
        parentCategoryId: null,
        name: this._translationService.translate('MENU.DISH_OF_THE_DAY_CATEGORY'),
        products: dishOfTheDayProductIds.map((x) => ({ productId: x } as CategoryProduct)),
      } as Category);
    }

    //add "new products" category
    const newProductsProductIds = menu.products.filter((p) => p.isNewProduct).map((p) => p.productId);
    if (newProductsProductIds.length > 0) {
      categories.push({
        categoryId: index++,
        position: index,
        parentCategoryId: null,
        name: this._translationService.translate('MENU.NEW_PRODUCT_CATEGORY'),
        products: newProductsProductIds.map((x) => ({ productId: x } as CategoryProduct)),
      } as Category);
    }

    newMenu.categories = categories;
    // count matched products
    const resultCount = newMenu.categories.reduce((sum, current) => sum + current.products.length, 0);
    return [newMenu, resultCount];
  }

  getProductDetail(menu: Menu, productId: number, stepProduct?: StepProduct, stepLevel: number = MAX_PRODUCT_STEP_LEVEL): ProductDetailModel {
    const foundProduct = menu.products.find((p) => p.productId === productId);
    if (!foundProduct) {
      return null;
    }

    // informations de base du produit
    const product = this._mapper.toProductDetailModel(foundProduct, stepProduct);

    // récupérer le contenu des steps
    const steps = menu.steps.filter((step) =>
      foundProduct.steps.find((s) => s.stepId === step.stepId)
    );

    // affecter les steps complet au produit
    if (stepLevel > 0) {
      product.steps = steps.map((step) =>
        this.getStepDetail(menu, step, foundProduct.steps.find((s) => s.stepId === step.stepId), stepLevel)
      );
    }

    return product;
  }

  getStepDetail(menu: Menu, step: Step, productStep: ProductStep, stepLevel): ProductStepModel {
    if (!menu || !step) {
      return null;
    }

    const stepModel = this._mapper.toProductStepModel(step, productStep);
    stepModel.detailedProducts = stepModel.stepConfig.products.map(
      (stepProduct) => this.getProductDetail(menu, stepProduct.productId, stepProduct, stepLevel - 1)
    );
    return stepModel;
  }

  getAvailablePickupSchedules(menu?: Menu): PickupSchedule[] {
    return this._menuState.getAvailableSchedules(menu);
  }

}
