import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninPageComponent } from './pages/signin-page/signin-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { AppRoutes } from '@app/core/routes';
import { NavToMenuIfLoggedGuard } from '@app/core/routes/nav-to-menu-if-logged.guard';
import { RouteDataModel } from '@app/core/models/route-data.model';

const routes: Routes = [
  ...[AppRoutes.default, AppRoutes.signin].map(path =>
  ({
    path: path,
    component: SigninPageComponent,
    data: { animation: 'signin', title: 'SIGNIN.TITLE', analyticsTitle: 'SignIn' } as RouteDataModel,
    canActivate: [NavToMenuIfLoggedGuard]
  })
  ),
  {
    path: AppRoutes.forgotPassword,
    component: ForgotPasswordPageComponent,
    data: { animation: 'forgotPassword', title: 'FORGOT_PASSWORD.TITLE', analyticsTitle: 'ForgotPassword' } as RouteDataModel,
  },
  {
    path: AppRoutes.forgotPassword_FR,
    component: ForgotPasswordPageComponent,
    data: { animation: 'forgotPassword', title: 'FORGOT_PASSWORD.TITLE', analyticsTitle: 'ForgotPassword' } as RouteDataModel,
  },
  {
    path: `${AppRoutes.resetPassword}`,
    component: ResetPasswordPageComponent,
    data: { animation: 'resetPassword', title: 'RESET_PASSWORD.TITLE', analyticsTitle: 'ResetPassword' } as RouteDataModel,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SigninRoutingModule { }
