import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrls: ['./html-dialog.component.scss']
})
export class HtmlDialogComponent implements OnInit {

  title: string;
  content: string;

  constructor() { }

  ngOnInit() {
  }
}
