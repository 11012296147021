<form class="cart-promocode"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">
  <mat-form-field class="cart-promocode-field">
    <mat-label>{{ 'CART.PROMOCODE.PROMPT' | transloco }}</mat-label>
    <mat-icon matPrefix
              [svgIcon]="'coupon'"
              color="primary"></mat-icon>
    <input matInput
           type="text"
           [placeholder]="'CART.PROMOCODE.PLACEHOLDER' | transloco"
           [formControlName]="'promocode'" />
    <mat-error *ngIf="promocodeError">{{ 'CART.PROMOCODE.INVALID' | transloco }}</mat-error>
  </mat-form-field>
  <button mat-flat-button
          class="submit-button"
          [color]="'primary'"
          [disabled]="readonly || isLoading"
          type="submit">
    <span *ngIf="canInputPromocode">
      {{ 'CART.PROMOCODE.APPLY' | transloco }}
    </span>
    <span *ngIf="canRemovePromocode">
      {{ 'CART.PROMOCODE.REMOVE' | transloco }}
    </span>
    <!-- <app-loader *ngIf="true || isCheckingPromoCode" [color]="accent"></app-loader> -->
  </button>
</form>
