<mat-form-field class="cart-delivery-location-field">
  <mat-label>{{'CART.ADDRESS' | transloco}}</mat-label>
  <mat-icon matPrefix
            color="primary">pin_drop</mat-icon>
  <input matInput
         type="text"
         [formControl]="addressSearchControl"
         class="form-control"
         #search
         required>
  <mat-error *ngIf="control?.touched && (control?.hasError('required') || addressSearchControl.hasError('required'))">
    {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: ('CART.ADDRESS' | transloco) } }}
  </mat-error>
  <mat-error *ngIf="control?.touched && control?.hasError('validateAddress')">
    {{ 'TODO validateAddress' }}
  </mat-error>
</mat-form-field>
