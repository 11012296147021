<div class="command-list-item-template">
    <div fxLayout="row" fxLayoutAlign="space-between stretch">
        <content-loader class="command-list-item-template-loader" [viewBox]="null">
            <svg:circle cx="10" cy="20" r="8"></svg:circle>
            <svg:rect x="25" y="12" width="250" height="16" />
            <svg:rect x="175" y="40" width="100" height="12" />
        </content-loader>

        <div class="command-list-icon-select" fxLayout="column" fxLayoutAlign="space-evenly end" fxFlex fxLayoutGap="5px">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </div>
    </div>

</div>
