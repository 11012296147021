import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Order } from '@app/core/api-client/models';
import { ConsumptionMode, consumptionModes } from '@app/core/services/menu/models/consumption-mode.model';

@Component({
  selector: 'app-command-detail-delivery',
  templateUrl: './command-detail-delivery.component.html',
  styleUrls: ['./command-detail-delivery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandDetailDeliveryComponent implements OnInit, OnChanges {
  @Input() order: Order;
  @Input() customerDisplayName: string;
  @Input() isReadOnly: boolean;
  @Input() hideComment: boolean = false;
  deliveryDate: Date;

  consumptionMode: ConsumptionMode;

  constructor() { }

  ngOnInit(): void {
    this.consumptionMode = this.order && consumptionModes.find(c => c.type === this.order.consumptionMode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (changes['order']) {
      this.deliveryDate = new Date(this.order.deliveryDate);
    }
  }

}
