import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

// see: https://stackblitz.com/edit/angular-flex-layout-breakpoint?file=app%2Fmy-break-points.module.ts
// BreakpointObserver is also available : https://stackblitz.com/edit/cdk-breakpoint-observer-so?file=app%2Fscreen-type%2Fscreen-type.component.ts
@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  private _alias$: ReplaySubject<string> = new ReplaySubject(1);
  public get alias$(): Observable<string> { return this._alias$.asObservable(); }
  private _changes$: ReplaySubject<MediaChange[]> = new ReplaySubject(1);
  public get changes$(): Observable<MediaChange[]> { return this._changes$.asObservable(); }
  private _isDesktop: boolean;


  constructor(
    private _mediaObserver: MediaObserver,
    private _breakpointObserver: BreakpointObserver
  ) {
    _mediaObserver.asObservable().pipe(
      filter((changes: MediaChange[]) => changes.length > 0)
      , map((changes: MediaChange[]) => {
        // debug
        // console.log(changes);
        return changes[0].mqAlias;
      })
      , distinctUntilChanged()
    ).subscribe(this._alias$);
    _mediaObserver.asObservable().pipe(
      filter((changes: MediaChange[]) => changes.length > 0)
      , distinctUntilChanged()
    ).subscribe(this._changes$);

    this.checkMediaQuery();
  }


  private checkMediaQuery() {
    this._breakpointObserver
      .observe('(min-width:600px)')
      .pipe(map((result) => result.matches))
      .subscribe((desktop) => (this._isDesktop = desktop));
  }

  public isDesktop(): boolean {
    return this._isDesktop;
  }
}
