<mat-expansion-panel #panel
                     [expanded]="panel.expanded || expanded">
  <mat-expansion-panel-header class="header-panel-header">
    <mat-panel-title class="header-panel-title"> {{ order?.processingState?.destination }} </mat-panel-title>
    <mat-panel-description>
      <app-order-preparation-destination-orders-summary [order]="order"
                                                        [orderPreparation]="orderPreparation"></app-order-preparation-destination-orders-summary>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <app-order-preparation-products-recap [orderPreparation]="orderPreparation"
                                        [loadingOrderPreparation]="loadingOrderPreparation"></app-order-preparation-products-recap>
</mat-expansion-panel>
