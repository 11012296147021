<form [formGroup]="deliveryDateForm"
      #form="ngForm">
  <div class="delivery-date-time-title"
       mat-dialog-title>{{ "CART.DELIVERY_DATE_TIME_TITLE" | transloco }}</div>
  <div mat-dialog-content>
    <mat-button-toggle-group class="delivery-date-time-dayselector"
                             name="fontStyle"
                             aria-label="Font Style"
                             [value]="selectedDay"
                             (change)="onSelectDay($event)">
      <mat-button-toggle *ngFor="let pickupDay of pickupDays"
                         [value]="pickupDay.day">
        <!-- <ng-container *ngFor="let dayDiffCount of [dayDiff(currentDate, pickupDay.day)]">
          <span *ngIf="dayDiffCount === 0"
                class="delivery-date-time-dayselector-day">{{ "COMMON.TODAY" | transloco }}</span>
          <span *ngIf="dayDiffCount > 0 && dayDiffCount <=2"
                class="delivery-date-time-dayselector-day">{{ pickupDay.day | translocoDate: { weekday: "long" } }}</span>
          <span *ngIf="dayDiffCount >2"
                class="delivery-date-time-dayselector-day">{{ pickupDay.day | translocoDate: { dateStyle: 'full' } }}</span>
        </ng-container> -->
        <ng-container *ngFor="let dayDiffCount of [dayDiff(currentDate, pickupDay.day)]">
          <ng-container *ngIf="dayDiffCount === 0; else elseTemplate">
            <span class="delivery-date-time-dayselector-day">{{ "COMMON.TODAY" | transloco }}</span>
          </ng-container>
          <ng-template #elseTemplate>
            <span class="delivery-date-time-dayselector-day">{{ pickupDay.day | translocoDate: dateFormatOptions }}</span>
          </ng-template>
        </ng-container>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field class="delivery-date-time-timeselector">
      <mat-select [placeholder]="'CART.DELIVERY_HOUR'| transloco"
                  [value]="selectedDate"
                  formControlName="deliveryDate"
                  (selectionChange)="onSelectTime($event)"
                  [compareWith]="compareDates">
        <mat-option *ngFor="let heure of heuresRetrait"
                    [value]="heure">{{ heure | translocoDate: { timeStyle: 'short' } }}</mat-option>
      </mat-select>
      <mat-error *ngIf="deliveryDateForm.get('deliveryDate').hasError('required')">
        {{ 'COMMON.ERRORS.FIELD_REQUIRED_FORMAT' | transloco : { field: 'CART.DELIVERY_HOUR' | transloco } }}
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions
       fxLayout="row"
       fxLayoutAlign="center center">
    <button class="confirm-button"
            mat-button
            (click)="onValueChanges()"
            mat-flat-button
            color="accent">
      {{ "COMMON.CONFIRM" | transloco }}
    </button>
  </div>
</form>
