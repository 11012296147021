<div
  class="cart-product-detail"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  *ngIf="cartProduct"
>
  <!-- Header-->
  <div class="cart-product-detail-image-container" *ngIf="cartProduct.product.image?.fullUrl">
    <app-header [imageUrl]="cartProduct.product.image?.fullUrl"> </app-header>
  </div>

  <!-- Content-->
  <app-bottom-container fxFlex="stretch">
    <div class="cart-product-detail-content" fxLayout="column">
      <!-- Title & qualités-->
      <div
        class="cart-product-detail-content-header"
        fxLayout="column"
        fxLayoutGap="2px"
      >
        <!-- product title-->
        <div
          class="cart-product-detail-title"
          fxLayout="row"
          fxLayoutAlign="center"
          fxLayoutGap="20px"
        >
          <div>{{ cartProduct.product.name }}</div>
        </div>
      </div>

      <div
        class="cart-product-quantity-actions"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <button
          mat-flat-button
          class="cart-product-quantity-button"
          (click)="onProductRemoved()"
        >
          <mat-icon>remove</mat-icon>
        </button>

        <span class="cart-product-quantity">
          {{ cartProduct.quantity }}
        </span>

        <button
          mat-flat-button
          class="cart-product-quantity-button"
          (click)="onProductAdded()"
          *ngIf="canOrderProduct"
        >
          <mat-icon>add</mat-icon>
        </button>
        <!-- Indisponibilité-->
        <div *ngIf="!isAvailable" class="cart-product-unavailable">{{ "MENU.PRODUCT.UNAVAILABLE" | transloco }}</div>
        <div *ngIf="isAvailable && !canOrderProduct" class="cart-product-unavailable">{{ "MENU.PRODUCT.MAXREACHED" | transloco }}</div>
      </div>

      <!-- Buttons-->
      <div
        class="cart-product-detail-buttons"
        fxlayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <button
          class="full-width"
          mat-flat-button
          color="accent"
          (click)="onDeleteQuantityProduct()"
        >
          {{ "CART.PRODUCT_DELETE" | transloco }}
        </button>
        <button
          class="full-width"
          mat-flat-button
          color="accent"
          (click)="onExit()"
        >
        {{ "COMMON.CLOSE" | transloco }}
        </button>
      </div>
    </div>
  </app-bottom-container>
</div>
