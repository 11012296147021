import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/storage/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalizationState {

  private _activeLang$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(this._storageService.loadActiveLang());

  constructor(
    private _storageService: StorageService
  ) {
    // // init _activeLang$
    // let activeLang = this._storageService.loadActiveLang();
    // if (!activeLang) {
    //   activeLang = GlobalizationHelper.getBrowserLocales(true)[0];
    //   this.storeActiveLang(activeLang);
    // }
    // this._activeLang$ = new BehaviorSubject<string>(activeLang);
    // // end init _activeLang$
  }

  public getActiveLang$() {
    return this._activeLang$.asObservable();
  }

  public getActiveLang() {
    return this._activeLang$.getValue();
  }

  public setActiveLang(language: string) {
    if (language !== this.getActiveLang()) {
      this._activeLang$.next(language);
      this.storeActiveLang(language);
    }
  }

  private storeActiveLang(language: string) {
    this._storageService.storeActiveLang(language);
  }

}
