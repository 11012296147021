import { Injectable } from "@angular/core";
import { Order } from "@app/core/api-client/models";
import { SessionStorageProvider } from "@app/core/storage/session-storage-provider";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StorageMap } from "@ngx-pwa/local-storage";
import endOfDay from "date-fns/endOfDay";
import { concat, first, map, Observable, Subject, shareReplay, tap, filter, of, BehaviorSubject } from "rxjs";

const LastOrderWithPendingPreparation_StorageKey = "LastOrderWithPendingPreparation";
const Destination_StorageKey = "PreparationDestination";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class OrderState {

  private _lastOrderWithPendingPreparationSubject = new Subject<Order | null>();
  private _lastOrderWithPendingPreparation$ = concat(
    this.load$(),
    this._lastOrderWithPendingPreparationSubject.asObservable()
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true }), // on partage toujours la même subscription et on propose la dernière valeur
    filter(order => order == null || endOfDay(new Date(order.deliveryDate)) > new Date()), // on ne garde que les commandes qui sont encore à venir
    untilDestroyed(this)
  );

  private _orderUpdatedSubject = new Subject<Order | null>();

  private _destinationSubject = new BehaviorSubject<string | null>(this._sessionStorage.getString(Destination_StorageKey));
  private _destination$ = this._destinationSubject.asObservable();

  // private _destination$ = concat(
  //   this.loadSessionDestination$(),
  //   this._destinationSubject.asObservable()
  // ).pipe(
  //   shareReplay({ bufferSize: 1, refCount: true }), // on partage toujours la même subscription et on propose la dernière valeur
  //   untilDestroyed(this)
  // );

  constructor(
    private _storage: StorageMap,
    private _sessionStorage: SessionStorageProvider
  ) {
  }

  getOrderUpdated$(): Observable<Order> {
    return this._orderUpdatedSubject.asObservable();
  }

  notifyOrderUpdated(order: Order): void {
    this._orderUpdatedSubject.next(order);
  }

  getLastOrderWithPendingPreparation$(): Observable<Order> {
    return this._lastOrderWithPendingPreparation$;
  }

  setLastOrderWithPendingPreparation$(order: Order): Observable<Order | null> {
    return this._storage.set(LastOrderWithPendingPreparation_StorageKey, order)
      .pipe(
        tap(() => this._lastOrderWithPendingPreparationSubject.next(order)),
        map(() => order)
      );
  }

  private load$(): Observable<Order | null> {
    return this._storage.get(LastOrderWithPendingPreparation_StorageKey)
      .pipe(
        first(),
        map(stored => stored as Order | null)
      );
  }

  setDestination$(destination: string | null): Observable<string | null> {
    this._sessionStorage.setString(Destination_StorageKey, destination);
    this._destinationSubject.next(destination);
    return of(destination);
  }

  // private loadSessionDestination$(): Observable<string | null> {
  //   const dest = this._sessionStorage.getString(Destination_StorageKey);
  //   return of(dest);
  // }

  getDestination$(): Observable<string | null> {
    return this._destination$;
  }

}
