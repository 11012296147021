<div class="product-list">

  <div class="product-list-name"
       *ngIf="showTitle">{{ "CART.TITLE_PRODUCTS" | transloco }}</div>

  <!-- choix produits -->
  <div @inOutAnimation
       *ngFor="let category of displayedCartProductsByCategory;  trackBy: trackByCategoryName; let isLast = last;"
       [ngClass]="{'product-list-category': showCategory}">

    <div class="product-list-category-title"
         *ngIf="showCategory">{{ category.categoryName }}</div>

    <div @inOutAnimation
         *ngFor="let product of category.cartProducts; trackBy: trackByCartProductIdentity; let isLast = last;"
         class="product-list-item">
      <app-cart-product-tile [cartProduct]="product"
                             [currencyCode]="currencyCode"
                             [showZeroPrices]="showZeroPrices"
                             [showDivider]="showDivider && !isLast"
                             (editCartProductClick)="onEditCartProductClick($event)"></app-cart-product-tile>
    </div>

    <mat-divider class="cart-category-divider"
                 *ngIf="showCategory && !isLast"></mat-divider>
  </div>

</div>
