import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { CartProduct } from '@app/core/services/cart/models/cart.model';
import { Product } from '@app/core/api-client/models';

@Component({
  selector: 'app-cart-product-tile',
  templateUrl: './cart-product-tile.component.html',
  styleUrls: ['./cart-product-tile.component.scss']
})
export class CartProductTileComponent implements OnInit {
  @Input() cartProduct: CartProduct;
  @Input() currencyCode: string | null;
  @Input() showZeroPrices: boolean = true;
  @Input() showDivider: boolean = true;

  @Output() editCartProductClick = new EventEmitter<CartProduct>();

  constructor() {}

  ngOnInit(): void {}

  onEditCartProductClick(){
    this.editCartProductClick.emit(this.cartProduct);
  }
}
