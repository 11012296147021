import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from '@app/shared/services/dialog.service';
import { StaticFileService } from '@app/shared/services/static-file.service';

@Component({
  selector: 'app-cart-confirm',
  templateUrl: './cart-confirm.component.html',
  styleUrls: ['./cart-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartConfirmComponent implements OnInit {
  @Input() cartConfirmForm: UntypedFormGroup;
  @Input() total: number;
  @Input() inProgress: boolean;
  @Input() currencyCode: string | null;
  @Input() showPrice: boolean = false;
  @Output() confirmCart = new EventEmitter();

  constructor(
    private _dialog: MatDialog,
    private _translateService: TranslocoService,
    private _dialogService: DialogService,
    private _staticFileService: StaticFileService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  openLegalDialog(): void {
    this._staticFileService
      .getLegalPage(this._translateService.getActiveLang())
      .subscribe(result =>
        this._dialogService.openDialogFromHtmlPage(
          this._dialog,
          result,
          'LEGAL_NOTICE.TITLE'
        )
      );
  }

  onConfirmClick() {
    this.confirmCart.emit();
  }

  private createForm() {
    // this.cartConfirmForm.addControl(
    //   'legalNotice',
    //   new FormControl(null, Validators.requiredTrue)
    // );
  }
}
