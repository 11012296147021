import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parse as parseUrl } from 'url';
import { Logger } from '../logging';

export type RoutesMatches = Array<string | RegExp | ((request: HttpRequest<any>) => boolean)>;

const acceptAllRequestsInterceptorOptions: HttpInterceptorOptions = {
  whitelistedRoutes: [(req) => true],
  blacklistedRoutes: [(req) => false]
};

export abstract class BaseHttpInterceptor implements HttpInterceptor {

  private _options: HttpInterceptorOptions;

  constructor(
    protected _log: Logger
  ) {
    this._options = this.configureInterceptorOptions() || acceptAllRequestsInterceptorOptions;
  }

  abstract configureInterceptorOptions(): HttpInterceptorOptions;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.canIntercept(req)) {
      // this._log.debug(`intercept ${req.url}`);
      return this.handleInterception(req, next);
    } else {
      // this._log.debug(`bypass ${req.url}`);
      return next.handle(req);
    }
  }

  private canIntercept(request: HttpRequest<any>): boolean {
    return this.isWhitelistedDomain(request)
      && !this.isBlacklistedRoute(request);
  }

  protected abstract handleInterception(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;

  private isBlacklistedRoute(request: HttpRequest<any>): boolean {
    return this.checkRoutesMatches(this._options.blacklistedRoutes, request);
  }

  private isWhitelistedDomain(request: HttpRequest<any>): boolean {
    return this.checkRoutesMatches(this._options.whitelistedRoutes, request);
  }

  private checkRoutesMatches(routesConfig: RoutesMatches, request: HttpRequest<any>): boolean {
    if (!routesConfig) {
      return true;
    }

    for (const routeMatchItem of routesConfig) {

      if (typeof routeMatchItem === 'string') {
        // check on host (if not null) and path
        const requestUrl = parseUrl(request.url);
        const routeMatchItemUrl = parseUrl(routeMatchItem as string);

        if ((routeMatchItemUrl.host === null || routeMatchItemUrl.host === requestUrl.host)
          && (routeMatchItemUrl.path === null || this.sanitizeUrlPath(routeMatchItemUrl.path) === this.sanitizeUrlPath(requestUrl.path))) {
          return true;
        }
      } else if (routeMatchItem instanceof RegExp) {
        // match regex on full url
        if (routeMatchItem.test(request.urlWithParams)) {
          return true;
        }
      } else if (typeof routeMatchItem === 'function') {
        // match with callback function
        if (routeMatchItem(request) === true) {
          return true;
        }
      }
    }

    return false;
  }

  private sanitizeUrlPath(urlPath: string): string {
    urlPath = urlPath.trim();
    if (urlPath.startsWith('./')) {
      urlPath = urlPath.substring(1);
    }
    return urlPath;
  }

}



export interface HttpInterceptorOptions {
  whitelistedRoutes?: RoutesMatches;
  blacklistedRoutes?: RoutesMatches;
}
