<div class="forgot-password-page mat-typography custom-input"
     fxLayout="column"
     fxLayoutAlign="space-between stretch">
  <!-- Signin page Header-->
  <div class="forgot-password-page-header"
       [ngClass.gt-xs]="{'full-size': true}"
       fxFlex>
    <!-- Header-->
    <app-header imageUrl="./assets/imgs/backgrounds/signin.jpg">
      <!-- back button-->
      <app-back-button [stopNavigation]="true"
                       (goBack)="cancel()"></app-back-button>
    </app-header>
  </div>

  <!-- form container-->
  <div class="forgot-password-container"
       [ngClass.gt-xs]="{'centered': true}">
    <app-forgot-password (forgotPassword)="forgotPassword($event)"
                         (cancel)="cancel()"
                         [submitting]="submitting"></app-forgot-password>
  </div>
</div>
