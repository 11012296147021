declare var LZString: any; // Je n'ai pas réussi à faire fonctionner les définitions de type :-(

export interface IStorageProvider {
  setString(key: string, val: string): void;
  getString(key: string): string;
  setObject(key: string, val: any): void;
  getObject(key: string): any;
  removeItem(key: string): void;
  clear(): void;
}

export class StorageProvider implements IStorageProvider {

  constructor(private _storage: Storage) { }

  shouldCompress = false;
  compressPrefix = 'secure;';
  setString(key: string, val: string): void {
    if (val) {
      if (this.shouldCompress) {
        val = this.compressPrefix + LZString.compressToUTF16(val);
      }
      this._storage.setItem(key, val);
    } else {
      this.removeItem(key);
    }
  }

  getString(key: string): string {
    let data = this._storage.getItem(key);

    if (data) {
      // on essaie de décompresser la donnée
      if (data.startsWith(this.compressPrefix)) {
        try {
          data = data.substring(this.compressPrefix.length);
          data = LZString.decompressFromUTF16(data);
          if (!this.shouldCompress) {
            this.setString(key, data);
          }
        } catch (error) {
          // silent fail
        }
      } else {
        if (this.shouldCompress) {
          // La donnée n'est pas compressée, on la compresse
          this.setString(key, data);
        }
      }
    }

    return data;
  }

  setObject(key: string, val: any): void {
    if (val) {
      const data = JSON.stringify(val);
      this.setString(key, data);
    } else {
      this.removeItem(key);
    }
  }

  getObject(key: string): any {
    const data = this.getString(key);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  removeItem(key: string): void {
    this._storage.removeItem(key);
  }

  clear(): void {
    this._storage.clear();
  }
}
