
export class GlobalizationHelper {

  static getBrowserLocales(languageCodeOnly: boolean = false): string[] {
    // adapted from https://phrase.com/blog/posts/detecting-a-users-locale/
    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      return languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }

}
