import { Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';
import { StorageKeys } from './storage-keys';
import { OauthToken } from '../authentication/models/authentication-token.model';
import { Cart } from '../services/cart/models/cart.model';
import { Restaurant } from '../api-client/models';

// TODO voir pourquoi on a pas utilisé @ngx-pwa/local-storage ?

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storageProvider: StorageProvider) { }

  /// Token
  public storeToken(token: OauthToken) {
    this.storageProvider.setObject(StorageKeys.token, token);
  }

  public loadStoredToken(): OauthToken | null {
    return this.storageProvider.getObject(StorageKeys.token) as OauthToken;
  }

  /// cart
  public storeCart(cart?: Cart) {
    this.storageProvider.setObject(
      StorageKeys.cart.replace('{restaurantId}', cart?.restaurantId?.toString()),
      cart
    );
  }

  public loadStoredCart(restaurantKey: string): Cart | null {
    return this.storageProvider.getObject(StorageKeys.cart.replace('{restaurantId}', restaurantKey)) as Cart;
  }

  public storeCurrentRestaurant(restaurant: Restaurant) {
    this.storageProvider.setObject(StorageKeys.restaurant, restaurant);
  }

  public loadCurrentRestaurant(): Restaurant | null {
    return this.storageProvider.getObject(StorageKeys.restaurant) as Restaurant;
  }

  public storeActiveLang(language: string) {
    this.storageProvider.setString(StorageKeys.language, language);
  }

  public loadActiveLang(): string | null {
    return this.storageProvider.getString(StorageKeys.language);
  }

}
