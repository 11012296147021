<div class="app-bar">
  <div class="bar-content"
       fxLayout="row"
       fxLayoutAlign="space-around stretch">
    <app-bar-item *ngFor="let barItem of state.barItems"
                  [svgIcon]='barItem.svgIcon'
                  [routerLink]="barItem.routerLink && [barItem.routerLink]"
                  (click)="barItem.onClick && barItem.onClick()"
                  [badgeValue]="barItem.badgeValue$ | async"
                  [badgeColor]="barItem.badgeColor"
                  routerLinkActive="router-link-active">
    </app-bar-item>

  </div>
</div>
