import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Order } from '@app/core/api-client/models';
import { CommandsHelper, CommandStatus } from '../../helpers/commands-helper';

@Component({
  selector: 'app-command-detail-state',
  templateUrl: './command-detail-state.component.html',
  styleUrls: ['./command-detail-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandDetailStateComponent implements OnInit {

  @Input() order: Order;

  commandStatus: CommandStatus;

  constructor() { }

  ngOnInit(): void {
    this.commandStatus = this.order && CommandsHelper.getCommandStatutLabel(this.order.status);
  }

  getStatutLabel(key: any) {
    return CommandsHelper.getCommandStatutLabel(key);
  }
}
