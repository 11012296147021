import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/analytics/analytics-service';
import { AnalyticsEvent } from '@app/core/analytics/models/analytics-event.model';
import { Product, Step } from '@app/core/api-client/models';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { RouteDataModel } from '@app/core/models/route-data.model';
import { ActivatedRouteData, TitleService } from '@app/core/ui/title/title.service';
import { asapScheduler } from 'rxjs';
import { filter, map, observeOn, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartProduct } from '../cart/models/cart.model';
import { CartState } from '../cart/state/cart.state';
import { MenuState } from '../menu/state/menu.state';
import { SiteState } from '../site/state/site.state';
import { BaseMenuEvent, ProductAddedToCart, ProductOpened, ProductQuantityUpdated, ProductRemovedFromCart, ProductSearch } from './models/analytic-events';

@Injectable({
  providedIn: 'root'
})
export class AppAnalyticsService {

  constructor(
    private _siteState: SiteState,
    private _menuState: MenuState,
    private _cartState: CartState,
    private _authenticationService: AuthenticationService,
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _titleService: TitleService
  ) {

    // On s'abonne au router pour les pageView et au UserId$
    this.subscribeToRouter();
    this.subscribeToUserSignin();
  }

  private subscribeToRouter() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        observeOn(asapScheduler),
        map(event => event as NavigationEnd),
        switchMap(event =>
          this._titleService.getActivatedRouteData$(this._router.routerState.snapshot.root) // or use ActivatedRoute service
            .pipe(
              map<ActivatedRouteData, [NavigationEnd, ActivatedRouteData]>(routeData => ([event, routeData]))
            )
        ),
      )
      .subscribe(([event, routeData]) => {
        this._analyticsService.trackUrlChange({
          url: event.urlAfterRedirects,
          pageTitle: routeData.pageAnalyticsTitle
        });
      });
  }

  private subscribeToUserSignin() {
    this._authenticationService.currentUser$
      .subscribe(user => {
        this._analyticsService.setUserId(user?.userId?.toString());
      });
  }

  private getBaseMenuEvent(): BaseMenuEvent {
    const restaurant = this._siteState.getCurrentRestaurant();
    const site = restaurant?.site;
    const menu = this._menuState.getCurrentMenu();

    const menuName = menu && `${menu?.menuId}`; // TODO add menuName
    const restaurantName = restaurant && `${site.name}(${site.siteId})/${restaurant.name}(${restaurant.restaurantId})`;
    return {
      isUserRegistered: !!this._authenticationService.currentUser,
      menuName: menuName,
      restaurant: restaurantName
    };
  }

  private dumpCartProduct(cartProduct: CartProduct): { [key: string]: any } {
    function dumpProduct(product: Partial<Product>) {
      return product && { productId: product.productId, name: product.name };
    }
    function dumpStep(step: Partial<Step>) {
      return step && { stepId: step.stepId, name: step.name };
    }
    return {
      product: dumpProduct(cartProduct.product),
      steps: cartProduct.steps
        && cartProduct.steps.map(s => ({
          step: dumpStep(s.step),
          products: s.products.map(p => this.dumpCartProduct(p))
        })
        )
    };
  }

  trackProductOpenedEvent(event: ProductOpened) {
    const ev: AnalyticsEvent = {
      eventName: 'ProductOpened',
      category: 'Menu',
      isUserInteraction: true,
      metricValue: 1,
      ...event,
      ...this.getBaseMenuEvent()
    };
    this._analyticsService.trackEvent(ev);
  }

  trackProductAddedToCartEvent(event: ProductAddedToCart) {
    const ev: AnalyticsEvent = {
      eventName: 'ProductAddedToCart',
      category: 'Menu',
      isUserInteraction: true,
      metricValue: 1,
      productName: event.product.product.name,
      ...event,
      product: this.dumpCartProduct(event.product),
      ...this.getBaseMenuEvent()
    };
    this._analyticsService.trackEvent(ev);
  }

  trackProductRemovedFromCartEvent(event: ProductRemovedFromCart) {
    const ev: AnalyticsEvent = {
      eventName: 'ProductRemovedFromCart',
      category: 'Menu',
      isUserInteraction: true,
      metricValue: 1,
      productName: event.product.product.name,
      ...event,
      product: this.dumpCartProduct(event.product),
      ...this.getBaseMenuEvent()
    };
    this._analyticsService.trackEvent(ev);
  }

  trackProductQuantityUpdatedEvent(event: ProductQuantityUpdated) {
    const ev: AnalyticsEvent = {
      eventName: 'ProductQuantityUpdated',
      category: 'Menu',
      isUserInteraction: true,
      metricValue: event.delta,
      productName: event.product.product.name,
      ...event,
      product: this.dumpCartProduct(event.product),
      ...this.getBaseMenuEvent()
    };
    this._analyticsService.trackEvent(ev);
  }

  trackProductSearchEvent(event: ProductSearch) {
    const ev: AnalyticsEvent = {
      eventName: 'ProductSearch',
      category: 'Menu',
      isUserInteraction: true,
      metricValue: 1,
      ...event,
      ...this.getBaseMenuEvent()
    };
    this._analyticsService.trackEvent(ev);
  }

}
