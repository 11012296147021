import { Category, Menu } from "@app/core/api-client/models";
import { CartProduct } from "./cart.model";
import _groupBy from 'lodash-es/groupBy';
import _keyBy from 'lodash-es/keyBy';

export interface CategoryCartProducts { category: Category | null, cartProducts: CartProduct[] }

export class CartModelHelper {

  /**
   * Reorganize products by root category according categories position
   */
  public static GroupCartProductsByRootCategory(cartProducts: CartProduct[], menu: Menu): CategoryCartProducts[] {
    //group products under its first matched rootCategory (a product can be referenced in multiple category)

    const rootCategoryProducts: { rootCategory: Category | null, cartProducts: CartProduct[] }[] = [];
    //optimize cartProduct lookup
    const cartProductsById = _groupBy(cartProducts, c => c.product.productId.toString());

    const visitCategory = function (category: Category, rootCategory) {
      for (const categoryProduct of category.products) { // category.products is already ordered from server
        const productId = categoryProduct.productId.toString();
        const cartProducts = cartProductsById[productId];
        if (cartProducts) {
          rootCategoryProducts.push({ rootCategory, cartProducts });
          //skip all further categories for these products
          delete cartProductsById[productId];
        }
      }
      //visit children categories
      const subCategories = menu.categories
        .filter(c => c.parentCategoryId === category.categoryId)
        .sort((a, b) => a.position - b.position);

      for (const subCategory of subCategories) {
        visitCategory(subCategory, rootCategory);
      }
    }

    const rootCategories = menu.categories
      .filter(c => c.parentCategoryId == null)
      .sort((a, b) => a.position - b.position);

    for (const category of rootCategories) {
      visitCategory(category, category);
    }

    //append remaining products not categorized under a category (cutlery / ReturnableContainerProducts...)
    for (const [productId, cartProducts] of Object.entries(cartProductsById)) {
      rootCategoryProducts.push({ rootCategory: null, cartProducts });
    }

    //group entries by Category
    const productsByRootCategory = rootCategoryProducts.reduce<CategoryCartProducts[]>((prev, current) => {
      const entry = prev.find(c => c.category === current.rootCategory);
      if (entry) {
        entry.cartProducts.push(...current.cartProducts);
      } else {
        prev.push({ category: current.rootCategory, cartProducts: [...current.cartProducts] });
      }
      return prev;
    }, []);

    return productsByRootCategory;

    //const categoryProducts = menu.categories.reduce<CategoryProduct[]>((prev, current) => [...prev, ...current.products], []);
    // const productsByRootCategoryId = _groupBy(cart.products, p => MenuModelHelper.getRootCategory(menu.categories.find(c => c.categoryId === p.product.), menu).categoryId.toString())
    // return Object.keys(productsByRootCategoryId)
    //   .map(categoryIdString => ({ category: menu.categories.find(c => c.categoryId.toString() === categoryIdString), cartProducts: productsByRootCategoryId[categoryIdString] }))
    //   .sort((a, b) => a.category.position - b.category.position);
  }

}
