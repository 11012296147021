/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomerBalance } from '../models/customer-balance';
import { CustomerPaymentMethod } from '../models/customer-payment-method';
import { DeleteCustomerPaymentMethod$Json } from '../fn/payment/delete-customer-payment-method-json';
import { DeleteCustomerPaymentMethod$Json$Params } from '../fn/payment/delete-customer-payment-method-json';
import { DeleteCustomerPaymentMethod$Plain } from '../fn/payment/delete-customer-payment-method-plain';
import { DeleteCustomerPaymentMethod$Plain$Params } from '../fn/payment/delete-customer-payment-method-plain';
import { DeleteCustomerPaymentMethodResult } from '../models/delete-customer-payment-method-result';
import { GetCustomerBalance$Json } from '../fn/payment/get-customer-balance-json';
import { GetCustomerBalance$Json$Params } from '../fn/payment/get-customer-balance-json';
import { GetCustomerBalance$Plain } from '../fn/payment/get-customer-balance-plain';
import { GetCustomerBalance$Plain$Params } from '../fn/payment/get-customer-balance-plain';
import { GetCustomerPaymentMethods$Json } from '../fn/payment/get-customer-payment-methods-json';
import { GetCustomerPaymentMethods$Json$Params } from '../fn/payment/get-customer-payment-methods-json';
import { GetCustomerPaymentMethods$Plain } from '../fn/payment/get-customer-payment-methods-plain';
import { GetCustomerPaymentMethods$Plain$Params } from '../fn/payment/get-customer-payment-methods-plain';

@Injectable({ providedIn: 'root' })
export class ApiPaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPaymentCustomerpaymentmethods()` */
  static readonly GetPaymentCustomerpaymentmethodsPath = '/api/Payment/customerPaymentMethods';

  /**
   * get-payment-customerpaymentmethods.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCustomerPaymentMethods$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerPaymentMethods$Plain$Response(params?: GetCustomerPaymentMethods$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerPaymentMethod>>> {
    return GetCustomerPaymentMethods$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-payment-customerpaymentmethods.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetCustomerPaymentMethods$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerPaymentMethods$Plain(params?: GetCustomerPaymentMethods$Plain$Params, context?: HttpContext): Observable<Array<CustomerPaymentMethod>> {
    return this.GetCustomerPaymentMethods$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerPaymentMethod>>): Array<CustomerPaymentMethod> => r.body)
    );
  }

  /**
   * get-payment-customerpaymentmethods.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCustomerPaymentMethods$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerPaymentMethods$Json$Response(params?: GetCustomerPaymentMethods$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerPaymentMethod>>> {
    return GetCustomerPaymentMethods$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-payment-customerpaymentmethods.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetCustomerPaymentMethods$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerPaymentMethods$Json(params?: GetCustomerPaymentMethods$Json$Params, context?: HttpContext): Observable<Array<CustomerPaymentMethod>> {
    return this.GetCustomerPaymentMethods$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerPaymentMethod>>): Array<CustomerPaymentMethod> => r.body)
    );
  }

  /** Path part for operation `getPaymentPaymentmethodByPaymentmethodidBalance()` */
  static readonly GetPaymentPaymentmethodByPaymentmethodidBalancePath = '/api/Payment/paymentMethod/{paymentMethodId}/balance';

  /**
   * get-payment-paymentmethod-by-paymentmethodid--balance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCustomerBalance$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerBalance$Plain$Response(params: GetCustomerBalance$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBalance>> {
    return GetCustomerBalance$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * get-payment-paymentmethod-by-paymentmethodid--balance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetCustomerBalance$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerBalance$Plain(params: GetCustomerBalance$Plain$Params, context?: HttpContext): Observable<CustomerBalance> {
    return this.GetCustomerBalance$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBalance>): CustomerBalance => r.body)
    );
  }

  /**
   * get-payment-paymentmethod-by-paymentmethodid--balance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetCustomerBalance$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerBalance$Json$Response(params: GetCustomerBalance$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBalance>> {
    return GetCustomerBalance$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * get-payment-paymentmethod-by-paymentmethodid--balance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetCustomerBalance$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetCustomerBalance$Json(params: GetCustomerBalance$Json$Params, context?: HttpContext): Observable<CustomerBalance> {
    return this.GetCustomerBalance$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBalance>): CustomerBalance => r.body)
    );
  }

  /** Path part for operation `deletePaymentCustomerpaymentmethodByCustomerpaymentmethodid()` */
  static readonly DeletePaymentCustomerpaymentmethodByCustomerpaymentmethodidPath = '/api/Payment/customerPaymentMethod/{customerPaymentMethodId}';

  /**
   * delete-payment-customerpaymentmethod-by-customerpaymentmethodid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteCustomerPaymentMethod$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteCustomerPaymentMethod$Plain$Response(params: DeleteCustomerPaymentMethod$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCustomerPaymentMethodResult>> {
    return DeleteCustomerPaymentMethod$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * delete-payment-customerpaymentmethod-by-customerpaymentmethodid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteCustomerPaymentMethod$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteCustomerPaymentMethod$Plain(params: DeleteCustomerPaymentMethod$Plain$Params, context?: HttpContext): Observable<DeleteCustomerPaymentMethodResult> {
    return this.DeleteCustomerPaymentMethod$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCustomerPaymentMethodResult>): DeleteCustomerPaymentMethodResult => r.body)
    );
  }

  /**
   * delete-payment-customerpaymentmethod-by-customerpaymentmethodid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteCustomerPaymentMethod$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteCustomerPaymentMethod$Json$Response(params: DeleteCustomerPaymentMethod$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteCustomerPaymentMethodResult>> {
    return DeleteCustomerPaymentMethod$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * delete-payment-customerpaymentmethod-by-customerpaymentmethodid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteCustomerPaymentMethod$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteCustomerPaymentMethod$Json(params: DeleteCustomerPaymentMethod$Json$Params, context?: HttpContext): Observable<DeleteCustomerPaymentMethodResult> {
    return this.DeleteCustomerPaymentMethod$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteCustomerPaymentMethodResult>): DeleteCustomerPaymentMethodResult => r.body)
    );
  }

}
