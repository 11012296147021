<div fxLayout="row"
     fxLayoutGap="10px">

  <app-place-selector class="top-bar-site"
                      (placeEditEvent)="onPlaceSectionClick($event)"
                      [iconColor]="iconColor"></app-place-selector>

  <ng-container *ngIf="isLoading; else dateSelectorTemplate">
    <mat-divider [vertical]="true"></mat-divider>
    <app-delivery-date-selector [isLoading]="true"
                                [iconColor]="iconColor"></app-delivery-date-selector>
  </ng-container>
  <ng-template #dateSelectorTemplate>
    <ng-container *ngIf="orderingIsOpen">
      <mat-divider [vertical]="true"></mat-divider>
      <app-delivery-date-selector [deliveryDate]="deliveryDate"
                                  (deliveryDateChange)="onDeliveryDateChange($event)"
                                  [iconColor]="iconColor"></app-delivery-date-selector>
    </ng-container>
  </ng-template>

</div>
