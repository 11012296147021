import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoConfig
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { GlobalizationHelper } from './core/services/globalization/globalization-helper';
import { GlobalizationState } from './core/services/globalization/globalization.state';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${environment.appVersion}`); // force cache burst by version
  }
}


function getDefaultLang(defaultLangs: string[], translocoAvailableLangs: string[]): string {
  // get first browser locale matching availableLangs
  for (const locale of defaultLangs) {
    if (translocoAvailableLangs.includes(locale)) {
      return locale;
    }
  }
  return translocoAvailableLangs[0];
}

export function translocoConfigFactory(globalizationState: GlobalizationState): TranslocoConfig {
  if (!globalizationState) {
    throw new Error('globalizationState not loaded');
  }

  const storedLang = globalizationState.getActiveLang();
  const browserLangs = GlobalizationHelper.getBrowserLocales(true);
  const defaultLangs = storedLang ? [storedLang, ...browserLangs] : browserLangs;

  const translocoAvailableLangs = ['fr', 'en'];
  const defaultLang = getDefaultLang(defaultLangs, translocoAvailableLangs);

  return translocoConfig({
    availableLangs: translocoAvailableLangs,
    defaultLang: defaultLang,
    fallbackLang: 'fr',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production,
  });
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: translocoConfigFactory,
      deps: [GlobalizationState]
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
