import { Injectable } from '@angular/core';
import { Theme } from '../models/theme';
import { themeRed } from '../themes/themeRed';
import { themeGreen } from '../themes/themeGreen';
import { themeBrigade } from '../themes/themeBrigade';
import { themeOrange } from '../themes/themeOrange';
import { themeBlue } from '../themes/themeBlue';
import { themeKaki } from '../themes/themeKaki';
import { themeKakiDark } from '../themes/themeKakiDark';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const DEFAULT_THEME = themeGreen;

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private _activeThemeSubject$ = new BehaviorSubject<Theme>(DEFAULT_THEME);
  private _availableThemes: Theme[] = [themeBlue, themeRed, themeGreen, themeOrange, themeBrigade, themeKaki, themeKakiDark];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) { }

  getAvailableThemes(): Theme[] {
    return this._availableThemes.slice();
  }

  resolveDefaultTheme() {
    // seek class on body element
    const resolvedDefaultTheme = this._availableThemes.find(t => this._document.body.classList.contains(t.name));

    // if (resolvedDefaultTheme) {
    //   console.log(`theme resolved : '${resolvedDefaultTheme.name}'`);
    // }
    // else {
    //   console.log(`fallback to default theme : '${DEFAULT_THEME.name}'`);
    // }

    this.setActiveTheme(resolvedDefaultTheme || DEFAULT_THEME);
  }

  getActiveTheme$(): Observable<Theme> {
    return this._activeThemeSubject$.asObservable();
  }

  getActiveTheme(): Theme {
    return this._activeThemeSubject$.getValue();
  }

  setActiveTheme(theme: Theme): void {

    // console.log(`activate theme '${theme.name}'`);
    this._activeThemeSubject$.next(theme);

    Object.keys(theme.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        theme.properties[property]
      );
    });
  }

  setNextTheme(): void {
    const activeTheme = this.getActiveTheme() || DEFAULT_THEME;
    const nextIndex = (this._availableThemes.indexOf(activeTheme) + 1) % this._availableThemes.length;
    this.setActiveTheme(this._availableThemes[nextIndex]);
  }

}
