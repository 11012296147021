import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '@app/core/services/order/order.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './payment-callback-page.component.html',
  styleUrls: ['./payment-callback-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCallbackPageComponent implements OnInit {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _orderService: OrderService
  ) { }

  ngOnInit(): void {
    const route = this._activatedRoute.snapshot;
    const orderId = parseInt(route.params.orderId, 10);
    this.processOrder$(orderId).subscribe();
  }


  private processOrder$(orderId: number) {

    return this._orderService.getOrder(orderId)
      .pipe(
        first(),
        switchMap(order => this._orderService.processOrderActions(order)),
        // untilDestroyed(this) //ne pas canceller si la page est fermée (puisque l'on redirige vers la page de menu via processOrderActions )
      );

  }
}
