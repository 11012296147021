import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, forwardRef, Injectable, Injector, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { CartPayment, CustomerPaymentMethod } from '@app/core/api-client/models';
import { extractTouchedChanges } from '@app/core/helpers/forms-helper';
import { ModelsHelper } from '@app/core/helpers/models-helper';
import { PaymentService } from '@app/core/services/payment/payment.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import _isEqual from 'lodash-es/isEqual';

function paymentEquals(paymentA: CartPayment | CustomerPaymentMethod, paymentB: CartPayment | CustomerPaymentMethod): boolean {
  return paymentA == paymentB
    || (
      // same paymentMethodId
      paymentA?.paymentMethodId === paymentB?.paymentMethodId
      // non strict equality here to allow null/undefined comparison on customerPaymentMethodId
      && paymentA?.customerPaymentMethodId == paymentB?.customerPaymentMethodId
    )
}

@UntilDestroy()
@Component({
  selector: 'app-cart-payment',
  templateUrl: './cart-payment.component.html',
  styleUrls: ['./cart-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, //Commented to allow recheck of "control.touched" when form is markedAsTouched
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CartPaymentComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CartPaymentComponent),
      multi: true,
    }
  ],
  animations: [
    trigger(
      'errorInOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
        ])
      ]
    )
  ]
})
export class CartPaymentComponent implements OnInit, Validator, ControlValueAccessor, AfterViewInit, OnChanges {
  @Input() readonly: boolean;
  @Input() selectedPaymentMethod: CartPayment;
  @Output() selectedPaymentMethodChange = new EventEmitter<CartPayment>();

  customerPaymentMethods$: Observable<CustomerPaymentMethod[]> = this._paymentService.getCustomerPaymentMethods();
  paymentMethodsTranslations$ = this._translationService.selectTranslateObject('PAYMENT_METHOD');


  private _controlValueAccessorOnChange: (_: any) => void = null;
  private _controlValueAccessorOnTouched: (_: any) => void = null;
  private _validatorChange: (_: any) => void = null;


  // observable errors for internal use
  errors$ = new BehaviorSubject<ValidationErrors>({});

  // // ControlValueAccessor hack to get FormControl instance. see https://stackoverflow.com/a/44732530
  // @Input() formControl: FormControl;
  // @Input() formControlName: string;

  control: AbstractControl;

  constructor(
    private _paymentService: PaymentService,
    private _translationService: TranslocoService,
    // @Optional() @Host() @SkipSelf() private _controlContainer: ControlContainer // see https://stackoverflow.com/a/44732530
    private _injector: Injector,
    private _cd: ChangeDetectorRef,
  ) { }



  ngOnInit(): void {
    this.customerPaymentMethods$
      .pipe(
        filter(customerPaymentMethods => customerPaymentMethods != null), // apply only if customerPaymentMethods are loaded
        untilDestroyed(this)
      )
      .subscribe((customerPaymentMethods) => {

        if (this.selectedPaymentMethod) {
          // retrieve matching customerPaymentMethod from pre-selectedPaymentMethod
          const matchingCustomerPaymentMethod = customerPaymentMethods?.find(cpm => paymentEquals(cpm, this.selectedPaymentMethod));

          this.selectPaymentMethod(matchingCustomerPaymentMethod);
        }

        if (!this.selectedPaymentMethod
          && customerPaymentMethods?.length === 1) {
          // if not pre-selectedPaymentMethod and there exists only one customerPaymentMethod, then select it by default
          this.selectPaymentMethod(customerPaymentMethods[0]);
        }

      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.

    // if (changes['selectedPaymentMethod']) {
    //   console.log('selectedPaymentMethod changed from ', changes['selectedPaymentMethod'].previousValue, ' to ', changes['selectedPaymentMethod'].currentValue);
    // }
  }

  selectPaymentMethod(paymentMethod: CustomerPaymentMethod | null) {
    const cartPayment: CartPayment = paymentMethod && {
      customerPaymentMethodId: paymentMethod.customerPaymentMethodId,
      paymentMethodId: paymentMethod.paymentMethodId
    };

    const valueHasChanged = !_isEqual(this.selectedPaymentMethod, cartPayment); // deep compare

    if (valueHasChanged) {
      this.selectedPaymentMethod = cartPayment;
      this.selectedPaymentMethodChange.emit(cartPayment);

      this._controlValueAccessorOnChange?.call(this, cartPayment);
      this._controlValueAccessorOnTouched?.call(this);

      this._cd.markForCheck();
    }
  }

  isSelected(paymentMethod: CustomerPaymentMethod): boolean {
    return paymentEquals(paymentMethod, this.selectedPaymentMethod);
  }

  //#region ControlValueAccessor implementation
  // see : https://blog.angular-university.io/angular-custom-form-controls/#understandingthecontrolvalueaccessorinterface

  writeValue(obj: any): void {
    if ((obj === null || ModelsHelper.isCartPayment(obj))
      && !paymentEquals(obj, this.selectedPaymentMethod)) {
      this.selectedPaymentMethod = obj;
    }
  }
  registerOnChange(fn: (_: any) => void): void {
    this._controlValueAccessorOnChange = fn;
  }
  registerOnTouched(fn: (_: any) => void): void {
    this._controlValueAccessorOnTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  //#endregion

  //#region ControlValueAccessor hack to get FormControl instance
  // see https://stackoverflow.com/a/44732530

  // private getControl(): AbstractControl {
  //   return this.formControl || this._controlContainer.control.get(this.formControlName);
  // }

  ngAfterViewInit(): void {
    // resolve formControl for this instance, using injection AfterViewInit.
    // see: https://stackoverflow.com/a/51126965

    this.control = this._injector.get(NgControl, null)?.control;
    if (this.control) {
      // update component state if FormControl is touched (from Form submition)
      // this.control.statusChanges
      extractTouchedChanges(this.control)
        .pipe(
          untilDestroyed(this),
          tap(() => this._cd.markForCheck())
        )
        .subscribe();

    } else {
      // Component is missing form control binding
    }
  }

  //#endregion


  //#region Validator implementation
  // see : https://blog.angular-university.io/angular-custom-form-controls/#introductiontothevalidatorinterface

  validate(control: AbstractControl): ValidationErrors {
    const errors = !this.selectedPaymentMethod && {
      required: this._translationService.translate('CART.PAYMENT_ERROR_REQUIRED')
    };

    // notify error for internal use
    this.errors$.next(errors);

    return errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._validatorChange = fn;
  }

  //#endregion

}
