import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
// import { CommonModule, Location } from '@angular/common';
import { AnalyticsService } from './analytics-service';
import { Router } from '@angular/router';
// import { AnalyticsConfigurationParams, AnalyticsConfiguration } from './analytics-configuration';



@NgModule({
  declarations: [],
  imports: [
    // CommonModule
  ],
  providers: [
    AnalyticsService
  ]
})
export class AnalyticsModule {

  // static forRoot(params: AnalyticsConfigurationParams): ModuleWithProviders<AnalyticsModule> {
  //   return {
  //     ngModule: AnalyticsModule,
  //     providers: [
  //       {
  //         provide: AnalyticsConfiguration,
  //         useValue: params
  //       }
  //     ]
  //   };
  // }

  constructor(@Optional() @SkipSelf() parentModule: AnalyticsModule) {
    if (parentModule) {
      throw new Error('AnalyticsModule is already loaded. Import in your base AppModule only.');
    }

  }
}
