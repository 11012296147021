import { transition, trigger } from '@angular/animations';
import { slideDownAnimation } from './animations/slide-down.animation';
import { slideLeftAnimation } from './animations/slide-left.animation';
import { slideRightAnimation } from './animations/slide-right.animation';
import { slideUpAnimation } from './animations/slide-up.animation';

export const routeAnimations = trigger('routeAnimations', [
  // Attention! l'ordre est important!!

  // Welcome
  transition('welcome => *', slideLeftAnimation),
  transition('* => welcome', slideRightAnimation),

  // Signin
  transition('signin => forgotPassword', slideLeftAnimation),
  transition('forgotPassword => signin', slideRightAnimation),
  transition('signin => resetPassword', slideLeftAnimation),
  transition('resetPassword => signin', slideRightAnimation),
  transition('signin => register', slideLeftAnimation),
  transition('signin => *', slideLeftAnimation),

  // Register
  transition('register => signin', slideRightAnimation),

  // Home
  transition('home => *', slideLeftAnimation),
  transition('* => home', slideRightAnimation),

  // Search
  transition('search => *', slideLeftAnimation),
  transition('* => search', slideRightAnimation),

  // cart
  transition('cart => *', slideLeftAnimation),
  transition('* => cart', slideRightAnimation),

  // Account
  transition('account => *', slideRightAnimation),
  transition('* => account', slideLeftAnimation),

]);
