<app-side-container>
  <!-- desktop Header-->
  <div class="landing-page-header"
       fxHide
       fxShow.gt-xs
       [ngClass.gt-xs]="{ 'full-size': true }">
    <app-header imageUrl="./assets/imgs/backgrounds/landing.jpg">
      <div class="landing-page-logo"></div>
    </app-header>
  </div>

  <!-- Page-->

  <app-scrollable-container [noFooter]="true"
                            class="command-list-page"
                            fxLayout.gt-xs="column"
                            fxLayoutAlign.gt-xs="stretch"
                            [ngClass.gt-xs]="{ centered: true }"
                            [noHeader]="(isEmbedded$ | async) === true">
    <div header
         class="command-list-header-toolbar">
      <div class="command-list-header-toolbar-content"
           fxLayout="row"
           fxLayoutAlign="center center">
        <div>
          <!-- button retour-->
          <app-back-button [style]="{ padding: 0 }"></app-back-button>
        </div>
        <div class="command-list-header-toolbar-title">
          {{ "COMMAND.COMMANDS_TITLE" | transloco }}
        </div>
      </div>
    </div>

    <div content
         class="command-list-container">
      <ng-container *ngIf="(lastPreparationOrder$ | async) as lastPreparationOrder">
        <!-- <app-message-bar messageType="info"
                         [canClose]="false">
          <div>
            Suivre ma commande du jour : {{ getOrderDescription(lastPreparationOrder) }}
          </div>
        </app-message-bar> -->
        <app-command-track-order-bar [order]="lastPreparationOrder"
                                     (trackOrder)="goToTrackOrder(lastPreparationOrder)"></app-command-track-order-bar>
      </ng-container>


      <!-- TODO faire une message bar avec un transclude du message pour pourvoir piloter l'affichage du detail de la commande / suivi de la commande -->



      <!--confirmed commands-->
      <div>
        <app-command-list [commandList]="orders"
                          [displayMoreOrder]="displayMoreOrders"
                          [isLoading]="isLoading"
                          [isEmbedded]="(isEmbedded$ | async)"
                          (commandClick)="goToOrderDetail($event)"
                          (getMoreOrdersClick)="getMoreOrders()"
                          (createOrderClick)="goToCreateOrder()"></app-command-list>
        <!-- <mat-divider class="command-list-divider"></mat-divider> -->
      </div>
    </div>
  </app-scrollable-container>

</app-side-container>
