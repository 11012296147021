<mat-toolbar class="receipt-header-toolbar"
             fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="10px">
  <mat-toolbar-row fxLayout="row"
                   fxLayoutAlign="start center">
    <div>
      <!-- button retour-->
      <app-back-button fxFlexAlign="start"
                       [stopNavigation]="true"
                       (click)="onExit()"
                       [style]="{ padding: 0 }"></app-back-button>
    </div>
    <span class="receipt-header-toolbar-title">{{"RECEIPT.INNER_TITLE" | transloco}}</span>
  </mat-toolbar-row>
</mat-toolbar>
<div class="receipt-container">
  <app-receipt [order]="data"
               (exit)="onExit()"></app-receipt>
</div>
