import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MenuService } from '@app/core/services/menu/menu.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryDateTimeDialogComponent, DeliveryDateTimeDialogParameters, DeliveryDateTimeDialogResult } from '../components/delivery-date-time-dialog/delivery-date-time-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDateTimeService {
  constructor(
    private _menuService: MenuService,
    private _matDialog: MatDialog,
  ) { }


  showDeliveryDateTimePicker(deliveryDate?: Date): Observable<Date> {
    const dialogConfig = new MatDialogConfig<DeliveryDateTimeDialogParameters>();
    dialogConfig.maxHeight = '90vh';
    dialogConfig.data = {
      deliveryDate: deliveryDate,
      pickupDays: this._menuService.getAvailablePickupSchedules()
    };
    const dialogRef = this._matDialog.open<DeliveryDateTimeDialogComponent, DeliveryDateTimeDialogParameters, DeliveryDateTimeDialogResult>(DeliveryDateTimeDialogComponent, dialogConfig);

    return dialogRef.afterClosed()
      .pipe(
        map(result => {
          return (result != null)
            ? result.deliveryDate // selected date
            : deliveryDate; // dialog closed without date, return same date
        }
        )
      );
  }

}
