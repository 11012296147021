import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <app-cart-page [sideViewMode]="true"></app-cart-page>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartPageSidePanelWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
