import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export type BadgeColor = ThemePalette;

@Component({
  selector: 'app-bar-item',
  templateUrl: './bar-item.component.html',
  styleUrls: ['./bar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarItemComponent implements OnInit {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() badgeValue: number | null;
  @Input() badgeColor: BadgeColor | null;

  constructor() { }

  ngOnInit(): void {
  }
}
