import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Constants } from '@app/core/models/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResetCredentialsContext } from '../../models/reset-credentials-context';
import { ResetCredentialsInput } from '../../models/reset-credentials-input';

@UntilDestroy()
@Component({
  selector: 'app-reset-credentials',
  templateUrl: './reset-credentials.component.html',
  styleUrls: ['./reset-credentials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetCredentialsComponent implements OnInit, OnChanges {

  @Input() inProgress = false;
  @Input() context: ResetCredentialsContext | null = null;
  @Input() canCancel = false;

  @Output() resetCredentials = new EventEmitter<ResetCredentialsInput>();
  @Output() cancel = new EventEmitter();

  showPassword = false;

  resetCredentialsForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    emailConfirmation: new UntypedFormControl(null, [
      Validators.required,
      Validators.email,
      ResetCredentialsComponent.matchValues('email'),
    ]),
    password: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(Constants.passwordMinLength),
      Validators.pattern(Constants.passwordPattern),
    ]),
    passwordConfirmation: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(Constants.passwordMinLength),
      Validators.pattern(Constants.passwordPattern),
      ResetCredentialsComponent.matchValues('password'),
    ]),
  });;

  constructor() { }

  ngOnInit(): void {

    // revalidate passwordConfirmation if password changes
    this.resetCredentialsForm.controls['password'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const pwdConfirmCtrl = this.resetCredentialsForm.controls['passwordConfirmation'];
        if (pwdConfirmCtrl.value) {
          this.resetCredentialsForm.controls['passwordConfirmation'].updateValueAndValidity();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context) {
      // define default email if not already inputed
      if (!this.resetCredentialsForm.controls['email'].value) {
        this.resetCredentialsForm.patchValue({
          email: this.context?.email,
        }, {
          onlySelf: true, // do not after form validation/state
          emitEvent: false // do not emit event to prevent validation
        });
      }
    }
  }

  onResetCredentialsClick() {

    if (this.resetCredentialsForm.invalid) {
      return;
    }

    const formValue = this.resetCredentialsForm.value;
    this.resetCredentials.emit({
      email: formValue.email,
      newEmail: formValue.emailConfirmation,
      password: formValue.password,
      newPassword: formValue.passwordConfirmation
    } as ResetCredentialsInput);
  }

  onCancelClick() {
    if (!this.canCancel) {
      throw new Error('Cancel is not allowed');
    }

    this.cancel.emit();
  }

  /**
   * Angular Form Validator to check if tow fields are the same
   * @param {string} matchTo - The name of the control to match to
   */
  public static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: true };
    };
  }

}
