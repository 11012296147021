import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/authentication/auth.guard';
import { RouteDataModel } from '@app/core/models/route-data.model';
import { AppRoutes } from '@app/core/routes';
import { CommandListPageComponent } from './pages/command-list-page/command-list-page.component';
import { EdenredSigninPageComponent } from './pages/edenred-signin-page/edenred-signin-page.component';
import { PaymentCallbackPageComponent } from './pages/payment-callback-page/payment-callback-page.component';
import { SwileSigninPageComponent } from './pages/swile-signin-page/swile-signin-page.component';

const routes: Routes = [
  {
    // commande/
    path: AppRoutes.orderList,
    component: CommandListPageComponent,
    data: { title: 'COMMAND.COMMANDS_TITLE', hideCartButton: true, analyticsTitle: 'Orders' } as RouteDataModel,
    canActivate: [AuthGuard]
  },
  {
    // commande/retour-paiement
    path: `${AppRoutes.orderPaymentCallback}/:orderId`,
    component: PaymentCallbackPageComponent,
    data: { title: 'COMMAND.COMMANDS_TITLE', analyticsTitle: 'OrderPaymentCallback' } as RouteDataModel,
    canActivate: [AuthGuard]
  },
  // "edenred/login"
  {
    // commande/edenred/login
    path: `${AppRoutes.orderEdenredSigninCallback}`,
    component: EdenredSigninPageComponent,
    data: { title: 'COMMAND.COMMANDS_TITLE', analyticsTitle: 'OrderEdenredSigninCallback' } as RouteDataModel,
    canActivate: [AuthGuard]
  },
  {
    // orders/swile/login
    path: `${AppRoutes.orderSwileSigninCallback}`,
    component: SwileSigninPageComponent,
    data: { title: 'COMMAND.COMMANDS_TITLE', analyticsTitle: 'orderSwileSigninCallback' } as RouteDataModel,
    canActivate: [AuthGuard]
  },
  {
    path: `${AppRoutes.orderEdenredSignoutCallback}`,
    redirectTo: '/account/payment-methods',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommandRoutingModule { }
