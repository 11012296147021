<app-bottom-container [title]="'SIGNIN.TITLE' | transloco"
                      [hasLogo]="true">
  <!--content-->
  <form content
        [formGroup]="loginForm"
        class="signin-form-content"
        (ngSubmit)="onSignInClick()"
        fxLayout="column"
        fxLayoutAlign="start stretch">
    <!-- Email-->
    <mat-form-field class="signin-field">
      <input type="email"
             formControlName="email"
             matInput
             [appAutofocus]="true"
             [placeholder]="'SIGNIN.EMAIL' | transloco"
             autocomplete="off" />
      <mat-icon matSuffix
                color="accent"
                svgIcon='at'></mat-icon>
      <mat-error *ngIf="submitted && loginForm.controls['email'].hasError('required')">
        {{ "SIGNIN.ERRORS.EMAIL_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="submitted && loginForm.controls['email'].hasError('email')">
        {{ "SIGNIN.ERRORS.EMAIL_FORMAT" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Password-->
    <mat-form-field class="signin-field">
      <input matInput
             formControlName="password"
             [placeholder]="'SIGNIN.PASSWORD' | transloco"
             [type]="showPassword ? 'text' : 'password'"
             autocomplete="off" />
      <mat-icon matSuffix
                color="accent"
                class="show-password-icon"
                (click)="showPassword = !showPassword"
                [svgIcon]="showPassword ? 'unlock' : 'lock'"></mat-icon>
      <mat-error *ngIf="submitted">{{ "SIGNIN.ERRORS.PASSWORD_REQUIRED" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Forgot password -->
    <div class="forgot-password"
         matButton>
      <a mat-button
         [routerLink]="[forgotPasswordLink]">{{"SIGNIN.FORGOT_PASSWORD" | transloco }}</a>
    </div>

    <!-- Buttons-->
    <div class="signin-buttons"
         fxLayout="column"
         fxLayoutGap="10px">
      <button type="submit"
              class="full-width"
              mat-flat-button
              color="accent"
              [disabled]="disabled">
        {{ "SIGNIN.SIGN_IN" | transloco }}
        <app-loader *ngIf="disabled"></app-loader>
      </button>
    </div>

    <div class="signup-link">
      <span>{{ "WELCOME.ASK_SIGNUP" | transloco }}</span>
      <a mat-button
         color="primary"
         class="light-color"
         (click)="onSignUpClick()">{{ "WELCOME.SIGNUP" | transloco }}</a>
    </div>

    <div class="buttons-row">
      <button type="button"
              class="main-button go-back-button"
              mat-button
              *ngIf="showGoBackButton"
              (click)="onGoBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>&nbsp;{{ "COMMON.BACK" | transloco }}
      </button>
      <button type="button"
              class="main-button go-to-menu-button"
              mat-button
              *ngIf="showGoToMenuButton"
              (click)="onGoToMenu()">
        {{ "SIGNIN.GO_TO_MENU" | transloco }}&nbsp;<mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

  </form>
</app-bottom-container>
