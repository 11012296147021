import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AppRoutes } from '@app/core/routes';
import { NavigationService } from '@app/core/services/navigation.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ForgotPasswordInput } from '../../models/forgot-password-input';

@Component({
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  signinLink = `/${AppRoutes.signin}`;

  submitting = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _toastr: ToastrService,
    private _translate: TranslocoService,
    private _navigationService: NavigationService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  forgotPassword(input: ForgotPasswordInput) {

    this.submitting = true;
    this._authenticationService.forgotPassword(input.email)
      .pipe(
        finalize(() => {
          this.submitting = false;
          this._cd.markForCheck();
        })
      )
      .subscribe(() => this.forgotPasswordSuccess());
  }

  cancel() {
    this._navigationService.back();
  }

  private async forgotPasswordSuccess() {
    this._navigationService.navigateHome();

    this._toastr.success(
      this._translate.translate('FORGOT_PASSWORD.SUCCESS_MESSAGE'),
      this._translate.translate('FORGOT_PASSWORD.SUCCESS_TITLE'),
      { timeOut: 10000 }
    );
  }

}
