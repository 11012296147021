<div class="confirmation-container"
     [ngSwitch]="displayMode">

  <section *ngSwitchCase="'RequiresDestinationInput'">
    <div class="confirmation-status">
      <mat-icon class="confirmation-status-icon">check_circle</mat-icon>
      <div class="confirmation-status-title">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.VALIDATED' | transloco}}</div>
    </div>
    <div class="confirmation-status-description">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.INFO_REQUIRED' | transloco}}</div>
  </section>
  <section *ngSwitchCase="'WaitingForOtherOrders'">
    <div class="confirmation-status">
      <mat-icon class="confirmation-status-icon">check_circle</mat-icon>
      <div class="confirmation-status-title">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.VALIDATED' | transloco}}</div>
    </div>
    <div class="confirmation-status-description">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.WAITING_OTHER_ORDERS' | transloco}}</div>
  </section>
  <section *ngSwitchCase="'OrderInPreparation'">
    <div class="confirmation-status">
      <mat-icon class="confirmation-status-icon">check_circle</mat-icon>
      <div class="confirmation-status-title">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.ORDER_PROCESSING' | transloco}}</div>
    </div>
  </section>
  <section *ngSwitchCase="'TableInPreparation'">
    <div class="confirmation-status">
      <mat-icon class="confirmation-status-icon">check_circle</mat-icon>
      <div class="confirmation-status-title">{{'COMMAND.ORDER_PREPARATION.ORDER_CONFIRMATION.TABLE_PROCESSING' | transloco}}</div>
    </div>
  </section>
  <section *ngSwitchDefault>
    <app-page-loader></app-page-loader>
  </section>

</div>
