import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Order, OrderPreparation } from '@app/core/api-client/models';

@Component({
  selector: 'app-order-preparation-header',
  templateUrl: './order-preparation-header.component.html',
  styleUrls: ['./order-preparation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationHeaderComponent implements OnInit {

  @Input() order: Order;
  @Input() orderPreparation: OrderPreparation;
  @Input() loadingOrderPreparation = false;
  @Input() expanded: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
