import { Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';
import { InMemoryStorage } from './in-memory-storage';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageProvider extends StorageProvider {

  constructor() {
    let storageProvider: Storage;

    let storageSupported = false;
    try { storageSupported = (window.sessionStorage && true) } catch (e) { }

    if (!storageSupported) {
      console.debug('session storage not supported, using in-memory storage');
      storageProvider = new InMemoryStorage();
    }
    else {
      storageProvider = sessionStorage;
    }

    super(storageProvider);
  }

}
