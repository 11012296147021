import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-command-list-template',
  templateUrl: './command-list-template.component.html',
  styleUrls: ['./command-list-template.component.scss']
})
export class CommandListTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
