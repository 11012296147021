import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SigninFormComponent } from './components/signin-form/signin-form.component';
import { SigninPageComponent } from './pages/signin-page/signin-page.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SigninRoutingModule } from './signin-routing.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ResetCredentialsComponent } from './components/reset-credentials/reset-credentials.component';
import { ResetCredentialsDialogComponent } from './components/reset-credentials-dialog/reset-credentials-dialog.component';
import { ResetCredentialsPageComponent } from './pages/reset-credentials-page/reset-credentials-page.component';
import { ResetCredentialsHeaderComponent } from './components/reset-credentials-header/reset-credentials-header.component';

@NgModule({
  declarations: [
    SigninFormComponent,
    SigninPageComponent,
    ForgotPasswordComponent,
    ForgotPasswordPageComponent,
    ResetPasswordComponent,
    ResetPasswordPageComponent,
    ResetCredentialsComponent,
    ResetCredentialsDialogComponent,
    ResetCredentialsPageComponent,
    ResetCredentialsHeaderComponent,
  ],
  imports: [CommonModule, SigninRoutingModule, SharedModule, TranslocoModule],
  exports: [
    ResetCredentialsDialogComponent
  ],
})
export class SigninModule {}
