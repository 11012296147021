import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output, Input
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppRoutes } from '@app/core/routes';
import { LoginInput } from '../../models/login-input';

enum LoginSteps {
  Landing = 0,
  Checking = 1,
  Welcome = 2,
}

@Component({
  selector: 'app-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninFormComponent implements OnInit {

  private _disabled: boolean;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) {
      this.loginForm?.disable();
    } else {
      this.loginForm?.enable();
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() showGoToMenuButton: boolean = false;
  @Input() showGoBackButton: boolean = true;

  @Output() goToMenu = new EventEmitter();

  @Output() signIn = new EventEmitter<LoginInput>();
  @Output() signUp = new EventEmitter();
  @Output() goBack = new EventEmitter();
  loginForm: UntypedFormGroup;
  submitted = false;
  showPassword = false;
  forgotPasswordLink = `/${AppRoutes.forgotPassword}`;
  landingLink = AppRoutes.default;

  constructor(
    private formBuilder:
      UntypedFormBuilder) { }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      connect: [false],
    });
  }

  onSignInClick() {
    if (this.disabled) {
      return;
    }

    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.signIn.emit({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    } as LoginInput);
  }

  onSignUpClick() {
    this.signUp.emit();
  }

  onGoToMenu() {
    this.goToMenu.emit();
  }

  onGoBack() {
    this.goBack.emit();
  }
}
