import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { WhiteMarkService } from '@app/core/layout/white-mark.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { AnalyticsService } from './core/analytics/analytics-service';
import { SideService } from './core/layout/side.service';
import { AppRoutes } from './core/routes';
import { ApplicationUpdateService } from './core/services/application-update.service';
import { CartService } from './core/services/cart/cart.service';
import { GlobalizationService } from './core/services/globalization/globalization.service';
import { MenuService } from './core/services/menu/menu.service';
import { NavigationService } from './core/services/navigation.service';
import { routeAnimations } from './core/ui/animation/route-animations';
import { CustomIconService } from './core/ui/CustomIcon/custom-icon.service';
import { ThemeService } from './core/ui/theme';
import { TitleService } from './core/ui/title/title.service';
import { CartPageSidePanelWrapperComponent } from './features/cart/pages/cart-page-side-panel-wrapper/cart-page-side-panel-wrapper.component';
import { BarStateModel } from './shared/components/bar';
import { BarStateItemModel } from './shared/components/bar/models/bar-state-item.model';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'SmartOrdering';

  barMenu: BarStateModel;
  private _isDesktop: boolean = true;
  isDesktop$ = this._breakpointObserver
    .observe('(min-width:600px)')
    .pipe(
      untilDestroyed(this),
      map((result) => result.matches),
    );

  isEmbedded = false;

  orderingIsOpen$ = this._menuService.getCurrentMenu$().pipe(
    map(menu => menu?.orderingSettings?.isOpen)
  );

  cartBadgeValue$ = combineLatest([this._cartService.getCountStandardProducts$(), this.orderingIsOpen$])
    .pipe(
      untilDestroyed(this),
      // display productsCount only if orderingIsOpen
      map(([productsCount, orderingIsOpen]) => (orderingIsOpen && productsCount) || undefined),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  panelLink = `/${AppRoutes.cart}`;

  constructor(
    private _themeService: ThemeService,
    private _customIconService: CustomIconService,
    private _globalizationService: GlobalizationService,
    private _menuService: MenuService,
    private _cartService: CartService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private _activatedRoute: ActivatedRoute,
    private _whiteMarkService: WhiteMarkService,
    private _sideService: SideService,
    private _applicationUpdateService: ApplicationUpdateService,
    private _navigationService: NavigationService, // early instanciation (for router monitoring)
    private _titleService: TitleService, // early instanciation (for router monitoring)
    private _analyticsService: AnalyticsService, // early instanciation (for router monitoring)
    private _notificationService: ToastrService
  ) {
    this._themeService.resolveDefaultTheme();
    this._customIconService.addCustomSvgIcons();
    this.manageDialogBackButton();
  }

  ngOnInit() {
    this.configBarMenu();
    this.observeMediaQuery();
    this.manageWhiteMark();
    this._applicationUpdateService.startMonitorApplicationUpdate();
  }

  @HostListener('window:keydown.alt.t')
  private changeTheme() {
    this._themeService.setNextTheme();
  }

  @HostListener('window:keydown.alt.l')
  private changeLanguage() {
    this._globalizationService.toggleLanguage();
  }

  prepareRoute(outlet: RouterOutlet) {
    if (this._isDesktop) {
      return '';
    }

    return outlet?.activatedRouteData?.animation;
  }

  onActivate(event, container) {
    // document.querySelector(container).scroll(0, 0);
  }

  configBarMenu() {
    const homeBarItem: BarStateItemModel = {
      svgIcon: 'home',
      onClick: () => this._navigationService.navigateToDefaultMenu$().subscribe(),
    };

    const searchBarItem: BarStateItemModel = {
      svgIcon: 'search',
      routerLink: `/${AppRoutes.productSearch}/search`,
    };

    const cartBarItem: BarStateItemModel = {
      svgIcon: 'shopping-basket',
      routerLink: `/${AppRoutes.cart}`,
      badgeValue$: this.cartBadgeValue$,
      badgeColor: 'primary'
    };

    const accountBarItem: BarStateItemModel = {
      svgIcon: 'account-circle',
      routerLink: `/${AppRoutes.account}`,
    };

    this.barMenu = {
      barItems: [
        // Page acceuil
        homeBarItem,
        // Recherche
        searchBarItem,
        // Panier
        cartBarItem,
        // Mon Compte
        accountBarItem,
      ],
    };
  }

  observeMediaQuery() {

    this.isDesktop$
      .subscribe(isDesktop => {
        // update desktop property
        this._isDesktop = isDesktop;

        //update default toast position
        const toastrConfig = this._notificationService.toastrConfig;
        toastrConfig.positionClass = isDesktop ? 'toast-bottom-center' : 'toast-top-center';
      });

  }

  isBarMenuShown(outlet: RouterOutlet): boolean {
    const hideBarMenu = outlet?.activatedRouteData?.hideBarMenu;
    return !hideBarMenu && !this.isEmbedded;
  }

  isCartFloatingButtonShown(outlet: RouterOutlet): boolean {
    return !outlet?.activatedRouteData?.hideCartButton && this.isEmbedded;
  }

  manageDialogBackButton() {
    // dialogs
    this._router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this._dialog.closeAll()),
      tap(() => this._bottomSheet.dismiss())
    ).subscribe();
  }

  manageWhiteMark() {

    // HACK : la récupération  via queryParams ne donne pas le résultat du premier coup
    this.isEmbedded = this._whiteMarkService.isEmbedded || window.location.search.indexOf('embedded=true') >= 0;
    if (this.isEmbedded) {
      this._whiteMarkService.enableEmbedded();
    }

    // this._activatedRoute.queryParams
    //   .subscribe((params) => {
    //     // this.isEmbedded = params[Constants.whiteMarkEmbeddedParam];
    //     // if (this.isEmbedded === undefined) {
    //     //   this.isEmbedded = false;
    //     // }
    //     if (this.isEmbedded) {
    //       this._whiteMarkService.enableEmbedded();
    //     }
    //   });
  }

  onPanelClick() {

    if (this._isDesktop) {
      this._sideService.toggle();
      this._sideService.setComponent({ component: CartPageSidePanelWrapperComponent, data: null });
    } else {
      this._router.navigate([this.panelLink]);
    }

  }

  ngOnDestroy() { }

}
