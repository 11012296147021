/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Order } from '../../models/order';

export interface GetOrders$Plain$Params {
  Limit?: number;
  Offset?: number;

/**
 * Filter on orders with preparation for the current consumption day
 */
  OnlyDailyPreparationOrders?: boolean;
  'force-tenant'?: String;
}

export function GetOrders$Plain(http: HttpClient, rootUrl: string, params?: GetOrders$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Order>>> {
  const rb = new RequestBuilder(rootUrl, GetOrders$Plain.PATH, 'get');
  if (params) {
    rb.query('Limit', params.Limit, {});
    rb.query('Offset', params.Offset, {});
    rb.query('OnlyDailyPreparationOrders', params.OnlyDailyPreparationOrders, {});
    rb.header('force-tenant', params['force-tenant'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<Order>>;
    })
  );
}

GetOrders$Plain.PATH = '/api/Orders';
