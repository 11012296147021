import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderPreparation, OrderPreparationProduct } from '@app/core/api-client/models';
import _groupBy from 'lodash-es/groupBy';
import _map from 'lodash-es/map';

export interface OrderPreparationProductCategory {
  name: string;
  position: number;
  products: OrderPreparationProduct[];
}


@Component({
  selector: 'app-order-preparation-products-recap',
  templateUrl: './order-preparation-products-recap.component.html',
  styleUrls: ['./order-preparation-products-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationProductsRecapComponent implements OnInit, OnChanges {

  @Input() orderPreparation: OrderPreparation;
  @Input() loadingOrderPreparation = false;

  productCategories: OrderPreparationProductCategory[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderPreparation && changes.orderPreparation.currentValue) {
      this.computeProductsByCategories();
    }
  }

  computeProductsByCategories() {
    this.productCategories = [];
    if (!this.orderPreparation) {
      return;
    }

    this.productCategories = _map(
      _groupBy(this.orderPreparation.orderPreparationProducts, p => p.categoryName),
      (products, categoryName) => ({ name: categoryName, position: products[0].categoryPosition, products }) as OrderPreparationProductCategory
    );

    this.productCategories.sort((a, b) => a.position - b.position);
  }

}
