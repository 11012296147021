import { HttpErrorResponse } from '@angular/common/http';
import { ProblemDetails } from '../api-client/models';
import { ModelsHelper } from './models-helper';

export class ErrorsHelper {

  static isHttpRequestError(error: unknown): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
  }

  static getErrorDetails(error: unknown): ProblemDetails | null {

    if (ErrorsHelper.isHttpRequestError(error)) {
      const errorModel = error.error;
      const details: ProblemDetails = (errorModel && ModelsHelper.isProblemDetail(errorModel)) ? errorModel : null;

      return details;
    }
    return null;
  }

  static isError(error: unknown): error is Error {
    return error instanceof Error;
  }

  static DumpError(error: Error): string {
    if (error == null) {
      return undefined;
    }

    return error.stack || error.toString();
  }

}
