/* tslint:disable */
/* eslint-disable */

/**
 * Creating (Order is created and wainting for payment processing)
 *
 * PendingValidation (Order is waiting for validation by the restaurant kitchen)
 *
 * PendingPreparation (Order is waiting for preparation / waiting for other table orders to be created)
 *
 * Processing (Order is validated and is being prepared by the restaurant kitchen)
 *
 * Processed (Order is ready to be delivered (at the pickup-point / locker / served))
 *
 * Finalized
 *
 * Cancelled
 */
export enum OrderProcessingStep {
  Creating = 'Creating',
  PendingValidation = 'PendingValidation',
  PendingPreparation = 'PendingPreparation',
  Processing = 'Processing',
  Processed = 'Processed',
  Finalized = 'Finalized',
  Cancelled = 'Cancelled'
}
