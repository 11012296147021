import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DelayAnimations } from '@app/core/ui/animation/animations/delayAnimations';

// From https://stackoverflow.com/questions/41873893/angular2-autofocus-input-element
@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnInit {

  @Input() set appAutofocus(condition: boolean) {
    this.focus = condition !== false;
  }

  private focus = true;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.focus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, DelayAnimations.medium);
    }
  }
}
