import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderPreparationDialogComponent } from './components/order-preparation-dialog/order-preparation-dialog.component';
import { OrderPreparationPageComponent } from './pages/order-preparation-page/order-preparation-page.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@app/shared/shared.module';
import { OrderPreparationDestinationComponent } from './components/order-preparation-destination/order-preparation-destination.component';
import { OrderPreparationDestinationOrdersSummaryComponent } from './components/order-preparation-destination-orders-summary/order-preparation-destination-orders-summary.component';
import { OrderPreparationPromptForActionComponent } from './components/order-preparation-prompt-for-action/order-preparation-prompt-for-action.component';
import { OrderPreparationConfirmationComponent } from './components/order-preparation-confirmation/order-preparation-confirmation.component';
import { OrderPreparationProductsRecapComponent } from './components/order-preparation-products-recap/order-preparation-products-recap.component';
import { OrderPreparationHeaderComponent } from './components/order-preparation-header/order-preparation-header.component';
import { TableServiceSharedModule } from '@app/features/table-service-shared/table-service-shared.module';


@NgModule({
  declarations: [
    OrderPreparationDialogComponent,
    OrderPreparationPageComponent,
    OrderPreparationDestinationComponent,
    OrderPreparationDestinationOrdersSummaryComponent,
    OrderPreparationPromptForActionComponent,
    OrderPreparationConfirmationComponent,
    OrderPreparationProductsRecapComponent,
    OrderPreparationHeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    TableServiceSharedModule,
  ]
})
export class TableServiceModule { }
