import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConsumptionModeType, Order, OrderPreparation, OrderPreparationSettings, OrderProcessingStep } from '@app/core/api-client/models';
import { ApiRestaurantsService } from '@app/core/api-client/services';
import { MenuService } from '@app/core/services/menu/menu.service';
import { OrderService } from '@app/core/services/order/order.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, delay, finalize, first, map, Observable, of, Subscription, switchMap, tap, withLatestFrom } from 'rxjs';
import { OrderPreparationAction } from '../../components/order-preparation-prompt-for-action/order-preparation-prompt-for-action.component';

// interface RestaurantPreparationSettings {
//   restaurantId: number;
//   consumptionMode: ConsumptionModeType;
//   orderPreparationSettings: OrderPreparationSettings;
// }

type PreparationStep = 'Loading' | 'RequireDestination' | 'PromptForAction' | 'DisplayConfirmation';

@UntilDestroy()
@Component({
  selector: 'app-order-preparation-page',
  templateUrl: './order-preparation-page.component.html',
  styleUrls: ['./order-preparation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderPreparationPageComponent implements OnInit {

  private _order: Order;
  public get order(): Order {
    return this._order;
  }
  @Input()
  public set order(value: Order) {
    this._order = value;
    this._orderSubjet.next(value);
    this.preparationStep = this.computePreparationStep(this._order);
  }

  private _orderSubjet = new BehaviorSubject<Order | null>(null);
  public preparationSettings$ = this._orderSubjet.pipe(
    withLatestFrom(this._menuService.getCurrentMenu$()),
    switchMap(([order, menu]) => {
      if (!order) {
        return of(null);
      }
      // try to retrieve settings from current menu if it matches the order restaurant / consumption mode
      if (menu
        && menu.site.siteId === order.siteId
        && menu.restaurant.restaurantId === order.restaurantId
        && menu.consumptionMode.type === order.consumptionMode) {
        return of(menu.consumptionMode.orderPreparationSettings)
      }

      return this._restaurantApiService.GetRestaurantConsumptionMode$Json({
        siteId: order.siteId,
        restaurantId: order.restaurantId,
        consumptionMode: order.consumptionMode
      })
        .pipe(
          first(),
          map(c => c?.orderPreparationSettings)
        );
    })
  );

  @Output() confirmed = new EventEmitter();

  inProgress: boolean = false;

  loadingOrderPreparation = false;

  orderPreparation: OrderPreparation;
  private _orderPreparationSub: Subscription = new Subscription();

  private _preparationStep: PreparationStep = 'Loading';
  public get preparationStep(): PreparationStep {
    return this._preparationStep;
  }
  public set preparationStep(value: PreparationStep) {
    this._preparationStep = value;
    if (this.preparationStep === 'DisplayConfirmation' || this.preparationStep === 'PromptForAction') {
      this.loadOrderPreparation(this._order?.processingState?.orderPreparationId);
    }
  }

  destinationForm = new UntypedFormGroup({
    destination: new UntypedFormControl(null)
  });

  constructor(
    private _restaurantApiService: ApiRestaurantsService,
    private _orderService: OrderService,
    private _menuService: MenuService,
    private _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._orderService.getDefaultDestination$()
      .pipe(
        tap(defaultDestination => {
          // define default destination if not defined
          this.destinationForm.patchValue({
            destination: defaultDestination
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private computePreparationStep(order: Order): PreparationStep {
    if (!order) {
      return 'Loading';
    }

    if (order.processingState.step === OrderProcessingStep.PendingPreparation) {
      if (order.processingState.destination == null) {
        return 'RequireDestination';
      } else {
        return 'PromptForAction';
      }
    }
    // else (Porcessing or further)
    return 'DisplayConfirmation';
  }

  onSubmitDestination() {
    this.destinationForm.markAllAsTouched();
    this.destinationForm.updateValueAndValidity();

    if (this.destinationForm.valid) {
      const destination = this.destinationForm.value.destination;
      this.setDestination$(destination)
        .pipe(
          first(),
          tap(order => {
            this.order = order;
            this._cd.markForCheck();
          })
        )
        .subscribe()
    }
  }

  private setDestination$(destination: string): Observable<Order> {
    this.inProgress = true;
    return this._orderService.setOrderDestination(this.order, destination)
      .pipe(
        tap(() => {
          //save DefaultDestination
          this._orderService.setDefaultDestination(destination);
        }),
        untilDestroyed(this),
        finalize(() => {
          this.inProgress = false;
          this._cd.markForCheck();
        })
      );
  }

  onConfirmSelectedAction(action: OrderPreparationAction) {
    of(true)
      .pipe(
        tap(() => {
          this.inProgress = true;
        }),
        switchMap(() => {
          switch (action) {
            case OrderPreparationAction.WaitForOtherOrders:
              return of(this.order.processingState);
            case OrderPreparationAction.SendTable:
              return this._orderService.sendOrdersToPreparation(this.order.processingState.orderPreparationId);
            case OrderPreparationAction.SendMyOrder:
              return this._orderService.sendCustomerOrdersToPreparation(this.order.processingState.orderPreparationId);
            default:
              throw new Error(`unhandled OrderPreparationAction: ${action}`);
          }
        }
        ),
        tap((newProcessingState) => {
          this.order.processingState = newProcessingState;
          this.preparationStep = 'DisplayConfirmation';
        }),
        finalize(() => {
          this.inProgress = false;
          this._cd.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private loadOrderPreparation(orderPreparationId: number | null): void {
    this._orderPreparationSub.unsubscribe();
    this._orderPreparationSub = new Subscription();
    this.loadingOrderPreparation = false;
    if (!orderPreparationId) {
      return;
    }

    this.loadingOrderPreparation = true;
    this._orderPreparationSub.add(
      this._orderService.getOrderPreparation(orderPreparationId)
        .pipe(
          untilDestroyed(this),
          //DEBUG
          // delay(60_000), // DEBUG : wait 1 minute = 60000ms
          tap(orderPreparation => {
            this.orderPreparation = orderPreparation;
          }),
          finalize(() => {
            this.loadingOrderPreparation = false;
            this._cd.markForCheck();
          })
        ).subscribe()
    );
  }

  onOkCloseClicked() {
    // confirm / close dialog
    this.confirmed.emit();
  }

  onAddArticlesClicked() {
    // confirm / close dialog
    this.confirmed.emit();
  }
}
