<ng-container *ngrxLet="paymentMethodsTranslations$ as paymentMethodsTranslations">
  <ng-container *ngIf="(customerPaymentMethods$ | async) as customerPaymentMethods">
    <div class="cart-payment">
      <div class="cart-payment-title"> {{ 'CART.PAYMENT_TITLE' | transloco }}</div>

      <div *ngIf="control?.touched && (errors$ | async) as errors"
           @errorInOutAnimation
           class="cart-payment-errors">
        <mat-error *ngIf="errors['required']">{{ errors['required'] }}</mat-error>
      </div>

      <div class="cart-payment-list">
        <div *ngFor="let customerPaymentMethod of customerPaymentMethods"
             (click)="!readonly && selectPaymentMethod(customerPaymentMethod)"
             class="cart-payment-list-item payment-method-{{customerPaymentMethod.type.toLowerCase().replace(' ','-')}}"
             [ngClass]="{'selected': isSelected(customerPaymentMethod), 'disabled': readonly}">
          <mat-icon customSvgIcon="{{customerPaymentMethod.type.toLowerCase().replace(' ','-')}}"
                    class="cart-payment-list-item-logo"></mat-icon>
          <div class="cart-payment-list-item-name">
            {{ paymentMethodsTranslations[customerPaymentMethod.name.toLocaleUpperCase()] || customerPaymentMethod.name }}
          </div>
          <ng-container *ngIf="customerPaymentMethod.balance !== null">
            <div class="cart-payment-list-item-expirationDate">{{ customerPaymentMethod.expirationDate }}</div>
            <div class="cart-payment-list-item-balance"> {{customerPaymentMethod.balance | translocoCurrency: "symbol":{ minimumFractionDigits: 2 }:"EUR"}} </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
