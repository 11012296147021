import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Order } from '@app/core/api-client/models';
import { getOrderDescription } from '@app/core/helpers/order-helper';

@Component({
  selector: 'app-command-track-order-bar',
  templateUrl: './command-track-order-bar.component.html',
  styleUrls: ['./command-track-order-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandTrackOrderBarComponent implements OnInit {

  @Input() order: Order;
  @Output() trackOrder = new EventEmitter<Order>();

  @Input() title: string = 'COMMAND.ORDER_PREPARATION.TRACK_DAILYORDER';
  @Input() showOrderDescription: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  onTrackOrderClick() {
    this.trackOrder.emit(this.order);
  }

  getOrderDescription(order: Order | null) {

    return getOrderDescription(order);
  }
}
