import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { RouteDataModel } from '@app/core/models/route-data.model';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';


function* getRoutesTreeBreadCrumb(node: ActivatedRouteSnapshot, appendCurrent: boolean = true): Generator<ActivatedRouteSnapshot> {
  if (appendCurrent) {
    yield node;
  }
  for (const children of node.children) {
    yield children;
  }
  for (const children of node.children) {
    for (const subChildren of getRoutesTreeBreadCrumb(children, false)) {
      yield subChildren;
    }
  }
}

function getRoutesPathFromRoot(activeRouteSnap: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
  // workaround on activeRouteSnap.pathFromRoot that returns only root node here because ActivatedRoute is contextualized on a component, here the service is global
  // walk down the tree and agregate all ActivatedRouteSnapshot in a bread crumb manner
  const nodes: ActivatedRouteSnapshot[] = [...getRoutesTreeBreadCrumb(activeRouteSnap)];
  return nodes;
}

export interface ActivatedRouteData {
  activatedRoute: ActivatedRouteSnapshot;
  data: RouteDataModel;
  routePath: string;
  pageTitle: string;
  pageAnalyticsTitle: string;
}
@Injectable({
  providedIn: 'root'
})
export class TitleService {

  /**
   * Application main title
   */
  appTitle = this._titleService.getTitle();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _translationService: TranslocoService,
    private _titleService: Title
  ) {

    // start monitoring naviguationChange
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap((navidationEndEvent: NavigationEnd) => this.getActivatedRouteData$(this._activatedRoute.snapshot)),
        map(routeData => routeData.pageTitle)
      )
      .subscribe((title: string) => {
        this._titleService.setTitle(title);
      });

  }

  public getActivatedRouteData$(activeRouteSnap: ActivatedRouteSnapshot): Observable<ActivatedRouteData> {
    // compute routeData from root route to active route
    // const routeData = activeRouteSnap.pathFromRoot.reduce((data, routeSnap) => ({ ...data, ...routeSnap.data }), {}) as RouteDataModel;
    // const routePath = activeRouteSnap.pathFromRoot.reduce((path, routeSnap) => path + '/' + routeSnap.url.reduce((url, segment) => url + '/' + segment.path, ''), '');
    const routesPathFromRoot = getRoutesPathFromRoot(activeRouteSnap);
    const routeData = routesPathFromRoot.reduce((data, routeSnap) => ({ ...data, ...routeSnap.data }), {}) as RouteDataModel;
    let routePath = routesPathFromRoot.reduce((path, routeSnap) => path + ((routeSnap.url.length > 0) ? routeSnap.url.reduce((url, segment) => url + '/' + segment.path, '') : ''), '');
    if(routePath.length === 0){
      routePath = '/';
    }

    const pageTitle$ = (!!routeData?.title) // has title ?
      ? this._translationService.selectTranslate(routeData?.title)
        .pipe(
          map(translation => translation as string),
          map(title => (title && `${this.appTitle} - ${title}`) || this.appTitle)
        )
      : of(this.appTitle);

    const pageAnalyticsTitle = (!!routeData?.analyticsTitle) // has analytics title ?
      ? routeData?.analyticsTitle
      : routePath;

    return pageTitle$.pipe(
      map(pageTitle => (
        {
          activatedRoute: activeRouteSnap,
          data: routeData,
          routePath: routePath,
          pageTitle: pageTitle,
          pageAnalyticsTitle: pageAnalyticsTitle
        }
      ))
    );

  }

}
