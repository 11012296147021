<div class="cart-confirm">
  <div class="cart-confirm-content">
    <!-- Buttons-->
    <div class="cart-confirm-buttons"
         fxLayout="column"
         fxLayoutAlign="center center">
      <button type="submit"
              class="full-width"
              mat-flat-button
              color="accent"
              (click)="onConfirmClick()"
              [disabled]="inProgress">
        <span>{{ "CART.ORDER" | transloco }}&nbsp;</span>
        <app-price [price]="total"
                   [floating]="false"
                   [currencyCode]="currencyCode"
                   *ngIf="showPrice"
                   ></app-price>
        <app-loader *ngIf="inProgress"></app-loader>
      </button>
      <span class="cgv-text">
        {{ "CART.CGV_TEXT" | transloco }}
        <a class="cgu-link"
           (click)="openLegalDialog()">{{ "CART.CGV_LINK_TITLE" | transloco }}</a>
      </span>
    </div>
  </div>
</div>
