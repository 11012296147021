import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SideService {

  private _isSideContainerOpenedSubject = new BehaviorSubject<boolean>(false);
  private _isSideContainerOpened$ = this._isSideContainerOpenedSubject
    .asObservable()
    .pipe(
      distinctUntilChanged()
    );

  private _componentSubject = new BehaviorSubject<PortalComponentData>(null);
  private _component$ = this._componentSubject.asObservable();

  constructor() { }

  close() {
    this._isSideContainerOpenedSubject.next(false);
  }

  toggle() {
    const isOpen = !this._isSideContainerOpenedSubject.getValue();
    this._isSideContainerOpenedSubject.next(isOpen);
  }

  show(component: PortalComponentData) {
    this._isSideContainerOpenedSubject.next(true);
    this.setComponent(component);
  }

  isSideOpened$(): Observable<boolean> {
    return this._isSideContainerOpened$;
  }

  setComponent(component: PortalComponentData) {
    this._componentSubject.next(component);
  }

  getComponent$(): Observable<PortalComponentData> {
    return this._component$;
  }
}

export interface PortalComponentData {
  component: any;
  data: any;
}
