import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CartPayment, Restaurant } from '@app/core/api-client/models';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { SideService } from '@app/core/layout/side.service';
import { WhiteMarkService } from '@app/core/layout/white-mark.service';
import { ProductEventSource } from '@app/core/services/analytics/models/analytic-events';
import { CartService } from '@app/core/services/cart/cart.service';
import { Cart, CartDeliveryAddress, CartProduct } from '@app/core/services/cart/models/cart.model';
import { MenuService } from '@app/core/services/menu/menu.service';
import { MenuState } from '@app/core/services/menu/state/menu.state';
import { NavigationService } from '@app/core/services/navigation.service';
import { OrderService } from '@app/core/services/order/order.service';
import { PaymentService } from '@app/core/services/payment/payment.service';
import { SiteService } from '@app/core/services/site/site.service';
import { ConfirmDialogData } from '@app/shared/components/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { PlaceSelectorDialogResult } from '@app/shared/components/place-selector-dialog/place-selector-dialog-result.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, first, map, share, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CartProductEditComponent } from '../../components/cart-product-edit/cart-product-edit.component';
import { CartPromocodeComponent } from '../../components/cart-promocode/cart-promocode.component';

type CartState = 'LOADING' | 'EMPTY' | 'VISIBLE' | 'ORDERING_CLOSED';

@UntilDestroy()
@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'errorInOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
        ])
      ]
    )
  ]
})
export class CartPageComponent implements OnInit {

  @Input() sideViewMode: boolean = false;

  @ViewChild('promocodeInput') promocodeInput: CartPromocodeComponent | null = null;
  @ViewChild('promocodeInput', { read: ElementRef }) promocodeInputElement: ElementRef | null = null;

  constructor(
    private _siteService: SiteService,
    private _menuService: MenuService,
    private _menuState: MenuState,
    private _cartService: CartService,
    private _bottomSheet: MatBottomSheet,
    private _router: Router,
    private _sideService: SideService,
    private _navigationService: NavigationService,
    private _whiteMarkService: WhiteMarkService,
    private _authenticationService: AuthenticationService,
    private _toastr: ToastrService,
    private _orderService: OrderService,
    private _paymentService: PaymentService,
    private _dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private _el: ElementRef
  ) { }

  cartForm: UntypedFormGroup = new UntypedFormGroup({
    payment: new UntypedFormControl(this._cartService.getCurrentCart()?.payment),
    comment: new UntypedFormControl(this._cartService.getCurrentCart()?.comment, { updateOn: "blur" }),
    deliveryDate: new UntypedFormControl(this._cartService.getCurrentCart()?.deliveryDate),
    destination: new UntypedFormControl(this._cartService.getCurrentCart()?.destination),
    deliveryAddress: new FormControl<CartDeliveryAddress | null>(this._cartService.getCurrentCart()?.deliveryAddress),
  });

  isReviewingCart$ = this._cartService.isReviewingCart$();

  cart$ = this._cartService.getCurrentCart$();
  menu$ = this._menuService.getCurrentMenu$();
  closingMessage$ = this.menu$.pipe(
    switchMap(menu => this._menuState.getClosingMessage$(menu?.orderingSettings)),
    untilDestroyed(this)
  );

  deliveryDate$ = this.cart$.pipe(
    map(cart => cart?.deliveryDate && new Date(cart.deliveryDate))
  );

  currentRestaurant$: Observable<Restaurant> = this._siteService.getCurrentRestaurant$();
  cartProductList$: Observable<CartProduct[]> = this._cartService.getCurrentCart$()
    .pipe(
      map((c) => c?.products || [])
    );

  private _isSubmitingOrderSubject = new BehaviorSubject<boolean>(false);

  canEdit$ = combineLatest([
    this._isSubmitingOrderSubject.asObservable(),
    this._cartService.isUpdating$()
  ]).pipe(
    map(([isSubmitingOrder, isLoadingCart]) => {
      return !isSubmitingOrder && !isLoadingCart;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
    untilDestroyed(this)
  );

  canEditDeliveryDate$ =
    combineLatest(
      [
        this.canEdit$,
        this.cart$,
        this.menu$
      ]
    ).pipe(
      map(([canEdit, cart, menu]) => {
        const today = new Date().getDate();
        const isTableServiceEnabled = menu?.consumptionMode.orderPreparationSettings.tableServiceEnabled === true;
        return canEdit
          && (!isTableServiceEnabled || cart.deliveryDate == null || menu?.schedules.some(s => new Date(s.startTime).getDate() !== today));
      })
    );

  canEditPayment$ = combineLatest([
    this._paymentService.isUpdating$(),
    this.canEdit$,
  ]).pipe(
    map(([paymentIsLoading, canEdit]) => {
      return !paymentIsLoading && canEdit;
    }),
    untilDestroyed(this)
  );

  canSubmit$ = combineLatest([
    this.canEdit$,
    this._paymentService.isUpdating$(),
    this.isReviewingCart$
  ]).pipe(
    map(([canEdit, paymentIsLoading, isReviewingCart]) => {
      return canEdit && !paymentIsLoading && !isReviewingCart;
    }),
    untilDestroyed(this)
  );

  isSubmittingOrReviewinData$ = combineLatest([
    this._isSubmitingOrderSubject.asObservable(),
    this.isReviewingCart$,
    this._cartService.isUpdating$()
  ]).pipe(
    map(([isSubmitingOrder, isReviewingCart, isLoadingCart]) => {
      return isSubmitingOrder || isReviewingCart || isLoadingCart;
    }),
    untilDestroyed(this)
  );

  private _isLoadingSubject = new BehaviorSubject<boolean>(false);

  isLoading$ = combineLatest([
    this._cartService.isUpdating$(),
    this.cart$,
    this.menu$,
    this._isLoadingSubject.asObservable()
  ])
    .pipe(
      map(([cartIsLoading, cart, menu, isLoadingLocal]) => isLoadingLocal || cartIsLoading || !cart || !menu || (menu.restaurant.restaurantId !== cart.restaurantId)),
      untilDestroyed(this),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  isEmbedded$ = this._whiteMarkService.isEmbedded$();

  cartState$ = combineLatest([
    this.isLoading$,
    this.cart$,
    this.menu$
  ]).pipe(
    map(([isLoading, cart, menu]): CartState => {
      if (isLoading) {
        return 'LOADING';
      }
      if (menu && !menu.orderingSettings.isOpen) {
        return 'ORDERING_CLOSED';
      }
      return (cart?.products?.length > 0) ? 'VISIBLE' : 'EMPTY';
    }),
    untilDestroyed(this),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  restaurant$ = this._siteService.getCurrentRestaurant$();
  currencyCode$ = this.restaurant$.pipe(
    untilDestroyed(this),
    map(r => r?.currency)
  );
  showVAT$ = this.restaurant$.pipe(
    untilDestroyed(this),
    map(r => r?.showVAT)
  );

  enablePromocodeInput$ = this.menu$.pipe(
    map(menu => menu?.consumptionMode.enablePromocode),
  );

  nonCutleryNorReturnableContainerProductfilter: (cartProduct: CartProduct) => boolean = (p) => !p.product.isCutlery;

  showCutlery: boolean = true;
  mealCount: number | null = null;

  appliedPromocode: string | null = null;
  promocodeError: string | null = null;

  private _selectPaymentToast?: ActiveToast<any> = null;

  showReturnableProductContainers: boolean = true;

  isTableServiceEnabled$: Observable<boolean> = this.menu$.pipe(
    map(menu => menu?.consumptionMode.orderPreparationSettings.tableServiceEnabled === true)
  );

  isDeliveryEnabled$: Observable<boolean> = this.menu$.pipe(
    map(menu => menu?.consumptionMode.orderDeliverySettings.deliveryEnabled === true)
  );

  showPayments$ = this._authenticationService.isLoggedIn$();

  ngOnInit(): void {

    // en mode Page (pas dans le sideview) on vérifie si le cart a été chargé,
    // si ce n'est pas le cas (ouverture directement sur cette page) alors on charge
    // le cart du currentRestaurant qui est persisté dans le localstorage.
    if (!this.sideViewMode) {
      const cart = this._cartService.getCurrentCart();
      if (!cart) {
        this.restaurant$ // on se base sur l'observable au cas où le restaurant est en cours de chargement (depuis le localstorage par ex)
          .pipe(
            first(),
            switchMap(restaurant => {
              if (restaurant) {
                // load restaurant menu & associated cart (if any)
                return this._menuService.loadMenu$(restaurant.restaurantId);
              } else {
                return of(null);
              }
            }),
            untilDestroyed(this)
          )
          .subscribe(menu => {
            if (!menu) { // pas de restaurant chargé => retour à l'accueil
              console.log('no menu loaded => go home');
              this._navigationService.navigateHome();
            }
          });
      }
    }

    this.cart$
      .pipe(
        tap(cart => {
          //Refresh cartForm whenever cart is updated
          this.updateCartForm(cart);
        }),
        untilDestroyed(this)
      )
      .subscribe();


    this.cart$
      .pipe(
        withLatestFrom(this.menu$),
        filter(([cart, menu]) => cart != null && menu != null),
        tap(([cart, menu]) => {

          //adjust filters & display according Cutlery products presence in menu & cart
          const menuHasCutleryProducts = menu.products.some(p => p.isCutlery);
          const cartHasCutleryProducts = cart.products.some(p => p.product.isCutlery);
          const requiredCutleryAmount = this._cartService.countCartRequiredCutleryAmount(cart);
          const cartHasRequiringCutleryProducts = (requiredCutleryAmount > 0);

          this.mealCount = requiredCutleryAmount;
          this.showCutlery = menuHasCutleryProducts && (cartHasRequiringCutleryProducts || cartHasCutleryProducts);

          const cartHasReturnableContainerProducts = cart.products.some(p => p.product.isReturnableContainer);
          this.showReturnableProductContainers = cartHasReturnableContainerProducts;

          this.nonCutleryNorReturnableContainerProductfilter = this.showCutlery
            ? ((p) => !p.product.isCutlery && !p.product.isReturnableContainer)
            : ((p) => !p.product.isReturnableContainer); // no cutlery filter if panel is not shown


          //get applied promocode after review
          this.appliedPromocode = this._cartService.getCartAppliedPromocode(cart)?.label;
          this.promocodeError = cart?.promocodeError;

          this._cd.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this._orderService.getDefaultDestination$()
      .pipe(
        tap(defaultDestination => {
          // define default destination if not defined
          if (this.cartForm.value?.destination == null) {
            this.cartForm.patchValue({ destination: defaultDestination });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private updateCartForm(cart: Cart) {
    const formValues = {
      payment: cart?.payment,
      comment: cart?.comment,
      deliveryDate: cart?.deliveryDate,
    };

    //specify destination only if defined (to let default value be used)
    if (cart?.destination) {
      formValues['destination'] = cart?.destination;
    }

    this.cartForm.patchValue(formValues);
  }

  openProductEdit(cartProduct: CartProduct) {
    const cartProductEditSheet = this._bottomSheet.open(
      CartProductEditComponent,
      {
        data: this._cartService.getCartProduct$(cartProduct.id),
        panelClass: 'edit-cart-product-sheet-bottom-sheet-container',
      }
    );
    // set output
    const cartProductEditSub = cartProductEditSheet.instance.quantityEdit
      .asObservable()
      .pipe(
        untilDestroyed(this),
        switchMap(event => this._cartService.editProductQuantity$(event.product, event.newQuantity, ProductEventSource.Cart))
      )
      .subscribe();

    // unsubscribe
    cartProductEditSheet
      .afterDismissed()
      .pipe(first())
      .subscribe(() => {
        cartProductEditSub.unsubscribe();
      });
  }

  onAddProductClick() {
    this._navigationService.navigateToDefaultMenu$().subscribe();
  }

  onDeliveryDateChange(selectedDate: Date) {
    this._cartService.updateDeliveryDate(selectedDate);
  }

  onCommentChange(comment: string | null) {
    this._cartService.updateComment(comment);
  }

  onDestinationChange(destination: string | null) {
    this._cartService.updateDestination(destination);
  }

  onAppyPromocode(promocode: string) {
    this._cartService.applyPromocode(promocode);
  }

  onRemovePromocode() {
    this.appliedPromocode = null;
    this._cd.markForCheck();
    this._cartService.removePromocode();
  }

  onDeliveryAddressChange(address: CartDeliveryAddress | null) {
    this._cartService.updateDeliveryAddress(address);
  }

  confirmCart() {

    // TODO test if user is logged => redirect to login page
    const user = this._authenticationService.currentUser;
    const restaurant = this._siteService.getCurrentRestaurant();
    if (!user || !user.restaurants.some(r => r.restaurantId === restaurant.restaurantId)) {
      const redirecturl = this._router.url;
      // HACK setTimeout pour contourner un bug étrange (angular ?) qui fait se recharger l'application une fois la page de login chargée, tout en supprimant la querystring
      // cela ne se reproduit pas depuis les autres pages (comme account)
      // les services Angular ne sont même pas appelés ( Routeur / BrowserPlatformLocation ), ça semble être une redirection plus bas-niveau (location.href) mais ça n'existe pas dans Angular (hors du service BrowserPlatformLocation)
      // => Le SetTimeout semble rétablir un comportement normal
      setTimeout(() => {
        this._toastr.info('Veuillez vous inscrire sur ce site avant de valider cette commande.');
        this._navigationService.navigateToSignInForm(redirecturl);
      }, 0);
      return;
    }

    this.cartForm.markAllAsTouched();
    this.cartForm.updateValueAndValidity();

    if (this.cartForm.invalid) {

      // focus on first invalid control https://stackoverflow.com/questions/53924414/angular-6-reactive-forms-how-to-set-focus-on-first-invalid-input
      setTimeout(() => {
        //search dom for mat-error elements then focus on first
        var errorElements = (this._el?.nativeElement as Element).querySelectorAll('.mat-error');
        if (errorElements.length > 0) {
          (errorElements.item(0) as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        }
      }, 300); // let time for dom errors creation

      return;
    }

    //check if a promocode has been entered but not applied
    const unAppliedPromocode = this.promocodeInput?.unAppliedPromocode;
    if(unAppliedPromocode?.length > 0) {
      //notify user
      this._toastr.info('Veuillez appliquer le code promo avant de valider la commande.');
      this.promocodeInputElement.nativeElement.scrollIntoView({ behavior: 'smooth' });

      return;
    }

    this._isSubmitingOrderSubject.next(true); // notify loading
    this._cartService
      .createOrderFromCart()
      .pipe(
        tap(order => {
          //save DefaultDestination
          this._orderService.setDefaultDestination(order.processingState.destination);
        }),
        switchMap(order => {
          // close panel if any
          this._sideService.close();
          // process payment if needed
          return this._orderService.processOrderActions(order)
            .pipe(
              switchMap((paymentCompleted) => {
                // reinit Cart after command confirmation
                this._cartService.clearCart();

                if (!paymentCompleted) {
                  // HACK to prevent showing the "empty cart" layout when redirecting to payment page :
                  // => show the loader within a delay (enought to do the payment checkout redirection)
                  this._isLoadingSubject.next(true);
                  setTimeout(() => {
                    this._isLoadingSubject.next(false);
                  }, 4000);
                  return of(false);
                }
                else {
                  // reload menu & cart
                  return this._menuService.loadMenu$(null, true, true)
                    .pipe(
                      // then reload payment methods (Balances)
                      tap(() => this._paymentService.reloadCustomerPaymentMethods())
                    );
                }
              })
            );
        }),
        finalize(() => this._isSubmitingOrderSubject.next(false))
      )
      .subscribe();
  }

  onClose() {
    this._sideService.close();
  }

  navigateToMenu() {
    this._navigationService.navigateToDefaultMenu$().subscribe(() => {
      this._sideService.close();
    });
  }

  onPaymentSelected(selectedPayement: CartPayment) {
    this._cartService.updatePayment(selectedPayement);

    if (this._selectPaymentToast?.toastRef) {
      this._selectPaymentToast.toastRef.close();
    }
  }

  onPlaceChanged(event: PlaceSelectorDialogResult) {
    if (event.action === 'ChangeContext') {
      this._navigationService.navigateToMenu$(event.newContext.restaurant).subscribe();
    } else if (event.action === 'GotoSiteSelection') {
      this._navigationService.navigateToSiteSelection();
    }
  }

  onAddCutleryProducts() {
    this._cartService.addCutleryProducts(null, null, null, true);
  }

  onRemoveCutleryProducts() {
    this._cartService.removeCutleryProducts(null, null, true);
  }

  onClearCart(cart: Cart) {

    const confirmChangePlaceDialog = this._dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'CART.DELETE_CART_PROMPT'
      } as ConfirmDialogData
    });

    confirmChangePlaceDialog.afterClosed()
      .pipe(
        untilDestroyed(this),
        map(answer => !!answer), // answer is undefined if user has closed the dialog without using a button
        tap(answer => {
          if (answer) {
            // signout confirmed, signout and navigate to menu
            this._cartService.clearCart();
          }
        })
      )
      .subscribe();
  }

}
