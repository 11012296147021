import { Injectable, SecurityContext } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlDialogComponent } from '../components/html-dialog/html-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MediaQueryService } from '@app/core/layout/media-query.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private _translateService: TranslocoService,
    private _domSanitizer: DomSanitizer,
    private _mediaQueryService: MediaQueryService
  ) { }

  public openDialogFromHtmlPage(
    dialog: MatDialog,
    result: any,
    keyTranslateTitle: string
  ) {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.maxHeight = '90vh';
    const dialogRef = dialog.open(HtmlDialogComponent, this.getConfigFullScreen());
    dialogRef.componentInstance.title = this._translateService.translate(
      keyTranslateTitle
    );
    dialogRef.componentInstance.content = this._domSanitizer.sanitize(
      SecurityContext.HTML,
      result
    );
  }

  public getConfigFullScreen(): MatDialogConfig {
    const isDesktop = this._mediaQueryService.isDesktop();

    let config = new MatDialogConfig();
    if (isDesktop) {
      config.maxHeight = '80vh';
      config.maxWidth = '50vw';
    } else {
      config = {
        position: {
          top: '0',
          left: '0',
        },
        height: '100vh',
        width: '100vw',
        maxWidth: 'none',
        panelClass: 'full-screen-modal',
      };

    }

    return config;
  }
}
