import { Injectable } from '@angular/core';
import {
  OrderProduct,
  OrderProductStep, Product,
  ProductStep, Restaurant, SearchSitesResultItem,
  Site,
  Step,
  StepProduct
} from '../api-client/models';
import { StringHelper } from '../helpers/string-helper';
import { ProductDetailModel } from '../models/product-detail.model';
import { ProductStepModel } from '../models/product-step.model';
import { SiteModel } from '../models/site.model';
import { CartProduct, CartStep } from './cart/models/cart.model';
import { RestaurantContext } from './site/models/restaurant-context.model';

@Injectable({
  providedIn: 'root',
})
export class MapperService {
  constructor() { }

  mapSearchSitesResultToSiteModel(model: SearchSitesResultItem): SiteModel {
    if (!model) {
      return null;
    }

    return {
      address: model.address,
      name: model.name,
      restaurants: model.restaurants,
      siteId: model.siteId,
    };
  }

  mapSiteModelToSite(siteModel: SiteModel): Site {
    if (!siteModel) {
      return null;
    }

    return siteModel; // SiteModel signature matches Site interface
  }

  mapRestaurantToSiteModel(restaurant: Restaurant): SiteModel {
    if (!restaurant) {
      return null;
    }

    return {
      address: restaurant.address,
      name: restaurant.site.name,
      restaurants: [restaurant],
      siteId: restaurant.site.siteId,
    };
  }

  mapRestaurantContextToSiteModel(restaurantContext: RestaurantContext): SiteModel {
    if (!restaurantContext) {
      return null;
    }

    // selected restaurant must be first in SiteModel's restaurants (TODO rework this)
    const restaurants = [restaurantContext.restaurant, ...restaurantContext.site.restaurants.filter(r => r.restaurantId !== restaurantContext.restaurant.restaurantId)];
    return {
      address: restaurantContext.restaurant.address,
      name: restaurantContext.site.name,
      restaurants: restaurants,
      siteId: restaurantContext.site.siteId,
    };
  }

  mapSiteModelToRestaurantContext(siteModel: SiteModel): RestaurantContext {
    if (!siteModel) {
      return null;
    }

    return {
      restaurant: siteModel.restaurants[0], // selected restaurant is the first in SiteModel's restaurants (TODO rework this)
      site: this.mapSiteModelToSite(siteModel)
    };
  }

  toProductDetailModel(model: Product, stepProduct?: StepProduct): ProductDetailModel {
    if (!model) {
      return null;
    }

    return {
      product: model,
      steps: null,
      selected: false,
      productConfig: stepProduct,
      additionalPrice: null,
      quantity: 0
    };
  }

  toProductStepModel(step: Step, productStep: ProductStep): ProductStepModel {
    if (!step) {
      return null;
    }

    return {
      stepConfig: step,
      position: productStep.position,
    };
  }

  toCartProduct(product: ProductDetailModel, filterOnSelectedStepProductsOnly: boolean = false): CartProduct {
    if (!product) {
      return null;
    }

    // cart product's Id is for Cart Updates (quantity/deletion)
    // - for simple products only one instance is allowed use so use productId
    // - for composed products multiple instances can be configured in Cart, so use a uniqueId
    const isComposedProduct = (product.steps?.length > 0);
    const cartProductId = isComposedProduct ? StringHelper.getUniqueIdString() : product.product.productId.toString();

    return {
      id: cartProductId,
      product: product.product,
      quantity: product.quantity,
      steps: product.steps?.map((step) => this.toCartStep(step, filterOnSelectedStepProductsOnly)) || [],
      stepProduct: product.productConfig,
      totalPrice: 0,
      vatPrice: 0,
      unitPrice: 0
    };
  }

  toCartStep(step: ProductStepModel, filterOnSelectedStepProductsOnly: boolean = false): CartStep {
    if (!step) {
      return null;
    }

    return {
      step: step.stepConfig,
      products: step.detailedProducts
        .filter((product) => !filterOnSelectedStepProductsOnly || product.selected)
        .map((product) => this.toCartProduct(product, filterOnSelectedStepProductsOnly)),
    };
  }

  orderProductToCartProduct(product: OrderProduct): CartProduct {
    if (!product) {
      return null;
    }

    return {
      id: null, // useless here (only for cart updates)
      product: {
        productId: product.productId,
        name: product.name,
        price: product.originalUnitPrice,
        vat: product.vat,
      },
      quantity: product.quantity,
      steps: product.steps?.map((step) => this.orderSteptoCartStep(step)),
      stepProduct: { productId: product.productId },
      totalPrice: product.price,
      vatPrice: (product.price - (product.price / (1 + product.vat))),
      unitPrice: product.unitPrice
    };
  }

  orderSteptoCartStep(step: OrderProductStep): CartStep {
    if (!step) {
      return null;
    }

    return {
      step: {
        stepId: step.stepId,
        name: step.name,
        ticketLabel: step.ticketLabel
      },
      products: step.products.map((product) => this.orderProductToCartProduct(product)),
    };
  }

}
