<div class="top-bar"
     fxLayout="row"
     fxLayoutAlign="start center"
     fxFlex="1 1 auto">
  <!-- logo-->
  <app-logo class="top-bar-logo"
            fxFlex="0 1 150px"
            matRipple
            (click)="onHomeClick()"></app-logo>

  <!-- Site & date-->
  <app-menu-context-selector class="top-bar-context-selector"
                             fxFlex="0 0 auto"
                             fxFlexOffset="10px"
                             (placeEditEvent)="onPlaceEdit($event)"
                             [isLoading]="(isLoading$ | async)"
                             [orderingIsOpen]="(orderingIsOpen$ | async)"
                             [deliveryDate]="(deliveryDate$ | async)"
                             (deliveryDateChange)="onDeliveryDateChange($event)"></app-menu-context-selector>

  <!-- filler (white space) -->
  <div fxFlex="2 1 auto"> </div>
  <!-- Mon compte-->
  <button mat-button
          class="top-bar-account-button"
          fxFlex="0 1 auto"
          fxFlexOffset="10px"
          (click)="onAccountClick()">
    <mat-icon svgIcon="account-circle-solid"></mat-icon>
    <span class="account-label"> {{ "ACCOUNT.TITLE" | transloco }}</span>
  </button>
  <!-- Panier-->
  <button mat-icon-button
          class="top-bar-cart-button"
          (click)="onPanelClick()"
          fxFlex="0 0 auto">
    <mat-icon svgIcon="shopping-basket"
              color="primary"
              [matBadge]="(cartBadgeValue$ | async)"
              matBadgeSize="small"
              [matBadgeHidden]="(cartBadgeValue$ | async) < 1"></mat-icon>
  </button>
</div>
