import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Order } from '@app/core/api-client/models';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-receipt-sheet',
  templateUrl: './receipt-sheet.component.html',
  styleUrls: ['./receipt-sheet.component.scss']
})
export class ReceiptSheetComponent implements OnInit {

  @Output() exit = new EventEmitter();

  constructor(
    private bottomSheetRef: MatBottomSheetRef<Order>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Order
  ) {}

  ngOnInit(): void {}

  onExit() {
    this.bottomSheetRef.dismiss();
    this.exit.emit();
  }

}
