<div class="cart-product-total">
  <!-- Sous Total-->
  <div class="cart-product-total"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px"
       *ngIf="showSubTotal">
    <!-- Name-->
    <div>{{ "CART.SUB_TOTAL" | transloco }}</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="totalProductPrice"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>

  <!-- Fees -->
  <div @inOutAnimation
       *ngFor="let fee of fees; trackBy: trackByFeeIdentity"
       class="cart-product-total-light"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px">
    <!-- Name-->
    <div>{{ fee.label }}</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="fee.totalInclTax"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>

  <!-- Discounts -->
  <div @inOutAnimation
       *ngFor="let discount of discounts; trackBy: trackByDiscountIdentity"
       class="cart-product-total-light"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px">
    <!-- Name-->
    <div>{{ discount.label }}</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="-discount.amount"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>

  <!--TOTAl HT à retirer: à discuter-->
  <div *ngIf="withTaxDetails && showVAT"
       class="cart-product-total"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px">
    <!-- Name-->
    <div>Total HT</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="totalExclTaxWithDiscount"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>

  <div *ngIf="withTaxDetails && showVAT">
    <div *ngFor="let taxRate of taxRatesBreakdown"
         class="cart-product-total-light"
         fxLayout="row"
         fxLayoutAlign="space-evenly stretch"
         fxLayoutGap="10px">
      <!-- Name-->
      <div>
        {{ "CART.TAXES" | transloco }} {{ taxRate.taxRate * 100 }} {{ "%" }}
      </div>

      <!-- price && quantity-->
      <div fxFlex
           fxLayoutAlign="end">
        <div>
          <app-price [price]="taxRate.totalTax"
                     [currencyCode]="currencyCode"
                     [floating]="false"></app-price>
        </div>
      </div>
    </div>
  </div>

  <!-- Total -->
  <div class="cart-product-total-big-bold"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px">
    <!-- Name-->
    <div>{{ "CART.TOTAL" | transloco }}</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="totalPrice"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>

  <!-- Taxes -->
  <div *ngIf="!withTaxDetails && showVAT"
       class="cart-product-total-light"
       fxLayout="row"
       fxLayoutAlign="space-evenly stretch"
       fxLayoutGap="10px">
    <!-- Name-->
    <div>{{ "CART.TAXES" | transloco }}</div>

    <!-- price && quantity-->
    <div fxFlex
         fxLayoutAlign="end">
      <div>
        <app-price [price]="totalVatPrice"
                   [currencyCode]="currencyCode"
                   [floating]="false"></app-price>
      </div>
    </div>
  </div>
</div>
