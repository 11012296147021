// import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, OnChanges, Output, SimpleChanges, AfterViewInit, ChangeDetectorRef, Injector, Injectable, forwardRef } from '@angular/core';
import { UntypedFormControl, Validators, Validator, ControlValueAccessor, AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { OrderPreparationSettings } from '@app/core/api-client/models';
import { extractTouchedChanges } from '@app/core/helpers/forms-helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

function parseDestination(destination: string, tableNamePrefix: string | null): string | null {
  // dirty replace
  return destination?.replace(tableNamePrefix ?? '', '');
}

@UntilDestroy()
@Component({
  selector: 'app-order-preparation-destination-input',
  templateUrl: './order-preparation-destination-input.component.html',
  styleUrls: ['./order-preparation-destination-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // component self validates
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrderPreparationDestinationInputComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderPreparationDestinationInputComponent),
      multi: true,
    }
  ],
  // animations: [
  //   trigger(
  //     'errorInOutAnimation',
  //     [
  //       transition(':enter', [
  //         style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
  //         animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
  //       ]),
  //       transition(':leave', [
  //         style({ 'transform-origin': 'left top' }),
  //         animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
  //       ])
  //     ]
  //   )
  // ]
})
export class OrderPreparationDestinationInputComponent implements OnInit, OnChanges, Validator, ControlValueAccessor, AfterViewInit {

  @Input() preparationSettings: OrderPreparationSettings | null = null;

  @Input() destination: string | null = null;

  @Output() destinationChange = new EventEmitter<string | null>();

  @Input() readonly: boolean = false;

  @Input() headerPrompt: string | null = 'TABLE_NUMBER_PROMPT'; //tranlated

  @Input() appearance: MatFormFieldAppearance = 'legacy';

  destinationFormControl = new UntypedFormControl(null, { validators: [Validators.required, Validators.min(1)], updateOn: 'blur' });

  control: AbstractControl;

  private _controlValueAccessorOnChange: (_: any) => void = null;
  private _controlValueAccessorOnTouched: (_: any) => void = null;
  private _validatorChange: (_: any) => void = null;

  constructor(
    private _injector: Injector,
    private _cd: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    // resolve formControl for this instance, using injection AfterViewInit.
    // see: https://stackoverflow.com/a/51126965

    this.control = this._injector.get(NgControl, null)?.control;
    if (this.control) {
      const destinationFormControl = this.destinationFormControl;
      // update component state if FormControl is touched (from Form submition)
      // this.control.statusChanges
      extractTouchedChanges(this.control)
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.validate(this.control);
            destinationFormControl.markAsTouched();
            this._cd.markForCheck();
          })
        )
        .subscribe();

    } else {
      // Component is missing form control binding
    }
  }



  writeValue(obj: any): void {
    const inputValue = parseDestination(obj?.toString(), this.preparationSettings?.tableNamePrefix);
    if (this.destinationFormControl.value !== inputValue) {
      this.destinationFormControl.setValue(inputValue);
    }
  }
  registerOnChange(fn: any): void {
    this._controlValueAccessorOnChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._controlValueAccessorOnTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
  validate(control: AbstractControl): ValidationErrors {
    this.destinationFormControl.valid;
    return this.destinationFormControl.errors;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this._validatorChange = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preparationSettings']) {
      this.updateDestinationFormValidation(this.preparationSettings?.tableServiceMaxTables);
    }

    if (changes['destination']) {
      this.writeValue(this.destination);
    }

    if (changes['readonly']) {
      if (this.readonly) {
        this.destinationFormControl.disable();
      }
      else {
        this.destinationFormControl.enable();
      }
    }
  }

  ngOnInit(): void {
    this.destinationFormControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(_ => this.notifyDestinationChangedByUser())
      )
      .subscribe();
  }

  private notifyDestinationChangedByUser() {
    this.destination = this.destinationFormControl.value?.toString();
    this._controlValueAccessorOnChange?.call(this, this.destination);
    this._controlValueAccessorOnTouched?.call(this);
    this.destinationChange.emit(this.destination);
  }

  private updateDestinationFormValidation(maxValue: number | null) {
    this.destinationFormControl.setValidators(
      maxValue
        ? [Validators.required, Validators.min(1), Validators.max(maxValue)]
        : [Validators.required, Validators.min(1)]
    );
    this._validatorChange?.call(this);
  }

}
