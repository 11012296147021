import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Order, OrderStatus } from '@app/core/api-client/models';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReceiptSheetComponent } from '../receipt-sheet/receipt-sheet.component';

@Component({
  selector: 'app-command-detail-receipt',
  templateUrl: './command-detail-receipt.component.html',
  styleUrls: ['./command-detail-receipt.component.scss'],
})
export class CommandDetailReceiptComponent implements OnInit {
  @Input() order: Order;

  canGetReceipt: boolean;

  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.canGetReceipt = (this.order.status === OrderStatus.Finalized);
  }

  displayTicket() {

    const dismissed$: Observable<any> = (this._bottomSheet._openedBottomSheetRef?.afterDismissed() || of(true));

    dismissed$
      .pipe(first())
      .subscribe(() => {
        setTimeout(() => {
          this._bottomSheet.open(ReceiptSheetComponent, {
            data: this.order,
            panelClass: 'order-detail-sheet-bottom-sheet-container',
          });
        }, 200);
      });

    this._bottomSheet.dismiss();

  }

}
