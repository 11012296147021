<div class="cart-cutlery">

  <mat-divider class="cart-cutlery-divider"></mat-divider>

  <div class="cart-cutlery-prompt-item">
    <div class="cart-cutlery-title">
      <mat-icon svgIcon="utensils"
                color="primary"
                class="cart-cutlery-icon"
                [ngClass]="{'crossed-out':!hasCutleryProducts}"></mat-icon>
      <span class="cart-cutlery-title-text">{{ "CART.CUTLERY.TITLE" | transloco }}</span>
      <span class="cart-cutlery-mealnumber"
            *ngIf="mealCount && mealCount>0">( {{ mealCount }}&nbsp;{{ (mealCount > 1 ? 'CART.CUTLERY.MEALS' : 'CART.CUTLERY.MEAL') | transloco }} )</span>

    </div>

    <!-- <mat-slide-toggle class="cart-cutlery-switch"
                      [color]="'primary'"
                      [checked]="hasCutleryProducts"
                      [disabled]="isReadOnly"
                      (change)="onToggleCutlerySwitch($event.checked)">
    </mat-slide-toggle> -->

    <mat-button-toggle-group name="addCutleryProducts"
                             (change)="onToggleCutlerySwitch(addCutleryBtn.checked)"
                             [disabled]="isReadOnly">
      <mat-button-toggle value="no"
                         class="cart-cutlery-switch-no"
                         [checked]="!hasCutleryProducts">{{ 'COMMON.NO' | transloco }}</mat-button-toggle>
      <mat-button-toggle #addCutleryBtn
                         value="yes"
                         class="cart-cutlery-switch-yes"
                         [checked]="hasCutleryProducts">{{ 'COMMON.YES' | transloco }}</mat-button-toggle>
    </mat-button-toggle-group>

  </div>

  <app-cart-product-list [cartProductList]="cartCutleryProductsList"
                         [currencyCode]="currencyCode"
                         [showTitle]="false"
                         [showZeroPrices]="false"
                         [showDivider]="false"
                         (editCartProductClick)="onEditCartProductClick($event)"
                         [isReadOnly]="isReadOnly"></app-cart-product-list>

  <mat-divider class="cart-cutlery-divider"></mat-divider>

</div>
