import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs'; // TODO lazy load Hammer when needed => https://ngrefs.com/latest/templates/touch-events
import { Injectable } from '@angular/core';

@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { enable: true,  direction: Hammer.DIRECTION_ALL },
    // pinch: { enable: false },
    // rotate: { enable: false },

  };
}
