import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./html-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlContentComponent implements OnInit {
  @Input() data: string;
  @Input() ellipsis: number | null = null;
  dataHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dataHtml = this.sanitizer.bypassSecurityTrustHtml(
      this.trimEmptyTags(this.data)
    );
  }

  trimEmptyTags(data: string): string {
    if (!data) {
      data = '';
    }
    // TODO : améliorer et mettre en propre cette méthode
    const trimedElements = ['<p></p>', '<br/>', '<div></div>', '<span></span>'];
    let somethingToTrim: boolean;
    data = data.trim();
    do {
      somethingToTrim = false;

      trimedElements.forEach((elem) => {
        // supprimer du début
        if (data.startsWith(elem)) {
          somethingToTrim = true;
          data = data.slice(elem.length, data.length - 1);
        }

        // supprimer de la fin
        if (data.endsWith(elem)) {
          somethingToTrim = true;
          data = data.slice(0, data.length - elem.length - 1);
        }
      });
    } while (somethingToTrim);

    if (this.ellipsis) {
      data = (data.length > this.ellipsis)
        ? data.slice(0, this.ellipsis) + '...'
        : data;
    }

    return data;
  }
}
