
import { Location } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, share, shareReplay } from 'rxjs/operators';
import { AnalyticsDimensions } from './models/analytics-dimensions.model';
import { AnalyticsEvent } from './models/analytics-event.model';
import { AnalyticsProvider } from './providers/analytics-provider';


export const ANALYTICS_PROVIDERS = new InjectionToken<AnalyticsProvider>('ANALYTICS_PROVIDERS');

@Injectable()
export class AnalyticsService {
  private dimensions: AnalyticsDimensions = {};

  // private subjects
  private setUserIdSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private setCorrelationIdSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);

  private pageTrackSubject: Subject<{ title?: string, path: string, dimensions: AnalyticsDimensions }> = new Subject();
  private eventTrackSubject: Subject<{ event: AnalyticsEvent, dimensions: AnalyticsDimensions }> = new Subject();

  // Exposed subscribable events
  public pageTrack$ = this.pageTrackSubject.asObservable().pipe(distinctUntilChanged(this.deepCompare.bind(this)), share());
  public eventTrack$ = this.eventTrackSubject.asObservable().pipe(distinctUntilChanged(this.deepCompare.bind(this)), share());
  public setUserId$ = this.setUserIdSubject.asObservable().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
  public setCorrelationId$ = this.setCorrelationIdSubject.asObservable().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private _location: Location,
    @Inject(ANALYTICS_PROVIDERS) providers: AnalyticsProvider[]
  ) {

    // On link les childrens aux events (méthode générique, il suffit de fournir l'instance courante du service)
    for (const provider of providers) {
      provider.subscribeToMainService(this);
    }

  }

  /** Public methods */
  public trackEvent(event: AnalyticsEvent) {
    this.eventTrackSubject.next({
      event,
      dimensions: this.dimensions
    });
  }

  public setUserId(userId: string) {
    this.dimensions.userId = userId;
    this.setUserIdSubject.next(userId);
  }

  public setCorrelationId(correlationId: string) {
    this.dimensions.correlationId = correlationId;
    this.setCorrelationIdSubject.next(correlationId);
  }

  public setDimensionProperties(dimensionValues: any) {
    if (dimensionValues != null) {
      // eslint-disable-next-line guard-for-in
      for (const dimensionProp in dimensionValues) {
        this.dimensions[dimensionProp] = dimensionValues[dimensionProp];
      }
    }
  }

  public clearDimensionProperties() {
    this.dimensions = {};
  }

  public trackUrlChange(urlChange: { url: string, pageTitle?: string }) {
    this.pageTrackSubject.next(
      {
        path: this._location.prepareExternalUrl(urlChange.url),
        title: urlChange.pageTitle,
        dimensions: this.dimensions
      });
  }

  private deepCompare<T>(object: T, base: T): boolean {
    // cheap deep-comparison. see:https://stackoverflow.com/a/44446930/590741
    return JSON.stringify(object) === JSON.stringify(base);
  }

}
