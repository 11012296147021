import { ComponentPortal, Portal } from '@angular/cdk/portal';
import {
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef, Component,
  OnInit
} from '@angular/core';
import {
  PortalComponentData, SideService
} from '@app/core/layout/side.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-side-container',
  templateUrl: './side-container.component.html',
  styleUrls: ['./side-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideContainerComponent implements OnInit, AfterViewInit {
  component: PortalComponentData;

  isSideOpened$ = this._sideService.isSideOpened$();

  drawerPortal: Portal<any>;
  drawerPortal$: Observable<Portal<any>>;

  constructor(
    private _sideService: SideService,
    private _cd: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this._sideService
      .getComponent$()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((sideComponent) => {
        if (!sideComponent) {
          return;
        }
        this.component = sideComponent;

        // HACK : utiliser le setTimout pour countourner le bug => Expression has changed after it was checked...
        setTimeout(() => {
          this.drawerPortal = new ComponentPortal(this.component.component);
          this._cd.markForCheck();
        }, 0);
      });
  }

  ngOnInit(): void {
  }

}
