import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwileSigninResponse } from '@app/core/api-client';
import { StringHelper } from '@app/core/helpers/string-helper';
import { OrderService } from '@app/core/services/order/order.service';
import { first, switchMap } from 'rxjs';

@Component({
  templateUrl: './swile-signin-page.component.html',
  styleUrls: ['./swile-signin-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwileSigninPageComponent implements OnInit {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _orderService: OrderService
  ) { }

  ngOnInit(): void {
    const route = this._activatedRoute.snapshot;
    this.processSwileSignInCallback();
  }


  private processSwileSignInCallback() {

    console.log('processSwileSignInCallback()', this._activatedRoute.snapshot.queryParams);

    const payload: SwileSigninResponse = {
      queryString: StringHelper.toQuerystring(this._activatedRoute.snapshot.queryParams)
    };

    // call edenred signin & pay then process order's remainings actions (other checkouts or display order confirmation)
    this._orderService.signinSwileAndPay(payload)
      .pipe(
        first(),
        switchMap(order => this._orderService.processOrderActions(order)),
        // untilDestroyed(this) //ne pas canceller si la page est fermée (puisque l'on redirige vers la page de menu via processOrderActions )
      ).subscribe();

  }


}
