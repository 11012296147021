import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { SessionStorageProvider } from '../storage/session-storage-provider';

interface WhiteMarkServiceStoredSettings {
  isEmbedded: boolean;
}

const WhiteMarkServiceStoredSettingsKey = 'WhiteMarkServiceStoredSettings';
@Injectable({
  providedIn: 'root'
})
export class WhiteMarkService {

  private _embeddedSubject$: BehaviorSubject<boolean>;
  private _isEmbedded$: Observable<boolean>;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _sessionStorage: SessionStorageProvider
  ) {
    const isInIframe = this.isInIframe();
    // const settings = this._sessionStorage.getObject(WhiteMarkServiceStoredSettingsKey) as WhiteMarkServiceStoredSettings | null;

    // console.log('isInIframe : ', isInIframe);
    // console.log('WhiteMarkServiceStoredSettings : ', settings);

    // // Init IsEbedded from session settings if any or from Iframe detection
    // const isEmbeddedDefaultValue = (settings?.isEmbedded != null) ? settings.isEmbedded : isInIframe;
    // finally use only IFrame detection to prevent conflict by using the same browser tab across embed version and standalone version
    const isEmbeddedDefaultValue = isInIframe;
    this._embeddedSubject$ = new BehaviorSubject<boolean>(isEmbeddedDefaultValue);
    this._isEmbedded$ = this._embeddedSubject$.asObservable().pipe(distinctUntilChanged());
    // this.storeSessionSetting(isEmbeddedDefaultValue);
  }

  // private storeSessionSetting(isEmbedded: boolean) {
  //   this._sessionStorage.setObject(WhiteMarkServiceStoredSettingsKey, { isEmbedded: isEmbedded } as WhiteMarkServiceStoredSettings);
  // }

  enableEmbedded() {
    this._embeddedSubject$.next(true);
    // this.storeSessionSetting(true);
  }

  disableEmbedded() {
    this._embeddedSubject$.next(false);
    // this.storeSessionSetting(false);
  }

  isEmbedded$(): Observable<boolean> {
    return this._isEmbedded$;
  }

  public get isEmbedded(): boolean { return this._embeddedSubject$.value; }

  private isInIframe() {
    const windowRef = this._document.defaultView;
    try {
      return windowRef.self !== windowRef.top;
    } catch (e) {
      return true;
    }
  }
}
