import { OnInit, Inject, PLATFORM_ID, Directive, OnDestroy } from '@angular/core';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { filter, tap, first } from 'rxjs/operators';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import { HostListener } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


let BackBottomSheetId = 0;

// Pour avoir un comportement correct des classes héritée.
// sur la classe fille :
//   1. appeler ce constructeur parent => super(...)
//   2. executer la méthode ngOnInit => super.ngOnInit();

@UntilDestroy()
@Directive()
export class BackBottomSheet<T> implements OnInit, OnDestroy {

  protected removeStateOnDestroy = false;
  private _stateReference = { backBottomSheetId: ++BackBottomSheetId };

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.removeDummyHistoryState();
    this.bottomSheetRef.dismiss(true);
  }

  constructor(
    protected bottomSheetRef: MatBottomSheetRef<T, boolean>,
    protected router: Router,
    @Inject(PLATFORM_ID) protected _platformId: object) {

    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      first(),
      tap(() => {
        this.removeDummyHistoryState();
        this.bottomSheetRef.dismiss(true);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      history.pushState(this._stateReference, document.getElementsByTagName('title')[0].innerHTML, window.location.href);
    }
  }

  ngOnDestroy(): void {
    this.removeDummyHistoryState();
  }

  private removeDummyHistoryState() {
    if (this.removeStateOnDestroy
      && isPlatformBrowser(this._platformId)
      && history.state.backBottomSheetId === this._stateReference.backBottomSheetId) {
      history.back();
    }
  }

}

