import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Network } from '@ngx-pwa/offline';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProblemDetails, ValidationProblemDetails } from '../api-client/models';
import { ErrorsHelper } from '../helpers/errors-helper';
import { ModelsHelper } from '../helpers/models-helper';
import { Logger, LogService } from '../logging';
import { ErrorModel, ErrorSeverity } from './models/error-model.model';

const chunkFailedMessageRegex = /Loading chunk [\d]+ failed/;

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private _logger: Logger;

  // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
  constructor(
    private _translateService: TranslocoService,
    private _network: Network,
    @Inject(DOCUMENT) private _document: Document,
    logService: LogService,
  ) {
    this._logger = logService.getLogger('ErrorHandlingService');
  }


  formatError(error: unknown): Observable<ErrorModel> {
    if (!environment.production) {
      console.error(error);
    }

    if (error instanceof HttpErrorResponse) {

      this._logger.error(`${error.status} - ${error.message} - ${JSON.stringify(error.error)}`);

      // Server or connection error happened
      if (!this._network.online) {
        // Handle offline error
        return this.handleOfflineError();
      } else {
        // Handle Http Error (error.status === 403, 404...)
        return this.handleHttpError(error);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      return this.handleClientError(error);
    }
  }


  private buildTranslatedError(keyTitle: string, keyMessage: string, subMessage: string = null, severity: ErrorSeverity = ErrorSeverity.Error)
    : Observable<ErrorModel> {

    return this._translateService.selectTranslate([keyTitle, keyMessage])
      .pipe(
        map(translation => ({
          title: translation[0],
          message: translation[1] + (subMessage ? `\n${subMessage}` : ''),
          severity: severity
        } as ErrorModel))
      );
  }

  private handleOfflineError(): Observable<ErrorModel> {
    return this.buildTranslatedError('ERRORS.OFFLINE.TITLE', 'ERRORS.OFFLINE.MESSAGE', null, ErrorSeverity.Information);
  }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorModel> {

    const errorModel = error.error;

    const problem: ProblemDetails = (errorModel && ModelsHelper.isProblemDetail(errorModel)) ? errorModel : null;

    switch (error.status) {
      case HttpStatusCode.BadRequest: {
        return this.handleBadRequestError(error);
      }
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.Forbidden: {

        if (problem) {
          return of({
            message: problem.title,
            severity: ErrorSeverity.Error
          });
        } else {
          return this.buildTranslatedError(
            'ERRORS.UNAUTHORIZED.TITLE',
            'ERRORS.UNAUTHORIZED.MESSAGE'
          );
        }
      }
      case HttpStatusCode.GatewayTimeout: {
        return this.buildTranslatedError(
          'ERRORS.GATEWAY_TIMEOUT.TITLE',
          'ERRORS.GATEWAY_TIMEOUT.MESSAGE'
        );
      }
      default:
        if (problem) {
          return of({
            message: problem.title,
            severity: ErrorSeverity.Error
          });
        } else {
          return this.buildTranslatedError(
            'ERRORS.APP.TITLE',
            'ERRORS.APP.MESSAGE'
          );
        }
    }
  }

  private handleBadRequestError(error: HttpErrorResponse): Observable<ErrorModel> {

    const errorModel = error.error;

    const problem: ProblemDetails = (errorModel && ModelsHelper.isProblemDetail(errorModel)) ? errorModel : null;
    const validationProblem: ValidationProblemDetails = (problem && ModelsHelper.isValidationProblemDetail(problem)) ? problem : null;

    if (validationProblem) {
      let errorContent = '';

      // format errors
      // eslint-disable-next-line guard-for-in
      for (const propertyKey in validationProblem.errors) {
        const errors = validationProblem.errors[propertyKey];
        const errorsString = (errors.length > 1)
          ? errors.reduce((prev, current) => `${prev}\n    - ${current}`, '\n')
          : (errors.length === 1) ? errors[0] : '';

        errorContent = `${propertyKey} : ${errorsString}`;
      }

      return of({
        title: problem.title,
        message: errorContent,
        severity: ErrorSeverity.Error
      });
    }
    else if (problem) {
      return of({
        message: problem.title,
        severity: ErrorSeverity.Error
      });
    }
    else {
      return of({
        message: error.message,
        severity: ErrorSeverity.Error
      });
    }
  }

  private handleClientError(error: unknown): Observable<ErrorModel> {

    let errorMessage: string | null = null;

    if (ErrorsHelper.isError(error)) {
      errorMessage = error.message;
      this._logger.error(ErrorsHelper.DumpError(error));
    }
    else {
      errorMessage = error?.toString();
      this._logger.error(errorMessage);
    }

    // handle web app updated on server so lazy loaded chunk are 404
    if (chunkFailedMessageRegex.test(errorMessage)) {

      return this.buildTranslatedError(
        'APP_UPDATE.TITLE',
        'APP_UPDATE.TEXT'
      ).pipe(
        map(errorModel => {

          //reload browser
          errorModel.resolve = () => {
            this._document.location.reload();
          };
          errorModel.severity = ErrorSeverity.Information;

          return errorModel;
        })
      );

    }


    return this.buildTranslatedError(
      'ERRORS.APP.TITLE',
      'ERRORS.APP.MESSAGE',
      errorMessage
    );
  }



}
