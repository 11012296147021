<div class="reset-credentials-container"
     fxLayout="column"
     fxLayoutAlign="start stretch">

  <span class="reset-credentials-description"
        [innerHtml]="'RESET_CREDENTIALS.DESCRIPTION' | transloco"></span>

  <!--content-->
  <form content
        [formGroup]="resetCredentialsForm"
        class="reset-credentials-form"
        (ngSubmit)="onResetCredentialsClick()"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        autocomplete="off">

    <!-- Email-->
    <mat-form-field class="field">
      <input type="email"
             formControlName="email"
             matInput
             [appAutofocus]="true"
             [placeholder]="'RESET_CREDENTIALS.NEW_EMAIL' | transloco"
             autocomplete="off" />
      <mat-icon matSuffix
                color="accent"
                svgIcon='at'></mat-icon>
      <mat-error *ngIf="resetCredentialsForm.controls['email'].hasError('required')"> {{ "SIGNIN.ERRORS.EMAIL_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="resetCredentialsForm.controls['email'].hasError('email')"> {{ "SIGNIN.ERRORS.EMAIL_FORMAT" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Email confirmtion-->
    <mat-form-field class="field emailConfirmation-field">
      <input type="email"
             formControlName="emailConfirmation"
             matInput
             [placeholder]="'RESET_CREDENTIALS.NEW_EMAIL_CONFIRMATION' | transloco"
             autocomplete="off" />
      <mat-icon matSuffix
                color="accent"
                svgIcon='at'></mat-icon>
      <mat-error *ngIf="resetCredentialsForm.controls['emailConfirmation'].hasError('required')"> {{ "SIGNIN.ERRORS.EMAIL_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="resetCredentialsForm.controls['emailConfirmation'].hasError('email')"> {{ "SIGNIN.ERRORS.EMAIL_FORMAT" | transloco }}</mat-error>
      <mat-error *ngIf="resetCredentialsForm.controls['emailConfirmation'].hasError('isMatching')"> {{ "RESET_CREDENTIALS.ERRORS.NEW_EMAIL_CONFIRMATION_MATCHES" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Password-->
    <mat-form-field class="field">
      <input matInput
             formControlName="password"
             [placeholder]="'RESET_CREDENTIALS.NEW_PASSWORD' | transloco"
             [type]="showPassword ? 'text' : 'password'"
             autocomplete="new-password" />
      <mat-icon matSuffix
                color="accent"
                class="show-password-icon"
                (click)="showPassword = !showPassword"
                [svgIcon]="showPassword ? 'unlock' : 'lock'"></mat-icon>
      <mat-error *ngIf="resetCredentialsForm.controls['password'].hasError('required')"> {{ "COMMON.ERRORS.PASSWORD_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="resetCredentialsForm.controls['password'].hasError('minlength')
                        && !resetCredentialsForm.controls['password'].hasError('required')"> {{ "COMMON.ERRORS.PASSWORD_MIN_LENGTH" | transloco }}</mat-error>
      <mat-error style="padding-bottom: 5px;"
                 *ngIf="resetCredentialsForm.controls['password'].hasError('pattern')
                        && !resetCredentialsForm.controls['password'].hasError('required')
                        && !resetCredentialsForm.controls['password'].hasError('minlength')">{{ "COMMON.ERRORS.PASSWORD_PATTERN" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Password confirmation-->
    <mat-form-field class="field">
      <input matInput
             formControlName="passwordConfirmation"
             [placeholder]="'RESET_CREDENTIALS.NEW_PASSWORD_CONFIRMATION' | transloco"
             [type]="showPassword ? 'text' : 'password'"
             autocomplete="new-password" />
      <mat-icon matSuffix
                color="accent"
                class="show-password-icon"
                (click)="showPassword = !showPassword"
                [svgIcon]="showPassword ? 'unlock' : 'lock'"></mat-icon>
      <mat-error *ngIf="resetCredentialsForm.controls['passwordConfirmation'].hasError('required')"> {{ "COMMON.ERRORS.PASSWORD_REQUIRED" | transloco }}</mat-error>
      <mat-error *ngIf="resetCredentialsForm.controls['passwordConfirmation'].hasError('isMatching')"> {{ "RESET_CREDENTIALS.ERRORS.NEW_PASSWORD_CONFIRMATION_MATCHES" | transloco }}</mat-error>
    </mat-form-field>

    <!-- Buttons-->
    <div class="buttons-section"
         fxLayout="column"
         fxLayoutGap="10px">

      <button class="full-width"
              mat-flat-button
              color="accent"
              [disabled]="inProgress">
        {{ "COMMON.CONFIRM" | transloco }}
        <app-loader *ngIf="inProgress"></app-loader>
      </button>
      <button type="button"
              class="full-width"
              mat-button
              color="accent"
              (click)="onCancelClick()"
              *ngIf="canCancel">
        {{ "COMMON.CANCEL" | transloco }}
      </button>
    </div>
  </form>

</div>
