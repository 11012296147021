import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, REDIRECT_URL_QUERYPARAM } from '@app/core/authentication/authentication.service';
import { NavigationService } from '@app/core/services/navigation.service';
import { finalize, take } from 'rxjs/operators';
import { LoginInput } from '../../models/login-input';

@Component({
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SigninPageComponent implements OnInit {
  private _backLinkUrl?: string = null;
  private _redirectUrl?: string;
  isLoading: boolean;
  showGoToMenuButton: boolean = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    private _navigationService: NavigationService
    // private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(
        (params) => {
          this._backLinkUrl = window.history.state?.link;
          this._redirectUrl = params[REDIRECT_URL_QUERYPARAM];

          // display go to menu only if no redirection is required after signin
          this.showGoToMenuButton = !(this._backLinkUrl || this._redirectUrl);
        });
  }

  signIn(loginInput: LoginInput) {
    this.startLoading();

    this._authenticationService
      .signIn$(loginInput.email, loginInput.password)
      .pipe(finalize(() => this.finishLoading()))
      // .pipe(
      //   catchError(error => {
      //     if (!this.signInFail(error)) {
      //       throw error; // rethrow to handle in global error handler
      //     }
      //     return of(false);
      //   })
      // )
      // .subscribe((succeed) => succeed && this.signInSuccess());
      .subscribe(() => this.signInSuccess());

  }

  signUp() {
    this._navigationService.navigateToRegisterOnCurrentRestaurant$().subscribe();
  }

  private signInSuccess() {
    if (this._redirectUrl) {
      this._router.navigateByUrl(this._redirectUrl);
    } else {
      if (this._backLinkUrl) {
        this._router.navigateByUrl(this._backLinkUrl);
      } else {
        // redirect to current restaurant menu or default
        this._navigationService.navigateToDefaultMenu$().subscribe();
      }
    }
  }

  private startLoading() {
    this.isLoading = true;
    this._cd.markForCheck();
  }

  private finishLoading() {
    this.isLoading = false;
    this._cd.markForCheck();
  }

  goToMenu() {
    this._navigationService.navigateToSiteSelection();
  }

  goBack() {
    this._navigationService.back();
  }
  // /*
  //  * Check and display the right error message in the UI
  //  */
  // private signInFail(failResponse): boolean {
  //   if (failResponse instanceof HttpErrorResponse && failResponse.status === StatusCodes.UNAUTHORIZED) {
  //     let errorMsg = '';
  //     if (
  //       failResponse.error &&
  //       failResponse.error.crossDomain &&
  //       failResponse.error.title.indexOf('Access-Control-Allow-Origin') !== -1
  //     ) {
  //       errorMsg = failResponse.error.title;
  //     }
  //     else if (
  //       failResponse.status === 403 &&
  //       failResponse.message.indexOf('Invalid CSRF-token') !== -1 // TODO implémenter le CSRF
  //     ) {
  //       errorMsg = 'LOGIN.MESSAGES.LOGIN-ERROR-CSRF';
  //     } else if (
  //       failResponse.status === 403 &&
  //       failResponse.message.indexOf('The system is currently in read-only mode') !== -1
  //     ) {
  //       errorMsg = 'LOGIN.MESSAGES.LOGIN-ECM-LICENSE';
  //     }
  //     else {
  //       errorMsg = failResponse.error.title || 'LOGIN.MESSAGES.LOGIN-ERROR-CREDENTIALS';
  //     }

  //     this.toastr.error(errorMsg);
  //     return true; // handled
  //   }
  //   return false; // not handled
  // }

}
