export enum AppRoutes {
  default = '',
  signin = 'connexion',
  register = 'register',
  sandbox = 'sandbox',
  forgotPassword_FR = 'mot-de-passe-oublie',
  forgotPassword = 'lost-password',
  resetPassword = 'reset-password',
  menu = 'menu',
  restaurant = 'restaurant',
  siteSearch = 'sites',
  cart = 'cart',
  orderBase_FR = 'commande',
  ordersBase_FR = 'commandes',
  ordersBase = 'orders',
  orderDetail = 'detail',
  orderList = '',
  orderPaymentCallback = 'retour-paiement',
  orderEdenredSigninCallback = 'edenred/login',
  orderEdenredSignoutCallback = 'edenred/logout',
  orderSwileSigninCallback = 'swile/login',

  myPaymentMethods = 'account/payment-methods',
  account = 'account',
  productSearch= 'product-search',
  notfound = 'notfound'
}
