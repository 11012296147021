export interface ErrorModel {
  title?: string;
  message?: string;
  details?: string;
  severity: ErrorSeverity;
  // TODO customIcon, shouldRetryLater ...

  // optional action to invoke when error is handled
  resolve?: () => void;
}


export enum ErrorSeverity {
  Information,
  Warning,
  Error
}
