import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Order, OrderDiscount, OrderFee, TaxRatesBreakdown } from '@app/core/api-client/models';
import { CartProduct } from '@app/core/services/cart/models/cart.model';

@Component({
  selector: 'app-cart-product-total',
  templateUrl: './cart-product-total.component.html',
  styleUrls: ['./cart-product-total.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'scaleY(0.1)', 'transform-origin': 'left top', height: '0px' }),
          animate('200ms', style({ transform: 'scaleY(1)', height: 'auto' })),
        ]),
        transition(':leave', [
          style({ 'transform-origin': 'left top' }),
          animate('100ms', style({ height: '0px', transform: 'scaleY(0.1)' }))
        ])
      ]
    )
  ]
})
export class CartProductTotalComponent implements OnInit, OnChanges {
  @Input() discounts: OrderDiscount[];
  @Input() fees: OrderFee[];
  @Input() taxRatesBreakdown: TaxRatesBreakdown[];
  @Input() cartProductList: CartProduct[];
  @Input() totalVatPrice: number;
  @Input() withTaxDetails: boolean;
  @Input() totalPrice: number;
  @Input() totalExclTaxWithDiscount: number;
  @Input() currencyCode: string | null;
  @Input() showVAT: boolean | null = true;

  totalProductPrice: number;
  showSubTotal: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (changes['cartProductList']) {
      // compute totalProductPrice
      this.totalProductPrice = this.cartProductList.reduce((sum, current) => sum + current.totalPrice, 0);

      //hide subtotal if price is the same and there is no fee/discount
      this.showSubTotal = (this.totalProductPrice != this.totalPrice)
        || this.fees?.length > 0
        || this.discounts?.length > 0;
    }
  }

  trackByFeeIdentity(index: number, fee: OrderFee): any {
    return fee.label;
  }

  trackByDiscountIdentity(index: number, discount: OrderDiscount): any {
    return discount.label;
  }
}
