import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { CommandRoutingModule } from './command-routing.module';
import { CommandDetailDeliveryComponent } from './components/command-detail-delivery/command-detail-delivery.component';
import { CommandDetailReceiptComponent } from './components/command-detail-receipt/command-detail-receipt.component';
import { CommandDetailSheetComponent } from './components/command-detail-sheet/command-detail-sheet.component';
import { CommandDetailStateComponent } from './components/command-detail-state/command-detail-state.component';
import { CommandDetailComponent } from './components/command-detail/command-detail.component';
import { CommandListItemTemplateComponent } from './components/command-list-item/command-list-item-template/command-list-item-template.component';
import { CommandListItemComponent } from './components/command-list-item/command-list-item.component';
import { CommandListTemplateComponent } from './components/command-list/command-list-template/command-list-template.component';
import { CommandListComponent } from './components/command-list/command-list.component';
import { ReceiptSheetComponent } from './components/receipt-sheet/receipt-sheet.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { CommandListPageComponent } from './pages/command-list-page/command-list-page.component';
import { PaymentCallbackPageComponent } from './pages/payment-callback-page/payment-callback-page.component';
import { EdenredSigninPageComponent } from './pages/edenred-signin-page/edenred-signin-page.component';
import { CommandPaymentListComponent } from './components/command-payment-list/command-payment-list.component';
import { CommandPaymentItemComponent } from './components/command-payment-item/command-payment-item.component';
import { TableServiceModule } from '../table-service/table-service.module';
import { CommandTrackOrderBarComponent } from './components/command-track-order-bar/command-track-order-bar.component';
import { SwileSigninPageComponent } from './pages/swile-signin-page/swile-signin-page.component';

@NgModule({
  declarations: [
    CommandDetailComponent,
    CommandDetailStateComponent,
    CommandDetailDeliveryComponent,
    CommandListItemComponent,
    CommandListPageComponent,
    CommandListComponent,
    CommandDetailSheetComponent,
    CommandListItemTemplateComponent,
    CommandListTemplateComponent,
    ReceiptComponent,
    ReceiptSheetComponent,
    CommandDetailReceiptComponent,
    PaymentCallbackPageComponent,
    EdenredSigninPageComponent,
    CommandPaymentListComponent,
    CommandPaymentItemComponent,
    CommandTrackOrderBarComponent,
    SwileSigninPageComponent
  ],
  imports: [
    CommonModule,
    CommandRoutingModule,
    SharedModule,
    TranslocoModule,
    TableServiceModule
    // ContentLoaderModule
  ],
  exports: [CommandDetailSheetComponent]
})
export class CommandModule {}
