<div class="cart-product-tile"
     fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="10px"
     (click)="onEditCartProductClick()">
  <!-- Image -->
  <div *ngIf="cartProduct.product.image"
       class="cart-product-tile-image"
       [ngStyle]="{'background-image': 'url(\'' + cartProduct.product.image?.fullUrl + '\')'}"></div>
  <!-- name && description -->
  <div fxLayout="column"
       fxLayoutAlign="start start"
       LayoutGap="5px">
    <!-- Name-->
    <app-html-content class="cart-product-tile-name"
                      [data]="cartProduct.product.name"
                      [ellipsis]="55"></app-html-content>
    <div class="cart-product-detail-recap">
      <app-product-recap *ngIf="cartProduct"
                         [cartProduct]="cartProduct"></app-product-recap>
    </div>
  </div>
  <!-- price && quantity -->
  <div fxLayout="column"
       fxLayoutAlign="space-evenly end"
       fxFlex
       fxLayoutGap="5px">
    <div class="cart-product-tile-price" *ngIf="showZeroPrices || cartProduct.totalPrice > 0">
      <app-price [price]="cartProduct.totalPrice"
                 [floating]="false"
                 [currencyCode]="currencyCode"></app-price>
    </div>
    <div class="cart-product-tile-quantity">x {{ cartProduct.quantity }}</div>
  </div>
</div>

<mat-divider class="cart-product-tile-divider" *ngIf="showDivider"></mat-divider>
