import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-cart-promocode',
  templateUrl: './cart-promocode.component.html',
  styleUrls: ['./cart-promocode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartPromocodeComponent implements OnInit, OnChanges {

  @Input() appliedPromocode: string | null = null;
  @Input() readonly: boolean = false;
  @Input() isLoading: boolean = false;

  @Input() promocodeError: string | null = null;

  @Output() applyPromocode = new EventEmitter<string>();
  @Output() removePromocode = new EventEmitter();

  form = this._fb.group({
    promocode: this._fb.control<string | null>(
      null,
      {
        validators: [
          ((c: AbstractControl): ValidationErrors | null => {
            return this.promocodeError ? { promocodeError: { error: this.promocodeError } } : null;
          })
        ],
        // updateOn: 'blur'
      }
    )
  });
  promocodeInputControl = this.form.get('promocode') as FormControl;

  get canInputPromocode() {
    return !this.appliedPromocode;
  }

  get canApplyPromocode() {
    return !this.appliedPromocode && this.getInputPromocode()?.length > 0;
  }

  get canRemovePromocode() {
    return !!this.appliedPromocode;
  }

  get unAppliedPromocode() {
    return !this.appliedPromocode && this.getInputPromocode();
  }

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.promocodeInputControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter((value) => this.promocodeError != null && value?.length > 0),
      )
      .subscribe(() => {
        this.promocodeError = null;
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
        this._cd.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If there is an applied promocode, the input should be disabled
    if (this.readonly || this.appliedPromocode) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    if (changes['appliedPromocode']) {
      if (!this.promocodeError) {
        // update/reset applied promocode only if there is no error
        this.promocodeInputControl.setValue(this.appliedPromocode);
      }
    }

    if (changes['promocodeError']) {
      if (this.promocodeError) {
        this.promocodeInputControl.reset(); //clear input

        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
      } else {
        this.form.updateValueAndValidity();
      }
    }
  }

  onSubmit() {
    if (this.canApplyPromocode) {
      this.promocodeError = null;
      this.applyPromocode.emit(this.getInputPromocode());
    } else if (this.canRemovePromocode) {
      this.removePromocode.emit();
    }
  }

  private getInputPromocode(): string | null {
    //sanitize input
    const promocode = this.promocodeInputControl.value?.trim();
    return promocode?.length > 0 ? promocode : null;
  }

}
