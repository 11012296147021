<div class="back-button-container" [ngStyle]="style" [ngClass]="{'fixed': fixed}">
  <button
    type="button"
    class="back-button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="onGoBack()"
  >
    <mat-icon fxHide.gt-xs>arrow_back</mat-icon>
    <mat-icon fxHide fxShow.gt-xs>{{ stopNavigation ? "close" : "arrow_back" }}</mat-icon>
  </button>
</div>
