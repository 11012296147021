import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '@app/core/services/order/order.service';
import { first, switchMap } from 'rxjs/operators';
import { EdenredSigninResponse } from '@app/core/api-client/models';
import { StringHelper } from '@app/core/helpers/string-helper';

@Component({
  templateUrl: './edenred-signin-page.component.html',
  styleUrls: ['./edenred-signin-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EdenredSigninPageComponent implements OnInit {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _orderService: OrderService
  ) { }

  ngOnInit(): void {
    const route = this._activatedRoute.snapshot;
    this.processEdenredSignInCallback();
  }


  private processEdenredSignInCallback() {

    const payload: EdenredSigninResponse = {
      queryString: StringHelper.toQuerystring(this._activatedRoute.snapshot.queryParams)
    };

    // call edenred signin & pay then process order's remainings actions (other checkouts or display order confirmation)
    this._orderService.signinEdenredAndPay(payload)
      .pipe(
        first(),
        switchMap(order => this._orderService.processOrderActions(order)),
        // untilDestroyed(this) //ne pas canceller si la page est fermée (puisque l'on redirige vers la page de menu via processOrderActions )
      ).subscribe();

  }

}
